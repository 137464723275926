import axios from "axios";
export const BASE_IMAGE_URL = process.env.REACT_APP_BASE_IMAGE_URL;
axios.defaults.baseURL = process.env.REACT_APP_API_KEY;

export const getApi = async (routeName) => {
  try {
    const response = await axios.get(routeName, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const postApi = async (routeName, data) => {
  try {
    const response = await axios.post(routeName, data, {
      headers: { "Content-Type": "application/json" }
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
