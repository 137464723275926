import React, { useEffect, useState } from "react";
import { useFeedsColumnContracts } from "../../../utils/helpers/tableHelpers/useFeedColumnContracts";
import { useFeedsColumnBills } from "../../../utils/helpers/tableHelpers/useFeedColumnBills";
import DashboardCard from "../../common/DashboardCard";
import { VENDOR_APPROVE, VENDOR_REJECT } from "../../../utils/constant";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";
import { useFeedsColumnIndividualBills } from "../../../utils/helpers/tableHelpers/useFeedColumnIndividualBill";
import { fetchBillList, fetchIndividualBillList, fetchPurchaseContractList } from "../../../utils/helpers/globalMethod";

const VendorDashboard = () => {
  const user = JSON.parse(localStorage.getItem("userData"));

  const [purchaseContractPageNumber, setPurchaseContractPageNumber] = useState(1);
  const [billPageNumber, setBillPageNumber] = useState(1);
  const [individualBillPageNumber, setIndividualBillPageNumber] = useState(1);

  const [totalPagesPurchase, setTotalPagesPurchase] = useState(1);
  const [totalPagesBill, setTotalPagesBill] = useState(1);
  const [totalPagesIndividualBill, setTotalPagesIndividualBill] = useState(1);

  const [pageSize] = useState(5);
  const [purchaseContract, setPurchaseContract] = useState([]);
  const [bills, setBills] = useState([]);
  const [individualBill, setIndividualBills] = useState([]);
  const [loader, setLoader] = useState(false);

  const handlerPurchaseContractList = async () => {
    fetchPurchaseContractList(pageSize, purchaseContractPageNumber, VENDOR_APPROVE, setPurchaseContract, setLoader, setTotalPagesPurchase, user);
  }

  const handlerBillList = async () => {
    fetchBillList(pageSize, billPageNumber, VENDOR_REJECT, setBills, setLoader, setTotalPagesBill, user);
  }

  const handlerIndividualBillList = async () => {
    fetchIndividualBillList(pageSize, individualBillPageNumber, VENDOR_REJECT, setIndividualBills, setLoader, setTotalPagesIndividualBill, user);
  }

  const purchasecolumns = useFeedsColumnContracts(handlerPurchaseContractList);
  const billcolumns = useFeedsColumnBills(handlerBillList);
  const individualbillcolumns = useFeedsColumnIndividualBills(handlerIndividualBillList);

  useEffect(() => {
    handlerPurchaseContractList();
  }, [purchaseContractPageNumber]);

  useEffect(() => {
    handlerBillList();
  }, [billPageNumber]);

  useEffect(() => {
    handlerIndividualBillList();
  }, [individualBillPageNumber]);

  const handlePurchaseContractPageChange = (page) => {
    setPurchaseContractPageNumber(page);
  };


  const handleBillPageChange = (page) => {
    setBillPageNumber(page);
  };


  const handleIndividualBillPageChange = (page) => {
    setIndividualBillPageNumber(page);
  };
  
  return (
    <>
      <Helmet>
        <title>Vendor Portal || Vendor Dashboard</title>
      </Helmet>
      <Loader isLoading={loader} />
      <>
      <DashboardCard
            title={"Purchase Contracts"}
            columns={purchasecolumns}
            data={purchaseContract}
            handlerPurchaseContractList={handlerPurchaseContractList}
            purchaseContractPageNumber={purchaseContractPageNumber}
            setPurchaseContractPageNumber={setPurchaseContractPageNumber}
            totalPagesPurchase={totalPagesPurchase}
            handlePurchaseContractPageChange={handlePurchaseContractPageChange}
            pageSize={pageSize}
            mt="mt-4"
            fn=""
            tableId="contracts"
            approvalType="contract"
          />
 
          <DashboardCard
            title={"Bills"}
            columns={billcolumns}
            data={bills}
            handlerBillList={handlerBillList}
            billPageNumber={billPageNumber}
            setBillPageNumber={setBillPageNumber}
            totalPagesBill={totalPagesBill}
            handleBillPageChange={handleBillPageChange}
            pageSize={pageSize}
            mt="mt-4"
            fn=""
          />
 
          <DashboardCard
            title={"Standalone Bill"}
            columns={individualbillcolumns}
            data={individualBill}
            mt="mt-4"
            fn=""
            tableId="invoices"
            handlerIndividualBillList={handlerIndividualBillList}
            approvalType="invoice"
            individualBillPageNumber={individualBillPageNumber}
            setIndividualBillPageNumber={setIndividualBillPageNumber}
            totalPagesIndividualBill={totalPagesIndividualBill}
            handleIndividualBillPageChange={handleIndividualBillPageChange}
            pageSize={pageSize}
          />
      </>
    </>
  );
};

export default VendorDashboard;
