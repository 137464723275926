import React, { useEffect, useState } from 'react'
import { getApi } from '../../../utils/services';
import { BUDGET_RECORD } from '../../../utils/apiRoutes';
import LineColumnChart from '../../charts/linechart/LineColumnChart';
import LineChart from '../../charts/linechart/LineChart'
import { Helmet } from "react-helmet";
import Loader from '../../common/Loader';

const PlanActualBudget = () => {
    const [loader, setLoader] = useState(false);
    const [budgetRecord, setBudgetRecord] = useState([]);
    const [budgetMonths, setBudgetMonths] = useState([]);
    const [spendTrend, setSpendTrend] = useState([]);

    useEffect(() => {
        handlerBudgetChart();
        handlerSpendTrendChart();
    }, []);

    const handlerBudgetChart = async () => {
        try {
            setLoader(true)
            let result = await getApi(BUDGET_RECORD);
            const { actualBudget, planBudget, month } = result.res
            let categ = month
            let seriesData = [{
                name: 'Plan Budget',
                type: 'column',
                data: planBudget,
            },
            {
                name: 'Actual Budget',
                type: 'line',
                data: actualBudget
            }];
            setLoader(false)
            setBudgetRecord(seriesData)
            setBudgetMonths(categ)
        } catch (error) {
            console.log(error);
        }
    }

    const handlerSpendTrendChart = async () => {
        try {
            setLoader(true)
            let result = await getApi(BUDGET_RECORD);
            const { actualBudget, month } = result.res
            let categ = month
            let seriesData = [{
                name: 'Spend ',
                data: actualBudget
            }];
            setLoader(false)
            setSpendTrend(seriesData)
            setBudgetMonths(categ)
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <Helmet>
                <title>Vendor Portal || Plan vs Actual Budget</title>
            </Helmet>
            <Loader isLoading={loader} />
            <>
                <div className='col-12 mb-2'>
                </div>
                <div className='col-12 mb-2'>
                    <div className="row g-2">
                        <div className="col-12">
                            <div className='container-section'>
                                <div className="card-body">
                                    <LineColumnChart
                                        title='Budget Record'
                                        data={budgetRecord}
                                        categories={budgetMonths} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className='container-section'>
                                <div className="card-body">
                                    <LineChart
                                        title='Spend Trend'
                                        data={spendTrend}
                                        categories={budgetMonths}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}

export default PlanActualBudget