import React, { useEffect, useState, useRef } from "react";
import BackButton from "../../../assets/image/BackButton";
import RemoveIcon from "../../../assets/image/RemoveIcon";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import AddVendorValidation from "../../../middleware/addVendorValidation";
import { uploadFile } from "../../../utils/helpers/useFileUpload";
import {
  TERMS_LIST,
  SUBSIDIARY_LIST,
  COUNTRY_LIST,
  STATE_LIST,
  CITY_LIST,
  CURRENCY_LIST,
  GET_EMAIL,
} from "../../../utils/apiRoutes";
import { getApi, postApi } from "../../../utils/services";
import { useTransactionContext } from "../../../utils/helpers/useTransaction";
import { Helmet } from "react-helmet";
import { EMAIL_REGX, NON_DIGIT_REGX } from "../../../utils/helpers/globalRegx";
import { INVALID_CHARACTER_REGX } from "../../../utils/helpers/globalRegx";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const AddVendor = ({
  activeStep,
  steps,
  setActiveStep,
  setVendorDetails,
  vendorDetails,
  emailError,
  setEmailError,
  setBankInfoDetails,
  bankinfoDetails
}) => {
  const navigate = useNavigate();
  const transactionList = useTransactionContext();
  const user = JSON.parse(localStorage.getItem("userData"));
  const [formState, setFormState] = useState({
    vendor: vendorDetails?.vendor ? vendorDetails?.vendor : "",
    subsidiary: vendorDetails?.subsidiary ? vendorDetails?.subsidiary : "",
    terms: vendorDetails?.terms ? vendorDetails?.terms : "",
    addressLine1: vendorDetails?.addressLine1
      ? vendorDetails?.addressLine1
      : "",
    addressLine2: vendorDetails?.addressLine2
      ? vendorDetails?.addressLine2
      : "",
    pincode: vendorDetails?.pincode ? vendorDetails?.pincode : "",
    country: vendorDetails?.country ? vendorDetails?.country : "",
    state: vendorDetails?.state ? vendorDetails?.state : "",
    city: vendorDetails?.city ? vendorDetails?.city : "",
    pEmail: vendorDetails?.primary_email ? vendorDetails?.primary_email : "",
    website: vendorDetails?.website ? vendorDetails?.website : "",
    currency: vendorDetails?.currency ? vendorDetails?.currency : "",
    file: [],
  });

  const [termsList, setTermsList] = useState([]);
  const [subsidiaryList, setSubsidiaryList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [attachDocument, setAttachDocument] = useState(false);
  const [relevantDoc, setRelevantDoc] = useState([]);
  const { errors, setErrors, validateForm } = AddVendorValidation();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const fileInputRef = useRef(null);


  const [rows, setRows] = useState(() => {
    const initialRows = vendorDetails?.contact_information || [
      {
        firstName: "",
        lastName: "",
        email: "",
        phoneNo: "",
        phoneCode: "91",
        checked: true,
      },
    ];
    return initialRows;
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const termResult = await getApi(TERMS_LIST);
        setTermsList(termResult.res.terms_list);

        const subsidiaryResult = await getApi(SUBSIDIARY_LIST);
        setSubsidiaryList(subsidiaryResult.res.subsidiary_list);

        const countryResult = await getApi(COUNTRY_LIST);
        setCountryList(countryResult.res.country_list);

        const currencyResult = await getApi(CURRENCY_LIST);
        setCurrencyList(currencyResult.res.currency_list);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchStates = async () => {
      if (formState.country) {
        const splitedCountryCode = formState.country.split(",")[1];

        try {
          const result = await getApi(`${STATE_LIST}/${splitedCountryCode}`);
          setStateList(result.res.state_list);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchStates();
  }, [formState.country]);

  useEffect(() => {
    const fetchCity = async () => {
      if (formState.country && formState.state) {
        const splitedCountryCode = formState.country.split(",")[1];
        const splitedStateCode = formState.state.split(",")[1];

        try {
          const result = await getApi(
            `${CITY_LIST}/${splitedCountryCode}/${splitedStateCode}`
          );
          setCityList(result.res.city_list);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchCity();
  }, [formState.country, formState.state]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[name];
      return updatedErrors;
    });

    if (name === "vendor") {
      setFormState({
        ...formState,
        [name]: value,
      });
    } else if (name === "pincode") {
      const newVal = value.replace(NON_DIGIT_REGX, "");
      setFormState({
        ...formState,
        [name]: newVal,
      });
    } else if (name === "file") {
      setFormState({
        ...formState,
        [name]: Array.from(files),
      });
    } else if (name === "pEmail") {
      setFormState({
        ...formState,
        [name]: value,
      });

      const isValidEmail = EMAIL_REGX.test(value);
      setIsEmailValid(isValidEmail);

      if (!isValidEmail) {
        setSubmitDisable(true);
      } else {
        setSubmitDisable(false);
      }
    } else {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };

  const handleEmailBlur = async () => {
    const emailValue = formState.pEmail.trim();
    try {
      const response = await postApi(GET_EMAIL, {
        type: "add",
        emailId: emailValue,
      });
      if (response.res.success === true) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          pEmail: response.res.message,
        }));
        setIsEmailValid(false);
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          pEmail: response.res.message,
        }));
        setIsEmailValid(true);
      }
    } catch (error) {
      console.error("Error checking email existence:", error);
      setIsEmailValid(false);
    }
  };

  const handleCheckbox = (event) => {
    const isChecked = event.target.checked;
    setAttachDocument(isChecked);

    if (!isChecked) {
      setRelevantDoc(null);
      fileInputRef.current.value = null;
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors['relevantDoc'];
        return newErrors;
      });
    }
  };

  const handleContactChange = (index, e) => {
    const { name, value } = e.target;

    if (name === "firstName" || name === "lastName") {
      const newVal = value.replace(INVALID_CHARACTER_REGX, "");

      const updatedRows = [...rows];
      updatedRows[index][name] = newVal;

      setRows(updatedRows);
    } else if (name === "phoneNo") {
      const newVal = value.replace(NON_DIGIT_REGX, "");

      const updatedRows = [...rows];
      updatedRows[index][name] = newVal;

      setRows(updatedRows);
    } else if (name === "email" || name === "phoneCode") {
      const updatedRows = [...rows];
      updatedRows[index][name] = value;

      setRows(updatedRows);
    }

    const updatedErrors = { ...errors };
    if (updatedErrors.rows && updatedErrors.rows[index]) {
      delete updatedErrors.rows[index][name];
    }

    setErrors(updatedErrors);
  };

  const handleAdd = () => {
    if (rows.length <= 2) {
      setRows([
        ...rows,
        {
          firstName: "",
          lastName: "",
          email: "",
          phoneNo: "",
          phoneCode: "91",
          checked: false,
        },
      ]);
    }
  };

  const handleRemove = (index) => {
    const updatedRows = [...rows];

    updatedRows.splice(index, 1);
    setRows(updatedRows);

    const updatedErrors = { ...errors };
    if (updatedErrors.rows && updatedErrors.rows.length > index) {
      updatedErrors.rows.splice(index, 1);
    }
    setErrors(updatedErrors);
  };

  const handleCheckboxChange = (row) => {
    const updatedRows = rows.map((r) => ({
      ...r,
      checked: r === row ? true : false,
    }));

    setRows(updatedRows);
  };

  const handleFileChange = (e) => {
    const relevantDoc = Array.from(e.target.files);
    setRelevantDoc(relevantDoc);

    const updatedErrors = { ...errors };
    delete updatedErrors.relevantDoc;
    setErrors(updatedErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm(
      formState.vendor,
      formState.subsidiary,
      formState.currency,
      formState.terms,
      formState.pEmail,
      formState.website,
      formState.addressLine1,
      formState.country,
      formState.state,
      formState.city,
      formState.pincode,
      rows,
      formState.file,
      relevantDoc
    );

    if (isValid) {
      setSubmitDisable(true);

      let fileLogo = {};

      const files = Array.isArray(formState.file) ? formState.file : [];

      if (files.length <= 0) {
        fileLogo = { LogoName: null, LogoPath: null };
      }

      const logoUploadPromises = files.map(async (file) => {
        if (!file) {
          const result = await uploadFile("profile", "logo", "vendor", file);
          fileLogo = { LogoName: result.fileName, LogoPath: result.filePath };
        }
      });

      await Promise.all(logoUploadPromises);

      let relevantDocs = {};

      const relevantFiles = Array.isArray(relevantDoc) ? relevantDoc : [];

      if (relevantFiles.length <= 0) {
        relevantDocs = { RelevantDocName: null, RelevantDocPath: null };
      }
      const relevantDocPromises = relevantFiles.map(async (file) => {
        const result = await uploadFile(
          "document",
          "relevantdoc",
          "vendor",
          file
        );
        relevantDocs = {
          RelevantDocName: result.fileName,
          RelevantDocPath: result.filePath,
        };
      });

      await Promise.all(relevantDocPromises);

      let vendorDetails = {
        userId: user.id,
        username: user.Name,
        vendor: formState.vendor,
        transactionId: transactionList[3].id,
        roleId: user.RoleId,
        subsidiary: Number(formState.subsidiary),
        terms: Number(formState.terms),
        currency: Number(formState.currency),
        addressLine1: formState.addressLine1,
        addressLine2: formState.addressLine2,
        pincode: formState.pincode,
        country: formState.country,
        state: formState.state,
        city: formState.city,
        primary_email: formState.pEmail,
        website: formState.website,
        logo: fileLogo,
        contact_information: rows,
        relevantDoc: relevantDocs,
      };
      setBankInfoDetails(bankinfoDetails);
      setVendorDetails(vendorDetails);
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Vendor</title>
      </Helmet>

      <div className="container-section">
        <div className="vendor-req-sec">
          <div className="section-header">
            <button onClick={() => navigate(-1)} className="back-viewbtn me-2">
              <BackButton />
            </button>
            <h3>Vendor Requisition Form</h3>
          </div>
          <div className="vendor-req-sec-form form-style">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="vendor" className="form-label">
                  Legal Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="vendor"
                  className="form-control"
                  placeholder="Enter legal name"
                  name="vendor"
                  value={formState.vendor}
                  onChange={handleChange}
                />
                {errors.vendor && (
                  <span className="error-text">{errors.vendor}</span>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="subsidiary" className="form-label">
                  Subsidiary<span className="required">*</span>
                </label>
                <select
                  name="subsidiary"
                  id="subsidiary"
                  className="form-select"
                  value={formState.subsidiary}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {subsidiaryList.map((item, index) => (
                    <option key={index} value={item.Id}>
                      {item.Name}
                    </option>
                  ))}
                </select>
                {errors.subsidiary && (
                  <span className="error-text">{errors.subsidiary}</span>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="currency" className="form-label">
                  Currency<span className="required">*</span>
                </label>
                <select
                  name="currency"
                  id="currency"
                  className="form-select"
                  value={formState.currency}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {currencyList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.CurrencyCode}
                    </option>
                  ))}
                </select>
                {errors.currency && (
                  <span className="error-text">{errors.currency}</span>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="terms" className="form-label">
                  Payment Terms<span className="required">*</span>
                </label>
                <select
                  name="terms"
                  id="terms"
                  className="form-select"
                  value={formState.terms}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {termsList.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.Term}
                    </option>
                  ))}
                </select>
                {errors.terms && (
                  <span className="error-text">{errors.terms}</span>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="pEmail" className="form-label">
                  Email<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="pEmail"
                  className="form-control"
                  placeholder="Enter email"
                  name="pEmail"
                  value={formState.pEmail}
                  onChange={handleChange}
                  onBlur={handleEmailBlur}
                />
                {errors.pEmail && (
                  <div className="error-text">{errors.pEmail}</div>
                )}
                {!errors.pEmail && emailError.pEmail && (
                  <div className="error-text">{emailError.pEmail}</div>
                )}
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="website" className="form-label">
                  Website
                </label>
                <input
                  type="text"
                  id="website"
                  className="form-control"
                  placeholder="Enter website"
                  name="website"
                  value={formState.website}
                  onChange={handleChange}
                />
                {errors.website && (
                  <span className="error-text">{errors.website}</span>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="files" className="form-label">
                  Logo<span className="ms-1 infoIcon"></span>
                </label>
                <div className="input-group file-input-container">
                  <input
                    type="file"
                    className="form-control"
                    name="file"
                    onChange={handleChange}
                  />
                  {errors.file && (
                    <span className="error-text">{errors.file}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="billing-adrs-sec">
          <div className="section-header">
            <h3>Billing Address</h3>
          </div>
          <div className="billing-adrs-sec-form form-style">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="addressLine1" className="form-label">
                  Address Line 1<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="addressLine1"
                  className="form-control"
                  placeholder="Enter address line 1"
                  name="addressLine1"
                  value={formState.addressLine1}
                  onChange={handleChange}
                />
                {errors.addressLine1 && (
                  <span className="error-text">{errors.addressLine1}</span>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="addressLine2" className="form-label">
                  Address Line 2
                </label>
                <input
                  type="text"
                  id="addressLine2"
                  className="form-control"
                  placeholder="Enter address line 2"
                  name="addressLine2"
                  value={formState.addressLine2}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="country" className="form-label">
                  Country<span className="required">*</span>
                </label>
                <select
                  name="country"
                  id="country"
                  className="form-select"
                  value={formState.country}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {countryList.map((item, index) => (
                    <option key={index}>
                      {item.name + "," + item.isoCode}
                    </option>
                  ))}
                </select>
                {errors.country && (
                  <span className="error-text">{errors.country}</span>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="state" className="form-label">
                  State<span className="required">*</span>
                </label>
                <select
                  name="state"
                  id="state"
                  className="form-select"
                  value={formState.state}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {stateList.map((item, index) => (
                    <option key={index}>
                      {item.name + "," + item.isoCode}
                    </option>
                  ))}
                </select>
                {errors.state && (
                  <span className="error-text">{errors.state}</span>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="city" className="form-label">
                  City<span className="required">*</span>
                </label>
                <select
                  name="city"
                  id="city"
                  className="form-select"
                  value={formState.city}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {cityList.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {errors.city && <span className="error-text">{errors.city}</span>}
              </div>
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="pincode" className="form-label">
                  Zip Code<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="pincode"
                  className="form-control"
                  placeholder="Enter pincode"
                  name="pincode"
                  maxLength={6}
                  value={formState.pincode}
                  onChange={handleChange}
                />
                {errors.pincode && (
                  <span className="error-text">{errors.pincode}</span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="contact-info-sec">
          <div className="section-header justify-content-between">
            <h3>Contact Information</h3>
            <button
              type="button"
              className="btn btn-sm section-hdr-btn"
              onClick={handleAdd}
            >
              Add More
            </button>
          </div>
          <div className="billing-adrs-sec-form form-style">
            {rows.map((row, index) => (
              <div
                className={
                  row.checked
                    ? "row primary-card-information"
                    : "row optional-div-information"
                }
                key={index}
              >
                <div className="primary-contact">
                  <Checkbox
                    {...label}
                    checked={row.checked}
                    onChange={() => handleCheckboxChange(row)}
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 28 },
                    }}
                  />{" "}
                  <label className="ms-1">Primary Contact</label>
                </div>

                {!row.checked && (
                  <span
                    className="remove-icon"
                    onClick={() => handleRemove(index)}
                  >
                    <RemoveIcon />
                  </span>
                )}

                <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                  <label htmlFor="firstName" className="form-label">
                    First Name<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    className="form-control"
                    placeholder="Enter first name"
                    name="firstName"
                    value={row.firstName}
                    onChange={(e) => handleContactChange(index, e)}
                  />
                  {errors.rows &&
                    errors.rows[index] &&
                    errors.rows[index].firstName && (
                      <span className="error-text">
                        {errors.rows[index].firstName}
                      </span>
                    )}
                </div>
                <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                  <label htmlFor="lastName" className="form-label">
                    Last Name<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    className="form-control"
                    placeholder="Enter last name"
                    name="lastName"
                    value={row.lastName}
                    onChange={(e) => handleContactChange(index, e)}
                  />
                  {errors.rows &&
                    errors.rows[index] &&
                    errors.rows[index].lastName && (
                      <span className="error-text">
                        {errors.rows[index].lastName}
                      </span>
                    )}
                </div>
                <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                  <label htmlFor="vendor" className="form-label">
                    Email<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="email"
                    className="form-control"
                    placeholder="Enter email"
                    name="email"
                    value={row.email}
                    onChange={(e) => handleContactChange(index, e)}
                  />
                  {errors.rows &&
                    errors.rows[index] &&
                    errors.rows[index].email && (
                      <span className="error-text">
                        {errors.rows[index].email}
                      </span>
                    )}
                </div>
                <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                  <label htmlFor="phoneCode" className="form-label">
                    Phone Number
                  </label>
                  <div className="input-group">
                    <select
                      name="phoneCode"
                      id="phoneCode"
                      className="inp-mrg-sec"
                      value={row.phoneCode}
                      onChange={(e) => handleContactChange(index, e)}
                    >
                      {countryList.map((item, index) => (
                        <option key={index}>{item.phonecode}</option>
                      ))}
                    </select>
                    <input
                      type="text"
                      id="phoneNo"
                      className="form-control inp-rgt-radius"
                      placeholder="Enter phone number"
                      name="phoneNo"
                      maxlength={10}
                      value={row.phoneNo}
                      onChange={(e) => handleContactChange(index, e)}
                    />
                    {errors.rows &&
                      errors.rows[index] &&
                      errors.rows[index].phoneNo && (
                        <span className="error-text">
                          {errors.rows[index].phoneNo}
                        </span>
                      )}
                    <div data-lastpass-icon-root="true"></div>
                  </div>
                </div>
              </div>
            ))}
            <div className="cstm-checkbox mb-2">
              <div className="cstm-checkbox-inner d-flex align-items-center">
                <input
                  className="form-check-input m-0"
                  type="checkbox"
                  name="attachDoc"
                  id="attachDoc"
                  checked={attachDocument}
                  onChange={handleCheckbox}
                />
                <div className="cstm-checkbox-inr-lbl-field d-flex align-items-center">
                  <label htmlFor="attachDoc" className="ms-2 form-check-label">
                    Check for relevant document
                  </label>
                </div>
              </div>
            </div>
            <div className="input-group row mb-3 m-0">
              <div className="col-xl-4 col-lg-4 col-md-5 mb-20 position-relative p-0">
                <div className="input-group file-input-container">
                  <input
                    type="file"
                    id="files"
                    className="form-control"
                    name="files"
                    onChange={handleFileChange}
                    disabled={!attachDocument}
                    ref={fileInputRef}
                  />
                  {errors.relevantDoc && (
                    <span className="error-text">{errors.relevantDoc}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="stepper-next-btn">
              {activeStep !== 0 && activeStep !== steps.length - 1 && (
                <span className="danger-outline-btn ">
                  <button
                    onClick={() => setActiveStep(activeStep - 1)}
                    className="btn previousstepper"
                  >
                    Previous
                  </button>
                </span>
              )}
              {activeStep !== steps.length - 1 && (
                <span className="success-btn ">
                  <button
                    onClick={(e) => handleSubmit(e)}
                    className="btn"
                    disabled={submitDisable || !isEmailValid}
                  >
                    Next
                  </button>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddVendor;
