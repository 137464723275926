export const PASSWORD_REGX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,100}$/;
export const EMAIL_REGX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const IS_NUMERIC_OR_DECIMAL_REGX = /^\d*(\.\d{0,2})?$/;
export const HAS_DECIMAL_VALUE_REGX = /^(\d{0,3})(\.\d{0,2})?$/;
export const NON_NUMERIC_DOT_REGX = /[^0-9.]/g;
export const NON_DIGIT_REGX = /[^0-9]/g;
export const INVALID_CHARACTER_REGX = /[^a-zA-Z\s]/g;
export const WEBSITE_REGX = /^(http|https)?:\/\/[a-zA-Z0-9-\.]+\.[a-z]{2,4}/;
export const DISCARD_NON_DIGIT_REGX = /\D/g;
export const TEXT_ONLY_REGX = /^[a-zA-Z\s]+$/;
export const DECIMAL_NUMBER_REGX = /^(\d+(\.\d{0,2})?)?$/;
export const CAMEL_CASE_REGX = /(?:^\w|[A-Z]|\b\w)/g;
export const TRIM_SPACE_REGX = /\s+/g;
export const DECIMAL_TWO_PLACES_REGX = /^\d+(\.\d{0,2})?$/;
export const INTEGER_REGX = /^\d+$/;
export const DECIMAL_OPTIONAL_TWO_PLACES_REGX = /^\d*\.?\d{0,2}$/;
export const NUMBER_ONLY_REGX = /^[0-9]+$/;
export const NON_ALFA_NUMERIC_REGX = /^[^\w\s]+$/