import { useState } from 'react';
import { validateBillDate, validateDepartmentId, validateSubTotal, validateUploadDocuments } from '../utils/helpers/globalValidation';
import { validateGSTFields, validateTaxEditFields } from '../utils/helpers/globalValidation';
import { MIN_QUANTITY_ERROR } from '../utils/helpers/globalErrorMessage';

const EditBillValidation = () => {
  const [errors, setErrors] = useState({});

  const validateForm = (BillDate, lineGSTcheck, Files, Cgst, Sgst, UTgst, Igst, Tax, InvoiceTotal, subsidiaryId, selectedFiles, lineItems, roleId, departmentId, description) => {
    let formIsValid = true;
    let errors = {};

    const billDateError = validateBillDate(BillDate);
    if (billDateError) {
      formIsValid = false;
      errors.billDate = billDateError;
    }

    const subtotalError = validateSubTotal(InvoiceTotal);
    if (subtotalError) {
      formIsValid = false;
      errors.subtotal = subtotalError;
    }

    const departmentError = validateDepartmentId(roleId, departmentId);
    if (departmentError) {
      formIsValid = false;
      errors.departmentId = departmentError;
    }

    if (!lineGSTcheck) {
      const { formIsValid: gstFormIsValid, errors: gstErrors } = validateGSTFields({ cgst: Cgst, sgst: Sgst, utgst: UTgst, igst: Igst }, subsidiaryId);
      formIsValid = formIsValid && gstFormIsValid;
      errors = { ...errors, ...gstErrors };
      const zeroQuantityLines = lineItems.filter((item) => {
        return (
          (item.Quantity <= 0 || item.Quantity === '')
        );
      });
      if (zeroQuantityLines.length > 0) {
        alert(MIN_QUANTITY_ERROR)
        return;
      }
    } else {
      const { formIsValid: gstFormIsValid, errors: gstErrors } = validateTaxEditFields(lineGSTcheck, Cgst, Sgst, UTgst, Igst, Tax, subsidiaryId, lineItems);
      formIsValid = formIsValid && gstFormIsValid;
      errors = { ...errors, ...gstErrors };
    }

    const documentError = validateUploadDocuments(Files, selectedFiles);
    formIsValid = formIsValid && documentError.formIsValid;
    errors.files = documentError.errors.files;

    setErrors(errors);
    return formIsValid;
  };

  return { errors, setErrors, validateForm };
};

export default EditBillValidation;
