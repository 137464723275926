import React, { useEffect, useState, useContext, useRef } from "react";
import BackButton from "../../../assets/image/BackButton";
import RemoveIcon from "../../../assets/image/RemoveIcon";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate, useParams, Link } from "react-router-dom";
import { uploadFile } from "../../../utils/helpers/useFileUpload";
import { ModalContext } from "../../../utils/Context";
import Modal from "react-bootstrap/Modal";
import { useTransactionContext } from "../../../utils/helpers/useTransaction";
import {
  TERMS_LIST,
  SUBSIDIARY_LIST,
  COUNTRY_LIST,
  STATE_LIST,
  CITY_LIST,
  VIEW_VENDOR,
  CURRENCY_LIST,
  GET_EMAIL,
} from "../../../utils/apiRoutes";
import { BASE_IMAGE_URL, getApi, postApi } from "../../../utils/services";
import DownloadIcon from "../../../assets/image/DownloadIcon";
import EyeIcon from "../../../assets/image/EyeIcon";
import DeleteIcon from "../../../assets/image/DeleteIcon";
import cross from "../../../assets/image/cross.png";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";
import { NON_DIGIT_REGX, EMAIL_REGX, INVALID_CHARACTER_REGX, WEBSITE_REGX } from "../../../utils/helpers/globalRegx";
import { ADDRESS_LINE1_ERROR, ALLOWED_IMAGE_TYPES, CITY_ERROR, COUNTRY_ERROR, CURRENCY_ERROR, ENTER_EMAIL_ERROR, ENTER_VENDOR_NAME_ERROR, FIRST_NAME_ERROR, INVALID_PHONE_ERROR, INVALID_PINCODE_ERROR, LAST_NAME_ERROR, PAYMENT_TERMS_ERROR, PINCODE_ERROR, STATE_ERROR, SUBSIDIARY_ERROR, UPLOAD_MAX_SIZE_ERROR, UPLOAD_PDF_ERROR, VALID_FORMAT_ERROR } from "../../../utils/helpers/globalErrorMessage";


const label = { inputProps: { "aria-label": "Checkbox demo" } };

const EditVendor = ({ activeStep, steps, setActiveStep, setVendorDetails, vendorDetails, setViewData, viewdata, updatedEmail, emailError, setEmailError, bankinfoDetails, setBankInfoDetails }) => {

  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const transactionList = useTransactionContext();
  const user = JSON.parse(localStorage.getItem("userData"));
  const modalContext = useContext(ModalContext);
  const { handleModalData } = modalContext;

  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    vendor: "",
    subsidiary: "",
    terms: "",
    addressLine1: "",
    addressLine2: "",
    pincode: "",
    country: "",
    state: "",
    city: "",
    pEmail: "",
    website: "",
    currency: "",
  });

  const [termsList, setTermsList] = useState([]);
  const [subsidiaryList, setSubsidiaryList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [relevantDoc, setRelevantDoc] = useState();
  const [attachDoc, setAttachDoc] = useState(false);
  const [attcrelevantDoc, setAttchRelevantDoc] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  // logofile //
  const [attachlogo, setAttachLogo] = useState({});
  const [attachLogoFile, setAttachLogoFile] = useState();
  const fileInputRef = useRef(null);

  const [infoid, setInfoId] = useState([]);

  useEffect(() => {
    if (vendorDetails?.deletedRow?.length) {
      setContactInfoId(vendorDetails.deletedRow);
    }
  }, [vendorDetails.deletedRow]);

  const [show, setShow] = useState(false);
  const [rows, setRows] = useState(() => {
    const initialRows = vendorDetails.contact_information || [
      {
        firstName: "",
        lastName: "",
        email: "",
        phoneNo: "",
        checked: true,
        PhoneCode: "91",
        file: {},
        fileTwo: null,
      }
    ];
    return initialRows;
  });

  const [errors, setErrors] = useState({});
  const [contactInfoId, setContactInfoId] = useState([])

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "pEmail") {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors.pEmail;
        return updatedErrors;
      });
    }

    if (name === "vendor") {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (name === "pincode") {
      const newVal = value.replace(NON_DIGIT_REGX, "");
      setFormState((prevState) => ({
        ...prevState,
        [name]: newVal,
      }));
    } else if (name === "pEmail") {
      const isValidEmail = EMAIL_REGX.test(value);

      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      setEmailError((prevEmailError) => ({
        ...prevEmailError,
        [name]: isValidEmail,
      }));

      if (!isValidEmail) {
        setSubmitDisable(true);
        return;
      } else {
        setSubmitDisable(false);
      }
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[name];
      return updatedErrors;
    });
  };

  const handleEmailBlur = async () => {
    const emailValue = formState.pEmail.trim();
    const response = await postApi(GET_EMAIL, {
      vendorId: Number(id),
      type: 'update',
      emailId: emailValue,
    });

    if (response.res.success === true) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        pEmail: response.res.message,
      }));
      setSubmitDisable(true);
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        pEmail: response.res.message,
      }));
      setSubmitDisable(false);
    }
  };

  const handleContactChange = (index, e) => {
    const { name, value } = e.target;

    if (name === "firstName" || name === "lastName") {
      const newVal = value.replace(INVALID_CHARACTER_REGX, "");

      const updatedRows = [...rows];
      updatedRows[index][name] = newVal;

      setRows(updatedRows);

      const updatedErrors = { ...errors };
      if (updatedErrors.rows && updatedErrors.rows[index]) {
        delete updatedErrors.rows[index][name];
      }

      setErrors(updatedErrors);
    } else if (name === "phoneNo") {
      const newVal = value.replace(NON_DIGIT_REGX, "");

      const updatedRows = [...rows];
      updatedRows[index][name] = newVal;

      setRows(updatedRows);

      const updatedErrors = { ...errors };
      if (updatedErrors.rows && updatedErrors.rows[index]) {
        delete updatedErrors.rows[index][name];
      }

      setErrors(updatedErrors);
    } else if (name === "email" || name === "phoneCode") {
      const updatedRows = [...rows];
      updatedRows[index][name] = value;

      setRows(updatedRows);
    }

    const updatedErrors = { ...errors };
    if (updatedErrors.rows && updatedErrors.rows[index]) {
      delete updatedErrors.rows[index][name];
    }

    setErrors(updatedErrors);
  };

  const handleAdd = () => {
    if (rows.length <= 2) {
      setRows([
        ...rows,
        {
          firstName: "",
          lastName: "",
          email: "",
          phoneNo: "",
          PhoneCode: "91",
          checked: false,
          fileTwo: null,
        },
      ]);
    }
    // } else {
    //     toast.error("Maximum 3 contact information is allowed");
    // }
  };

  const handleCheckbox = (event) => {
    const isChecked = event.target.checked;
    setAttachDoc(isChecked);

    if (!isChecked) {
      setAttchRelevantDoc(null);
      fileInputRef.current.value = null;
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors['docErr'];
        return newErrors;
      });
    }
  };

  const handleRemove = (index) => {
    const updatedRows = [...rows];
    let deleteConInf = updatedRows.find((item, i) => i === index);

    if (deleteConInf?.ContactLineId !== undefined) {
      let newObject = {
        contactLineId: deleteConInf.ContactLineId,
      };
      const deletedRows = Array.isArray(vendorDetails.deletedRow) ? vendorDetails.deletedRow : []
      setContactInfoId((prevContactInfoId) => [
        ...prevContactInfoId,
        ...deletedRows,
        newObject,
      ]);
    }

    const newUpdatedRows = updatedRows.filter((_, i) => i !== index);
    setRows(newUpdatedRows)
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const termResult = await getApi(TERMS_LIST);
        setTermsList(termResult.res.terms_list);

        const subsidiaryResult = await getApi(SUBSIDIARY_LIST);
        setSubsidiaryList(subsidiaryResult.res.subsidiary_list);

        const countryResult = await getApi(COUNTRY_LIST);
        setCountryList(countryResult.res.country_list);

        const currencyResult = await getApi(CURRENCY_LIST);
        setCurrencyList(currencyResult.res.currency_list);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchStates = async () => {
      if (formState.country) {
        const splitedCountryCode = formState.country.split(",")[1];

        try {
          const result = await getApi(`${STATE_LIST}/${splitedCountryCode}`);
          setStateList(result.res.state_list);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchStates();
  }, [formState.country]);

  useEffect(() => {
    const fetchCity = async () => {
      if (formState.country && formState.state) {
        const splitedCountryCode = formState.country.split(",")[1];
        const splitedStateCode = formState.state.split(",")[1];

        try {
          const result = await getApi(
            `${CITY_LIST}/${splitedCountryCode}/${splitedStateCode}`
          );
          setCityList(result.res.city_list);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchCity();
  }, [formState.country, formState.state]);

  const handleCheckboxChange = (row) => {
    const updatedRows = rows.map((r) => ({
      ...r,
      checked: r === row ? true : false,
    }));

    setRows(updatedRows);
  };

  const isValidRelevantDoc = (file) => {
    const allowedTypes = ["application/pdf"];
    return allowedTypes.includes(file.type);
  };
  const MAX_FILE_SIZE = 2 * 1024 * 1024;

  const isValidLogoType = (file) => {
    const allowedTypes = ["image/png", "image/jpg", "image/jpeg"];
    return allowedTypes.includes(file.type);
  };

  useEffect(() => {
    if (id) {
      const handleVendorView = async (id) => {
        setLoading(true);
        const result = await getApi(`${VIEW_VENDOR}/${id}`);
        setViewData(result.res.vendor_details);
        setLoading(false);
        setFormState({
          ...formState,
          vendor: vendorDetails.vendor ? vendorDetails.vendor : result?.res?.vendor_details?.LegalName,
          pEmail: updatedEmail ? updatedEmail : vendorDetails.primary_email ? vendorDetails.primary_email : result.res.vendor_details.EmailId,
          addressLine1: vendorDetails.addressLine1 ? vendorDetails.addressLine1 : result.res.vendor_details.AddressLine1,
          addressLine2: vendorDetails.addressLine2 ? vendorDetails.addressLine2 : result.res.vendor_details.AddressLine2,
          pincode: vendorDetails.pincode ? vendorDetails.pincode : result.res.vendor_details.PostalCode,
          city: vendorDetails.city ? vendorDetails.city : result.res.vendor_details.City,
          country: vendorDetails.country ? vendorDetails.country : result.res.vendor_details.Country,
          state: vendorDetails.state ? vendorDetails.state : result.res.vendor_details.State,
          subsidiary: vendorDetails.subsidiary ? vendorDetails.subsidiary : result.res.vendor_details.SubsidiaryId,
          terms: vendorDetails.terms ? vendorDetails.terms : result.res.vendor_details.TermId,
          website: vendorDetails.website ? vendorDetails.website : result.res.vendor_details.Website,
          currency: vendorDetails.currency ? vendorDetails.currency : result.res.vendor_details.CurrencyId,
        });

        let NewData = [];

        if (vendorDetails.contact_information) {
          NewData = vendorDetails.contact_information.map((item) => ({
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
            phoneNo: item.phoneNo,
            PhoneCode: item.phoneCode || 91,
            checked: item.checked,
            file: item.FilePath,
            ContactLineId: item.contactLineId,
            fileTwo: { FileName: item.FileName, FilePath: item.FilePath },
          }));
        } else if (result?.res?.vendor_details?.ContactInfo) {
          NewData = result.res.vendor_details.ContactInfo.map((item) => ({
            firstName: item.FirstName,
            lastName: item.LastName,
            email: item.Email,
            phoneNo: item.Phone,
            PhoneCode: item.PhoneCode || 91,
            checked: item.IsPrimary,
            file: item.FilePath,
            ContactLineId: item.ContactLineId,
            fileTwo: { FileName: item.FileName, FilePath: item.FilePath },
          }));
        }
        setRows(NewData);

        let relevantDocArr = [
          {
            RelevantDocName: result.res.vendor_details.RelevantDocName,
            RelevantDocPath: result.res.vendor_details.RelevantDocPath,
          },
        ];
        if (result.res.vendor_details.RelevantDocName) {
          setRelevantDoc(relevantDocArr);
        } else {
          setRelevantDoc(null);
        }

        if (result.res.vendor_details.RelevantDocPath) {
          setAttachDoc(result.res.vendor_details.RelevantDocPath);
        } else {
          setAttachDoc(null);
        }

        setAttachLogo({
          LogoName: vendorDetails.logo?.LogoName ? vendorDetails.logo.LogoName : result.res.vendor_details.LogoName,
          LogoPath: vendorDetails.logo?.LogoPath ? vendorDetails.logo.LogoPath : result.res.vendor_details.LogoPath,
        });
        setAttachLogoFile(vendorDetails.logo?.LogoPath ? vendorDetails.logo.LogoPath : result.res.vendor_details.LogoPath);
      };
      handleVendorView(id);
    }
  }, [id]);

  const handleImageChange = (e, rowIndex) => {
    const file = e.target.files[0];
    // Ensure that the rows array is properly initialized
    const updatedRows = rows.map((r, index) =>
      index === rowIndex ? { ...r, file } : r
    );
    setRows(updatedRows);
  };

  const handleImage = (file, index) => {
    if (file === null) {
      return (
        <img
          src={
            "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
          }
          alt={`Profile_Image ${index + 1}`}
          className="contact-image"
        />
      );
    } else if (typeof file === "object") {
      return (
        <img
          src={URL.createObjectURL(new Blob([file]))}
          alt={`Profile_Image ${index + 1}`}
          className="contact-image"
        />
      );
    } else if (typeof file === "string") {
      return (
        <img
          src={`${BASE_IMAGE_URL}${file}`}
          alt={`Profile_Image ${index + 1}`}
          className="contact-image"
        />
      );
    } else {
      return (
        <img
          src={
            "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
          }
          alt={`Profile_Image ${index + 1}`}
          className="contact-image"
        />
      );
    }
  };

  const handleDeletefile = () => {
    setRelevantDoc();
    setAttachDoc();
  };

  const handleFileChange = (e) => {
    const relevantDoc = Array.from(e.target.files);
    setAttchRelevantDoc(relevantDoc);
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.docErr;
      return updatedErrors;
    });
  };

  const handleFileLogo = (e) => {
    const logoFile = Array.from(e.target.files);
    setAttachLogoFile(logoFile);
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.logo;
      return updatedErrors;
    });
  };

  const handleShow = () => {
    handleClose();
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const validateForm = () => {
    let errors = {};

    if (!formState.vendor) {
      errors.vendor = ENTER_VENDOR_NAME_ERROR;
    }
    if (!formState.subsidiary) {
      errors.subsidiary = SUBSIDIARY_ERROR;
    }
    if (!formState.currency) {
      errors.currency = CURRENCY_ERROR;
    }
    if (!formState.terms) {
      errors.terms = PAYMENT_TERMS_ERROR;
    }
    if (!formState.addressLine1) {
      errors.addressLine1 = ADDRESS_LINE1_ERROR;
    }
    if (!formState.pincode) {
      errors.pincode = PINCODE_ERROR;
    } else {
      if (formState.pincode.length < 6) {
        errors.pincode = INVALID_PINCODE_ERROR;
      }
    }
    if (!formState.country) {
      errors.country = COUNTRY_ERROR;
    }
    if (!formState.state) {
      errors.state = STATE_ERROR;
    }
    if (!formState.city) {
      errors.city = CITY_ERROR;
    }
    if (formState.website) {
      if (!WEBSITE_REGX.test(formState.website)) {
        errors.website = VALID_FORMAT_ERROR;
      }
    }
    if (!formState.pEmail) {
      errors.pEmail = ENTER_EMAIL_ERROR;
    } else {
      if (!EMAIL_REGX.test(formState.pEmail)) {
        errors.pEmail = VALID_FORMAT_ERROR;
      }
    }
    if (Array.isArray(attachLogoFile)) {
      for (const file of attachLogoFile) {
        if (!isValidLogoType(file)) {
          errors.logo = ALLOWED_IMAGE_TYPES;
          break;
        }
        if (file.size > MAX_FILE_SIZE) {
          errors.logo = UPLOAD_MAX_SIZE_ERROR;
          break;
        }
      }
    }

    // if (!relevantDoc && attcrelevantDoc.length == 0) {
    //   errors.docErr = "Please upload document !";
    // } else {
    if (Array.isArray(attcrelevantDoc)) {
      for (const file of attcrelevantDoc) {
        if (!isValidRelevantDoc(file)) {
          errors.docErr = UPLOAD_PDF_ERROR;
          break;
        }
        if (file.size > MAX_FILE_SIZE) {
          errors.docErr = UPLOAD_MAX_SIZE_ERROR;
          break;
        }
      }
    }

    errors.rows = [];

    rows.forEach((item, index) => {
      const rowsErrors = {};

      if (!item.firstName) {
        rowsErrors.firstName = FIRST_NAME_ERROR;
      }
      if (!item.lastName) {
        rowsErrors.lastName = LAST_NAME_ERROR;
      }
      if (!item.email) {
        rowsErrors.email = ENTER_EMAIL_ERROR;
      } else {
        if (!EMAIL_REGX.test(item.email)) {
          rowsErrors.email = VALID_FORMAT_ERROR;
        }
      }

      if (item.phoneNo && item.phoneNo.length < 10) {
        rowsErrors.phoneNo = INVALID_PHONE_ERROR;
      }

      if (Object.keys(rowsErrors).length > 0) {
        errors.rows[index] = rowsErrors;
      }
      return rowsErrors;
    });

    if (errors.rows.length === 0) {
      delete errors.rows;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const ValidattachLogoFile = Array.isArray(attachLogoFile) ? attachLogoFile : [];

    try {
      if (validateForm()) {
        setSubmitDisable(true);
        setLoader(true);
        let fileLogo = {};
        if (ValidattachLogoFile.length > 0) {
          const logoUploadPromises = ValidattachLogoFile.map(async (file) => {
            if (!file) {
              const result = await uploadFile(
                "profile",
                "logo",
                "vendor",
                file
              );
              fileLogo = {
                LogoName: result.fileName,
                LogoPath: result.filePath,
              };
            }
          });
          await Promise.all(logoUploadPromises);
        }

        let revDocFile = {};
        if (Array.isArray(attcrelevantDoc) && attcrelevantDoc.length > 0) {
          const relevantDocPromises = attcrelevantDoc.map(async (file) => {
            const result = await uploadFile("document", 'relevantdoc', 'vendor', file);
            revDocFile = { RelevantDocName: result.fileName, RelevantDocPath: result.filePath }
          });
          await Promise.all(relevantDocPromises);
        } else {
          revDocFile = { RelevantDocName: null, RelevantDocPath: null };
        }

        //done addupload //
        let fileUploadPromises = rows.map(async (file, index) => {
          if (typeof file.file === "object") {
            if (!file.file) {
              const result = await uploadFile(
                "profile",
                "contact",
                "vendor",
                file.file
              );
              const singlefile = {
                FileName: result.fileName,
                FilePath: result.filePath,
              };
              rows[index].file = singlefile;
            } else {
              rows[index].file = { FileName: null, FilePath: null };
            }
          }
        });
        await Promise.all(fileUploadPromises);

        let newRow = rows.map((item) => ({
          contactLineId: item?.ContactLineId ? item?.ContactLineId : "",
          firstName: item?.firstName,
          lastName: item?.lastName,
          email: item?.email,
          phoneNo: item?.phoneNo,
          checked: item?.checked,
          phoneCode: item?.PhoneCode,
        }));

        const result = {
          // selfService: false,
          userId: user.id,
          username: user.Name,
          vendor: formState.vendor,
          vendorId: Number(id),
          transactionId: transactionList[3].id,
          roleId: Number(user.RoleId),
          subsidiary: Number(formState.subsidiary),
          currency: Number(formState.currency),
          addressLine1: formState.addressLine1,
          addressLine2: formState.addressLine2,
          pincode: formState.pincode,
          country: formState.country,
          state: formState.state,
          city: formState.city,
          terms: Number(formState.terms),
          website: formState.website,
          logo: typeof attachLogoFile === "string" ? attachlogo : fileLogo,
          primary_email: formState.pEmail,
          deletedRow: contactInfoId,
          contact_information: newRow,
          relevantDoc: relevantDoc && relevantDoc.length > 0
            ? relevantDoc[0]
            : (revDocFile && Object.keys(revDocFile).length !== 0)
              ? revDocFile
              : null,

        };
        setVendorDetails(result);
        setActiveStep(activeStep + 1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCrossImage = () => {
    setAttachLogoFile(null);
  };

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Vendor</title>
      </Helmet>
      <Loader isLoading={loading} />
      <div className="container-section">
        <div className="vendor-req-sec">
          <div className="section-header">
            <button onClick={() => navigate(-1)} className="back-viewbtn me-2">
              <BackButton />
            </button>
            <h3>Vendor Requisition Form</h3>
          </div>
          <div className="vendor-req-sec-form form-style">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="vendor" className="form-label">
                  Legal Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="vendor"
                  className="form-control"
                  placeholder="Enter legal name"
                  name="vendor"
                  value={formState.vendor}
                  onChange={handleChange}
                />
                {errors.vendor && (
                  <span className="error-text">{errors.vendor}</span>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="subsidiary" className="form-label">
                  Subsidiary<span className="required">*</span>
                </label>
                <select
                  name="subsidiary"
                  id="subsidiary"
                  className="form-select"
                  value={formState.subsidiary}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {subsidiaryList.map((item, index) => (
                    <option key={index} value={item.Id}>
                      {item.Name}
                    </option>
                  ))}
                </select>
                {errors.subsidiary && (
                  <span className="error-text">{errors.subsidiary}</span>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="currency" className="form-label">
                  Currency<span className="required">*</span>
                </label>
                <select
                  name="currency"
                  id="currency"
                  className="form-select"
                  value={formState.currency}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {currencyList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.CurrencyCode}
                    </option>
                  ))}
                </select>
                {errors.currency && (
                  <span className="error-text">{errors.currency}</span>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="terms" className="form-label">
                  Payment Terms<span className="required">*</span>
                </label>
                <select
                  name="terms"
                  id="terms"
                  className="form-select"
                  value={formState.terms}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {termsList.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.Term}
                    </option>
                  ))}
                </select>
                {errors.terms && (
                  <span className="error-text">{errors.terms}</span>
                )}
              </div>
              {/* <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="pEmail" className="form-label">
                  Email<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="pEmail"
                  className="form-control"
                  placeholder="Enter email"
                  name="pEmail"
                  value={formState.pEmail}
                  onChange={handleChange}
                />
                {errors.pEmail && (
                  <span className="error-text">{errors.pEmail}</span>
                )}
              </div>  */}
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="pEmail" className="form-label">
                  Email<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="pEmail"
                  className="form-control"
                  placeholder="Enter email"
                  name="pEmail"
                  value={formState.pEmail}
                  onChange={handleChange}
                  onBlur={handleEmailBlur}
                />
                {errors.pEmail && (
                  <div className="error-text">{errors.pEmail}</div>
                )}
                {!errors.pEmail && emailError.pEmail && (
                  <div className="error-text">{emailError.pEmail}</div>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="website" className="form-label">
                  Website
                </label>
                <input
                  type="text"
                  id="website"
                  className="form-control"
                  placeholder="Enter website"
                  name="website"
                  value={formState.website}
                  onChange={handleChange}
                />
                {errors.website && (
                  <span className="error-text">{errors.website}</span>
                )}
              </div>
              <div className={`col-${attachLogoFile
                ? "xl-3 col-lg-4 col-md-3"
                : "xl-3 col-lg-4 col-md-3 "
                } mb-20 position-relative`}
              >
                <label htmlFor="files" className="form-label">
                  Logo<span className="ms-1 infoIcon"></span>
                </label>
                <div className="d-flex position-relative align-items-center">
                  <div className="input-group file-input-container position-relative">
                    <input
                      class="form-control"
                      type="file"
                      id="formFile"
                      onChange={(e) => handleFileLogo(e)}
                      value={attachLogoFile ? attachLogoFile.Name : ""}
                    />
                    {attachLogoFile &&
                      <span
                        className="mdi mdi-close-circle file-preview-cross"
                        onClick={handleCrossImage}
                      ></span>
                    }
                  </div>
                  {typeof attachLogoFile == "string" ? (
                    <div>
                      <div className="cstm-preview-container">
                        <div className="cstm-view-file m-0">

                          <button type="button" className="btn" onClick={handleShow}>
                            view
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : Array.isArray(attachLogoFile) &&
                    attachLogoFile.length > 0 ? (
                    <div>
                      <div className="cstm-preview-container">
                        <div className="cstm-view-file m-0">

                          <button type="button" className="btn" onClick={handleShow}>
                            view
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                {errors.logo && <span className="error-text">{errors.logo}</span>}
              </div>
            </div>
          </div>
        </div>

        <div className="billing-adrs-sec">
          <div className="section-header">
            <h3>Billing Address</h3>
          </div>
          <div className="billing-adrs-sec-form form-style">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="addressLine1" className="form-label">
                  Address Line 1<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="addressLine1"
                  className="form-control"
                  placeholder="Enter address line 1"
                  name="addressLine1"
                  value={formState.addressLine1}
                  onChange={handleChange}
                />
                {errors.addressLine1 && (
                  <span className="error-text">{errors.addressLine1}</span>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="addressLine2" className="form-label">
                  Address Line 2
                </label>
                <input
                  type="text"
                  id="addressLine2"
                  className="form-control"
                  placeholder="Enter address line 2"
                  name="addressLine2"
                  value={formState.addressLine2}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="country" className="form-label">
                  Country<span className="required">*</span>
                </label>
                <select
                  name="country"
                  id="country"
                  className="form-select"
                  value={formState.country}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {countryList.map((item, index) => (
                    <option key={index}>
                      {item.name + "," + item.isoCode}
                    </option>
                  ))}
                </select>
                {errors.country && (
                  <span className="error-text">{errors.country}</span>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="state" className="form-label">
                  State<span className="required">*</span>
                </label>
                <select
                  name="state"
                  id="state"
                  className="form-select"
                  value={formState.state}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {stateList.map((item, index) => (
                    <option key={index}>
                      {item.name + "," + item.isoCode}
                    </option>
                  ))}
                </select>
                {errors.state && (
                  <span className="error-text">{errors.state}</span>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="city" className="form-label">
                  City<span className="required">*</span>
                </label>
                <select
                  name="city"
                  id="city"
                  className="form-select"
                  value={formState.city}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {cityList.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {errors.city && <span className="error-text">{errors.city}</span>}
              </div>
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="pincode" className="form-label">
                  Zip Code<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="pincode"
                  className="form-control"
                  placeholder="Enter pincode"
                  name="pincode"
                  maxLength={6}
                  value={formState.pincode}
                  onChange={handleChange}
                />
                {errors.pincode && (
                  <span className="error-text">{errors.pincode}</span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="contact-info-sec">
          <div className="section-header justify-content-between">
            <h3>Contact Information</h3>
            <button
              type="button"
              className="btn btn-sm section-hdr-btn"
              onClick={handleAdd}
            >
              Add More
            </button>
          </div>
          <div className="billing-adrs-sec-form form-style">
            {rows.map((row, index) => (
              <div
                className={
                  row.checked
                    ? "row primary-card-information"
                    : "row optional-div-information"
                }
                key={index}
              >
                <div className="primary-contact">
                  <Checkbox
                    {...label}
                    checked={row.checked}
                    onChange={() => handleCheckboxChange(row)}
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 28 },
                    }}
                  />{" "}
                  <label className="ms-1">Primary Contact</label>
                </div>

                {!row.checked && (
                  <span
                    className="remove-icon"
                    onClick={() => handleRemove(index)}
                  >
                    <RemoveIcon />
                  </span>
                )}

                <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                  <label htmlFor="firstName" className="form-label">
                    First Name<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    className="form-control"
                    placeholder="Enter first name"
                    name="firstName"
                    value={row.firstName}
                    onChange={(e) => handleContactChange(index, e)}
                  />
                  {errors.rows &&
                    errors.rows[index] &&
                    errors.rows[index].firstName && (
                      <span className="error-text">
                        {errors.rows[index].firstName}
                      </span>
                    )}
                </div>
                <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                  <label htmlFor="lastName" className="form-label">
                    Last Name<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    className="form-control"
                    placeholder="Enter last name"
                    name="lastName"
                    value={row.lastName}
                    onChange={(e) => handleContactChange(index, e)}
                  />
                  {errors.rows &&
                    errors.rows[index] &&
                    errors.rows[index].lastName && (
                      <span className="error-text">
                        {errors.rows[index].lastName}
                      </span>
                    )}
                </div>
                <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                  <label htmlFor="vendor" className="form-label">
                    Email<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="email"
                    className="form-control"
                    placeholder="Enter email"
                    name="email"
                    value={row.email}
                    onChange={(e) => handleContactChange(index, e)}
                  />
                  {errors.rows &&
                    errors.rows[index] &&
                    errors.rows[index].email && (
                      <span className="error-text">
                        {errors.rows[index].email}
                      </span>
                    )}
                </div>
                {/* <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                  <label htmlFor="phoneCode" className="form-label">
                    Phone Number
                  </label>
                  <div className="input-group">
                    <select
                      name="phoneCode"
                      id="phoneCode"
                      className="inp-mrg-sec"
                      value={row.PhoneCode}
                      onChange={(e) => handleContactChange(index, e)}
                    >
                      {countryList.map((item, index) => (
                        <option key={index}>{item.phonecode}</option>
                      ))}
                    </select>
                    <input
                      type="text"
                      id="phoneNo"
                      className="form-control inp-rgt-radius"
                      placeholder="Enter phone number"
                      name="phoneNo"
                      maxlength={10}
                      value={row.phoneNo}
                      onChange={(e) => handleContactChange(index, e)}
                    />
                    {errors.rows &&
                      errors.rows[index] &&
                      errors.rows[index].phoneNo && (
                        <span className="error-text">
                          {errors.rows[index].phoneNo}
                        </span>
                      )}
                    <div data-lastpass-icon-root="true"></div>
                  </div>
                </div> */}

                <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                  <label htmlFor="phoneCode" className="form-label">
                    Phone Number
                  </label>
                  <div className="input-group">
                    <select
                      name="phoneCode"
                      id="phoneCode"
                      className="inp-mrg-sec"
                      value={row.PhoneCode}
                      onChange={(e) => handleContactChange(index, e)}
                    >
                      {countryList.map((item, index) => (
                        <option key={index} value={item.phonecode}>
                          {item.phonecode}
                        </option>
                      ))}
                    </select>
                    <input
                      type="text"
                      id="phoneNo"
                      className="form-control inp-rgt-radius"
                      placeholder="Enter phone number"
                      name="phoneNo"
                      maxLength={10}
                      value={row.phoneNo}
                      onChange={(e) => handleContactChange(index, e)}
                    />
                    {errors.rows &&
                      errors.rows[index] &&
                      errors.rows[index].phoneNo && (
                        <span className="error-text">
                          {errors.rows[index].phoneNo}
                        </span>
                      )}
                    <div data-lastpass-icon-root="true"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="section-header">
          <h3>Edit File</h3>
        </div>

        <div className="form-style">
          <div className="table-responsive  tbl-body-shadow mb-4">
            <table className="w-100  table-theme-1   ">
              <thead>
                <tr>
                  <th className="text-left">File Name</th>
                  <th className="text-left">File Type</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {(relevantDoc && relevantDoc.length > 0) ? (
                  relevantDoc.map((item) => (
                    <tr key={item?.RelevantDocName}>
                      <td className="text-left">{item?.RelevantDocName}</td>
                      <td className="text-left">Pdf</td>
                      <td className="text-center d-flex align-items-center justify-content-center">
                        <div className="download-btn-container">
                          <Link
                            to={`${BASE_IMAGE_URL}/${item.RelevantDocPath}`}
                            download
                          >
                            <button type="button" className="btn">
                              <DownloadIcon /> Download
                            </button>
                          </Link>
                        </div>
                        <div className="preview-btn-container ms-2">
                          <Link
                            className="ms-2"
                            target="_blank"
                            to={`/preview-pdf/${btoa(item.RelevantDocPath)}`}
                          >
                            <button type="button" className="btn">
                              <EyeIcon /> Preview
                            </button>
                          </Link>
                        </div>

                        <span className="ms-2" onClick={handleDeletefile}>
                          <DeleteIcon />
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center">
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {!relevantDoc && (
            <div className="pt-0">
              <div className="row mb-2">
                <div className="col-md-12 d-flex align-items-center">
                  <input
                    type="checkbox"
                    id="attachDoc"
                    className="form-check-input mt-0"
                    name="attachDoc"
                    checked={attachDoc}
                    onChange={handleCheckbox}
                  />

                  <label htmlFor="attachDoc" className="form-check-label ms-2">
                    Check for relevant document
                    {/* <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip className="custom-tooltip" id="tooltips1">
                        Maximum file size is 2MB
                      </Tooltip>
                    }
                  > */}
                    <span className="ms-1 infoIcon">
                      {/* <Icon
                        path={mdiInformationVariantCircleOutline}
                        size={0.6}
                      /> */}
                    </span>
                    {/* </OverlayTrigger> */}
                  </label>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-xl-4 col-lg-4 col-md-5">
                  <div className="input-group file-input-container">
                    <input
                      type="file"
                      id="files"
                      className="form-control"
                      name="files"
                      disabled={!attachDoc}
                      onChange={handleFileChange}
                      accept=".pdf"
                      ref={fileInputRef}
                    />
                    {errors.docErr && (
                      <span className="validation-error mt-4">
                        {errors.docErr}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* <div className="d-flex align-items-center justify-content-end mb-20">
            <div className="success-btn ms-2">
              <button
                type="submit"
                className="btn"
                onClick={(e) => handleSubmit(e)}
                disabled={loader}
              >
                {loader ? (
                  <>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div> */}
          <div className="stepper-next-btn">
            {activeStep !== 0 && activeStep !== steps.length - 1 && (
              <span className="danger-outline-btn ">
                <button
                  onClick={() => setActiveStep(activeStep - 1)}
                  className="btn previousstepper"
                >
                  Previous
                </button>
              </span>
            )}
            {activeStep !== steps.length - 1 && (
              <span className="success-btn ">
                <button
                  onClick={(e) => handleSubmit(e)}
                  className="btn"
                  disabled={submitDisable}
                >
                  Next
                </button>
              </span>
            )}
          </div>
        </div>

        <>
          <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            animation={false}
            className="modal-wrap custom-modal-backdrop"
            backdrop="static"
            keyboard={false}
          >
            <div className="file-preview-modal-body">
              <div className="">
                <span className="cross" onClick={handleClose}>
                  <img src={cross} alt="" />
                </span>
                <div className="">
                  <>
                    {typeof attachLogoFile == "string" ? (
                      <div className="d-flex justify-content-center">
                        <div className="view-logo">
                          <img
                            src={`${BASE_IMAGE_URL}/${attachLogoFile}`}
                            alt=""
                          />
                        </div>
                      </div>
                    ) : Array.isArray(attachLogoFile) &&
                      attachLogoFile.length > 0 ? (
                      <div className="d-flex justify-content-center">
                        <div className="view-logo">
                          <img
                            src={URL.createObjectURL(
                              new Blob([attachLogoFile[0]])
                            )}
                            alt=""
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <div className="view-logo">
                          <img
                            src={
                              "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
          </Modal>
        </>
      </div>
    </>
  );
};

export default EditVendor;
