import ReactApexChart from "react-apexcharts";
import { THOUSAND_VALUE } from "../../../utils/constant";

function GroupedBarChart({ categories = [], data = [], title }) {
    const chartData = {
        series: data,
        options: {
            chart: {
                toolbar: {
                    show: false
                },
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        position: 'top'
                    }
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val + THOUSAND_VALUE;
                },
                offsetY: -20,
                style: {
                    fontSize: '10px',
                    colors: ["#304758"]
                }
            },

            xaxis: {
                categories: categories,
                position: 'bottom',
                title: {
                    text: 'Months',
                },
            },
            yaxis: {
                title: {
                    text: 'Amount',
                },
                labels: {
                    formatter: function (val) {
                        return val + THOUSAND_VALUE;
                    }
                }
            },
            title: {
                text: title
            }
        }
    }
    return (
        <div id="Barchart">
            <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={337.5} />
        </div>
    );
}
export default GroupedBarChart;





