import React, { useContext, useState, useEffect } from 'react'
import AuthenContext from './utils/helpers/AuthContext';
import LoginPage from './components/authentication/LoginPage';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Layout from './components/layouts';
import ForgetPage from './components/authentication/ForgetPage';
import OtpPage from './components/authentication/OtpPage';
import { Toaster } from 'react-hot-toast';
import RestpwdPage from './components/authentication/ResetpwdPage';
import '@mdi/font/css/materialdesignicons.min.css';
import { ModalContext } from './utils/Context';
import CommonModal from './utils/modalContex';
import { TransactionProvider } from './utils/helpers/useTransaction';
import Pending from './components/pages/vendor/onboarding/Pending';
import MaintenancePage from './components/authentication/MaintenancePage';
import { getApi } from './utils/services';

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("");
  const [data, setData] = useState({});
  const authCtx = useContext(AuthenContext);
  const navigate = useNavigate();

  useEffect(() => {
    const getMaintenanceFlag = async () => {
      try {
        const result = await getApi('/maintenance');
        setIsMaintenance(result.res.maintenance_mode);
        if (result.res.maintenance_mode) {
          authCtx.logout();
          navigate("/login");
        }
      }
      catch (error) {
        console.log(error);
      }
    };
    getMaintenanceFlag();
  }, []);

  const handleModalData = (data, size = "xl") => {
    setModalData(data);
    setModalSize(size);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <TransactionProvider>
      <div>
        <ModalContext.Provider value={{
          handleModalData,
          closeModal,
          setData,
          data,
          isModalOpen,
          modalData,
        }}>
          <Toaster position="top-right" reverseOrder={false} />
          <Routes>
            {isMaintenance ? (
              <Route path="*" element={<MaintenancePage />} />
            ) : (
              <>
                <Route exact path="/login" element={<LoginPage />} />
                <Route exact path="/forgot-password" element={<ForgetPage />} />
                <Route exact path="/one-time-password/:id" element={<OtpPage />} />
                <Route exact path="/approval-pending" element={<Pending />} />
                {
                  authCtx.verifyOtp &&
                  <Route exact path="/rest-password" element={<RestpwdPage />} />
                }
                {
                  authCtx.isLoggedIn ?
                    <Route exact path="/*" element={<Layout />} />
                    : <Route exact path="/login" element={<LoginPage />} />
                }
                <Route path="*" element={<Navigate to={authCtx.isLoggedIn ? '/' : '/login'} />} />
              </>
            )}
          </Routes>
          <CommonModal
            handleModalData={handleModalData}
            isModalOpen={isModalOpen}
            modalData={modalData}
            modalSize={modalSize}
            closeModal={closeModal}
          />
        </ModalContext.Provider>
      </div>
    </TransactionProvider >
  )
}

export default App