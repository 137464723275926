import React, { useEffect, useState } from 'react';
import { useFeedsColumnsVendor } from '../../../utils/helpers/tableHelpers/useFeedsColumnsVendor';
import { getApi } from '../../../utils/services';
import { VENDOR } from '../../../utils/apiRoutes';
import Vendor from '../../Table/Vendor';
import { Helmet } from "react-helmet";
import Loader from '../../common/Loader';

const VendorsList = () => {
    const [vendors, setVendor] = useState([]);
    const [loader, setLoader] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const pageSize = 10;
    const [totalPages, setTotalPages] = useState(1);
    const [globalSearch, setGlobalSearch] = useState("");
    const [searchQuery, setSearchQuery] = useState(""); 

    const handleVendorList = async (pageNumber, pageSize, searchQuery) => {
        try {
            setLoader(true);
            const result = await getApi(`${VENDOR}?&page=${pageNumber}&pageSize=${pageSize}&globalSearch=${searchQuery}`);
            setLoader(false);
            setVendor(result.res.vendor_list);
            const totalItems = result.res.record_count;
            setTotalPages(Math.ceil(totalItems / pageSize));
        } catch (error) {
            setLoader(false);
        }
    };

    const columns = useFeedsColumnsVendor(handleVendorList, pageNumber, pageSize, searchQuery);

    useEffect(() => {
        handleVendorList(pageNumber, pageSize, searchQuery);
    }, [pageNumber, pageSize, searchQuery]);

    const handlePageChange = (page) => {
        setPageNumber(page);
    };

    const handleSearchSubmit = () => {
        if (globalSearch !== searchQuery) {
          setSearchQuery(globalSearch);
          setPageNumber(1);
        } else {
            handleVendorList(pageNumber, pageSize, searchQuery);
        }
      };

    return (
        <>
            <Helmet>
                <title>Vendor Portal || Vendor List</title>
            </Helmet>
            <Loader isLoading={loader} />
            <div className="container-section">
                <div className="section-header">
                    <h3>Vendors</h3>
                </div>
                <div className="form-style">
                    <Vendor
                        columns={columns}
                        data={vendors}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        globalSearch={globalSearch}
                        setGlobalSearch={setGlobalSearch}
                        handleVendorList={handleVendorList}
                        pageSize={pageSize}
                        handleSearchSubmit={handleSearchSubmit} 
                    />
                </div>
            </div>
        </>
    );
};

export default VendorsList;