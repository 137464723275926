import React from "react";

const ContractIcon = () => {
  return (
    <>
      <svg
        width="19"
        height="15"
        viewBox="0 0 19 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 14.5455V12C0 11.4849 0.132727 11.0115 0.398182 10.58C0.663636 10.1485 1.01576 9.81881 1.45455 9.59093C2.39394 9.12123 3.34848 8.76911 4.31818 8.53457C5.28788 8.30002 6.27273 8.18245 7.27273 8.18184C8.27273 8.18123 9.25758 8.29881 10.2273 8.53457C11.197 8.77033 12.1515 9.12245 13.0909 9.59093C13.5303 9.8182 13.8827 10.1479 14.1482 10.58C14.4136 11.0121 14.5461 11.4855 14.5455 12V14.5455H0ZM15.3636 14.5455V11.8182C15.3636 11.1515 15.1782 10.5112 14.8073 9.8973C14.4364 9.28336 13.9097 8.75699 13.2273 8.3182C14 8.40911 14.7273 8.56457 15.4091 8.78457C16.0909 9.00457 16.7273 9.27336 17.3182 9.59093C17.8636 9.89396 18.2803 10.2309 18.5682 10.6018C18.8561 10.9728 19 11.3782 19 11.8182V14.5455H15.3636ZM7.27273 7.27275C6.27273 7.27275 5.41667 6.91669 4.70455 6.20457C3.99242 5.49245 3.63636 4.63639 3.63636 3.63639C3.63636 2.63639 3.99242 1.78033 4.70455 1.06821C5.41667 0.356084 6.27273 2.33097e-05 7.27273 2.33097e-05C8.27273 2.33097e-05 9.12879 0.356084 9.84091 1.06821C10.553 1.78033 10.9091 2.63639 10.9091 3.63639C10.9091 4.63639 10.553 5.49245 9.84091 6.20457C9.12879 6.91669 8.27273 7.27275 7.27273 7.27275ZM15.3636 3.63639C15.3636 4.63639 15.0076 5.49245 14.2955 6.20457C13.5833 6.91669 12.7273 7.27275 11.7273 7.27275C11.5606 7.27275 11.3485 7.25396 11.0909 7.21639C10.8333 7.17881 10.6212 7.13699 10.4545 7.09093C10.8636 6.60608 11.1782 6.0682 11.3982 5.4773C11.6182 4.88639 11.7279 4.27275 11.7273 3.63639C11.7267 3.00002 11.617 2.38639 11.3982 1.79548C11.1794 1.20457 10.8648 0.66669 10.4545 0.181841C10.6667 0.106084 10.8788 0.0566901 11.0909 0.0336598C11.303 0.0106295 11.5152 -0.000582751 11.7273 2.33097e-05C12.7273 2.33097e-05 13.5833 0.356084 14.2955 1.06821C15.0076 1.78033 15.3636 2.63639 15.3636 3.63639Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default ContractIcon;
