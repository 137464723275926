import { useState } from 'react';
import { TDS_ERROR } from '../utils/helpers/globalErrorMessage';

const AddTDSValidation = () => {
    const [errors, setErrors] = useState({});

    const validateForm = (tdsAmount) => {

        let errors = {};
        let formIsValid = true;

        if (!tdsAmount) {
            formIsValid = false;
            errors.tdsAmount = TDS_ERROR;
        }

        setErrors(errors);
        return formIsValid;
    };

    return { errors, setErrors, validateForm };
};

export default AddTDSValidation;
