import React, { useEffect, useState } from 'react'
import { getApi } from '../../../utils/services';
import { AP_AGING, PAYMENT_LIST_API, PENDING_REPORTS } from '../../../utils/apiRoutes';
import { useFeedPayment } from '../../../utils/helpers/tableHelpers/useFeedPayment';
import { PAYMENT_PENDING } from '../../../utils/constant';
import PieChart from '../../charts/piechart/PieChart';
import { Helmet } from "react-helmet";
import Loader from '../../common/Loader';
import GroupedBarChart from '../../charts/barchart/GroupedBarChart';
import Payment from '../../Table/Payment';

const SuperAdminDashboard = () => {
    const [paymentList, setPaymentList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [reportsPieChart, setReportsPieChart] = useState();
    const [totalAmount, setTotalAmount] = useState([]);
    const [agingBuckets, setAgingBuckets] = useState([]);
    const [label, setLabel] = useState();
    // const [selectedRows, setSelectedRows] = useState([]);

    //------------------------------pagination-------------------
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [globalSearch, setGlobalSearch] = useState("");
    const pageSize = 5;

    useEffect(() => {
        handlerReportsPieChart();
        handlerApAging()
    }, []);

    useEffect(() => {
        handlerPayList(pageNumber, pageSize, globalSearch);
    }, [pageNumber]);


    const handlerPayList = async (pageNumber, pageSize, globalSearch) => {
        try {
            setLoader(true)
            let result = await getApi(`${PAYMENT_LIST_API}?page=${pageNumber}&pageSize=${pageSize}&globalSearch=${globalSearch}&status=${PAYMENT_PENDING}`);
            setLoader(false)
            setPaymentList(result.res.payment_list)
            const totalItems = result.res.record_count;
            setTotalPages(Math.ceil(totalItems / pageSize));
        } catch (error) {
            setLoader(false)
        }
    }

    // const handleRowSelection = (selectedRows) => {
    //     setSelectedRows(selectedRows);
    // };

    const handlerReportsPieChart = async () => {
        try {
            setLoader(true)
            let result = await getApi(PENDING_REPORTS);
            let data = result.res.series;
            let label = result.res.labels;
            setLoader(false)
            setReportsPieChart(data)
            setLabel(label)
        } catch (error) {
            console.log(error);
        }
    }

    const handlerApAging = async () => {
        try {
            setLoader(true)
            let result = await getApi(AP_AGING);
            const { agingBuckets, totalAmount } = result.res
            let seriesData = [{
                name: 'Aging',
                data: totalAmount
            }];
            setLoader(false)
            setTotalAmount(seriesData)
            setAgingBuckets(agingBuckets)
        } catch (error) {
            console.log(error);
        }
    }

    const paymentColumn = useFeedPayment(handlerPayList, pageNumber, pageSize, globalSearch);

    const handlePageChange = (page) => {
        setPageNumber(page);
    };

    return (
        <>
            <Helmet>
                <title>Vendor Portal || Home</title>
            </Helmet>
            <Loader isLoading={loader} />
            <>
                <div className='col-12 mb-2'>
                </div>
                <div className='col-12 mb-2'>
                    <div className="row g-2">
                        <div className="col-12 col-lg-6">
                            <div className='container-section mb-0'>
                                <div className="card-body  min-card-body-height">
                                    <h5>Approval Queue</h5>
                                    <PieChart
                                        // title='Approval Queue'
                                        data={reportsPieChart}
                                        label={label} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className='container-section mb-0'>
                                <div className="card-body  min-card-body-height">
                                    <h5>AP Aging</h5>
                                    <GroupedBarChart
                                        // title='AP Aging'
                                        categories={agingBuckets}
                                        data={totalAmount}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="container-section mb-0">
                        <div className="section-header">
                            <h3>Pending Payment</h3>
                        </div>
                        <div className="card-body">
                            <Payment
                                columns={paymentColumn}
                                data={paymentList}
                                // handleRowSelection={handleRowSelection}
                                pageNumber={pageNumber}
                                setPageNumber={setPageNumber}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                                globalSearch={globalSearch}
                                setGlobalSearch={setGlobalSearch}
                                pageSize={pageSize}
                                handlerPayList={handlerPayList} />
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}

export default SuperAdminDashboard