import React, { useEffect, useState } from "react";
import { useFeedsColumnBills } from "../../../utils/helpers/tableHelpers/useFeedColumnBills";
import { FINANCE_PENDING } from "../../../utils/constant";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";
import { useFeedsColumnIndividualBills } from "../../../utils/helpers/tableHelpers/useFeedColumnIndividualBill";
import DashboardCard from '../../common/DashboardCard';
import { fetchBillList, fetchIndividualBillList } from "../../../utils/helpers/globalMethod";

const FinanceDashboard = () => {
  const [bills, setBills] = useState([]);
  const [loader, setLoader] = useState(false);
  const [individualBill, setIndividualBills] = useState([]);

  // pagination 
  const [billPageNumber, setBillPageNumber] = useState(1);
  const [individualBillPageNumber, setIndividualBillPageNumber] = useState(1);
  const [totalPagesBill, setTotalPagesBill] = useState(1);
  const [totalPagesIndividualBill, setTotalPagesIndividualBill] = useState(1);
  const [pageSize] = useState(5);
  const user = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    handlerBillList();
  }, [billPageNumber]);

  useEffect(() => {
    handlerIndividualBillList();
  }, [individualBillPageNumber]);

  const handlerIndividualBillList = async () => {
    await fetchIndividualBillList(pageSize, individualBillPageNumber, FINANCE_PENDING, setIndividualBills, setLoader, setTotalPagesIndividualBill, user);
  }

  const individualbillcolumns = useFeedsColumnIndividualBills(handlerIndividualBillList)

  const handlerBillList = async () => {
    fetchBillList(pageSize, billPageNumber, FINANCE_PENDING, setBills, setLoader, setTotalPagesBill, user);
  }

  const handleBillPageChange = (page) => {
    setBillPageNumber(page);
  };

  const handleIndividualBillPageChange = (page) => {
    setIndividualBillPageNumber(page);
  };

  const billcolumns = useFeedsColumnBills(handlerBillList);

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Home</title>
      </Helmet>

      <Loader isLoading={loader} />

      <>
        <DashboardCard
          title={"Bills"}
          columns={billcolumns}
          data={bills}
          handlerBillList={handlerBillList}
          billPageNumber={billPageNumber}
          setBillPageNumber={setBillPageNumber}
          totalPagesBill={totalPagesBill}
          handleBillPageChange={handleBillPageChange}
          pageSize={pageSize}
          mt="mt-4"
          fn=""
        />
        <DashboardCard
          title={"Standalone Bill"}
          columns={individualbillcolumns}
          data={individualBill}
          mt="mt-4"
          fn=""
          tableId="invoices"
          handlerIndividualBillList={handlerIndividualBillList}
          approvalType="invoice"
          individualBillPageNumber={individualBillPageNumber}
          setIndividualBillPageNumber={setIndividualBillPageNumber}
          totalPagesIndividualBill={totalPagesIndividualBill}
          handleIndividualBillPageChange={handleIndividualBillPageChange}
          pageSize={pageSize}
        />
      </>

    </>
  );
};

export default FinanceDashboard;
