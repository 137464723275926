
import { useState } from 'react';
import { validateBankInfo, validateBankDocument } from '../utils/helpers/globalValidation';

const AddFinanceInfoValidation = () => {
    const [errors, setErrors] = useState({});

    const validateForm = (paymentModeId, inputValues, bankDocument) => {
        let { formIsValid, errors: bankInfoErrors } = validateBankInfo(inputValues);

        if (paymentModeId == 1) {
            const bankDocumentValidation = validateBankDocument(bankDocument);
            formIsValid = formIsValid && bankDocumentValidation.formIsValid;

            bankInfoErrors = { ...bankInfoErrors, ...bankDocumentValidation.errors };
        }

        setErrors(bankInfoErrors);

        return formIsValid;
    };

    return { errors, setErrors, validateForm };
};

export default AddFinanceInfoValidation;
