import React from 'react';

const BillToContent = () => {
  return (
    <div className="data-details-1">
                <div className="section-sub-heading">
                  <h4>Bill To:</h4>
                  <p>
                    JSTREAMS SOLUTION PVT LIMITED,
                    <br />
                    <span>Address: </span>Unitech Cyber Park, Unit No.- 1005 ,
                    10th Floor, Tower C, Sector 39, Gurugram, Haryana,
                    Pincode: 122003
                  </p>
                </div>
              </div>
  );
};

export default BillToContent;
