import React, { useEffect } from "react";
import { Pagination } from "react-bootstrap";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";

const Contract = ({
  columns,
  data,
  pageNumber,
  setPageNumber,
  totalPages,
  onPageChange,
  setGlobalSearch,
  globalSearch,
  handleSearchSubmit,
}) => {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex },
    canNextPage,
    previousPage,
    nextPage,
    canPreviousPage,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: pageNumber - 1 },
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    gotoPage(pageNumber - 1);
  }, [pageNumber, gotoPage]);

  const handlePaginationChange = (page) => {
    setPageNumber(page + 1);
    onPageChange(page + 1);
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  const renderPaginationItems = () => {
    const paginationItems = [];

    if (pageIndex > 2) {
      paginationItems.push(
        <Pagination.Item key={0} onClick={() => handlePaginationChange(0)}>
          {1}
        </Pagination.Item>
      );
      paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
    }

    for (
      let i = Math.max(0, pageIndex - 1);
      i <= Math.min(totalPages - 1, pageIndex + 1);
      i++
    ) {
      paginationItems.push(
        <Pagination.Item
          key={i}
          active={i === pageIndex}
          onClick={() => handlePaginationChange(i)}
        >
          {i + 1}
        </Pagination.Item>
      );
    }

    if (pageIndex < totalPages - 3) {
      paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
      paginationItems.push(
        <Pagination.Item
          key={totalPages - 1}
          onClick={() => handlePaginationChange(totalPages - 1)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return paginationItems;
  };

  const handleChange = (e) => {
    setGlobalSearch(e.target.value);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-4 d-flex align-items-center">
          <input
            value={globalSearch}
            onKeyDown={handleKeyPress}
            onChange={handleChange}
            placeholder="Search"
            className="form-control mb-3 me-2"
          />
          <span className="success-btn mb-3" onClick={handleSearchSubmit}>
            <button className="btn">Go</button>
          </span>
        </div>
      </div>

      <div className="table-responsive tbl-body-shadow">
        <table className="w-100 table-theme-1" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="d-flex">
                      {column.render("Header")}
                      {columnIndex !== columns.length - 1 &&
                        columnIndex !== columns.length - 2 &&
                        (column.isSorted ? (
                          column.isSortedDesc ? (
                            <span> 🔽</span>
                          ) : (
                            <span> 🔼</span>
                          )
                        ) : (
                          <span> ⬆️⬇️</span>
                        ))}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
            {page.length === 0 && (
              <tr>
                <td colSpan={columns.length} className="text-center">
                  No data found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-end mt-3">
        <Pagination>
          <div
            onClick={() => {
              if (canPreviousPage) {
                previousPage();
                onPageChange(pageNumber - 1);
              }
            }}
            className={`custom-button ${!canPreviousPage ? "disabled" : ""}`}
          >
            Previous
          </div>

          {/* Render pagination items */}
          {renderPaginationItems()}
          <div
            onClick={() => {
              if (!canNextPage && pageNumber < totalPages) {
                nextPage();
                onPageChange(pageNumber + 1);
              } else if (pageNumber < totalPages) {
                onPageChange(pageNumber + 1);
              }
            }}
            className={`custom-button ${!canNextPage || pageNumber >= totalPages ? "disabled" : "not-allowed"
              }`}
          >
            Next
          </div>
        </Pagination>
      </div>
    </div>
  );
};

export default Contract;