import React, { useState, useContext, useEffect } from 'react';
import { Pagination } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect
} from 'react-table';
import toast from 'react-hot-toast';
import { ModalContext } from '../../utils/Context';
import BulkApproval from '../modal/BulkApproval';
import { ADMIN_MANAGER_ID, VENDOR_REJECT, VP_ID } from '../../utils/constant';
import RejectionModal from '../modal/RejectionModal';

const DashboardTable = ({
  columns,
  data,
  title,
  handlerBillList,
  handlerPurchaseContractList,
  handleVendorList,
  IndividualBillTrue,
  allIndividualBillFalse,
  handlerIndividualBillList,
  pageNumber,
  individualBillPageNumber,
  billPageNumber,
  purchaseContractPageNumber,
  vendorPageNumber,
  totalPagesIndividualBill,
  totalPagesBill,
  totalPagesPurchase,
  totalPagesVendor,
  onPageChange,
  setPurchaseContractPageNumber,
  setVendorPageNumber,
  setIndividualBillPageNumber,
  setBillPageNumber,
  handlePurchaseContractPageChange,
  handleVendorPageChange,
  handleBillPageChange,
  handleIndividualBillPageChange,
  pageSize
}) => {

  const user = JSON.parse(localStorage.getItem('userData'));
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const modalContext = useContext(ModalContext);
  const { handleModalData } = modalContext;
  const [disableApprove, setDisableApprove] = useState(false);
  const [disableReject, setDisableReject] = useState(false);

  const totalPages = title === 'Purchase Contracts' ? totalPagesPurchase : title === 'Bills' ? totalPagesBill : title === 'Standalone Bill' ? totalPagesIndividualBill : title === 'Vendors' ? totalPagesVendor : 0;

  const handleBulkApproval = () => {
    const selectedRowData = selectedFlatRows
      .filter(row => row.original.Status !== VENDOR_REJECT)
      .map(row => row.original.id);
    if (selectedRowData.length === 0) {
      toast.error('Please select at least one row to approve.');
      setDisableApprove(true);
      return;
    }

    let app = (
      <BulkApproval
        selectedRowData={selectedRowData}
        title={title}
        handlerBillList={handlerBillList}
        handlerPurchaseContractList={handlerPurchaseContractList}
        allIndividualBillFalse={allIndividualBillFalse}
        IndividualBillTrue={IndividualBillTrue}
        handlerIndividualBillList={handlerIndividualBillList}
      />
    );
    handleModalData(app, 'md');
  };

  const handleBulkRejection = () => {
    const selectedRowData = selectedFlatRows
      .filter(row => row.original.Status !== VENDOR_REJECT)
      .map(row => row.original);

    if (selectedRowData.length === 0) {
      toast.error('Please select at least one row to reject.');
      setDisableReject(true);
      return;
    }

    let reject = (
      <RejectionModal
        selectedRowData={selectedRowData}
        title={title}
        handlerBillList={handlerBillList}
        handlerPurchaseContractList={handlerPurchaseContractList}
        allIndividualBillFalse={allIndividualBillFalse}
        IndividualBillTrue={IndividualBillTrue}
        handlerIndividualBillList={handlerIndividualBillList}
      />
    );
    handleModalData(reject, 'md');
  };

  const defaultColumn = React.useMemo(
    () => ({
      Filter: () => null
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex },
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    selectedFlatRows
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: title === 'Purchase Contracts' ? purchaseContractPageNumber - 1 : title === 'Bills' ? billPageNumber - 1 : title === 'Standalone Bill' ? individualBillPageNumber - 1 : title === 'Vendors' ? vendorPageNumber - 1 : 0 },
      manualPagination: true,
      pageCount: totalPages,
      state: { selectedRowIds },
      setSelectedRowIds
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      const showSelectionColumn =
        title !== 'Vendors' &&
        (user.RoleId === ADMIN_MANAGER_ID || user.RoleId === VP_ID) &&
        (title === 'Bills' || title === 'Standalone Bill' || title === 'Purchase Contracts');

      if (showSelectionColumn) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              row.original.Status !== VENDOR_REJECT ? (
                <input type="checkbox" {...row.getToggleRowSelectedProps()} />
              ) : null
            ),
            disableSortBy: true,
          },
          ...columns,
        ]);
      }
    }
  );

  useEffect(() => {
    gotoPage(title === 'Purchase Contracts' ? purchaseContractPageNumber - 1 : title === 'Bills' ? billPageNumber - 1 : title === 'Standalone Bill' ? individualBillPageNumber - 1 : title === 'Vendors' ? vendorPageNumber - 1 : 0);
  }, [pageNumber, gotoPage]);

  const handlePaginationChange = (page) => {
    if (title === 'Purchase Contracts') {
      setPurchaseContractPageNumber(page + 1);
      handlePurchaseContractPageChange(page + 1);
    } else if (title === 'Bills') {
      setBillPageNumber(page + 1);
      handleBillPageChange(page + 1);
    } else if (title === 'Standalone Bill') {
      setIndividualBillPageNumber(page + 1)
      handleIndividualBillPageChange(page + 1);
    } else if (title === 'Vendors') {
      setVendorPageNumber(page + 1);
      handleVendorPageChange(page + 1);
    }
  };

  const renderPaginationItems = () => {
    const paginationItems = [];

    if (pageIndex > 2) {
      paginationItems.push(
        <Pagination.Item key={0} onClick={() => handlePaginationChange(0)}>
          {1}
        </Pagination.Item>
      );
      paginationItems.push(<Pagination.Ellipsis key='start-ellipsis' />);
    }

    for (
      let i = Math.max(0, pageIndex - 1);
      i <= Math.min(totalPages - 1, pageIndex + 1);
      i++
    ) {
      paginationItems.push(
        <Pagination.Item
          key={i}
          active={i === pageIndex}
          onClick={() => handlePaginationChange(i)}
        >
          {i + 1}
        </Pagination.Item>
      );
    }

    if (pageIndex < totalPages - 3) {
      paginationItems.push(<Pagination.Ellipsis key='end-ellipsis' />);
      paginationItems.push(
        <Pagination.Item
          key={totalPages - 1}
          onClick={() => handlePaginationChange(totalPages - 1)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return paginationItems;
  };

  return (
    <div>
      <div className='table-responsive tbl-body-shadow'>
        <table className='w-100 table-theme-1' {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className='d-flex'>
                      {column.render('Header')}
                      {(headerGroup.headers[0].id !== 'selection' || columnIndex !== 0) && (columnIndex !== columns.length - 1) && (columnIndex !== columns.length - 2) && (columnIndex !== columns.length) && (
                        column.isSorted ? (
                          column.isSortedDesc ? (
                            <span> 🔽</span>
                          ) : (
                            <span> 🔼</span>
                          )
                        ) : (
                          <span> ⬆️⬇️</span>
                        )
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td
                      {...cell.getCellProps()}
                      style={{ border: '1px solid black' }}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
            {page.length === 0 && (
              <tr>
                <td colSpan={columns.length} className='text-center'>
                  No data found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className='d-flex justify-content-end mt-3'>
        <Pagination>
          <div
            onClick={() => {
              if (canPreviousPage) {
                previousPage();
                handlePaginationChange(pageIndex - 1);
              }
            }}
            className={`custom-button ${!canPreviousPage ? 'disabled' : ''}`}
            style={{
              cursor: canPreviousPage ? 'pointer' : 'not-allowed'
            }}
          >
            Previous
          </div>

          {renderPaginationItems()}

          <div
            onClick={() => {
              if (canNextPage) {
                nextPage();
                handlePaginationChange(pageIndex + 1); // Pass the new page index
              }
            }}
            className={`custom-button ${!canNextPage ? 'disabled' : ''}`}
            style={{
              cursor: canNextPage ? 'pointer' : 'not-allowed'
            }}
          >
            Next
          </div>
        </Pagination>
        <div className="ms-2">
          {(selectedFlatRows.length > 0 && title !== 'Vendors') && (
            <div className="d-flex">
              <div className="danger-btn me-2">
                <button type="button" className="btn" onClick={handleBulkRejection} disabled={disableReject}>Reject</button>
              </div>
              <div className="success-btn me-2">
                <button type="button" className="btn" onClick={handleBulkApproval} disabled={disableApprove}>Approve</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardTable;

