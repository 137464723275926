import React, { useState, useContext, useEffect } from "react";
// import { ModalContext } from '../../utils/Context';
// import BulkPaymentApproveModal from '../modal/BulkPaymentApprove';
import { Pagination } from "react-bootstrap";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
  // useRowSelect
} from "react-table";

const Payment = ({
  columns,
  data,
  pageNumber,
  setPageNumber,
  totalPages,
  onPageChange,
  // handleRowSelection,
  handlerPayList,
  setGlobalSearch,
  globalSearch,
  pageSize,
}) => {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  // const [selectedRowIds, setSelectedRowIds] = useState([]);
  // const modalContext = useContext(ModalContext);
  // const { handleModalData } = modalContext;

  // const handleCancel = () => {
  //   toggleAllRowsSelected(false);
  // };

  // const handleBulkModal = () => {
  //   handleRowSelection(selectedFlatRows.map(row => row.id), selectedFlatRows.map(row => row.original));
  //   let pay = <BulkPaymentApproveModal selectedRows={selectedFlatRows.map(row => row.original)} handlerPayList={handlerPayList} pageNumber={pageNumber} pageSize={pageSize} globalSearch={globalSearch} onCancel={handleCancel} />;
  //   handleModalData(pay, "md");
  // };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex },
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    // selectedFlatRows,
    // toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: pageNumber - 1,
        pageSize: 10,
        sortBy: [{ id: columns[0].accessor, desc: true }],
      },
      manualPagination: true,
      pageCount: totalPages,
      // state: {
      //   selectedRowIds,
      // },
      // setSelectedRowIds,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    // useRowSelect,
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [
    //     {
    //       id: "selection",
    //       Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
    //       ),
    //       Cell: ({ row }) => (
    //         <input
    //           type="checkbox"
    //           {...row.getToggleRowSelectedProps()}
    //         />
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  );

  useEffect(() => {
    gotoPage(pageNumber - 1);
  }, [pageNumber, gotoPage]);

  const handlePaginationChange = (page) => {
    setPageNumber(page + 1);
    onPageChange(page + 1);
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const renderPaginationItems = () => {
    const paginationItems = [];

    if (pageIndex > 2) {
      paginationItems.push(
        <Pagination.Item key={0} onClick={() => handlePaginationChange(0)}>
          {1}
        </Pagination.Item>
      );
      paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
    }

    for (let i = Math.max(0, pageIndex - 1); i <= Math.min(totalPages - 1, pageIndex + 1); i++) {
      paginationItems.push(
        <Pagination.Item
          key={i}
          active={i === pageIndex}
          onClick={() => handlePaginationChange(i)}
        >
          {i + 1}
        </Pagination.Item>
      );
    }

    if (pageIndex < totalPages - 3) {
      paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
      paginationItems.push(
        <Pagination.Item
          key={totalPages - 1}
          onClick={() => handlePaginationChange(totalPages - 1)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return paginationItems;
  };

  const handleChange = (e) => {
    setGlobalSearch(e.target.value);
  };

  const handleSubmit = () => {
    handlerPayList(1, pageSize, globalSearch);
  };

  const GrandTotal = page.reduce((total, obj) => total + obj.original.GrandTotal, 0);
  const TotalGrandAmount = page.reduce((total, obj) => total + obj.original.Total, 0);
  const TotaltdsAmount = page.reduce((total, obj) => total + obj.original.TDSAmount, 0);
  const currencySymbol = data.find(item => item.SubsidiaryId === 2)?.CurrencySymbol;
  // const TotalPaidAmount = page.reduce((total, obj) => total + obj.original.TotalAmountPaid, 0);

  return (
    <div>
      <div className="row">
        <div className="col-md-4 d-flex align-items-center">
          <input
            value={globalSearch}
            onKeyDown={handleKeyPress}
            onChange={handleChange}
            placeholder="Search"
            className="form-control mb-3 me-2"
          />
          <span onClick={handleSubmit} className="success-btn mb-3">
            <button className="btn">Go</button>
          </span>
        </div>
      </div>

      <div className="table-responsive tbl-body-shadow">
        <table className="w-100 table-theme-1" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="d-flex">
                      {column.render('Header')}
                      {columnIndex !== 0 && columnIndex !== columns.length - 1 && columnIndex !== columns.length - 2 && columnIndex !== columns.length && (
                        column.isSorted ? (
                          column.isSortedDesc ? (
                            <span> 🔽</span>
                          ) : (
                            <span> 🔼</span>
                          )
                        ) : (
                          <span> ⬆️⬇️</span>
                        )
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
            {page.length === 0 && (
              <tr>
                <td colSpan={columns.length} className="text-center">
                  No data found.
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            {page.length !== 0 && (
              <tr className="total-show border-0">
                {/* <td className="text-end text-dark table-footer fw-bold"></td> */}
                <td className="text-end text-dark table-footer fw-bold"></td>
                <td className="text-end text-dark table-footer fw-bold"></td>
                <td className="text-left text-dark fw-bold">Total</td>
                <td className="text-end text-dark fw-bold">
                  {currencySymbol} {GrandTotal && !isNaN(GrandTotal) ? GrandTotal.toFixed(2) : "0.00"}
                </td>
                <td className="text-end text-dark fw-bold">
                  {currencySymbol} {TotaltdsAmount && !isNaN(TotaltdsAmount) ? TotaltdsAmount.toFixed(2) : "0.00"}
                </td>
                <td className="text-end text-dark fw-bold">
                  {currencySymbol} {TotalGrandAmount && !isNaN(TotalGrandAmount) ? TotalGrandAmount.toFixed(2) : "0.00"}
                </td>
                {/* <td className="text-end text-dark fw-bold">
                  {currencySymbol} {TotalPaidAmount && !isNaN(TotalPaidAmount) ? TotalPaidAmount.toFixed(2) : "0.00"}
                </td> */}
                <td className="text-end text-dark fw-bold"></td>
                <td className="text-end text-dark fw-bold"></td>
              </tr>
            )}
          </tfoot>

        </table>
      </div>

      <div className="d-flex justify-content-end mt-3">
        <div>
          {/* {selectedFlatRows.length > 0 && (
            <div className="success-btn height-sucess-in-dash me-3">
              <button type="button" className="btn" onClick={handleBulkModal}>Pay</button>
            </div>
          )} */}
        </div>
        <Pagination>
          <div
            onClick={() => {
              if (canPreviousPage) {
                previousPage();
                onPageChange(pageNumber - 1);
              }
            }}
            className={`custom-button ${!canPreviousPage ? "disabled" : ""}`}
          >
            Previous
          </div>

          {/* Render pagination items */}
          {renderPaginationItems()}

          <div
            onClick={() => {
              if (!canNextPage && pageNumber < totalPages) {
                nextPage();
                onPageChange(pageNumber + 1);
              } else if (pageNumber < totalPages) {
                onPageChange(pageNumber + 1);
              }
            }}
            className={`custom-button ${!canNextPage || pageNumber >= totalPages ? "disabled" : "not-allowed"}`}
          >
            Next
          </div>
        </Pagination>
      </div>
    </div>
  );
};

export default Payment;
