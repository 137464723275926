import React from "react";
import ThumbIcon from "../../../../assets/image/ThumbIcon";

const Instructions = ({
  activeStep,
  setActiveStep,
  steps,
  setViewVendorDetails,
}) => {
  return (
    <div>
      <div className="edit-vendor-container mt-0">
        <div className="section-header">
          <h3>Steps to Follow</h3>
        </div>
        <div className="cstm-statement-div">
          <div className="cstm_instruction mt-4 p-4">

            <div className="cstm_instruction_div_1">
              <ThumbIcon />
              <div className="cstm_paragraph_inst_div">
                <span className="under_statement_div">Profile Setup:</span> Verify
                profile information like legal name, terms & address etc.</div>
            </div>

            <div className="cstm_instruction_div_1">
              {" "}
              <ThumbIcon />{" "}
              <div className="cstm_paragraph_inst_div">
                <span className="under_statement_div">
                  Upload Bank Info:
                </span>{" "}
                Upload your bank information.</div>
            </div>

            <div className="cstm_instruction_div">
              <ThumbIcon />{" "}
              <div className="cstm_paragraph_inst_div">
                <span className="under_statement_div">Confirmation:</span> Receive
                a confirmation once the process is complete.</div>
            </div>

          </div></div>

      </div>
      <div className="stepper-next-btn">
        {activeStep !== 0 && activeStep !== steps.length - 1 && (
          <span className="danger-outline-btn ">
            <button
              onClick={() => setActiveStep(activeStep - 1)}
              className="btn previousstepper"
            >
              Previous
            </button>
          </span>
        )}
        {activeStep !== steps.length - 1 && (
          <span className="success-btn ">
            <button
              onClick={(e) => setActiveStep(activeStep + 1)}
              className="btn"
            >
              Next
            </button>
          </span>
        )}
      </div>
    </div>
  );
};

export default Instructions;