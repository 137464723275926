import ApprovedIcon from "../../assets/image/ApprovedIcon";
import FullyPaidIcon from "../../assets/image/FullyPaidIcon";
import { RejectedIcon } from "../../assets/image/RejectedIcon";
import { ADMIN_EXECUTIVE_PENDING, FINANCE_PENDING, FULLY_PAID, PARTIALLY_PAID_STATUS_ID, PAYMENT_PENDING, VENDOR_APPROVE, VENDOR_MANAGER_PENDING, VENDOR_REJECT, VENDOR_VP_PENDING, VOID_STATUS_ID } from "../constant";
import VoidBillIcon from "../../assets/image/VoidBillIcon";
import PartiallyPaidIcon from "../../assets/image/PartiallyPaidIcon";
import CommonPendingIcon from "../../assets/image/CommonPendingIcon";

export const useStatusRenderer = (row) => {
  let statusIcon = null;
  switch (row.original.Status || row.original.StatusId) {
    case VENDOR_REJECT:
      if (row.original.BillStatusId === VOID_STATUS_ID) {
        statusIcon = <VoidBillIcon />;
      } else {
        statusIcon = <RejectedIcon />;
      }
      break;
    case VENDOR_MANAGER_PENDING:
      statusIcon = <CommonPendingIcon statusName={row?.original?.StatusName} />;
      break;
    case ADMIN_EXECUTIVE_PENDING:
      statusIcon = <CommonPendingIcon statusName={row?.original?.StatusName} />;
      break;
    case VENDOR_APPROVE:
      statusIcon = <ApprovedIcon />;
      break;
    case VENDOR_VP_PENDING:
      statusIcon = <CommonPendingIcon statusName={row?.original?.StatusName} />;
      break;
    case FINANCE_PENDING:
      statusIcon = <CommonPendingIcon statusName={row?.original?.StatusName} />;
      break;
    case PAYMENT_PENDING:
      if (row.original.BillStatusId === PARTIALLY_PAID_STATUS_ID) {
        statusIcon = <PartiallyPaidIcon />;
      } else if (row.original.BillStatusId === VOID_STATUS_ID) {
        statusIcon = <VoidBillIcon />;
      } else {
        statusIcon = <CommonPendingIcon statusName={row?.original?.StatusName} />;
      }
      break;
    case FULLY_PAID:
      statusIcon = <FullyPaidIcon />;
      break;
    default:
      statusIcon = null;
      break;
  }
  return <>{statusIcon}</>;
};