import { useState } from 'react';
import { EMAIL_REGX, WEBSITE_REGX } from '../utils/helpers/globalRegx';
import { ADDRESS_LINE1_ERROR, ALLOWED_IMAGE_TYPES, CITY_ERROR, COUNTRY_ERROR, CURRENCY_ERROR, ENTER_EMAIL_ERROR, FIRST_NAME_ERROR, INVALID_PHONE_ERROR, INVALID_PINCODE_ERROR, LAST_NAME_ERROR, LEGAL_NAME_ERROR, PAYMENT_TERMS_ERROR, PINCODE_ERROR, STATE_ERROR, SUBSIDIARY_ERROR, UPLOAD_MAX_SIZE_ERROR, UPLOAD_PDF_ERROR, VALID_FORMAT_ERROR } from '../utils/helpers/globalErrorMessage';

const AddVendorValidation = () => {
    const [errors, setErrors] = useState({});

    const isValidRelevantDoc = (file) => {
        const allowedTypes = ["application/pdf"];
        return allowedTypes.includes(file.type);
    };

    const isValidLogoType = (file) => {
        const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg'];
        return allowedTypes.includes(file.type);
    };

    const MAX_FILE_SIZE = 2 * 1024 * 1024;

    const validateForm = (vendor, subsidiary, currency, terms, pEmail, website, addressLine1, country, state, city, pincode, rows, file, relevantDoc) => {

        let errors = {};
        errors.rows = []
        let formIsValid = true;

        if (!vendor.trim()) {
            formIsValid = false;
            errors.vendor = LEGAL_NAME_ERROR;
        }
        if (!subsidiary) {
            formIsValid = false;
            errors.subsidiary = SUBSIDIARY_ERROR;
        }
        if (!currency) {
            formIsValid = false;
            errors.currency = CURRENCY_ERROR;
        }
        if (!terms) {
            formIsValid = false;
            errors.terms = PAYMENT_TERMS_ERROR;
        }
        if (!pEmail) {
            formIsValid = false;
            errors.pEmail = ENTER_EMAIL_ERROR;
        }
        else {
            if (!EMAIL_REGX.test(pEmail)) {
                formIsValid = false;
                errors.pEmail = VALID_FORMAT_ERROR;
            }
        }
        if (website) {
            if (!WEBSITE_REGX.test(website)) {
                formIsValid = false;
                errors.website = VALID_FORMAT_ERROR;
            }
        }
        if (!addressLine1.trim()) {
            formIsValid = false;
            errors.addressLine1 = ADDRESS_LINE1_ERROR;
        }
        if (!country) {
            formIsValid = false;
            errors.country = COUNTRY_ERROR;
        }
        if (!state) {
            formIsValid = false;
            errors.state = STATE_ERROR;
        }
        if (!city) {
            formIsValid = false;
            errors.city = CITY_ERROR;
        }
        if (!pincode) {
            formIsValid = false;
            errors.pincode = PINCODE_ERROR;
        }
        else {
            if (pincode.length < 6) {
                formIsValid = false;
                errors.pincode = INVALID_PINCODE_ERROR;
            }
        }
        if (file) {
            for (const item of file) {
                if (!isValidLogoType(item)) {
                    formIsValid = false;
                    errors.file = ALLOWED_IMAGE_TYPES;
                    break;
                }
                if (item.size > MAX_FILE_SIZE) {
                    formIsValid = false;
                    errors.file = UPLOAD_MAX_SIZE_ERROR;
                    break;
                }
            }
        }
        if (relevantDoc && relevantDoc.length > 0) {
            for (const item of relevantDoc) {
                if (!isValidRelevantDoc(item)) {
                    formIsValid = false;
                    errors.relevantDoc = UPLOAD_PDF_ERROR;
                    break;
                }
                if (item.size > MAX_FILE_SIZE) {
                    formIsValid = false;
                    errors.relevantDoc = UPLOAD_MAX_SIZE_ERROR;
                    break;
                }
            }
        }
        rows.forEach((item, index) => {
            const rowsErrors = {};

            if (!item.firstName.trim()) {
                formIsValid = false;
                rowsErrors.firstName = FIRST_NAME_ERROR;
            }
            if (!item.lastName.trim()) {
                formIsValid = false;
                rowsErrors.lastName = LAST_NAME_ERROR;
            }
            if (!item.email) {
                formIsValid = false;
                rowsErrors.email = ENTER_EMAIL_ERROR;
            }
            else {
                if (!EMAIL_REGX.test(item.email)) {
                    formIsValid = false;
                    rowsErrors.email = VALID_FORMAT_ERROR;
                }
            }
            if (item.phoneNo && item.phoneNo.length < 10) {
                formIsValid = false;
                rowsErrors.phoneNo = INVALID_PHONE_ERROR;
            }
            if (Object.keys(rowsErrors).length > 0) {
                errors.rows[index] = rowsErrors;
            }
            return rowsErrors;
        });

        if (errors.rows.length === 0) {
            delete errors.rows;
        }

        setErrors(errors);
        return formIsValid;
    };

    return { errors, setErrors, validateForm };
};

export default AddVendorValidation;
