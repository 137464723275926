import ReactApexChart from "react-apexcharts"
import { THOUSAND_VALUE } from "../../../utils/constant";

function LineColumnChart({ title, data = [], categories = [] }) {
  const chartData = {
    series: data,
    options: {
      chart: {
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        height: 350,
        type: 'line',
      },
      stroke: {
        width: [0, 4]
      },
      title: {
        text: title
      },
      colors: ['#0047AB', '#f01e2c'],

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '35%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
        formatter: function (val) {
          return val + THOUSAND_VALUE;
        }
      },
      xaxis: {
        categories: categories,
        title: {
          text: 'Months',
        },
      },
      yaxis: [{
        title: {
          text: 'Amount',
        },
        labels: {
          show: true,
          formatter: function (val) {
            return val + THOUSAND_VALUE;
          }
        }
      }],
      legend: {
        position: 'top'
      },
      Menubar: {
        show: true
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + THOUSAND_VALUE;
          }
        }
      }
    }
  }
  return (
    <div id="LineColumnChart">
      <ReactApexChart options={chartData.options} series={chartData.series} type="line" height={350}></ReactApexChart>
    </div>
  )

}
export default LineColumnChart











