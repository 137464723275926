import React, { useState } from "react";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { postApi } from "../../utils/services";
import { EDIT_ROLE } from "../../utils/apiRoutes";

const EditTerminology = ({ handleClose, show, selectedItem, onEditSuccess }) => {
    const [loader, setLoader] = useState(false);
    const [disable, setDisable] = useState(false);
    const [roleValue, setRoleValue] = useState({
        rolename: selectedItem ? selectedItem.RoleName : "",
        levels: selectedItem ? selectedItem.Levels : "",
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRoleValue((prev) => ({
            ...prev,
            [name]: value,
        }));
        setErrors({});
    };

    const handleSubmitData = async (e) => {
        e.preventDefault();

        const errors = {};
        if (!roleValue.rolename.trim()) {
            errors.rolename = "Role name is required";
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        setLoader(true);
        setDisable(true);

        try {
            const response = await postApi(EDIT_ROLE, {
                roleId: selectedItem.id,
                roleName: roleValue.rolename,
            });
            if (response.res.success) {
                toast.success(response.res.message);
                handleClose();
                onEditSuccess();
            }
            if (!response.res.success) {
                setLoader(false);
                setErrors({ rolename: response.res.message });
            }
        } catch (error) {
            console.error("Server error:", error);
        }
    };

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                animation={false}
                className="modal-wrap edit-section-header"
                backdrop="static"
                keyboard={false}
            >
                <div className="section-header cstm-section-color">
                    <h3 className="heading-color">Edit Terminology</h3>
                </div>
                <form onSubmit={handleSubmitData}>
                    <div className="form-style">
                        <div className="col-md-12 mb-20">
                            <div className="form-group position-relative">
                                <label htmlFor="rolename" className="form-label">
                                    Role Name<span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="rolename"
                                    className="form-control"
                                    placeholder="Enter item name"
                                    name="rolename"
                                    value={roleValue.rolename}
                                    onChange={handleChange}
                                />
                                {errors.rolename && (
                                    <div className="validation-error">
                                        {errors.rolename}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-end mb-20">
                            <div className="danger-outline-btn me-2">
                                <button type="button" className="btn" onClick={handleClose}>
                                    Cancel
                                </button>
                            </div>
                            <div className="success-btn ">
                                <button type="submit" className="btn" disabled={disable}>
                                    {loader ? (
                                        <>
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                            Loading...
                                        </>
                                    ) : (
                                        "Submit"
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default EditTerminology;