export const VENDOR = 'vendor/list'
export const REFRESH_TOKEN = 'refresh'
export const VENDORLOGIN = 'vendor/login'
export const STAFF_LOGIN = 'staff/login'
export const FINANCE_LOGIN = 'finance/login'
export const FORGOT_LOGIN_API = 'password/forgot'
export const VERIFY_OTP = 'otp/verify'
export const RESEND_OTP = 'otp/resend'
export const RESET_PASSWORD = 'password/update'
export const CONTRACT_LIST = 'contract/list'
export const BILLS_API = 'bill/list'
export const TRANSACTION_API = 'transaction/list'
export const PURCHASE_CONTRACT_APROVE = 'contract/approve'
export const SITE_CONFIGERATION = 'siteConfigeration/list'
export const VENDOR_TERMS_SUBSIDIARY = 'vendor/terms-subsidiary'
export const VENDOR_ITEMS = 'vendor/items'
export const ADD_CONTRACT = 'contract/add'
export const VIEW_CONTRACT = 'contract/details'
export const EDIT_CONTRACT = 'contract/update'
export const TERMS_LIST = 'terms/list'
export const SUBSIDIARY_LIST = 'subsidiary/list'
export const COUNTRY_LIST = 'country/list'
export const STATE_LIST = 'state/list'
export const CITY_LIST = 'city/list'
export const ADD_VENDOR = 'vendor/add'
export const CHANGE_PASSWORD_STAFF = 'staff/changePassword'
export const CHANGE_PASSWORD_FINANCE = 'finance/changePassword'
export const CHANGE_PASSWORD_VENDOR = 'vendor/changePassword'
export const REJECT_PURCHASE_CONTRACT = 'contract/reject'
export const REJECTED_LIST = 'rejectedAt/list'
export const APPROVE_BILL_API = 'bill/approve'
export const REJECTED_BILL_API = 'bill/reject'
export const VIEW_BILL = 'bill/details'
export const PAYMENT_LIST_API = 'payment/list'
export const PAY_PAYMENT = 'payment/add'
export const ADD_TDS = 'finance/tds'
export const VIEW_PAYMENT = 'payment/details'
export const STATUS_LIST = 'status/list'
export const VIEW_INDIVIDUAL_BILL = '/individualBill/details'
export const ADD_ITEM = '/item/add';
export const EDIT_ITEM = '/item/update';
export const ITEM_LIST = '/item/list'
export const MAP_ITEM = '/item/assign'
export const MAP_EDIT_TIEM = '/item/assign-update'
export const MAP_ITEM_LIST = '/item/assign-list'
export const CREATE_INDIVIDUAL_BILL = 'individualBill/add'
export const VIEW_VENDOR = 'vendor/details'
export const VENDOR_DETAILS_APPROVE = 'vendor/approve'
export const VENDOR_DETAILS_REJECT = 'vendor/reject'
export const PAYMENT_MODE_LIST = 'vendor/paymentMode'
export const CREATE_BILL = 'bill/add'
export const BILL_DETAILS = 'bill/info'
export const EDIT_VENDOR = 'vendor/update'
export const VENDOR_SELF_SERVICE = 'vendor/selfService'
export const APPROVAL_ADD = 'approver/add'
export const APPROVAL_UPDATE = 'approver/update'
export const ROLE_LIST = 'role/list'
export const APPROVAL_LIST = 'approver/list'
export const BILL_EDIT = 'bill/update'
export const EDIT_ROLE = 'role/update'
export const CURRENCY_LIST = 'currency/list'
export const BANK_DETAILS_LIST = 'vendor/bank-details'
export const ADD_BANK_DETAILS = 'vendor/add/bankDetails'
export const EDIT_BANK_DETAILS = 'vendor/update/bankDetails'
export const PENDING_REPORTS = 'dashboard/report'
export const CONTRACT_STATUS = 'dashboard/contract'
export const BILL_STATUS = 'dashboard/bill'
export const TOTAL_EXPENSE = 'dashboard/expense'
export const BUDGET_RECORD = 'dashboard/budget'
export const VENDOR_SPEND = 'dashboard/spend'
export const SPEND_ITEM = 'dashboard/spend/item'
export const PAYMENT_REPORT = 'dashboard/payment'
export const AP_AGING = 'dashboard/apAging'
export const APPROVE_INDIVIDUAL_BILL_API = 'individualBill/approve'
export const REJECT_INDIVIDUAL_BILL_API = 'individualBill/reject'
export const UPDATE_INDIVIDUAL_BILL = 'individualBill/update'
export const DEPARTMENT_LIST = '/department/list'
export const BULKCONTRACTAPPROVAL = "contract/bulkApprove"
export const BULKBILLAPPROVAL = "bill/bulkApprove"
export const ROLE_DETAILS = 'role/details'
export const LOG_REPORT = 'log/report'
export const GET_EMAIL = 'vendor/email-check'
export const ITEM_DETAILS = '/item/details'
export const HOLD_Bill = 'bill/hold'
export const VOID_BILL = 'bill/void'
// export const BULK_PAYMENT_APPROVE = 'payment/bulkApprove'
// export const PAYMENT_BATCH = '/payment/batch'
 












