import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BackButtonWhite from "../../../assets/image/BackButtonWhite";
import { getApi } from "../../../utils/services";
import { VIEW_PAYMENT } from "../../../utils/apiRoutes";
import moment from "moment";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";
import { VENDORID } from "../../../utils/constant";
import BillToContent from "../../common/BillToContent";

const ViewPayment = () => {

  const { id } = useParams();

  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userData"));
  const [viewPayment, setViewPayment] = useState({});
  const [paymentHistory, setPaymentHistory] = useState([])
  const [showbackButton, setShowbackButton] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleViewPayment = async () => {
    setLoader(true)
    const result = await getApi(`${VIEW_PAYMENT}/${id}`);
    setLoader(false)
    setViewPayment(result.res.payment_details);

    const updatedHistory = result?.res.paymentHistory.map((item) => {
      const utcTime = item.ModifiedAt;
      const date = new Date(utcTime);
      const formattedTime = date.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
      return { ...item, ModifiedAt: formattedTime };
    });

    setPaymentHistory(updatedHistory)
  };

  useEffect(() => {
    if (id) {
      handleViewPayment();
    }
  }, [id]);

  useEffect(() => {
    if (window.history.length > 1) {
      setShowbackButton(true);
    } else {
      setShowbackButton(false)
    }
  }, []);

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <>
      <Helmet>
        <title>Vendor Portal || View Payment</title>
      </Helmet>

      <Loader isLoading={loader} />

      <div className="container-section">

        <div className="section-inv-header ">
          {showbackButton && (
            <button onClick={handleGoBack} className="back-viewbtn me-1">
              <div className="svgsize">
                <BackButtonWhite />
              </div>
            </button>
          )}

          <h3 className="bill-header-title">BILL PAYMENT</h3>
        </div>

        <div className="form-style">
          <div className="data-inv-info">
            <div className="data-inv-group-1">
              <BillToContent />
            </div>
            <div className="data-inv-group-2">
              <div className="view-footer-border">
                <div className="data-details-4 pt-2">
                  <div className="d-flex align-items-center ps-2 pb-1">
                    <span className="data-label">Billed Total</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value">{viewPayment?.CurrencySymbol} {viewPayment?.InvoiceTotal?.toFixed(2)}</span>
                  </div>
                  {viewPayment?.Discount !== 0 && viewPayment?.Discount !== null && (
                    <>
                      <div className="d-flex align-items-center ps-2 pb-1">
                        <span className="data-label">Discount</span>
                        <span className="data-semicolon">:</span>
                        <span className="data-value">{viewPayment?.CurrencySymbol} {viewPayment?.Discount?.toFixed(2)}</span>
                      </div>
                      <div className="d-flex align-items-center ps-2 pb-1">
                        <span className="data-label">Net Amount</span>
                        <span className="data-semicolon">:</span>
                        <span className="data-value">{viewPayment?.CurrencySymbol} {(viewPayment?.InvoiceTotal - viewPayment?.Discount)?.toFixed(2)}</span>
                      </div>
                    </>
                  )}
                  <div className="d-flex align-items-center ps-2 pb-1">
                    <span className="data-label">GST Total</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value">{viewPayment?.CurrencySymbol} {viewPayment?.TaxSubtotal?.toFixed(2)}</span>
                  </div>
                  <div className="d-flex align-items-center ps-2 pb-1">
                    <span className="data-label">Grand Total</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value">{viewPayment?.CurrencySymbol} {(viewPayment?.GrandTotal - viewPayment?.Discount)?.toFixed(2)}</span>
                  </div>
                  <div className="d-flex align-items-center ps-2 pb-1">
                    <span className="data-label">TDS Total</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value">{viewPayment?.CurrencySymbol} {viewPayment?.TDSAmount?.toFixed(2)}</span>
                  </div>
                  <div className="view-total-amount d-flex align-items-center ps-2 pb-1 pt-1">
                    <span className="data-label text-white">Total</span>
                    <span className="data-semicolon text-white">:</span>
                    <span className="data-value text-white">
                      {viewPayment?.CurrencySymbol} {(viewPayment?.PayableAmount - (viewPayment?.Discount || 0))?.toFixed(2)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-style">
          <hr />

          <div className="row pt-2">
            <div className="col-md-6 payment-details-container mb-2">
              <div className="d-flex">
                <span className="data-label">Payment ID</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">{viewPayment?.PaymentId}</span>
              </div>
            </div>
            {viewPayment?.DepartmentName && (
              <div className="col-md-6 payment-details-container mb-2">
                <div className="d-flex">
                  <span className="data-label">Department</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">{viewPayment?.DepartmentName}</span>
                </div>
              </div>
            )}
            <div className="col-md-6 payment-details-container mb-2">
              <div className="d-flex">
                <span className="data-label">Legal Name</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">{viewPayment?.LegalName}</span>
              </div>
            </div>
            {/* {viewPayment?.DepartmentName && (
              <div className="d-flex">
                <span className="data-label">Department</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">{viewPayment?.DepartmentName}</span>
              </div>
            )} */}
            <div className="col-md-6 payment-details-container mb-2">
              <div className="d-flex">
                <span className="data-label">Amount Paid</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">{viewPayment?.CurrencySymbol} {viewPayment?.AmountPaid?.toFixed(2)}</span>
              </div>
            </div>
            <div className="col-md-6 payment-details-container mb-2">
              <div className="d-flex">
                <span className="data-label">Payment Date</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">{moment(viewPayment?.CreatedAt).format('D/MM/YYYY')?.slice(0, 10)}</span>
              </div>
            </div>
            <div className="col-md-6 payment-details-container mb-2">
              <div className="d-flex">
                <span className="data-label">Bill#</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">{viewPayment.IndividualBill ?
                  <Link className="" to={`/view-individual-bill/${viewPayment?.InvoiceId}`} target="_blank">{viewPayment?.DocumentNo}</Link> :
                  <Link className="" to={`/view-bill/${viewPayment?.InvoiceId}`} target="_blank">{viewPayment?.DocumentNo}</Link>
                }
                </span>
              </div>
            </div>
            <div className="col-md-6 payment-details-container mb-2">
              <div className="d-flex">
                <span className="data-label">Reference No</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">{viewPayment?.ReferenceNo ? viewPayment?.ReferenceNo : "N/A"}</span>
              </div>
            </div>
            <div className="col-md-6 payment-details-container mb-2">
              <div className="d-flex">
                <span className="data-label">Due Date</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">{viewPayment?.DueDate ? moment(viewPayment?.DueDate).format('D/MM/YYYY')?.slice(0, 10) : "N/A"} </span>
              </div>
            </div>
            <div className="col-md-6 payment-details-container mb-2">
              <div className="d-flex">
                <span className="data-label">Status</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">{viewPayment?.StatusName}</span>
              </div>
            </div>
            {user.RoleId !== VENDORID && (
              <div className="col-md-6 payment-details-container mb-2">
                <div className="d-flex">
                  <span className="data-label">NetSuite Id</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">{viewPayment?.ExternalId ? viewPayment?.ExternalId : " N/A"}</span>
                </div>
              </div>
            )}
          </div>

          <hr />

          {paymentHistory.length > 0 &&
            <>
              <div className="section-sub-heading">
                <h3>Audit History</h3>
              </div>
              <div className="table-responsive tbl-body-shadow mb-4">
                <table className="w-100 table-theme-1">
                  <thead>
                    <tr>
                      <th className="text-left">Date</th>
                      <th className="text-left">Set By</th>
                      <th className="text-left">Type</th>
                      <th className="text-left">Field Name</th>
                      <th className="text-left">Old Field Value</th>
                      <th className="text-left">New Field Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentHistory?.map((row, index) => (
                      <tr key={index}>
                        <td className="text-left">{row.ModifiedAt}</td>
                        <td className="text-left">{row.CreatedByName}</td>
                        <td className="text-left">{row.Type}</td>
                        <td className="text-left">{row.FieldName}</td>
                        <td className="text-left">{row.OldFieldValue}</td>
                        <td className="text-left">{row.NewFieldValue}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          }

        </div>

      </div>
    </>
  );

};

export default ViewPayment;
