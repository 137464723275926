import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

function DateRangeCalender({ setSelectedDateRange, startDate, endDate, handlerContractRedicalChart, handlerBillRedicalChart, handlerSpendPieDonutChart, handlerVendorSpendChart }) {

  const handleApply = (event, data) => {
    setSelectedDateRange({
      startDate: data.startDate,
      endDate: data.endDate,
    });
    if (handlerBillRedicalChart) {
      handlerContractRedicalChart(data.startDate, data.endDate)
    }
    if (handlerBillRedicalChart) {
      handlerBillRedicalChart(data.startDate, data.endDate)
    }
    if (handlerSpendPieDonutChart) {
      handlerSpendPieDonutChart(data.startDate, data.endDate)
    }
    if (handlerVendorSpendChart) {
      handlerVendorSpendChart(data.startDate, data.endDate)
    }
  };

  return (
    <DateRangePicker initialSettings={{ startDate: startDate, endDate: endDate }} onApply={handleApply}>
      <input type="text" className="form-control" />
    </DateRangePicker>
  );
}

export default DateRangeCalender;