import MaintenanceIcon from '../../assets/image/maintenanceIcon.png'
import React from 'react';

const MaintenancePage = () => {
    return (
        <div className="maintenance-container">
            <div class="maintenance_container">
                <div class="maintenance_circle"></div>
                <div class="maintenance_icon" width="40" height="40"><img src={MaintenanceIcon} alt='Maintenance'></img></div>
            </div>
            <h1>Website Under Maintenance</h1>
            <p>Our website is currently undergoing scheduled maintenance.</p>
            <p>We should be back shortly. Thank you for your patience.</p>
        </div>
    );
};

export default MaintenancePage;
