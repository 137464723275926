import React, { useContext, useState } from 'react';
import { ModalContext } from '../../utils/Context';
import { postApi } from '../../utils/services';
import toast from 'react-hot-toast';
import { BULKBILLAPPROVAL, BULKCONTRACTAPPROVAL } from '../../utils/apiRoutes';
import { ADMIN_MANAGER_ID, VP_ID } from '../../utils/constant';
import Alert from './Alert';

const BulkApproval = ({
  selectedRowData,
  title,
  handlerBillList,
  handlerPurchaseContractList,
  handlerIndividualBillList,
  IndividualBillTrue,
  allIndividualBillFalse
}) => {
  const [disable, setDisable] = useState(false);
  const user = JSON.parse(localStorage.getItem("userData"));
  const modalContext = useContext(ModalContext);
  const { handleModalData } = modalContext;
  const { closeModal } = modalContext;

  const handleSubmit = async () => {
    setDisable(true);
    let payload = null;
    let apiUrl = null;

    if (title === 'Bills' || title === 'Standalone Bill') {
      apiUrl = BULKBILLAPPROVAL;
    } else if (title === 'Purchase Contracts') {
      apiUrl = BULKCONTRACTAPPROVAL;
    }

    if (title === 'Bills' || title === 'Standalone Bill') {
      if (user.RoleId === ADMIN_MANAGER_ID) {
        payload = {
          billId: selectedRowData,
          Status: 1,
          flag: 2,
        };
      } else if (user.RoleId === VP_ID) {
        payload = {
          billId: selectedRowData,
          Status: 4,
          flag: 3,
        };
      } else {
        toast.error('User role is not authorized for bulk approval');
        setDisable(false);
        return;
      }

      if (title === 'Standalone Bill' && IndividualBillTrue) {
        payload = {
          billId: selectedRowData,
          Status: 4,
          flag: 3,
        };
      }
    } else if (title === 'Purchase Contracts') {
      if (user.RoleId === ADMIN_MANAGER_ID) {
        payload = {
          contractId: Array.isArray(selectedRowData) ? selectedRowData : [selectedRowData],
          Status: 1,
          flag: 2,
        };
      } else if (user.RoleId === VP_ID) {
        payload = {
          contractId: Array.isArray(selectedRowData) ? selectedRowData : [selectedRowData],
          Status: 4,
          flag: 3,
        };
      } else {
        toast.error('User role is not authorized for bulk approval');
        setDisable(false);
        return;
      }
    }

    try {
      const response = await postApi(apiUrl, payload);
      if (response.res.success) {
        toast.success(response.res.message);

        if (title === 'Bills' && !allIndividualBillFalse) {
          handlerBillList();
        } else if (title === 'Standalone Bill') {
          handlerIndividualBillList();
        } else if (title === 'Purchase Contracts') {
          handlerPurchaseContractList();
        }
        closeModal();
      }
      if (response.res.holdMessage) {
        const alert = <Alert message={response.res.holdMessage} />;
        handleModalData(alert, "md");
      }
    } catch (error) {
      console.error('Failed Payload:', payload);
      toast.error('An error occurred during the bulk approval process.');
    } finally {
      setDisable(false);
    }
  };

  return (
    <div className="custom-cnfm-mdl">
      <div className="modal-body">
        <h2>Are you sure?</h2>
        <h6>Are you sure you want to approve these {title}? This action cannot be undone.</h6>
      </div>
      <div className="modal-footer">
        <div className="d-flex align-items-center mb-20">
          <div className="danger-btn">
            <button type="button" className="btn" onClick={closeModal}>
              Cancel
            </button>
          </div>
          <div className="success-btn ms-2">
            <button
              type="submit"
              className="btn"
              onClick={handleSubmit}
              disabled={disable}
            >
              Approve
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkApproval;