// src/components.js
import React, { useContext, useState, useEffect } from "react";
import { ModalContext } from "../../utils/Context";
import { postApi } from "../../utils/services";
import { REJECTED_LIST } from "../../utils/apiRoutes";
import RejectedAtModal from "./RejectedAtModal";
import cross from "../../assets/image/cross.png";
import { useTransactionContext } from "../../utils/helpers/useTransaction";

const RejectionModal = ({
  data,
  selectedRowData,
  handlerBillList,
  handlerIndividualBillList,
  handlerPurchaseContractList,
  pageNumber,
  pageSize,
  globalSearch,
  billStatusId
}) => {
  const modalContext = useContext(ModalContext);
  const { handleModalData, closeModal } = modalContext;
  const [levelValue, setLevelValue] = useState([]);
  const transactionList = useTransactionContext();
  const user = JSON.parse(localStorage.getItem("userData"));

  const isBulk = selectedRowData && selectedRowData.length > 0;
  const currentData = isBulk ? selectedRowData[0] : data;

  const handleReject = (rejectionType) => {
    const rejectedAt = isBulk ? (
      <RejectedAtModal
        data={selectedRowData}
        levelValue={levelValue}
        handlerList={
          currentData.IndividualBill === undefined
            ? handlerPurchaseContractList
            : currentData.IndividualBill
              ? handlerIndividualBillList
              : handlerBillList
        }
        rejectionType={rejectionType}
        pageNumber={pageNumber}
        pageSize={pageSize}
        globalSearch={globalSearch}
        billStatusId={billStatusId}
      />
    ) : (
      <RejectedAtModal
        data={data}
        levelValue={levelValue}
        handlerList={
          currentData.IndividualBill === undefined
            ? handlerPurchaseContractList
            : currentData.IndividualBill
              ? handlerIndividualBillList
              : handlerBillList
        }
        rejectionType={rejectionType}
        pageNumber={pageNumber}
        pageSize={pageSize}
        globalSearch={globalSearch}
        billStatusId={billStatusId}
      />
    );
    handleModalData(rejectedAt, "md");
  };

  const handlerLevelApi = async () => {
    const res = await postApi(REJECTED_LIST, {
      transactionId:
        currentData.IndividualBill === undefined
          ? transactionList[2].id
          : currentData.IndividualBill
            ? transactionList[4].id
            : transactionList[1].id,
      roleId: user.RoleId,
    });
    setLevelValue(res.res.rejectedAt_list);
  };

  useEffect(() => {
    if (user) {
      handlerLevelApi();
    }
  }, []);

  return (
    <div className="custom-cnfm-mdl">
      <div className="modal-body ">
        <span className="cross" onClick={closeModal}>
          <img src={cross} alt="close" />
        </span>
        <h2>Are you sure?</h2>
        <h6>
          Are you sure you want to reject {isBulk ? "these" : "this"}{" "}
          {currentData.IndividualBill === undefined
            ? "Purchase Contract"
            : currentData.IndividualBill
              ? "Standalone Bill"
              : "Bill"}
          ? This action cannot be reverted.
        </h6>
        <div className="modal-footer d-flex align-items-center justify-content-center mb-20 gap-2">
          <div className="danger-btn">
            <button
              type="button"
              className="btn"
              onClick={() => handleReject("Requester")}
            >
              Reject to Requester
            </button>
          </div>
          <div className="cstm-lvl-btn">
            <button
              disabled={levelValue.length === 0}
              type="submit"
              className="btn"
              onClick={() => handleReject("RejectAt")}
            >
              Reject at
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectionModal;