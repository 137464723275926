// sidebarConfig.js
import Sidebarinvoiceicon from "../../assets/image/BillIcon";
import EditIndividual from "../../assets/image/EditIndividual";
import VendorIcon from "../../assets/image/VendorIcon";
import TerminologyIcon from "../../assets/image/TerminologyIcon";
import ReconcileIcon from "../../assets/image/ReconcileIcon";
import ReportIcon from "../../assets/image/ReportIcon";
import HomeIcon from "../../assets/image/HomeIcon";
import ContractIcon from "../../assets/image/ContractIcon";

// ---------------------------------------- vendor side bar start ---------------------------------------- //

export const SIDEBAR_VENDOR_APPROVED = [
    {
        id: 1,
        label: 'Home',
        ref: 'dash',
        activelogo: <HomeIcon />,
        nonactivelogo: '',
        path: '/',
    },
    {
        id: 2,
        label: 'Purchase Contract',
        ref: 'Contract',
        activelogo: <ContractIcon />,
        nonactivelogo: '',
        path: '#',
        sublist: [
            {
                id: 1,
                label: 'View',
                path: 'contract-listing',
                ref: 'user',
            },
        ],
    },
    {
        id: 3,
        label: 'Bill',
        ref: 'Invoice',
        activelogo: <Sidebarinvoiceicon />,
        nonactivelogo: "",
        path: '#',
        sublist: [
            {
                id: 1,
                label: 'Create',
                path: "/bill-type",
                ref: 'user',

            },
            {
                id: 2,
                label: 'View Bill',
                path: 'invoice-listing',
                ref: 'user',
            },
            {
                id: 3,
                label: 'View Standalone',
                path: '/individual-bill-list',
                ref: 'user',
            },
        ]
    },
    // {
    //     id: 5,
    //     label: 'Void',
    //     ref: 'dash',
    //     activelogo: <VoidIcon />,
    //     nonactivelogo: "",
    //     path: '/void-list'
    // },
    // {
    //     id: 6,
    //     label: 'Hold',
    //     ref: 'dash',
    //     activelogo: <VoidIcon />,
    //     nonactivelogo: "",
    //     path: '/hold-list'
    // },
];

export const SIDEBAR_VENDOR_PENDING = [
    {
        id: 1,
        label: 'Home',
        ref: 'dash',
        activelogo: <i className="mdi mdi-home menu-icon" />,
        nonactivelogo: '',
        path: '/',
    }
];

// ---------------------------------------- vendor side bar start ---------------------------------------- //

// ---------------------------------------- admin executive side bar start ---------------------------------------- //

export const SIDEBAR_ADMIN_EXECUTIVE = [
    {
        id: 1,
        label: 'Home',
        ref: 'dash',
        activelogo: <HomeIcon />,
        nonactivelogo: "",
        path: '/'
    },
    {

        id: 2,
        label: 'Vendor',
        ref: 'Add Vendor',
        activelogo: <VendorIcon />,
        nonactivelogo: "",
        path: '#',
        sublist: [
            {
                id: 1,
                label: 'Add',
                path: '/add-vendor',
                ref: 'user',
            },
            {
                id: 2,
                label: 'View',
                path: '/vendors',
                ref: 'user',
            },

        ]

    },
    {
        id: 3,
        label: 'Purchase Contract',
        ref: 'Contract',
        activelogo: <ContractIcon />,
        nonactivelogo: "",
        path: '#',
        sublist: [
            {
                id: 1,
                label: 'Create',
                path: '/add-executive-contract',
                ref: 'user',

            },
            {
                id: 3,
                label: 'View',
                path: 'contract-listing',
                ref: 'user',
            },
        ]
    },
    {
        id: 4,
        label: 'Bill',
        ref: 'Invoice',
        activelogo: <Sidebarinvoiceicon />,
        nonactivelogo: "",
        path: '#',
        sublist: [
            {
                id: 1,
                label: 'Create',
                path: "/bill-type",
                ref: 'user',

            },
            {
                id: 2,
                label: 'View Bill',
                path: 'invoice-listing',
                ref: 'user',
            },
            {
                id: 3,
                label: 'View Standalone',
                path: '/individual-bill-list',
                ref: 'user',
            },
        ]
    }
    // {
    //     id: 7,
    //     label: 'Void',
    //     ref: 'dash',
    //     activelogo: <VoidIcon />,
    //     nonactivelogo: "",
    //     path: '/void-list'
    // },
    // {
    //     id: 8,
    //     label: 'Hold',
    //     ref: 'dash',
    //     activelogo: <VoidIcon />,
    //     nonactivelogo: "",
    //     path: '/hold-list'
    // }
    // {

    //     id: 6,
    //     label: 'Item',
    //     ref: 'Item',
    //     activelogo: <ItemIcon />,
    //     nonactivelogo: "",
    //     path: '#',
    //     sublist: [
    //         {
    //             id: 1,
    //             label: 'Add Item',
    //             path: '/add-item',
    //             ref: 'item',
    //         }
    //         // {
    //         //     id: 2,
    //         //     label: 'Map Item',
    //         //     path: '/map-item',
    //         //     ref: 'item',
    //         // }
    //     ]
    // }
];

// ---------------------------------------- admin executive side bar end ---------------------------------------- // 

// ---------------------------------------- admin manager side bar start ---------------------------------------- //

export const SIDEBAR_ADMIN_MANAGER = [
    {
        id: 1,
        label: 'Home',
        ref: 'dash',
        activelogo: <HomeIcon />,
        nonactivelogo: "",
        path: '/'
    },
    {

        id: 2,
        label: 'Vendor',
        ref: 'Add Vendor',
        activelogo: <VendorIcon />,
        nonactivelogo: "",
        path: '#',
        sublist: [
            {
                id: 1,
                label: 'View',
                path: '/vendors',
                ref: 'user',
            },
        ]

    },
    {
        id: 3,
        label: 'Purchase Contract',
        ref: 'Contract',
        activelogo: <ContractIcon />,
        nonactivelogo: "",
        path: '#',
        sublist: [
            {
                id: 1,
                label: 'Create',
                path: '/add-manager-contract',
                ref: 'user',

            },
            {
                id: 2,
                label: 'View',
                path: 'contract-listing',
                ref: 'user',
            },
        ]
    },
    {
        id: 4,
        label: 'Bill',
        ref: 'Invoice',
        activelogo: <Sidebarinvoiceicon />,
        nonactivelogo: "",
        path: '#',
        sublist: [
            {
                id: 1,
                label: 'Create',
                path: "/bill-type",
                ref: 'user',

            },
            {
                id: 2,
                label: 'View Bill',
                path: 'invoice-listing',
                ref: 'user',
            },
            {
                id: 3,
                label: 'View Standalone',
                path: '/individual-bill-list',
                ref: 'user',
            },

        ]
    },
    {
        id: 5,
        label: 'Report',
        ref: 'dash',
        activelogo: <ReportIcon />,
        nonactivelogo: "",
        path: '/report'
    },
    {
        id: 6,
        label: 'Reconcile Report',
        ref: 'dash',
        activelogo: <ReconcileIcon />,
        nonactivelogo: "",
        path: '/reconcile-report'
    },
    // {
    //     id: 6,
    //     label: 'Void',
    //     ref: 'dash',
    //     activelogo: <VoidIcon />,
    //     nonactivelogo: "",
    //     path: '/void-list'
    // },
    // {
    //     id: 7,
    //     label: 'Hold',
    //     ref: 'dash',
    //     activelogo: <VoidIcon />,
    //     nonactivelogo: "",
    //     path: '/hold-list'
    // },
];

// ---------------------------------------- admin manager side bar end ---------------------------------------- // 

// ---------------------------------------- vp side bar start ---------------------------------------- // 

export const SIDEBAR_VP = [
    {
        id: 1,
        label: 'Home',
        ref: 'dash',
        activelogo: <HomeIcon />,
        nonactivelogo: "",
        path: '/'
    },
    {

        id: 2,
        label: 'Vendor',
        ref: 'Add Vendor',
        activelogo: <VendorIcon />,
        nonactivelogo: "",
        path: '#',
        sublist: [
            {
                id: 1,
                label: 'View',
                path: '/vendors',
                ref: 'user',
            },
        ]

    },
    {
        id: 3,
        label: 'Purchase Contract',
        ref: 'Contract',
        activelogo: <ContractIcon />,
        nonactivelogo: "",
        path: '#',
        sublist: [

            {
                id: 1,
                label: 'View',
                path: 'contract-listing',
                ref: 'user',
            },
        ]
    },
    {
        id: 4,
        label: 'Bill',
        ref: 'Invoice',
        activelogo: <Sidebarinvoiceicon />,
        nonactivelogo: "",
        path: '#',
        sublist: [
            {
                id: 1,
                label: 'View Bill',
                path: 'invoice-listing',
                ref: 'user',
            },
            {
                id: 2,
                label: 'View Standalone',
                path: '/individual-bill-list',
                ref: 'user',
            },
        ]
    },
    {
        id: 5,
        label: 'Report',
        ref: 'dash',
        activelogo: <ReportIcon />,
        nonactivelogo: "",
        path: '#',
        sublist: [
            {
                id: 1,
                label: 'Report',
                path: '/report',
                ref: 'user',
            },
            {
                id: 2,
                label: 'AP Payment Health',
                path: '/finance-status',
                ref: 'user',
            },
            {
                id: 3,
                label: 'Planned Vs Actual',
                path: '/budget-record',
                ref: 'user',
            },

        ]

    },
    // {
    //     id: 7,
    //     label: 'Void',
    //     ref: 'dash',
    //     activelogo: <VoidIcon />,
    //     nonactivelogo: "",
    //     path: '/void-list'
    // },
    // {
    //     id: 8,
    //     label: 'Hold',
    //     ref: 'dash',
    //     activelogo: <VoidIcon />,
    //     nonactivelogo: "",
    //     path: '/hold-list'
    // },
]

// ---------------------------------------- vp side bar end ---------------------------------------- // 

// ---------------------------------------- finance side bar start ---------------------------------------- // 

export const SIDEBAR_FINANCE = [
    {
        id: 1,
        label: 'Home',
        ref: 'dash',
        activelogo: <HomeIcon />,
        nonactivelogo: "",
        path: '/'
    },
    {
        id: 3,
        label: 'Bill',
        ref: 'Invoice',
        activelogo: <Sidebarinvoiceicon />,
        nonactivelogo: "",
        path: '#',
        sublist: [

            {
                id: 1,
                label: 'View Bill',
                path: 'invoice-listing',
                ref: 'user',
            },
            {
                id: 2,
                label: 'View Standalone',
                path: '/individual-bill-list',
                ref: 'user',
            },

        ]
    },
    {
        id: 5,
        label: 'Report',
        ref: 'dash',
        activelogo: <ReportIcon />,
        nonactivelogo: "",
        path: '/report'
    },
]

// ---------------------------------------- finance side bar end ---------------------------------------- // 

// ---------------------------------------- super admin side bar start ---------------------------------------- //

export const SIDEBAR_SUPERADMIN = [
    {
        id: 1,
        label: 'Home',
        ref: 'dash',
        activelogo: <HomeIcon />,
        nonactivelogo: "",
        path: '/'
    },
    {

        id: 2,
        label: 'Vendor',
        ref: 'Add Vendor',
        activelogo: <VendorIcon />,
        nonactivelogo: "",
        path: '#',
        sublist: [
            {
                id: 1,
                label: 'View',
                path: '/vendors',
                ref: 'user',
            },
        ]

    },
    {
        id: 3,
        label: 'Purchase Contract',
        ref: 'Contract',
        activelogo: <ContractIcon />,
        nonactivelogo: "",
        path: '#',
        sublist: [
            {
                id: 1,
                label: 'View',
                path: 'contract-listing',
                ref: 'user',
            },

        ]
    },
    {
        id: 4,
        label: 'Bill',
        ref: 'Invoice',
        activelogo: <Sidebarinvoiceicon />,
        nonactivelogo: "",
        path: '#',
        sublist: [
            {
                id: 1,
                label: 'View Bill',
                path: 'invoice-listing',
                ref: 'user',
            },
            {
                id: 1,
                label: 'View Standalone',
                path: '/individual-bill-list',
                ref: 'user',
            },

        ]
    },
    {

        id: 5,
        label: 'Report',
        ref: 'dash',
        activelogo: <ReportIcon />,
        nonactivelogo: "",
        path: '#',
        sublist: [
            {
                id: 1,
                label: 'Report',
                path: '/report',
                ref: 'user',
            },
            {
                id: 2,
                label: 'AP Payment Health',
                path: '/finance-status',
                ref: 'user',
            },
            {
                id: 3,
                label: 'Planned Vs Actual',
                path: '/budget-record',
                ref: 'user',
            },

        ]

    },
    {
        id: 6,
        label: 'Reconcile Report',
        ref: 'dash',
        activelogo: <ReconcileIcon />,
        nonactivelogo: "",
        path: '/reconcile-report'
    },
    {
        id: 7,
        label: 'Approval flow',
        ref: 'dash',
        activelogo: <i className="mdi mdi-directions-fork menu-icon" />,
        nonactivelogo: "",
        path: '/approval-flow'
    },
    {
        id: 8,
        label: 'Terminology',
        ref: 'dash',
        activelogo: <TerminologyIcon />,
        nonactivelogo: "",
        path: '/terminology'
    },
]

// ---------------------------------------- super admin side bar end ---------------------------------------- // 
