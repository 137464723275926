import { useState } from 'react';
import { VENDORID } from '../utils/constant';
import { validateDepartmentId, validateUploadDocuments, validateVendor, validateItemDescription, validateBillDate, validateVendorId, validateSubtotalAndTax } from '../utils/helpers/globalValidation';

const AddIndividuallBillValidation = () => {
  const [errors, setErrors] = useState({});

  const validateForm = (vendorId, billDate, roleId, departmentId, individualBillDocument, itemDescription, tax, itemDescriptionLineLevel, subTotal, subTotalLineLevel, lineLevelCheck, isVendorIdPresent) => {
    let errors = {};
    let formIsValid = true;

    if (roleId === VENDORID) {
      const vendorIdError = validateVendorId(isVendorIdPresent);
      if (vendorIdError) {
        formIsValid = false;
        errors.vendorId = vendorIdError;
      }
    }

    const vendorError = validateVendor(vendorId);
    if (vendorError) {
      formIsValid = false;
      errors.vendorId = vendorError;
    }

    const billDateError = validateBillDate(billDate);
    if (billDateError) {
      formIsValid = false;
      errors.billDate = billDateError;
    }

    const itemErrors = validateItemDescription(lineLevelCheck ? itemDescriptionLineLevel : itemDescription, lineLevelCheck);
    if (itemErrors) {
      formIsValid = false;
      Object.assign(errors, itemErrors);
    }

    const { formIsValid: taxValidation, errors: taxErrors } = validateSubtotalAndTax(lineLevelCheck, subTotal, subTotalLineLevel, tax);
    if (!taxValidation) {
      formIsValid = false;
      Object.assign(errors, taxErrors);
    }

    const departmentError = validateDepartmentId(roleId, departmentId);
    if (departmentError) {
      formIsValid = false;
      errors.departmentId = departmentError;
    }

    const documentError = validateUploadDocuments(null, individualBillDocument);
    if (!documentError.formIsValid) {
      formIsValid = false;
      errors.files = documentError.errors.files;
    }

    setErrors(errors);
    return formIsValid;
  };

  return { errors, setErrors, validateForm };
};

export default AddIndividuallBillValidation;
