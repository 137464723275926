import icon from "../../assets/image/streamlogo.png";
import React from 'react';

const LoginLeftContent = () => {
    return (
        <div className='lgn-cntnr-lft'>
            <img className="lgn-logo" src={icon} alt="" />
            <div className='lgn-lft-cntn'>
                <h2>AP Automation</h2>
                <p>The responsibility of the accounts payable (AP) process involves the settlement of payments to vendors for the goods and services procured by the organization.</p>
            </div>
        </div>
    );
};

export default LoginLeftContent;