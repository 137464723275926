import React, { useEffect, useState, useRef } from "react";
import BackButton from "../../../assets/image/BackButton";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BASE_IMAGE_URL, getApi, postApi } from "../../../utils/services";
import {
  UPDATE_INDIVIDUAL_BILL,
  VIEW_INDIVIDUAL_BILL,
} from "../../../utils/apiRoutes";
import { Helmet } from "react-helmet";
import moment from "moment";
import DeleteIcon from "../../../assets/image/DeleteIcon";
import { useTransactionContext } from "../../../utils/helpers/useTransaction";
import DownloadIcon from "../../../assets/image/DownloadIcon";
import EyeIcon from "../../../assets/image/EyeIcon";
import { ADMIN_EXECUTIVE_ID, VENDORID } from "../../../utils/constant";
import EditIndividuallBillValidation from "../../../middleware/editIndividuallBillValidation";
import { handleDescriptionChange } from "../../../utils/helpers/globalMethod";
import Loader from "../../common/Loader";
import { HAS_DECIMAL_VALUE_REGX, NON_NUMERIC_DOT_REGX, DISCARD_NON_DIGIT_REGX, CAMEL_CASE_REGX, TRIM_SPACE_REGX } from "../../../utils/helpers/globalRegx";
import { fetchItemDetails, fetchVendorData, handleCheckboxChange, handleDepartmentChange, handleDepartmentList, handleDiscountChange, handleFileInputChange, handleRemoveItem } from "../../../utils/helpers/globalMethod";
import { uploadFile } from "../../../utils/helpers/useFileUpload";
import Swal from "sweetalert2";

const EditIndividualBill = () => {

  const navigate = useNavigate();
  const location = useLocation()
  const { id } = useParams();
  const transactionList = useTransactionContext();
  const user = JSON.parse(localStorage.getItem("userData"));
  const [vendorName, setVendorName] = useState('')
  const [vendorItems, setVendorItems] = useState([]);
  const [lineLevelCheck, setLineLevelCheck] = useState(false);
  const [attachDocuments, setAttachDocuments] = useState(false)
  const [individualBillDocument, setIndividualBillDocument] = useState([])
  const [loader, setLoader] = useState(false)
  const [spinner, setSpinner] = useState(false);
  const [refNo, setReferenceNo] = useState('')
  const [billDate, setBillDate] = useState('')
  const [dueDate, setDueDate] = useState('')
  const [vendorId, setVendorId] = useState('')
  const [itemDescription, setItemDescription] = useState([])
  const [deletedItemDescriptionId, setDeletedItemDescriptionId] = useState([]);
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [subTotal, setSubTotal] = useState(0);
  const [gstTotal, setGstTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0)
  const [oldFiles, setOldFiles] = useState([])
  const [deletedFileId, setDeletedFileId] = useState([]);
  const [termCount, setTermCount] = useState([])
  const [billId, setBillId] = useState('')
  const [cgst, setCgst] = useState('');
  const [sgst, setSgst] = useState('');
  const [utgst, setUtgst] = useState('');
  const [igst, setIgst] = useState('');
  const [departmentId, setDepartmentId] = useState('');
  const [departmentList, setDepartmentList] = useState([])
  const { errors, setErrors, validateForm } = EditIndividuallBillValidation();
  const [discountAmount, setDiscountAmount] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState('');
  const [invoiceTotal, setInvoiceTotal] = useState(0);
  const [saveDisable, setSaveDisable] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);

  const minBillDate = moment().subtract(30, 'days');
  const fileInputRef = useRef(null);

  const onDepartmentChange = handleDepartmentChange(setDepartmentId, setErrors);

  useEffect(() => {
    if (id) {
      handleViewData();
    }
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchVendorData(vendorId, null, null, null, setVendorItems, null, null);
    };
    fetchData()
  }, [vendorId])

  useEffect(() => {
    const fetchDepartmentList = async () => {
      await handleDepartmentList(setDepartmentList);
    }; fetchDepartmentList()
  }, [])

  useEffect(() => {
    const calculateTotal = () => {
      const subTotal = itemDescription.reduce((acc, item) => acc + item.amount, 0);
      setSubTotal(subTotal);
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors.subTotal;
        return newErrors;
      });
      let gstTotal = 0;
      let grandTotal = 0;
      if (!lineLevelCheck) {
        const modifiedCgst = parseFloat(cgst) || 0;
        const modifiedSgst = parseFloat(sgst) || 0;
        const modifiedUtgst = parseFloat(utgst) || 0;
        const modifiedIgst = parseFloat(igst) || 0;
        const gstWithDiscount = subTotal - (parseFloat(discountAmount) || 0);
        gstTotal = gstWithDiscount * ((modifiedCgst + modifiedSgst + modifiedUtgst + modifiedIgst) / 100);
        grandTotal = gstWithDiscount + gstTotal;
        setGstTotal(parseFloat(gstTotal.toFixed(2)));
        setGrandTotal(parseFloat(grandTotal.toFixed(2)));
      } else {
        gstTotal = itemDescription.reduce((acc, item) => acc + parseFloat(item.gST_Total || 0), 0);
        grandTotal = subTotal + gstTotal;
        setGstTotal(parseFloat(gstTotal.toFixed(2)));
        setGrandTotal(parseFloat(grandTotal.toFixed(2)));
      }

      if (subTotal !== invoiceTotal) {
        setDiscountPercentage('');
      }
      setInvoiceTotal(subTotal);
      const discountAmountParsed = parseFloat(discountAmount) || "";
      const discountPercentage = discountAmount ? ((discountAmountParsed / subTotal) * 100).toFixed(2) : "";
      setDiscountPercentage(discountPercentage);
    };
    calculateTotal();
  }, [itemDescription, cgst, sgst, utgst, igst, lineLevelCheck, discountAmount, discountPercentage, subTotal]);


  const onCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    handleCheckboxChange(isChecked, setAttachDocuments, setIndividualBillDocument, setErrors, fileInputRef);
  };

  const toCamelCase = (str) => {
    return str.replace(CAMEL_CASE_REGX, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase()
    )
      .replace(TRIM_SPACE_REGX, '');
  };

  const handleViewData = async () => {
    try {
      setSpinner(true);
      const result = await getApi(`${VIEW_INDIVIDUAL_BILL}/${id}`);
      setSpinner(false);
      setVendorName(result.res.individualBill_details.LegalName);
      setReferenceNo(result.res.individualBill_details.ReferenceNo);
      setVendorId(result.res.individualBill_details.vendorId)
      setBillDate(moment(result.res.individualBill_details.BillDate).format().slice(0, 10));
      setBillId(result.res.individualBill_details.id)
      setDueDate(moment(result.res.individualBill_details.DueDate).format().slice(0, 10));
      setLineLevelCheck(result.res.individualBill_details.LineGST)
      setSubTotal(result.res.individualBill_details.InvoiceTotal);
      setGstTotal(result.res.individualBill_details.TaxSubtotal);
      setGrandTotal(result.res.individualBill_details.Total)
      setTermCount(result.res.individualBill_details.TermName)
      const transformedItems = result.res.individualBill_details.InvoiceLines.map(item => {
        return Object.keys(item).reduce((acc, key) => {
          let value = item[key];
          if (key === 'cgst' || key === 'sgst' || key === 'utgst' || key === 'igst') {
            value = value !== null ? String(value) : '';
          }

          acc[toCamelCase(key)] = value;
          return acc;
        }, {});
      });

      setItemDescription(transformedItems);

      setOldFiles(result.res.individualBill_details.InvoiceDocuments);
      setCurrencySymbol(result.res.individualBill_details.CurrencySymbol);
      setDepartmentId(result.res.individualBill_details.DepartmentId);
      setDiscountAmount(result.res.individualBill_details.Discount || null)

      const { CGST, SGST, UTGST, IGST } = result.res.individualBill_details;

      setCgst(CGST !== null ? String(CGST) : '');
      setSgst(SGST !== null ? String(SGST) : '');
      setUtgst(UTGST !== null ? String(UTGST) : '');
      setIgst(IGST !== null ? String(IGST) : '');


    } catch (error) {
      console.log(error);
    }
  };

  const handleRefChange = (event) => {
    const { value } = event.target;
    setReferenceNo(value);
  };

  const handleBillDateChange = (e) => {
    const date = e.target.value;
    const term = termCount.replace(DISCARD_NON_DIGIT_REGX, "");

    if (date) {
      const newDueDate = new Date(date);
      newDueDate.setDate(newDueDate.getDate() + parseInt(term));

      setBillDate(date);
      setDueDate(newDueDate.toISOString().split("T")[0]);

    }

  };

  const handleLineLevelChange = (e) => {
    const check = e.target.checked;
    setLineLevelCheck(check);

    if (check) {
      const updatedItemDescription = itemDescription.map(item => {
        const rate = item.rate;
        const quantity = item.quantity;
        const totalAmount = rate * quantity;
        item.amount = totalAmount;

        const cgst = parseFloat(item.cgst) || 0;
        const sgst = parseFloat(item.sgst) || 0;
        const utgst = parseFloat(item.utgst) || 0;
        const igst = parseFloat(item.igst) || 0;

        const gstPercentage = cgst + sgst + utgst + igst;
        const gstAmount = totalAmount * (gstPercentage / 100);

        item.gST_Total = gstAmount.toFixed(2);
        return item;
      });

      setItemDescription(updatedItemDescription);
    }
  }

  const handleItemDescriptionChange = async (index, field, value) => {
    const updatedItemDescription = [...itemDescription];
    let newValue;

    if (field === "rate") {
      newValue = value.replace(/[^0-9.]/g, '');
      const parts = newValue.split('.');
      if (parts.length > 2) {
        newValue = parts.shift() + '.' + parts.join('');
      }
      const dotIndex = newValue.indexOf(".");
      if (dotIndex !== -1 && newValue.length - dotIndex > 3) {
        newValue = newValue.slice(0, dotIndex + 3);
      }
    } else if (field === "quantity") {
      newValue = value.replace(/[^0-9]/g, '');
      newValue = newValue.split('.')[0];
    } else if (["cgst", "sgst", "utgst", "igst"].includes(field)) {
      newValue = value.replace(NON_NUMERIC_DOT_REGX, "");

      const parts = newValue.split('.');
      if (parts.length > 2) {
        newValue = parts.shift() + '.' + parts.join('');
      }
      const dotIndex = newValue.indexOf(".");
      if (dotIndex !== -1 && newValue.length - dotIndex > 3) {
        newValue = newValue.slice(0, dotIndex + 3);
      }

      const isValid = parseFloat(newValue) >= 0 && parseFloat(newValue) <= 100;
      const hasValidDecimal = HAS_DECIMAL_VALUE_REGX.test(newValue);

      if (!isValid || !hasValidDecimal) {
        newValue = newValue.slice(0, -1);
      }
    } else {
      newValue = value;
    }

    updatedItemDescription[index][field] = newValue;

    if (field === "itemId" && value) {
      try {
        const description = await fetchItemDetails(value);
        updatedItemDescription[index].description = description || '';
      } catch (error) {
        console.error(error);
      }
    }

    const rate = parseFloat(updatedItemDescription[index].rate) || 0;
    const quantity = parseFloat(updatedItemDescription[index].quantity) || 0;
    const totalAmount = rate * quantity;
    updatedItemDescription[index].amount = totalAmount;

    if (lineLevelCheck) {
      const cgst = parseFloat(updatedItemDescription[index].cgst) || 0;
      const sgst = parseFloat(updatedItemDescription[index].sgst) || 0;
      const utgst = parseFloat(updatedItemDescription[index].utgst) || 0;
      const igst = parseFloat(updatedItemDescription[index].igst) || 0;
      const gstPercentage = cgst + sgst + utgst + igst;
      const gstAmount = totalAmount * (gstPercentage / 100);
      updatedItemDescription[index].gST_Total = gstAmount.toFixed(2);
    }

    setItemDescription(updatedItemDescription);

    const updatedErrors = { ...errors };
    if (updatedErrors.itemDescription && updatedErrors.itemDescription[index]) {
      delete updatedErrors.itemDescription[index][field];
    }

    setErrors(updatedErrors);
  };

  const handleAdd = () => {

    setItemDescription([...itemDescription, {
      id: '',
      itemId: '',
      description: '',
      rate: '',
      quantity: '',
      cgst: '',
      sgst: '',
      igst: '',
      utgst: '',
      gST_Total: '',
      amount: 0
    }])

  };

  const handleRemove = (index) => {
    handleRemoveItem(index, itemDescription, setItemDescription, errors, setErrors, setDeletedItemDescriptionId);
  };

  const onDiscountChange = (e) => {
    handleDiscountChange(e, subTotal, setErrors, setDiscountAmount, setDiscountPercentage);
  };

  const handleTax = (e, field) => {
    const { value } = e.target

    const isValid = value >= 0 && value <= 100;
    const hasValidDecimal = HAS_DECIMAL_VALUE_REGX.test(value);

    if (isValid && hasValidDecimal) {
      if (field === "Cgst") {
        setCgst(value);
      } else if (field === "Sgst") {
        setSgst(value);
      } else if (field === "Utgst") {
        setUtgst(value);
      } else if (field === "Igst") {
        setIgst(value);
      }
    }

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors.tax;
      return newErrors;
    });
  }

  const handleFileChange = (e) => {
    handleFileInputChange(e, setIndividualBillDocument, setErrors);
  };

  const handleRemoveFile = (index, event) => {
    event.preventDefault();

    const updatedFiles = [...oldFiles];
    const deletedFileId = updatedFiles[index].FileId;

    updatedFiles.splice(index, 1);

    setOldFiles(updatedFiles);
    setDeletedFileId((prevDeletedIds) => [...prevDeletedIds, deletedFileId]);
  };

  const handleSubmit = async (e, actionType) => {
    e.preventDefault();
    const currentPath = location.pathname;
    const validIndividualBillDocument = Array.isArray(individualBillDocument) ? individualBillDocument : [];
    try {
      const isValid = validateForm(
        user.RoleId,
        departmentId,
        billDate,
        itemDescription,
        subTotal,
        cgst,
        sgst,
        utgst,
        igst,
        lineLevelCheck,
        oldFiles,
        validIndividualBillDocument
      );

      if (isValid) {
        if (actionType === "Save") {
          setSaveDisable(true);
          setSaveLoader(true);
        } else {
          setSubmitDisable(true);
          setLoader(true);
        }

        let individualBillDocumentArray = [];

        const fileUploadPromises = validIndividualBillDocument.map(async (file) => {
          const result = await uploadFile(
            "individualbills",
            vendorName,
            vendorId,
            file
          );
          const singlefile = {
            FileName: result.fileName,
            FilePath: result.filePath,
          };
          individualBillDocumentArray.push(singlefile);
        });
        await Promise.all(fileUploadPromises);

        let updatedItemDescription;

        if (lineLevelCheck) {
          updatedItemDescription = itemDescription.map((row) => ({
            id: row.id,
            itemId: Number(row.itemId),
            description: row.description || null,
            rate: Number(row.rate),
            quantity: Number(row.quantity),
            cgst: row.cgst ? Number(row.cgst) : null,
            sgst: row.sgst ? Number(row.sgst) : null,
            utgst: row.utgst ? Number(row.utgst) : null,
            igst: row.igst ? Number(row.igst) : null,
            gstAmount: String(row.gST_Total) ? Number(row.gST_Total) : null,
            totalAmount: Number(row.amount)
          }));
        } else {
          updatedItemDescription = itemDescription.map((row) => ({
            id: row.id,
            itemId: Number(row.itemId),
            description: row.description || null,
            rate: Number(row.rate),
            quantity: Number(row.quantity),
            cgst: null,
            sgst: null,
            utgst: null,
            igst: null,
            gstAmount: null,
            totalAmount: Number(row.amount)
          }));
        }

        const result = await postApi(UPDATE_INDIVIDUAL_BILL, {
          vendorId: Number(vendorId),
          userId: Number(user.id),
          username: user.LegalName || user.Name,
          transactionId: transactionList[4].id,
          departmentId: departmentId,
          billId: billId,
          roleId: user.RoleId,
          referenceNo: refNo,
          billDate: billDate,
          dueDate: dueDate,
          paymentTypeId: 2,
          isGstInclude: false,
          isLineLevelGst: lineLevelCheck,
          itemDescription: updatedItemDescription,
          deletedItemDescriptionId: deletedItemDescriptionId,
          individualBillDocument: individualBillDocumentArray,
          deletedIndividualBillDocumentId: deletedFileId,
          cgst: lineLevelCheck ? null : cgst ? Number(cgst) : null,
          sgst: lineLevelCheck ? null : sgst ? Number(sgst) : null,
          utgst: lineLevelCheck ? null : utgst ? Number(utgst) : null,
          igst: lineLevelCheck ? null : igst ? Number(igst) : null,
          individualBillTotalAmount: subTotal,
          gstTotal: gstTotal,
          grandTotal: grandTotal,
          discount: Number(discountAmount),
          discountPercentage: Number(discountPercentage),
          actionType: actionType
        });

        if (result.res.success) {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: result.res.message,
            showConfirmButton: false,
            timer: 2000,
          });
          if (currentPath === "/") {
            navigate("/")
          } else if (currentPath.includes("/individual-bill-list")) {
            navigate("/individual-bill-list")
          } else {
            navigate(-1)
          }
        }
        else {
          setErrors({ refNo: result.res.message });
          setSaveLoader(false);
          setSaveDisable(false);
          setLoader(false);
          setSubmitDisable(false);
        }
      }
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Standalone Bill</title>
      </Helmet>

      <Loader isLoading={spinner} />

      <div className="container-section">

        <div className="section-header">
          <button onClick={() => navigate(-1)} className="back-viewbtn me-2">
            <div className="svgsize">
              <BackButton />
            </div>
          </button>
          <h3>Standalone Bill</h3>
        </div>

        <form>

          <div className="form-style">
            <div className="row">

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="vendorId" className="form-label">
                  Vendor<span className="required">*</span>
                </label>

                <input
                  type="text"
                  id="vendorId"
                  className="form-control"
                  name="vendorId"
                  value={vendorName}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="refNo" className="form-label">
                  Reference Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter reference number"
                  name="refNo"
                  value={refNo || ''}
                  onChange={handleRefChange}
                />
              </div>

              {(user.RoleId !== VENDORID) && (

                <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                  <label htmlFor="departmentId" className="form-label">
                    Department<span className="required">*</span>
                  </label>
                  <select name="departmentId" id="departmentId" className="form-select" value={departmentId} onChange={onDepartmentChange}>
                    <option value="">Select</option>
                    {departmentList.map((item, index) => (
                      <option key={index} value={item.DepartmentId}>
                        {item.DepartmentName}
                      </option>
                    ))}
                  </select>
                  {errors.departmentId && <span className="error-text">{errors.departmentId}</span>}
                </div>

              )}

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="billDate" className="form-label">
                  Bill Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  min={minBillDate.format('YYYY-MM-DD')}
                  className="form-control"
                  value={billDate}
                  name="billDate"
                  onChange={handleBillDateChange}
                  onKeyDown={(e) => e.preventDefault()}
                />
                {errors.billDate && (
                  <span className="error-text">{errors.billdate}</span>
                )}
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="dueDate" className="form-label">
                  Due Date
                </label>
                <input
                  type="date"
                  name="dueDate"
                  id="dueDate"
                  className="form-control"
                  value={dueDate}
                  disabled
                />
              </div>

            </div>
          </div>

          <div className="form-style">
            <div className="col-xl-3 col-lg-4 col-md-3 mb-2">
              <div className="d-flex algin-item-center">
                <input
                  type="checkbox"
                  checked={lineLevelCheck}
                  onChange={handleLineLevelChange}
                />
                <label className="ms-2 form-check-label">Line Level GST</label>
              </div>
            </div>
            <div className="">
              <div className="section-sub-heading">
                <h3>Item Description</h3>
              </div>
              <div className="pur-item-list table-responsive tbl-body-shadow">
                <table className="w-100 table table-theme-1 mb-0">

                  <thead className="table-heads">
                    <tr>
                      <th className="text-center">Item</th>
                      <th className="text-center">Description</th>
                      <th className="text-center">Rate</th>
                      <th className="text-center">Quantity</th>
                      {lineLevelCheck &&
                        <>
                          <th className="text-center">CGST(%)</th>
                          <th className="text-center">SGST(%)</th>
                          <th className="text-center">UTGST(%)</th>
                          <th className="text-center">IGST(%)</th>
                          <th className="text-center">GST Amount</th>
                        </>
                      }
                      <th className="text-center">Amount</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {itemDescription.map((item, index) => (
                      <tr key={index}>
                        <td className="custom-width">
                          <div className="position-relative">
                            <select className="form-select mb-20" name="itemId"
                              value={item.itemId}
                              onChange={(e) => handleItemDescriptionChange(index, "itemId", e.target.value)}
                            >
                              <option value="">Select</option>
                              {vendorId && vendorItems.map((item, index) => (
                                <option key={index} value={item.itemId}>
                                  {item.itemname}
                                </option>
                              ))}
                            </select>
                            {errors.itemDescription &&
                              errors.itemDescription[index] &&
                              errors.itemDescription[index].itemId && (
                                <span className="error-text">
                                  {errors.itemDescription[index].itemId}
                                </span>
                              )}
                          </div>
                        </td>
                        <td className="custom-width">
                          <div className="position-relative des-width">
                            <textarea name="description" className="form-control" rows="1" cols="50" value={item.description}
                              onChange={(e) => handleDescriptionChange(index, e.target.name, e.target.value, itemDescription, setItemDescription, errors, setErrors)}>
                            </textarea>
                            {errors.itemDescription &&
                              errors.itemDescription[index] &&
                              errors.itemDescription[index].description && (
                                <span className="error-text">
                                  {errors.itemDescription[index].description}
                                </span>
                              )}
                          </div>
                        </td>
                        <td className="custom-width">
                          <div className="position-relative">
                            <input type="text" className="form-control text-end mb-20" name="rate"
                              autocomplete="off"
                              placeholder="Rate"
                              value={item.rate}
                              onChange={(e) => handleItemDescriptionChange(index, "rate", e.target.value)}
                            >
                            </input>
                            {errors.itemDescription &&
                              errors.itemDescription[index] &&
                              errors.itemDescription[index].rate && (
                                <span className="error-text">
                                  {errors.itemDescription[index].rate}
                                </span>
                              )}
                          </div>
                        </td>
                        <td className="custom-width">
                          <div className="position-relative">
                            <input type="text" className="form-control text-end mb-20" name="quantity"
                              autocomplete="off"
                              placeholder="Quantity"
                              value={item.quantity}
                              onChange={(e) => handleItemDescriptionChange(index, "quantity", e.target.value)}
                            >
                            </input>
                            {errors.itemDescription &&
                              errors.itemDescription[index] &&
                              errors.itemDescription[index].quantity && (
                                <span className="error-text">
                                  {errors.itemDescription[index].quantity}
                                </span>
                              )}
                          </div>
                        </td>
                        {lineLevelCheck &&
                          <>
                            <td className="custom-width">
                              <div className="position-relative">
                                <input type="text" className="form-control text-end mb-20" name="cgst"
                                  autocomplete="off"
                                  placeholder="CGST(%)"
                                  value={item.cgst !== null ? item.cgst : ''}
                                  onChange={(e) => handleItemDescriptionChange(index, "cgst", e.target.value)}
                                  disabled={item.utgst || item.igst}
                                >
                                </input>
                                {errors.itemDescription &&
                                  errors.itemDescription[index] &&
                                  errors.itemDescription[index].cgst && (
                                    <span className="error-text">
                                      {errors.itemDescription[index].cgst}
                                    </span>
                                  )}
                              </div>
                            </td>
                            <td className="custom-width">
                              <div className="position-relative">
                                <input type="text" className="form-control text-end mb-20" name="sgst"
                                  autocomplete="off"
                                  placeholder="SGST(%)"
                                  value={item.sgst !== null ? item.sgst : ''}
                                  onChange={(e) => handleItemDescriptionChange(index, "sgst", e.target.value)}
                                  disabled={item.utgst || item.igst}
                                >
                                </input>
                                {errors.itemDescription &&
                                  errors.itemDescription[index] &&
                                  errors.itemDescription[index].sgst && (
                                    <span className="error-text">
                                      {errors.itemDescription[index].sgst}
                                    </span>
                                  )}
                              </div>
                            </td>
                            <td className="custom-width">
                              <div className="position-relative">
                                <input type="text" className="form-control text-end mb-20" name="utgst"
                                  autocomplete="off"
                                  placeholder="UTGST(%)"
                                  value={item.utgst !== null ? item.utgst : ''}
                                  onChange={(e) => handleItemDescriptionChange(index, "utgst", e.target.value)}
                                  disabled={item.cgst || item.sgst || item.igst}
                                >
                                </input>
                                {errors.itemDescription &&
                                  errors.itemDescription[index] &&
                                  errors.itemDescription[index].utgst && (
                                    <span className="error-text">
                                      {errors.itemDescription[index].utgst}
                                    </span>
                                  )}
                              </div>
                            </td>
                            <td className="custom-width">
                              <div className="position-relative">
                                <input type="text" className="form-control text-end mb-20" name="igst"
                                  autocomplete="off"
                                  placeholder="IGST(%)"
                                  value={item.igst !== null ? item.igst : ''}
                                  onChange={(e) => handleItemDescriptionChange(index, "igst", e.target.value)}
                                  disabled={item.cgst || item.sgst || item.utgst}
                                >
                                </input>
                                {errors.itemDescription &&
                                  errors.itemDescription[index] &&
                                  errors.itemDescription[index].igst && (
                                    <span className="error-text">
                                      {errors.itemDescription[index].igst}
                                    </span>
                                  )}
                              </div>
                            </td>
                            <td className="custom-width">
                              <div className="position-relative">
                                <input type="text" className="form-control text-end mb-20" name="gstAmount"
                                  autocomplete="off"
                                  placeholder="GST Amount"
                                  value={item.gST_Total !== null ? item.gST_Total : ''}
                                  disabled
                                >
                                </input>
                              </div>
                            </td>
                          </>
                        }
                        <td className="custom-width">
                          <div className="position-relative">
                            <input type="text" className="form-control text-end mb-20" placeholder="amount" name="totalAmount"
                              value={item.amount.toFixed(2)}
                              disabled
                            >
                            </input>
                          </div>
                        </td>
                        <td className="custom-width">
                          <div className="cursor-pointer d-flex align-items-center justify-content-center mb-20">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                  className="custom-tooltip"
                                  id="tooltips1"
                                >
                                  Delete
                                </Tooltip>
                              }
                            ><span
                              className=""
                              onClick={(e) => handleRemove(index)}
                            >
                                <DeleteIcon />
                              </span>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>

                </table>
              </div>
            </div>

            <div className="Addmoreicon">
              <button type="button" className="addIcon" onClick={handleAdd}>
                <p>+</p>
              </button>
            </div>

            <div className="d-flex justify-content-end inv-gst-tbl-cont mt-3 mb-3">
              <div className="GST-bill-width">

                <div className="d-flex align-items-center mb-3">
                  <span className="gst-cont-label">Sub Total</span>
                  <span className="gst-cont-semicolumn">:</span>
                  <span className="gst-cont-input">
                    <input
                      type="text"
                      value={`${currencySymbol} ${subTotal.toFixed(2)}`}
                      className="form-control text-end"
                      disabled
                    />
                    {errors.subTotal && (
                      <div className="validation-error">
                        {errors.subTotal}
                      </div>
                    )}
                  </span>
                </div>
                {!lineLevelCheck && (
                  <>
                    <div className="d-flex align-items-center mb-3">
                      <span className="gst-cont-label">Discount</span>
                      <span className="gst-cont-semicolumn">:</span>
                      <span className="gst-cont-input">
                        <input
                          className="form-control text-end"
                          type="text"
                          id="discount"
                          name="discount"
                          placeholder="Enter Discount"
                          value={discountAmount}
                          onChange={onDiscountChange}
                          disabled={parseFloat(subTotal) === 0}
                        />
                      </span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <span className="gst-cont-label">Discount(%)</span>
                      <span className="gst-cont-semicolumn">:</span>
                      <span className="gst-cont-input">
                        <input
                          className="form-control text-end"
                          type="text"
                          id="discount"
                          name="discount"
                          placeholder="Discount (%)"
                          value={discountPercentage}
                          disabled
                        />
                      </span>
                    </div>
                  </>
                )}

                {discountAmount !== 0 && discountAmount && !lineLevelCheck && (
                  <div className="d-flex align-items-center mb-2">
                    <span className="gst-cont-label">Net Amount</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        className="form-control text-end"
                        type="text"
                        id="discount"
                        name="discount"
                        placeholder="Enter Discount"
                        value={`${currencySymbol} ${(subTotal - discountAmount)?.toFixed(2)}`}
                        disabled
                      />
                    </span>
                  </div>
                )}

                {!lineLevelCheck &&
                  <>
                    <div className="d-flex align-items-center mb-2">
                      <span className="gst-cont-label">CGST(%)</span>
                      <span className="gst-cont-semicolumn">:</span>
                      <span className="gst-cont-input">
                        <input
                          type="text"
                          value={cgst}
                          name="cgst"
                          className="form-control text-end"
                          placeholder="Enter CGST(%)"
                          onChange={(e) => handleTax(e, "Cgst")}
                          disabled={utgst || igst}
                        />
                      </span>
                    </div>

                    <div className="d-flex align-items-center mb-2">
                      <span className="gst-cont-label">SGST(%)</span>
                      <span className="gst-cont-semicolumn">:</span>
                      <span className="gst-cont-input">
                        <input
                          type="text"
                          value={sgst}
                          name="sgst"
                          className="form-control text-end"
                          placeholder="Enter SGST(%)"
                          onChange={(e) => handleTax(e, "Sgst")}
                          disabled={utgst || igst}
                        />
                      </span>
                    </div>

                    <div className="d-flex align-items-center mb-2">
                      <span className="gst-cont-label">UTGST(%)</span>
                      <span className="gst-cont-semicolumn">:</span>
                      <span className="gst-cont-input">
                        <input
                          type="text"
                          value={utgst}
                          name="utgst"
                          className="form-control text-end"
                          placeholder="Enter UTGST(%)"
                          onChange={(e) => handleTax(e, "Utgst")}
                          disabled={cgst || sgst || igst}
                        />
                      </span>
                    </div>

                    <div className="d-flex align-items-center mb-3">
                      <span className="gst-cont-label">IGST(%)</span>
                      <span className="gst-cont-semicolumn">:</span>
                      <span className="gst-cont-input">
                        <input
                          type="text"
                          value={igst}
                          name="igst"
                          className="form-control text-end"
                          placeholder="Enter IGST(%)"
                          onChange={(e) => handleTax(e, "Igst")}
                          disabled={cgst || sgst || utgst}
                        />
                        {errors.tax && (
                          <p className="validation-error">
                            {errors.tax}
                          </p>
                        )}
                      </span>
                    </div>
                  </>
                }

                <div className="d-flex align-items-center mb-2">
                  <span className="gst-cont-label">GST Total</span>
                  <span className="gst-cont-semicolumn">:</span>
                  <span className="gst-cont-input">
                    <input
                      type="text"
                      value={`${currencySymbol} ${gstTotal.toFixed(2)}`}
                      className="form-control text-end"
                      disabled
                    />
                  </span>
                </div>

                <div className="d-flex align-items-center mb-2">
                  <span className="gst-cont-label">Grand Total</span>
                  <span className="gst-cont-semicolumn">:</span>
                  <span className="gst-cont-input">
                    <input
                      type="text"
                      value={`${currencySymbol} ${grandTotal.toFixed(2)}`}
                      className="form-control text-end"
                      disabled
                    />
                  </span>
                </div>

              </div>
            </div>

            <div className="section-sub-heading">
              <h3>Files</h3>
            </div>

            <div className="pur-item-list">
              <div className="table-responsive tbl-body-shadow mb-20">
                <table className="w-100  table-theme-1">
                  <thead className="table-heads">
                    <tr>
                      <th className="text-left">File Name</th>
                      <th className="text-left">File Type</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {oldFiles.length > 0 ? (
                      oldFiles.map((row, index) => (
                        <tr key={index}>
                          <td className="text-left">{row.FileName}</td>
                          <td className="text-left">Pdf</td>
                          <td>
                            <div className="cursor-pointer d-flex align-items-center justify-content-center">
                              <div className="download-btn-container">
                                <Link
                                  to={`${BASE_IMAGE_URL}/${row.Path}`}
                                  download
                                  target="_blank"
                                >
                                  <button type="button" className="btn">
                                    <DownloadIcon /> Download
                                  </button>
                                </Link>
                              </div>
                              <div className="preview-btn-container ms-2">
                                <Link
                                  to={`/preview-pdf/${btoa(row.Path)}`}
                                  target="_blank"
                                >
                                  <button type="button" className="btn">
                                    <EyeIcon /> Preview
                                  </button>
                                </Link>
                              </div>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip
                                    className="custom-tooltip"
                                    id="tooltips1"
                                  >
                                    Delete
                                  </Tooltip>
                                }
                              >
                                <span
                                  className="ms-2"
                                  onClick={(e) => handleRemoveFile(index, e)}
                                >
                                  <DeleteIcon />
                                </span>
                              </OverlayTrigger>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="cstm-checkbox mb-2">
              <div className="cstm-checkbox-inner d-flex align-items-center">
                <input
                  className="form-check-input m-0"
                  type="checkbox"
                  checked={attachDocuments}
                  onChange={onCheckboxChange}
                />
                <div className="cstm-checkbox-inr-lbl-field d-flex align-items-center">
                  <label htmlFor="" className="ms-2">
                    Check for relevant document
                  </label>
                  <span className="required">*</span>
                  <span className=" infoIcon"></span>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-xl-4 col-lg-4 col-md-5 position-relative">
                <div className="input-group file-input-container">
                  <input
                    type="file"
                    id="files"
                    className="form-control"
                    name="files"
                    disabled={!attachDocuments}
                    onChange={handleFileChange}
                    multiple
                    ref={fileInputRef}
                  />
                </div>
                {errors.files && (
                  <p className="validation-error">{errors.files}</p>
                )}
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-end mb-20">
              {(user?.RoleId === ADMIN_EXECUTIVE_ID) && (
                <div className="success-btn ms-2">
                  <button
                    type="submit"
                    className="btn"
                    onClick={(e) => handleSubmit(e, "Save")}
                    disabled={saveDisable || saveLoader}
                  >
                    {saveLoader ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              )}

              <div className="success-btn ms-2">
                <button
                  type="button"
                  className="btn"
                  onClick={(e) => handleSubmit(e, "Submit")}
                  disabled={submitDisable}
                >
                  {loader ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>

            </div>
          </div>

        </form >
      </div >
    </>
  );
};

export default EditIndividualBill;
