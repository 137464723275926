import Accordion from "react-bootstrap/Accordion";
import React from "react";
const SystemInfo = ({ viewHistory }) => {

    return (
        <>
            <div className="bank-accordian-box">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <h3>Audit History</h3>
                        </Accordion.Header>
                        <div className="">
                            <Accordion.Body className="form-style">
                                <div className="table-responsive tbl-body-shadow">
                                    <table className="w-100 table-theme-1">
                                        <thead>
                                            <tr>
                                                <th className="text-left">Date</th>
                                                <th className="text-left">Set By</th>
                                                <th className="text-left">Type</th>
                                                <th className="text-left">Field Name</th>
                                                <th className="text-left">Old Field Value</th>
                                                <th className="text-left">New Field Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {viewHistory?.map((row, index) => (
                                                <tr key={index}>
                                                    <td className="text-left">{row.ModifiedAt}</td>
                                                    <td className="text-left">{row.CreatedByName}</td>
                                                    <td className="text-left">{row.Type}</td>
                                                    <td className="text-left">{row.FieldName}</td>
                                                    <td className="text-left">{row.OldFieldValue}</td>
                                                    <td className="text-left">{row.NewFieldValue}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Accordion.Body>
                        </div>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
};

export default SystemInfo;
