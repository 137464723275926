import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import BackButton from "../../../assets/image/BackButton";
import DeleteIcon from "../../../assets/image/DeleteIcon";
import DownloadIcon from "../../../assets/image/DownloadIcon";
import EyeIcon from "../../../assets/image/EyeIcon";
import { getApi, BASE_IMAGE_URL, postApi } from "../../../utils/services";
import {
  EDIT_CONTRACT,
  VIEW_CONTRACT,
} from "../../../utils/apiRoutes";
import moment from "moment";
import EditContractValidation from "../../../middleware/editContractValidation";
import { useTransactionContext } from "../../../utils/helpers/useTransaction";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";
import { fetchConfigeration, fetchVendorData, handleCheckboxChange } from "../../../utils/helpers/globalMethod";
import { fetchItemDetails } from "../../../utils/helpers/globalMethod";
import { CAMEL_CASE_REGX, DISCARD_NON_DIGIT_REGX, NON_NUMERIC_DOT_REGX, TRIM_SPACE_REGX } from "../../../utils/helpers/globalRegx";
import { handleDescriptionChange } from "../../../utils/helpers/globalMethod";
import { CONTRACT_SITE_CONFIGERATION_ID } from "../../../utils/constant";
import { uploadFile } from "../../../utils/helpers/useFileUpload";
import Swal from "sweetalert2";

const EditContractPage = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const transactionList = useTransactionContext();
  const user = JSON.parse(localStorage.getItem("userData"));
  const [vendorName, setVendorName] = useState("");
  const [contractId, setContractId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [term, setTerm] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [subsidiary, setSubsidiary] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [contractTotal, setContractTotal] = useState("");
  const [documentNo, setDocumentNo] = useState("");
  const [itemDescription, setItemDescription] = useState([]);
  const [vendorItems, setVendorItems] = useState([]);
  const [files, setFiles] = useState([]);
  const [contractDocument, setContractDocument] = useState([]);
  const [deletedLineItemId, setDeletedLineItemId] = useState([]);
  const [deletedFileId, setDeletedFileId] = useState([]);
  const [contractStartDate, setContractStartDate] = useState("");
  const [isDisabledEndDate, setIsDisabledEndDate] = useState(true);
  const [attachDocument, setAttachDocument] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [loader, setLoader] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  const { errors, setErrors, validateForm } = EditContractValidation();
  const fileInputRef = useRef(null);

  const toCamelCase = (str) => {
    return str.replace(CAMEL_CASE_REGX, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase()
    )
      .replace(TRIM_SPACE_REGX, '');
  };

  const handleViewData = async () => {
    try {
      setSpinner(true);
      const result = await getApi(`${VIEW_CONTRACT}/${id}`);
      setSpinner(false);
      setVendorName(result.res.contract_details.LegalName);
      setContractId(result.res.contract_details.id);
      setDocumentNo(result.res.contract_details.DocumentNo);
      setStartDate(
        moment(result.res.contract_details.StartDate).format().slice(0, 10)
      );
      setEndDate(
        moment(result.res.contract_details.EndDate).format().slice(0, 10)
      );
      setTerm(result.res.contract_details.Term);
      setSubsidiary(result.res.contract_details.Name);
      setContractTotal(result.res.contract_details.ContractTotal);
      const transformedItems = result.res.contract_details.LineItems.map(item => {
        return Object.keys(item).reduce((acc, key) => {
          acc[toCamelCase(key)] = item[key];
          return acc;
        }, {});
      });

      setItemDescription(transformedItems);
      setFiles(result.res.contract_details.Files);
      setVendorId(result.res.contract_details.VendorId);
      setCurrencySymbol(result.res.contract_details.Symbol);

    } catch (error) {
      console.log(error);
    }
  };

  const onCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    handleCheckboxChange(isChecked, setAttachDocument, setContractDocument, setErrors, fileInputRef);
  };

  useEffect(() => {
    if (id) {
      handleViewData();
    }
  }, [id]);


  useEffect(() => {
    const fetchList = async () => {
      await fetchConfigeration(setContractStartDate, CONTRACT_SITE_CONFIGERATION_ID)
    };

    fetchList();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchVendorData(vendorId, null, null, null, setVendorItems, null, null);
    }; fetchData();
  }, [vendorId]);

  useEffect(() => {
    const calculateFinalTotalAmount = () => {
      const contractTotal = itemDescription.reduce(
        (acc, item) => acc + parseFloat(item.amount || 0),
        0
      );
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors.contractTotal;
        return updatedErrors;
      });
      setContractTotal(contractTotal.toFixed(2));
    };

    calculateFinalTotalAmount();
  }, [itemDescription]);

  const currentDate = new Date();
  const formattedStartDate = new Date(
    new Date().setDate(currentDate.getDate() - contractStartDate)
  );
  const minStartDate = formattedStartDate.toISOString().split("T")[0];

  const formattedEndDate = startDate ? new Date(startDate) : new Date();
  formattedEndDate.setDate(formattedEndDate.getDate() + 1);

  const minEndDate = formattedEndDate.toISOString().split("T")[0];

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setIsDisabledEndDate(false);
    if (errors.startDate) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        startDate: "",
      }));
    }
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    if (errors.endDate) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        endDate: "",
      }));
    }
  };

  const handleItemDescriptionChange = async (index, field, value) => {
    const updatedLineItems = [...itemDescription];

    let newValue;

    if (field === "quantity") {
      newValue = value.replace(DISCARD_NON_DIGIT_REGX, "");
    } else if (field === "rate") {
      newValue = value.replace(NON_NUMERIC_DOT_REGX, "");
      const parts = newValue.split('.');

      if (parts.length > 2) {
        newValue = parts.shift() + '.' + parts.join('');
      }
      const dotIndex = newValue.indexOf(".");

      if (dotIndex !== -1 && newValue.length - dotIndex > 3) {
        newValue = newValue.slice(0, dotIndex + 3);
      }
    } else {
      newValue = value;
    }

    updatedLineItems[index][field] = newValue;

    if (field === "itemId" && newValue) {
      try {
        const description = await fetchItemDetails(newValue);
        updatedLineItems[index].description = description || '';
      } catch (error) {
        console.error(error);
      }
    }

    updatedLineItems.forEach((row) => {
      row.amount =
        isNaN(row.quantity) || isNaN(row.rate)
          ? ""
          : (row.quantity * row.rate).toFixed(2);
    });

    const updatedErrors = { ...errors };
    if (updatedErrors.itemDescription && updatedErrors.itemDescription[index]) {
      delete updatedErrors.itemDescription[index][field];
    }

    setErrors(updatedErrors);
    setItemDescription(updatedLineItems);
  };


  const handleAdd = () => {
    const newItem = {
      id: "",
      ItemId: "",
      description: "",
      Rate: "",
      Quantity: "",
      Amount: "",
    };

    const updatedLineItems = [...itemDescription, newItem];
    setItemDescription(updatedLineItems);
  };

  const handleRemove = (index, event) => {
    event.preventDefault();

    if (itemDescription.length === 1) {
      alert("At least one item should be there");
      return;
    }

    const updatedLineItems = [...itemDescription];

    const deletedId = updatedLineItems[index].id;

    updatedLineItems.splice(index, 1);

    const updatedErrors = { ...errors };
    if (updatedErrors.itemDescription && updatedErrors.itemDescription.length > index) {
      updatedErrors.itemDescription.splice(index, 1);
    }
    setErrors(updatedErrors);

    setItemDescription(updatedLineItems);
    setDeletedLineItemId((prevDeletedIds) => [...prevDeletedIds, deletedId]);
  };

  const handleRemoveFile = (index, event) => {
    event.preventDefault();

    const updatedFiles = [...files];

    const deletedFileId = updatedFiles[index].FileId;

    updatedFiles.splice(index, 1);

    setFiles(updatedFiles);
    setDeletedFileId((prevDeletedIds) => [...prevDeletedIds, deletedFileId]);
  };

  const handleFileChange = (event) => {
    const contractDocument = Array.from(event.target.files);
    const updatedErrors = { ...errors };
    delete updatedErrors.files;

    setContractDocument(contractDocument);
    setErrors(updatedErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentPath = location.pathname;
    const validContractDocument = Array.isArray(contractDocument) ? contractDocument : [];

    const isValid = validateForm(
      startDate,
      endDate,
      itemDescription,
      contractTotal,
      files,
      validContractDocument
    );

    if (isValid) {

      setLoader(true);
      setSubmitDisable(true);

      let contractDocumentArray = [];

      const updatedItemDescription = itemDescription.map((row) => ({
        id: row.id,
        itemId: Number(row.itemId),
        rate: Number(row.rate),
        quantity: Number(row.quantity),
        totalAmount: Number(row.amount),
        description: row.description || null
      }));

      const words = vendorName.split(" ");
      const name = words[0].trim();
      const vendorNameLowerCase = name.toLowerCase();

      const fileUploadPromises = validContractDocument.map(async (file) => {
        const result = await uploadFile(
          "contracts",
          vendorNameLowerCase,
          vendorId,
          file
        );
        const singlefile = {
          FileName: result.fileName,
          FilePath: result.filePath,
        };
        contractDocumentArray.push(singlefile);
      });

      await Promise.all(fileUploadPromises);

      const result = await postApi(EDIT_CONTRACT, {
        contractId: contractId,
        transactionId: transactionList[2].id,
        roleId: user.RoleId,
        userId: Number(user.id),
        username: user.Name,
        startDate: startDate,
        endDate: endDate,
        itemDescription: updatedItemDescription,
        contractTotalAmount: Number(contractTotal),
        deletedItemDescriptionId: deletedLineItemId,
        deletedContractDocumentId: deletedFileId,
        contractDocument: contractDocumentArray,
      });

      if (result.res.success) {
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: result.res.message,
          showConfirmButton: false,
          timer: 2000,
        });
        if (currentPath === "/") {
          navigate("/")
        } else if (currentPath.includes("/contract-listing")) {
          navigate("/contract-listing")
        } else {
          navigate(-1)
        }
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Purchase Contract</title>
      </Helmet>
      <Loader isLoading={spinner} />
      <div className="container-section">
        <div className="section-header">
          <button onClick={() => navigate(-1)} className="back-viewbtn me-2">
            <BackButton />
          </button>
          <h3>Purchase Contract - {documentNo}</h3>
        </div>

        <form>
          <div className="form-style">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                <label htmlFor="vendor" className="form-label">
                  Vendor<span className="required">*</span>
                </label>

                <input
                  type="text"
                  id="vendor"
                  className="form-control"
                  name="vendor"
                  value={vendorName}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="startDate" className="form-label">
                  Start Date<span className="required">*</span>
                </label>

                <input
                  type="date"
                  id="startDate"
                  className="form-control"
                  name="startDate"
                  min={minStartDate}
                  value={startDate}
                  onChange={handleStartDateChange}
                  onKeyDown={(e) => e.preventDefault()}
                />
                {errors.startDate && (
                  <span className="error-text">{errors.startDate}</span>
                )}
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="endDate" className="form-label">
                  End Date<span className="required">*</span>
                </label>

                <input
                  type="date"
                  id="endDate"
                  className="form-control"
                  name="endDate"
                  min={minEndDate}
                  value={endDate}
                  onChange={handleEndDateChange}
                  disabled={isDisabledEndDate}
                  onKeyDown={(e) => e.preventDefault()}
                />
                {errors.endDate && (
                  <span className="error-text">{errors.endDate}</span>
                )}
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                <label htmlFor="terms" className="form-label">
                  Terms
                </label>
                <input
                  type="text"
                  id="terms"
                  className="form-control"
                  name="terms"
                  value={term}
                  disabled
                ></input>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                <label htmlFor="subsidiary" className="form-label">
                  Subsidiary
                </label>
                <input
                  type="text"
                  id="subsidiary"
                  className="form-control"
                  name="subsidiary"
                  value={subsidiary}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="form-style">
            <div className="section-sub-heading">
              <h3>Item Description</h3>
            </div>
            <div className="pur-item-list">
              <div className="table-responsive tbl-body-shadow">
                <table className="w-100 table-theme-1">
                  <thead className="table-heads">
                    <tr>
                      <th className="text-center">Item</th>
                      <th className="text-center">Description</th>
                      <th className="text-center">Rate</th>
                      <th className="text-center">Quantity</th>
                      <th className="text-center">Amount</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemDescription.map((row, index) => (
                      <tr key={index}>
                        <td className="text-nowrap vertical-align" style={{ minWidth: '200px' }}>
                          <div className="position-relative">
                            <select
                              className="form-select mb-20"
                              value={row.itemId}
                              onChange={(e) => handleItemDescriptionChange(index, "itemId", e.target.value)}
                            >
                              <option value="">Select</option>
                              {vendorItems.map((item) => (
                                <option key={item.itemId} value={item.itemId}>
                                  {item.itemname}
                                </option>
                              ))}
                            </select>
                            {errors.itemDescription &&
                              errors.itemDescription[index] &&
                              errors.itemDescription[index].itemId && (
                                <span className="error-text">
                                  {errors.itemDescription[index].itemId}
                                </span>
                              )}
                          </div>
                        </td>
                        <td className="align-top" style={{ minWidth: '200px' }}>
                          <div className="position-relative">
                            <textarea name="description" className="form-control mb-20" rows="1" cols="30"
                              value={row.description}
                              onChange={(e) => handleDescriptionChange(index, e.target.name, e.target.value, itemDescription, setItemDescription, errors, setErrors)}>
                            </textarea>
                            {errors.itemDescription &&
                              errors.itemDescription[index] &&
                              errors.itemDescription[index].description && (
                                <span className="error-text">
                                  {errors.itemDescription[index].description}
                                </span>
                              )}
                          </div>
                        </td>
                        <td className="" style={{ minWidth: '120px' }}>
                          <div className="position-relative">
                            <input type="text" className="form-control text-end mb-20"
                              autocomplete="off"
                              value={row.rate}
                              placeholder="Rate"
                              onChange={(e) => handleItemDescriptionChange(index, "rate", e.target.value)}
                            >
                            </input>
                            {errors.itemDescription &&
                              errors.itemDescription[index] &&
                              errors.itemDescription[index].rate && (
                                <span className="error-text">
                                  {errors.itemDescription[index].rate}
                                </span>
                              )}
                          </div>
                        </td>
                        <td className="" style={{ minWidth: '120px' }}>
                          <div className="position-relative">
                            <input type="text" className="form-control text-end mb-20"
                              autocomplete="off"
                              value={row.quantity}
                              placeholder="Quantity"
                              onChange={(e) => handleItemDescriptionChange(index, "quantity", e.target.value)}
                            >
                            </input>
                            {errors.itemDescription &&
                              errors.itemDescription[index] &&
                              errors.itemDescription[index].quantity && (
                                <span className="error-text">
                                  {errors.itemDescription[index].quantity}
                                </span>
                              )}
                          </div>
                        </td>
                        <td className="" style={{ minWidth: '120px' }}>
                          <div className="position-relative">
                            <input type="text" className="form-control text-end mb-20"
                              autoComplete="off"
                              value={row.amount}
                              disabled
                            >
                            </input>
                          </div>
                        </td>
                        <td className="" style={{ minWidth: '150px' }}>
                          <div className="cursor-pointer d-flex align-items-center justify-content-center mb-20">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                  className="custom-tooltip"
                                  id="tooltips1"
                                >
                                  Delete
                                </Tooltip>
                              }
                            >
                              <span
                                className=""
                                onClick={(e) => handleRemove(index, e)}
                              >
                                <DeleteIcon />
                              </span>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="Addmoreicon">
              <button type="button" className="addIcon" onClick={handleAdd}>
                <p>+</p>
              </button>
            </div>

            <div className="row justify-content-end mb-20">
              <div className="col-md-3 position-relative">
                <label htmlFor="contractTotal" className="form-label">
                  Total Amount
                </label>

                <input
                  type="text"
                  id="contractTotal"
                  className="form-control text-end"
                  name="contractTotal"
                  value={`${currencySymbol} ${contractTotal}`}
                  disabled
                />
                {errors.contractTotal && (
                  <span className="error-text">{errors.contractTotal}</span>
                )}
              </div>
            </div>
          </div>

          <div className="form-style">
            <div className="section-sub-heading">
              <h3>Files</h3>
            </div>
            <div className="pur-item-list">
              <div className="table-responsive tbl-body-shadow mb-20">
                <table className="w-100 table-theme-1">
                  <thead className="table-heads">
                    <tr>
                      <th className="text-left">File Name</th>
                      <th className="text-left">File Type</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {files.length > 0 ? (
                      files.map((row, index) => (
                        <tr key={index}>
                          <td className="text-left">{row.FileName}</td>
                          <td className="text-left">Pdf</td>
                          <td>
                            <div className="cursor-pointer d-flex align-items-center justify-content-center">
                              <div className="download-btn-container">
                                <Link
                                  to={`${BASE_IMAGE_URL}/${row.FilePath}`}
                                  download
                                  target="_blank"
                                >
                                  <button type="button" className="btn">
                                    <DownloadIcon /> Download
                                  </button>
                                </Link>
                              </div>
                              <div className="preview-btn-container ms-2">
                                <Link
                                  to={`/preview-pdf/${btoa(row.FilePath)}`}
                                  target="_blank"
                                >
                                  <button type="button" className="btn">
                                    <EyeIcon /> Preview
                                  </button>
                                </Link>
                              </div>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip
                                    className="custom-tooltip"
                                    id="tooltips1"
                                  >
                                    Delete
                                  </Tooltip>
                                }
                              >
                                <span
                                  className="ms-2"
                                  onClick={(e) => handleRemoveFile(index, e)}
                                >
                                  <DeleteIcon />
                                </span>
                              </OverlayTrigger>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="cstm-checkbox mb-2">
              <div className="cstm-checkbox-inner d-flex align-items-center">
                <input
                  className="form-check-input m-0"
                  type="checkbox"
                  checked={attachDocument}
                  onChange={onCheckboxChange}
                />
                <div className="cstm-checkbox-inr-lbl-field d-flex align-items-center">
                  <label htmlFor="" className="ms-2">
                    Check for relevant document
                  </label>
                  <span className="required">*</span>
                  <span className=" infoIcon">{/* <InfoIcon /> */}</span>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-4 col-lg-4 col-md-5 mb-20 position-relative">
                <div className="input-group file-input-container">
                  <input
                    type="file"
                    id="files"
                    className="form-control"
                    name="files"
                    onChange={handleFileChange}
                    multiple
                    disabled={!attachDocument}
                    ref={fileInputRef}
                  />
                </div>
                {errors.files && (
                  <span className="error-text">{errors.files}</span>
                )}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end mb-20">
              <div className="success-btn ms-2">
                <button
                  type="submit"
                  className="btn"
                  onClick={(e) => handleSubmit(e)}
                  disabled={submitDisable}
                >
                  {loader ? (
                    <>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

    </>
  );
};

export default EditContractPage;
