import { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useStatusRenderer } from "../useStatus";
import payIcon from "../../../assets/image/payment.png";
import { ModalContext } from "../../Context";
import PaymentModal from "../../../components/modal/PaymentModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { PAYMENT_PENDING } from "../../constant";
import { BILL_NUMBER, DUE_DATE, VENDOR_NAME } from "./TableHeader";

// Custom hook for defining FeedsColumns
export const useFeedPayment = (handlerPayList, pageNumber, pageSize, globalSearch) => {
  const modalContext = useContext(ModalContext);
  const { handleModalData } = modalContext;

  const handlerPayment = (item) => {
    let pay = <PaymentModal item={item} handlerPayList={handlerPayList} pageNumber={pageNumber} pageSize={pageSize} globalSearch={globalSearch} type="list" />;
    handleModalData(pay, "md");
  };

  const columns = useMemo(
    () => [
      {
        Header: DUE_DATE,
        accessor: "DueDate",
        Cell: ({ row }) => {
          return (
            <>
              {" "}
              {row.original.DueDate
                ? moment(row.original.DueDate).format("D/MM/YYYY")?.slice(0, 10)
                : "N/A"}{" "}
            </>
          );
        },
      },
      {
        Header: BILL_NUMBER,
        accessor: "DocumentNo",
        Cell: ({ row }) => {
          return (
            <>
              <div className="cursor-pointer bill-no">
                {
                  row.original?.IndividualBill && <Link className="" to={`/view-individual-bill/${row.original.InvoiceId}`} target="_blank">{row.original.DocumentNo}</Link>
                }
                {
                  !row.original?.IndividualBill && <Link to={`/view-bill/${row.original.InvoiceId}`} target="_blank">{row.original.DocumentNo}</Link>
                }
              </div>
            </>
          );
        },
      },
      {
        Header: VENDOR_NAME,
        accessor: "VendorName",
      },
      {
        Header: <div className="ms-auto">Grand Total</div>,
        accessor: "GrandTotal",
        Cell: ({ row }) => {
          return (
            <>
              <div className="text-end">
                {row?.original?.CurrencySymbol}{" "}
                {row?.original?.GrandTotal?.toFixed(2)}
              </div>
            </>
          );
        },
      },
      {
        Header: <div className="ms-auto">TDS Amount</div>,
        accessor: "TDSAmount",
        Cell: ({ row }) => {
          return (
            <>
              <div className="text-end">
                {row?.original?.CurrencySymbol}
                {row?.original?.TDSAmount?.toFixed(2)}
              </div>
            </>
          );
        },
      },
      {
        Header: <div className="ms-auto">Payment Amount</div>,
        accessor: "Total",
        Cell: ({ row }) => {
          return (
            <>
              <div className="text-end">
                {row?.original?.CurrencySymbol}{" "}
                {row?.original?.Total?.toFixed(2)}
              </div>
            </>
          );
        },
      },
      // {
      //   Header: <div className="ms-auto">Total Paid Amount</div>,
      //   accessor: "TotalAmountPaid",
      //   Cell: ({ row }) => {
      //     const totalAmount = row?.original?.TotalAmountPaid;
      //     const currencySymbol = row?.original?.CurrencySymbol;
      //     const formattedAmount = !isNaN(totalAmount) && totalAmount !== null
      //       ? `${currencySymbol} ${totalAmount.toFixed(2)}`
      //       : `${currencySymbol} 0.00`;

      //     return <div className="text-end">{formattedAmount}</div>;
      //   },
      // },
      {
        Header: <div className='m-auto'>Status</div>,
        accessor: "StatusName",
        Cell: ({ row }) => useStatusRenderer(row),
        disableSortBy: true,
      },
      {
        Header: "Action",
        accessor: (item) => {
          return (
            <>
              <div className="d-flex align-item-center">
                {item.Status === PAYMENT_PENDING && (
                  <div className="cursor-pointer cstm-icon-gap">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip
                          className="custom-tooltip cstm-tooltip"
                          id="tooltips2"
                        >
                          Pay
                        </Tooltip>
                      }
                    >
                      <img
                        src={payIcon}
                        alt=""
                        onClick={() => handlerPayment(item)}
                      />
                    </OverlayTrigger>
                  </div>
                )}
              </div>
            </>
          );
        },
        disableSortBy: true,
      },
    ],
    [handlerPayList, pageNumber, pageSize, globalSearch]
  );

  return columns;
};
