import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import BackButtonWhite from "../../../assets/image/BackButtonWhite";
import { getApi } from "../../../utils/services";
import { BASE_IMAGE_URL } from "../../../utils/services";
import { VIEW_INDIVIDUAL_BILL } from "../../../utils/apiRoutes";
import DownloadIcon from "../../../assets/image/DownloadIcon";
import EyeIcon from "../../../assets/image/EyeIcon";
import moment from "moment/moment";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";
import { ModalContext } from "../../../utils/Context";
import { ADMIN_MANAGER_ID, FINANCE_ID, FINANCE_PENDING, PAYMENT_PENDING, SUPER_ADMIN_ID, VENDORID, VENDOR_MANAGER_PENDING, VENDOR_VP_PENDING, VP_ID, FULLY_PAID, VENDOR_REJECT, VOID_STATUS_ID, HOLD_STATUS_ID, STANDALONE_BILL_APPROVE_ID, PARTIALLY_PAID_STATUS_ID } from "../../../utils/constant";
import PaymentModal from "../../modal/PaymentModal";
import RejectionModal from "../../modal/RejectionModal";
import HoldReleaseVoidPage from "../../modal/HoldReleaseVoidModal";
import { handleApproval } from "../../../utils/helpers/globalMethod";
import BillToContent from "../../common/BillToContent";
import SystemInfo from "../vendor/SystemInfo";

const ViewIndividualBill = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = JSON.parse(localStorage.getItem("userData"));
  const [Viewdetails, setViewDetails] = useState({});
  const [showbackButton, setShowbackButton] = useState(false);
  const [loader, setLoader] = useState(false);
  const modalContext = useContext(ModalContext);
  const { handleModalData } = modalContext;
  const [individualBillHistory, setIndividualBillHistory] = useState([]);

  useEffect(() => {
    handleIndividualViewdetails();
  }, []);

  useEffect(() => {
    if (window.history.length > 1) {
      setShowbackButton(true);
    } else {
      setShowbackButton(false)
    }
  }, []);

  const handleGoBack = () => {
    navigate(-1)
  }

  const handleIndividualViewdetails = async () => {
    try {
      setLoader(true);
      const result = await getApi(`${VIEW_INDIVIDUAL_BILL}/${id}`);
      setLoader(false);
      setViewDetails(result.res.individualBill_details);
      const updatedHistory = result?.res.individualBillHistory.map((item) => {
        const utcTime = item.ModifiedAt;
        const date = new Date(utcTime);
        const formattedTime = date.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
        return { ...item, ModifiedAt: formattedTime };
      });
      setIndividualBillHistory(updatedHistory);
    } catch (error) {
      console.log(error);
    }
  };

  let data = {
    id: Number(id),
  };

  const handleApprove = () => {
    handleApproval(data, handleModalData, handleIndividualViewdetails, STANDALONE_BILL_APPROVE_ID);
  };

  const isHold = Viewdetails?.BillStatusId !== HOLD_STATUS_ID;
  const actionType = isHold ? "hold" : "release";

  const handleButtonClick = () => {
    const holdBillModal = <HoldReleaseVoidPage data={Viewdetails} onSuccess={handleIndividualViewdetails} type={actionType} />;
    handleModalData(holdBillModal, "md");
  };

  const handleReject = () => {
    let reject = <RejectionModal data={Viewdetails} handlerIndividualBillList={handleIndividualViewdetails} />;
    handleModalData(reject, "reject");
  };

  const handleTDSCreation = () => {
    navigate(`/add-individual-bill-tds/${id}`);
  };

  const handlePayment = () => {
    const payment = <PaymentModal item={Viewdetails} id={id} handleView={handleIndividualViewdetails} />;
    handleModalData(payment, "md");
  }

  const handlerVoidBill = async () => {
    let voidbill = <HoldReleaseVoidPage data={Viewdetails} type={VOID_STATUS_ID} />
    handleModalData(voidbill, "md");
  }

  return (
    <>
      <Helmet>
        <title>Vendor Portal || View Standalone Bill</title>
      </Helmet>
      <Loader isLoading={loader} />
      {
        (user?.RoleId === SUPER_ADMIN_ID && Viewdetails.StatusId === PAYMENT_PENDING && Viewdetails?.BillStatusId !== VOID_STATUS_ID && Viewdetails?.BillStatusId !== HOLD_STATUS_ID) && (
          <div className="d-flex justify-content-end">
            <span className="success-btn mb-2">
              <button className="btn" onClick={handlePayment}>Make Payment</button>
            </span>
          </div>)
      }
      <div className="container-section">

        <div className="section-inv-header">
          <div className="d-flex align-items-center justify-content-between w-100">
            {showbackButton && (
              <button onClick={handleGoBack} className="back-viewbtn me-1">
                <div className="svgsize">
                  <BackButtonWhite />
                </div>
              </button>
            )}

            <h3 className="bill-header-title">Standalone Bill</h3>
          </div>
          <div className="view-bill-container d-flex">
            {loader && <Loader />}
            <span className="">
              {user.RoleId === ADMIN_MANAGER_ID && (
                Viewdetails?.StatusId !== VENDOR_REJECT &&
                Viewdetails?.StatusId !== FULLY_PAID && Viewdetails?.BillStatusId !== VOID_STATUS_ID) && (
                  <div>
                    {Viewdetails?.BillStatusId !== HOLD_STATUS_ID ? (
                      <span className="hold-btn hold-bill-button me-2">
                        <button
                          id="holdButton"
                          className="btn"
                          onClick={handleButtonClick}
                        >
                          Hold
                        </button>
                      </span>
                    ) : (
                      <span className="success-btn release-bill-button me-2">
                        <button
                          id="releaseButton"
                          className="btn"
                          onClick={handleButtonClick}
                        >
                          Release
                        </button>
                      </span>
                    )}
                  </div>
                )}
            </span>
            <span className="custom-voidbill">
              {(
                (Viewdetails.StatusId === PAYMENT_PENDING && user.RoleId === ADMIN_MANAGER_ID && Viewdetails?.BillStatusId !== VOID_STATUS_ID) ||
                (Viewdetails.StatusId === VENDOR_REJECT && user.RoleId === ADMIN_MANAGER_ID && Viewdetails?.BillStatusId !== VOID_STATUS_ID)
              ) && (
                  <span className="danger-btn void-bill-button">
                    <button type="button" onClick={() => handlerVoidBill()} className="btn">
                      Void
                    </button>
                  </span>
                )}
            </span>
          </div>
        </div>

        <div className="form-style">
          <div className="data-inv-info">
            <div className="data-inv-group-1">
              <BillToContent />
              <div className="d-flex row flex-column align-items-between">
                <div className="col-md-9">
                  <div className="data-details-4 mb-3">
                    <div className="section-sub-heading">
                      <h4>Vendor Details:</h4>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className=" data-label">Legal Name</span>
                      <span className="data-semicolon">:</span>
                      <span className="data-value">
                        {Viewdetails?.LegalName}
                      </span>
                    </div>
                  </div>
                  {user.RoleId !== VENDORID && (
                    <div className="data-details-4">
                      <div className="section-sub-heading">
                        <h4>Integration:</h4>
                      </div>
                      <div className="d-flex align-items-center ">
                        <span className=" data-label">NetSuite Id</span>
                        <span className="data-semicolon">:</span>
                        <span className="data-value">
                          {Viewdetails?.invoiceExternalId ? Viewdetails?.invoiceExternalId : " N/A"}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="data-inv-group-2">
              <div className="data-details-4">
                <div className="d-flex align-items-center mb-1">
                  <span className="data-label">Bill#</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">
                    {Viewdetails.InvDocumentNo}
                  </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                  <span className="data-label">Payment Id</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">
                    {Viewdetails?.PaymentTransactionId ? (
                      <Link
                        to={`/view-payment/${Viewdetails?.PaymentTransactionId}`}
                        target="_blank"
                        className=""
                      >
                        {Viewdetails?.PaymentId}
                      </Link>
                    ) : Viewdetails?.PaymentId ? (
                      Viewdetails?.PaymentId
                    ) : (
                      "N/A"
                    )}
                  </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                  <span className="data-label">Payment Mode</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">
                    {Viewdetails.PaymentMode}
                  </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                  <span className="data-label">Reference Number</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">
                    {Viewdetails?.ReferenceNo
                      ? Viewdetails.ReferenceNo
                      : "N/A"}
                  </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                  <span className="data-label">Bill Date</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">
                    {moment(Viewdetails?.CreatedDate)
                      .format("D/MM/YYYY")
                      ?.slice(0, 10)}
                  </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                  <span className="data-label">Due Date</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">
                    {moment(Viewdetails?.DueDate)
                      .format("D/MM/YYYY")
                      ?.slice(0, 10)}
                  </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                  <span className="data-label">Status</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">{Viewdetails?.BillStatusId === PARTIALLY_PAID_STATUS_ID ? "Partially Paid" : Viewdetails?.BillStatusId === VOID_STATUS_ID ? "Void" : Viewdetails?.StatusName}</span>
                </div>
                {Viewdetails?.VoidReason ? (
                  <div className="d-flex rej-reason-box mb-1">
                    <span className="data-label">Void Reason</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value pe-0">
                      {Viewdetails.VoidReason}
                    </span>
                  </div>
                ) : Viewdetails?.RejectionReason ? (
                  <div className="d-flex rej-reason-box mb-1">
                    <span className="data-label">Rejection Reason</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value pe-0">
                      {Viewdetails.RejectionReason}
                    </span>
                  </div>
                ) : (
                  <div className="d-flex rej-reason-box mb-1">
                    <span className="data-label">Rejection Reason</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value pe-0">N/A</span>
                  </div>
                )}
                {Viewdetails?.DepartmentName &&
                  <div className="d-flex align-items-center mb-1">
                    <span className="data-label">Department</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value pe-0">{Viewdetails?.DepartmentName}</span>
                  </div>
                }
                <div className="d-flex align-items-center mb-1">
                  <span className=" data-label">Hold</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">{Viewdetails.BillStatusId === HOLD_STATUS_ID ? "Yes" : "No"}</span>
                </div>
                {Viewdetails?.HoldReason && (
                  <div className="d-flex align-items-center mb-1">
                    <span className=" data-label">Hold Reason</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value pe-0">{Viewdetails?.HoldReason}</span>
                  </div>
                )}
                {Viewdetails?.ReleaseReason && (
                  <div className="d-flex align-items-center mb-1">
                    <span className=" data-label">Release Reason</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value pe-0">{Viewdetails?.ReleaseReason}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="form-style">
          <div className="section-sub-heading">
            <h3>Item Description</h3>
          </div>
          <div className="table-responsive  tbl-body-shadow">
            <table className="w-100 table-theme-1">
              <thead>
                <tr>
                  <th className="text-left">Item</th>
                  <th className="text-left">Description</th>
                  <th className="text-end">Rate</th>
                  <th className="text-end">Quantity</th>
                  <>
                    {Viewdetails?.LineGST && (
                      <>
                        <th className="text-end">CGST(%)</th>
                        <th className="text-end">SGST(%)</th>
                        <th className="text-end">UTGST(%)</th>
                        <th className="text-end">IGST(%)</th>
                        <th className="text-end">GST Amount</th>
                      </>
                    )}
                  </>
                  <th className="text-end">Amount</th>
                </tr>
              </thead>
              <tbody>
                {Viewdetails?.InvoiceLines &&
                  Viewdetails?.InvoiceLines.length > 0 ? (
                  Viewdetails.InvoiceLines.map((row, index) => (
                    <tr key={index}>
                      <td className="text-left text-nowrap">{row.ItemName}</td>
                      <td className="text-left text-wrap">{row.Description ? row.Description : "N/A"}</td>
                      <td className="text-end text-nowrap">
                        {Viewdetails.CurrencySymbol} {row?.Rate?.toFixed(2)}
                      </td>
                      <td className="text-end">
                        {row?.Quantity?.toFixed(2)}
                      </td>
                      {Viewdetails?.LineGST && (
                        <>
                          <td className="text-end">
                            {row?.cgst ? row?.cgst : 0}
                          </td>
                          <td className="text-end">
                            {row?.sgst ? row?.sgst : 0}
                          </td>
                          <td className="text-end">
                            {row?.utgst ? row?.utgst : 0}
                          </td>
                          <td className="text-end">
                            {row?.igst ? row?.igst : 0}
                          </td>
                          <td className="text-end">
                            &#x20B9; {row?.GST_Total?.toFixed(2)}
                          </td>
                        </>
                      )}
                      <td className="text-end text-nowrap">
                        {Viewdetails.CurrencySymbol} {row?.Amount?.toFixed(2)}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={Viewdetails?.LineGST ? "9" : "7"}
                      className="text-center"
                    >
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="form-style">
          <div className="section-sub-heading">
            <h3>Files</h3>
          </div>

          <div className="table-responsive tbl-body-shadow mb-20">
            <table className="w-100 table-theme-1">
              <thead>
                <tr>
                  <th className="text-left">File Name</th>
                  <th className="text-center">File Type</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {Viewdetails?.InvoiceDocuments &&
                  Viewdetails?.InvoiceDocuments.length > 0 ? (
                  Viewdetails.InvoiceDocuments.map((row, index) => (
                    <tr key={index}>
                      <td className="text-left">{row.FileName}</td>
                      <td className="text-center">pdf</td>
                      <td>
                        <div className="cursor-pointer d-flex align-items-center justify-content-center">
                          <div className="download-btn-container">
                            <Link
                              to={`${BASE_IMAGE_URL}/${row.Path}`}
                              download
                              target="_blank"
                            >
                              <button type="button" className="btn">
                                <DownloadIcon /> Download
                              </button>
                            </Link>
                          </div>
                          <div className="preview-btn-container ms-2">
                            <Link
                              className="ms-2"
                              to={`/preview-pdf/${btoa(row.Path)}`}
                              target="_blank"
                            >
                              <button type="button" className="btn">
                                <EyeIcon /> Preview
                              </button>
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="view-footer d-flex justify-content-end mb-20">
            <div className="data-details-3">
              <div className="d-flex align-items-center">
                <span className=" data-label">Sub Total</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">
                  {Viewdetails.CurrencySymbol} {Viewdetails?.InvoiceTotal?.toFixed(2)}
                </span>
              </div>
              {Viewdetails?.Discount != null && Viewdetails?.Discount != 0 && (
                <>
                  <div className="d-flex align-items-center">
                    <span className="data-label">Discount</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value">
                      {Viewdetails.CurrencySymbol} {Viewdetails.Discount.toFixed(2)}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="data-label">Discount(%)</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value">
                      {Viewdetails?.DiscountPercentage}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="data-label">Net Amount</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value">
                      {Viewdetails.CurrencySymbol} {(Viewdetails?.InvoiceTotal - Viewdetails?.Discount)?.toFixed(2)}
                    </span>
                  </div>
                </>
              )}
              {(Viewdetails?.CGST !== undefined && Viewdetails?.CGST !== null) && (
                <div className="d-flex align-items-center">
                  <span className=" data-label">CGST(%)</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">{Viewdetails?.CGST}</span>
                </div>
              )}
              {(Viewdetails?.SGST !== undefined && Viewdetails?.SGST !== null) && (
                <div className="d-flex align-items-center">
                  <span className=" data-label">SGST(%)</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">{Viewdetails?.SGST}</span>
                </div>
              )}
              {(Viewdetails?.UTGST !== undefined && Viewdetails?.UTGST !== null) && (
                <div className="d-flex align-items-center">
                  <span className=" data-label">UTGST(%)</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">{Viewdetails?.UTGST}</span>
                </div>
              )}
              {(Viewdetails?.IGST !== undefined && Viewdetails?.IGST !== null) && (
                <div className="d-flex align-items-center">
                  <span className=" data-label">IGST(%)</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">{Viewdetails?.IGST}</span>
                </div>
              )}

              <div className="d-flex align-items-center">
                <span className="data-label">GST Total</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">
                  {Viewdetails.CurrencySymbol} {Viewdetails?.TaxSubtotal?.toFixed(2)}
                </span>
              </div>
              {(Viewdetails?.TDSAmount !== undefined && Viewdetails?.TDSAmount !== null) && (
                <div className="d-flex align-items-center">
                  <span className="data-label">TDS Amount</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value"> {Viewdetails.CurrencySymbol} {Viewdetails?.TDSAmount}</span>
                </div>
              )}
              <div className=" view-total-amount d-flex align-items-center mt-3">
                <span className=" data-label text-align-left">Total</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">
                  {Viewdetails.CurrencySymbol} {Viewdetails?.Total?.toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </div>

        {individualBillHistory?.length > 0 && (
          <div className="">
            <SystemInfo viewHistory={individualBillHistory} />
          </div>
        )}

        {user?.RoleId === ADMIN_MANAGER_ID && Viewdetails.BillStatusId !== HOLD_STATUS_ID &&
          Viewdetails.StatusId === VENDOR_MANAGER_PENDING && (
            <div className="d-flex align-items-center justify-content-end mb-20 form-style pt-2">
              <div className="danger-outline-btn ">
                <button
                  type="button"
                  className="btn"
                  onClick={handleReject}
                >
                  Reject
                </button>
              </div>
              <div className="success-btn ms-2">
                <button
                  type="submit"
                  className="btn"
                  onClick={() => handleApprove()}
                >
                  Approve
                </button>
              </div>
            </div>
          )}

        {user?.RoleId === VP_ID && Viewdetails.BillStatusId !== HOLD_STATUS_ID &&
          Viewdetails.StatusId === VENDOR_VP_PENDING && (
            <div className="d-flex align-items-center justify-content-end mb-20 form-style pt-2">
              <div className="danger-outline-btn ">
                <button
                  type="button"
                  className="btn"
                  onClick={handleReject}
                >
                  Reject
                </button>
              </div>
              <div className="success-btn ms-2">
                <button
                  type="submit"
                  className="btn"
                  onClick={() => handleApprove()}
                >
                  Approve
                </button>
              </div>
            </div>
          )}

        {user?.RoleId === FINANCE_ID && Viewdetails.BillStatusId !== HOLD_STATUS_ID &&
          Viewdetails.StatusId === FINANCE_PENDING && (
            <div className="d-flex align-items-center justify-content-end mb-20 form-style pt-2">
              <div className="danger-outline-btn ">
                <button
                  type="button"
                  className="btn"
                  onClick={handleReject}
                >
                  Reject
                </button>
              </div>
              <div className="success-btn ms-2">
                <button
                  type="submit"
                  className="btn"
                  onClick={handleTDSCreation}
                >
                  Add TDS
                </button>
              </div>
            </div>
          )}

      </div>
    </>
  );
};

export default ViewIndividualBill;
