import React, { useContext, useEffect, useState } from 'react'
import { getApi, postApi } from '../../utils/services';
import { FINANCE_LOGIN, STAFF_LOGIN, VENDORLOGIN, VIEW_VENDOR } from '../../utils/apiRoutes';
import icon from "../../assets/image/streamlogo.png";
import Icon from "@mdi/react";
import { mdiEye, mdiRefresh, mdiEyeOff } from "@mdi/js";
import AuthenContext from '../../utils/helpers/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import LoginFormValidation from '../../middleware/loginErrorValidation';
import Loader from '../common/Loader';
import toast from 'react-hot-toast';
import { VENDORID, VENDOR_MANAGER_PENDING, VENDOR_VP_PENDING, RANDOM_CAPTCH_GENRATE } from '../../utils/constant';
import { Helmet } from "react-helmet";
import LoginLeftContent from '../common/LoginContent';

const LoginPage = () => {
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);

  const [loginType, setLoginType] = useState(2);
  const [captcha, setCaptcha] = useState("");
  const [captchaInput, setCaptchaInput] = useState("");
  const { errors, setErrors, validateForm } = LoginFormValidation();
  const [loader, setLoader] = useState(false)
  const authCtx = useContext(AuthenContext);
  let navigate = useNavigate();

  const generateCaptcha = () => {
    let genratedCaptcha = "";
    const charactersLength = RANDOM_CAPTCH_GENRATE.length;
    for (let i = 0; i < 6; i++) {
      genratedCaptcha += RANDOM_CAPTCH_GENRATE.charAt(Math.floor(Math.random() * charactersLength));
    }
    setCaptcha(genratedCaptcha);
  };

  const handleChange = (e) => {
    let { name, value } = e.target
    setLoginData({ ...loginData, [name]: value })
    setErrors({})
  }

  const handleRefreshCaptcha = () => {
    generateCaptcha();
    setCaptchaInput("");
    setErrors({});
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleServerValidations = (type, errorMessage) => {
    setErrors({ [type]: errorMessage });
  };

  const handleSubmit = async () => {
    const isFormValid = validateForm(loginType, loginData, captcha, captchaInput);
    if (!isFormValid) return
    setLoader(true);
    try {
      let LOGIN_URL = getLoginURL(loginType);
      const result = await postApi(LOGIN_URL, { "email": loginData.email, "password": loginData.password });
      if (result.res.success) {
        authCtx.login(result.res.access_token, JSON.stringify(result.res.login_detail))
        if (result.res.login_detail.RoleId === VENDORID) {
          const vendorDetails = await handlePending(result.res.login_detail.id)
          if ((vendorDetails.Status === VENDOR_MANAGER_PENDING && vendorDetails.bankInfoFlag === 1 && !vendorDetails.selfService) || (vendorDetails.Status === VENDOR_VP_PENDING && vendorDetails.bankInfoFlag === 1 && !vendorDetails.selfService)) {
            setLoader(false)
            navigate("/approval-pending")
            return;
          }
        }
        toast.success(result.res.message)
        setLoader(false)
        navigate("/")
      } else {
        handleServerValidations("invalidcred", result.res.message)
        setLoader(false)
      }

    } catch (error) {
      setLoader(false)
      handleServerValidations("invalidcred", error.message);
    }
  }

  useEffect(() => {
    generateCaptcha();
  }, []);

  const handlePending = async (id) => {
    const vendorResult = await getApi(`${VIEW_VENDOR}/${id}`);
    return vendorResult.res.vendor_details
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleCopyCutPaste = (event) => {
    event.preventDefault();
    return false;
  };

  useEffect(() => {
    const captchaInput = document.getElementById("captchatext");
    captchaInput.addEventListener("copy", handleCopyCutPaste);
    captchaInput.addEventListener("cut", handleCopyCutPaste);
    captchaInput.addEventListener("paste", handleCopyCutPaste);

    return () => {
      captchaInput.removeEventListener("copy", handleCopyCutPaste);
      captchaInput.removeEventListener("cut", handleCopyCutPaste);
      captchaInput.removeEventListener("paste", handleCopyCutPaste);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Login</title>
      </Helmet>

      <Loader isLoading={loader} />

      <div className='main-lgn-cntnr lgn-common'>
        <LoginLeftContent />

        <div className='lgn-cntnr-rgt'>

          <div className='lgn-rgt-cntn w-100'>
            <div className="text-center lgn-rgt-cntn-logo "><img className="lgn-logo" src={icon} alt="" /></div>

            <h3>Sign In</h3>
            <p>Please sign in to continue your account.</p>

            <div className="lgn-selection d-flex justify-content-between">
              {['Vendor', 'Employee', 'Finance'].map((type, index) => (
                <label key={type} className="d-flex align-items-center">
                  <input
                    type="radio"
                    className="input-radio"
                    name="lgn"
                    checked={loginType === index + 1}
                    onChange={() => setLoginType(index + 1)}
                  />
                  {type}
                </label>
              ))}
            </div>

            {errors.logintype && (
              <span className="validate_error-div">{errors.logintype}</span>
            )}
            <div className="lgn-section-form form">
              <div className="mb-20">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  User Name
                </label>
                <input
                  // type="email"
                  name="email"
                  value={loginData.email}
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Enter email id"
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                />
                {errors.email && (
                  <span className="validate_error-div">{errors.email}</span>
                )}
              </div>

              <div className="mb-20">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Password
                </label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={loginData.password}
                    className="form-control"
                    placeholder="Enter password"
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                  />
                  <span
                    className="input-group-text cstm-eye-icon cursor-pointer input-pass-icon"
                    onClick={togglePasswordVisibility}
                  >
                    <Icon
                      path={showPassword ? mdiEyeOff : mdiEye}
                      size={0.8}
                      color="black"
                    />
                  </span>
                </div>
                {errors.password && (
                  <span className="validate_error-div">{errors.password}</span>
                )}
                {errors.invalidcred && (
                  <span className="validate_error-div">{errors.invalidcred}</span>
                )}

              </div>
              <div className="captcha-container">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Captcha
                </label>
                <div className="lgn-captcha">
                  <span>{captcha}</span>
                </div>
                <div className="input-group enter-captcha ">
                  <input
                    type="text"
                    id="captchatext"
                    value={captchaInput}
                    className="form-control"
                    placeholder="Enter captcha"
                    onChange={(e) => setCaptchaInput(e.target.value)}
                    autoComplete="off"
                    onKeyPress={handleKeyPress}
                  />
                  <span
                    className="input-group-text fa-custome cursor-pointer"
                    id="refresh-captcha"
                    onClick={handleRefreshCaptcha}
                  >
                    <Icon path={mdiRefresh} size={1} color="black" />
                  </span>
                </div>
                {errors.captcha && (
                  <span className="validate_error-div">{errors.captcha}</span>
                )}
              </div>

              <div className="sign-in-btn">
                <button
                  type="button"
                  disabled={loader}
                  className="btn btn-signin"
                  onClick={handleSubmit}

                >
                  SIGN IN
                </button>
                <Link
                  to="/forgot-password"
                  className="justify-content-end d-flex"
                >
                  Forgot Password?
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default LoginPage;

const getLoginURL = (loginType) => {
  switch (loginType) {
    case 1:
      return VENDORLOGIN;
    case 2:
      return STAFF_LOGIN;
    case 3:
      return FINANCE_LOGIN;
    default:
      return STAFF_LOGIN;
  }
};