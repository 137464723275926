import { useState } from 'react';
import { validateStardDate, validateEndDate, validateContractTotal, validateItemDescription, validateUploadDocuments } from '../utils/helpers/globalValidation';

const EditContractValidation = () => {
    const [errors, setErrors] = useState({});

    const validateForm = (startDate, endDate, itemDescription, contractTotal, files, contractDocument) => {
        let errors = {};
        let formIsValid = true;

        const startDateError = validateStardDate(startDate);
        if (startDateError) {
            formIsValid = false;
            errors.startDate = startDateError;
        }

        const endDateError = validateEndDate(endDate);
        if (endDateError) {
            formIsValid = false;
            errors.endDate = endDateError;
        }

        const contractTotalError = validateContractTotal(contractTotal);
        if (contractTotalError) {
            formIsValid = false;
            errors.contractTotal = contractTotalError;
        }

        const documentError = validateUploadDocuments(files, contractDocument);
        formIsValid = documentError.formIsValid;
        errors.files = documentError.errors.files;

        const itemErrors = validateItemDescription(itemDescription);
        if (itemErrors) {
            formIsValid = false;
            errors.itemDescription = itemErrors.itemDescription || {};
        }

        if (errors.itemDescription && errors.itemDescription.length === 0) {
            delete errors.itemDescription;
        }

        setErrors(errors);
        return formIsValid;
    };

    return { errors, setErrors, validateForm };
};

export default EditContractValidation;