import { getApi } from '../services';
import { BILLS_API, CONTRACT_LIST, DEPARTMENT_LIST, ITEM_DETAILS, SITE_CONFIGERATION, VENDOR, VENDOR_ITEMS, VENDOR_TERMS_SUBSIDIARY } from '../apiRoutes';
import { DISCARD_NON_DIGIT_REGX, IS_NUMERIC_OR_DECIMAL_REGX } from './globalRegx';
import ApproveModal from '../../components/modal/ApproveModal';
import { TEXT_LENGTH } from './globalErrorMessage';
import { ADMIN_EXECUTIVE_ID, ADMIN_MANAGER_ID, FINANCE_ID, VENDOR_APPROVE, VENDORID, VP_ID } from '../constant';

export const IMAGES_TYPE = ['image/png', 'image/jpg', 'image/jpeg'];
export const PDF_TYPE = ["application/pdf"];

// API Global Common Methods 

export const fetchItemDetails = async (itemId) => {
    try {
        const result = await getApi(`${ITEM_DETAILS}/${itemId}`);
        return result.res.item_details.Description;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const handleList = async (setVendorList) => {
    try {
        if (setVendorList) {
            let vendorResult = await getApi(`${VENDOR}?type='dropdown'&status=${VENDOR_APPROVE}`);
            vendorResult = vendorResult.res.vendor_list
                .filter(i => i.bankInfoFlag === 1)
                .sort((a, b) => a.LegalName.trim().localeCompare(b.LegalName.trim()));
            setVendorList(vendorResult);
        }
    } catch (error) {
        console.log(error);
    }
};

export const handleDepartmentList = async (setDepartmentList) => {
    try {
        if (setDepartmentList) {
            let departmentResult = await getApi(DEPARTMENT_LIST);
            setDepartmentList(departmentResult.res.department_list);
        }
    } catch (error) {
        console.log(error);
    }
};

export const fetchConfigeration = async (minDate, siteConfigId) => {
    try {
        const result = await getApi(SITE_CONFIGERATION);
        const config = result.res.siteConfigeration_list.find(i => i.id === siteConfigId);
        minDate(config.Days);
    } catch (error) {
        console.error(error);
    }
};

export const fetchVendorData = async (vendorId, setTerms, setSubsidiary, setCurrencySymbol, setVendorItems, setTermCount, setDueDate) => {
    try {
        if (vendorId) {
            const result = await getApi(`${VENDOR_TERMS_SUBSIDIARY}/${vendorId}`);
            const termsSubsidiary = result.res.terms_subsidiary;

            if (setTerms) setTerms(termsSubsidiary.Term);
            if (setSubsidiary) setSubsidiary(termsSubsidiary.Name);
            if (setCurrencySymbol) setCurrencySymbol(termsSubsidiary.Symbol);

            if (setTermCount && setDueDate) {
                const termCount = termsSubsidiary.Term.replace(DISCARD_NON_DIGIT_REGX, "");
                setTermCount(termCount);
                const dueDate = new Date();
                dueDate.setDate(dueDate.getDate() + parseInt(termCount));
                setDueDate(dueDate.toISOString().split("T")[0]);
            }

            const vendorItemsResult = await getApi(`${VENDOR_ITEMS}/${vendorId}`);
            setVendorItems(vendorItemsResult.res.itemsList);
        }
    } catch (error) {
        console.log(error);
    }
};

export const fetchVendorList = async (vendorPageNumber, pageSize, status, setLoader, setVendor, setTotalPagesVendor) => {
    try {
        setLoader(true);
        const result = await getApi(
            `${VENDOR}?page=${vendorPageNumber}&pageSize=${pageSize}&${`status=${status}`}`
        );
        setLoader(false);
        setVendor(result.res.vendor_list);
        const totalItems = result.res.record_count;
        setTotalPagesVendor(Math.ceil(totalItems / pageSize));
    } catch (error) {
        console.log(error);
        setLoader(false);
    }
};


export const fetchPurchaseContractList = async (pageSize, pageNumber, status, setPurchaseContract, setLoader, setTotalPagesPurchase, user) => {
    try {
        setLoader(true);

        let queryParam = '';

        if (user?.RoleId === ADMIN_MANAGER_ID) {
            queryParam = `roleId=${user.RoleId}`;
        } else if (user?.RoleId === VENDORID) {
            queryParam = `vendorId=${user.id}&status=${status}`;
        } else {
            queryParam = `status=${status}`;
        }

        const result = await getApi(
            `${CONTRACT_LIST}?page=${pageNumber}&pageSize=${pageSize}&${queryParam}`
        );

        setLoader(false);
        setPurchaseContract(result.res.contract_list);

        const totalItems = result.res.record_count;
        setTotalPagesPurchase(Math.ceil(totalItems / pageSize));
    } catch (error) {
        setLoader(false);
        console.log(error);
    }
};


export const fetchBillList = async (pageSize, billPageNumber, status, setBills, setLoader, setTotalPagesBill, user) => {
    try {
        setLoader(true);
        let queryParam = '';

        if (user?.RoleId === ADMIN_MANAGER_ID) {
            queryParam = `roleId=${user.RoleId}`;
        } else if (user?.RoleId === VENDORID) {
            queryParam = `vendorId=${user.id}&status=${status}`;
        } else if (user?.RoleId === ADMIN_EXECUTIVE_ID) {
            queryParam = `roleId=${user.RoleId}`;
        } else if (user?.RoleId === VP_ID) {
            queryParam = `roleId=${user.RoleId}`;
        } else if (user?.RoleId === FINANCE_ID) {
            queryParam = `roleId=${user.RoleId}`;
        } else {
            queryParam = `status=${status}`;
        }

        const result = await getApi(
            `${BILLS_API}?billType=0&page=${billPageNumber}&pageSize=${pageSize}&${queryParam}`
        );

        setLoader(false);
        setBills(result.res.bill_list);

        const totalItems = result.res.record_count;
        setTotalPagesBill(Math.ceil(totalItems / pageSize));
    } catch (error) {
        setLoader(false);
        console.log(error);
    }
};


export const fetchIndividualBillList = async (pageSize, pageNumber, status, setData, setLoader, setTotalPages, user) => {
    try {
        setLoader(true);

        let queryParam = '';

        if (user?.RoleId === ADMIN_MANAGER_ID) {
            queryParam = `roleId=${user.RoleId}`;
        } else if (user?.RoleId === VENDORID) {
            queryParam = `vendorId=${user.id}&status=${status}`;
        } else if (user?.RoleId === ADMIN_EXECUTIVE_ID) {
            queryParam = `roleId=${user.RoleId}`;
        } else if (user?.RoleId === VP_ID) {
            queryParam = `roleId=${user.RoleId}`;
        } else if (user?.RoleId === FINANCE_ID) {
            queryParam = `roleId=${user.RoleId}`;
        } else {
            queryParam = `status=${status}`;
        }

        const result = await getApi(
            `${BILLS_API}?billType=1&page=${pageNumber}&pageSize=${pageSize}&${queryParam}`
        );

        setLoader(false);
        setData(result.res.bill_list);

        const totalItems = result.res.record_count;
        setTotalPages(Math.ceil(totalItems / pageSize));
    } catch (error) {
        setLoader(false);
        console.log(error);
    }
};

// ONCHANGE Global Common Methods 

export const handleCheckboxChange = (isChecked, setAttachDocument, setFormState, setErrors, fileInputRef) => {
    setAttachDocument(isChecked);

    if (!isChecked) {
        setFormState(prevState => ({
            ...prevState,
            contractDocument: null
        }));
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            delete newErrors['files'];
            return newErrors;
        });
        fileInputRef.current.value = null;
    }
};

export const handleDiscountChange = (e, totalAmount, setErrors, setDiscountAmount, setDiscountPercentage) => {
    const { value } = e.target;

    const numericalDecimal = IS_NUMERIC_OR_DECIMAL_REGX.test(value);

    if (!numericalDecimal) {
        return;
    }

    let discount = parseFloat(value);

    if (discount >= totalAmount) {
        setErrors((prevErrors) => ({
            ...prevErrors,
        }));
    } else {
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors['discountAmount'];
            return newErrors;
        });
        setDiscountAmount(value);
        setDiscountPercentage(discount ? ((discount / totalAmount) * 100).toFixed(2) : "");
    }
};

export const handleKeyPress = (e) => {
    if (!IS_NUMERIC_OR_DECIMAL_REGX.test(e.key)) {
        e.preventDefault();
    }
};

export const handleDepartmentChange = (setDepartmentId, setErrors) => (e) => {
    setDepartmentId(e.target.value);

    setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors.departmentId;
        return updatedErrors;
    });
};

export const handleNegative = (e) => {
    let newValue = e.target.value;
    newValue = newValue.replace(/-/g, "");
    e.target.value = newValue;
};

export const handleCheckCommon = (e, setTaxSubtotal, setTotal, items, invoiceTotal) => {
    const check = e.target.checked;

    if (check) {
        const updatedTaxTotal = items.reduce(
            (acc, item) => acc + parseFloat(item.GST_Total || item.totalGSTamount || 0),
            0
        );

        const updatedTotal = updatedTaxTotal + parseFloat(invoiceTotal);

        setTaxSubtotal(updatedTaxTotal.toFixed(2));
        setTotal(updatedTotal.toFixed(2));
    }
};

export const handleFileInputChange = (e, setFiles, setErrors) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
    setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors.files;
        return updatedErrors;
    });
};

export const handleRemoveItem = (index, itemDescription, setItemDescription, errors, setErrors, setDeletedItemDescriptionId = null) => {
    if (itemDescription.length === 1) {
        alert("At least one item should be there");
        return;
    }

    const updatedItemDescription = [...itemDescription];
    const deletedId = updatedItemDescription[index].id;

    updatedItemDescription.splice(index, 1);
    setItemDescription(updatedItemDescription);

    if (setDeletedItemDescriptionId) {
        setDeletedItemDescriptionId((prevDeletedIds) => [...prevDeletedIds, deletedId]);
    }

    const updatedErrors = { ...errors };
    if (updatedErrors.itemDescription && updatedErrors.itemDescription.length > index) {
        updatedErrors.itemDescription.splice(index, 1);
    }

    setErrors(updatedErrors);
};


export const handleApproval = (data, handleModalData, handlerList, type) => {
    const approveModal = (
        <ApproveModal handlerList={handlerList} data={data} type={type} />
    );
    handleModalData(approveModal, "approve")
};

export const handleDescriptionChange = (index, field, value, itemDescription, setItemDescription, errors, setErrors) => {
    const updatedItemDescription = [...itemDescription];
    const updatedErrors = { ...errors };

    if (field === "description") {
        if (value.length > 200) {
            updatedErrors.itemDescription = updatedErrors.itemDescription || [];
            updatedErrors.itemDescription[index] = updatedErrors.itemDescription[index] || {};
            updatedErrors.itemDescription[index].description = TEXT_LENGTH;
        } else {
            updatedItemDescription[index][field] = value;
            if (updatedErrors.itemDescription && updatedErrors.itemDescription[index]) {
                delete updatedErrors.itemDescription[index].description;
                if (Object.keys(updatedErrors.itemDescription[index]).length === 0) {
                    delete updatedErrors.itemDescription[index];
                }
            }
        }
    } else {
        updatedItemDescription[index][field] = value;
    }

    setItemDescription(updatedItemDescription);
    setErrors(updatedErrors);
};

export const handleDescriptionChangeCommon = (e, index, type, itemDescription, itemDescriptionLineLevel, errors, setItemDescription, setItemDescriptionLineLevel, setErrors) => {
    const { value } = e.target;

    const stateKey = type === 'itemDescription' ? 'itemDescription' : 'itemDescriptionLineLevel';
    const updatedState = [...(type === 'itemDescription' ? itemDescription : itemDescriptionLineLevel)];
    const updatedErrors = { ...errors };

    if (value.length > 200) {
        updatedErrors[stateKey] = updatedErrors[stateKey] || [];
        updatedErrors[stateKey][index] = updatedErrors[stateKey][index] || {};
        updatedErrors[stateKey][index].description = TEXT_LENGTH;
    } else {
        updatedState[index].description = value;

        if (updatedErrors[stateKey] && updatedErrors[stateKey][index]) {
            delete updatedErrors[stateKey][index].description;
            if (Object.keys(updatedErrors[stateKey][index]).length === 0) {
                delete updatedErrors[stateKey][index];
            }
        }
    }

    if (type === 'itemDescription') {
        setItemDescription(updatedState);
    } else {
        setItemDescriptionLineLevel(updatedState);
    }

    setErrors(updatedErrors);
};

