import { useState } from 'react';
import { validateVendor, validateStardDate, validateEndDate, validateContractTotal, validateItemDescription, validateUploadDocuments } from '../utils/helpers/globalValidation';

const AddContractValidation = () => {
    const [errors, setErrors] = useState({});

    const validateForm = (vendorId, startDate, endDate, itemDescription, contractTotal, contractDocument) => {
        let errors = {};
        let formIsValid = true;

        const vendorError = validateVendor(vendorId);
        if (vendorError) {
            formIsValid = false;
            errors.vendorId = vendorError;
        }

        const startDateError = validateStardDate(startDate);
        if (startDateError) {
            formIsValid = false;
            errors.startDate = startDateError;
        }

        const endDateError = validateEndDate(endDate);
        if (endDateError) {
            formIsValid = false;
            errors.endDate = endDateError;
        }

        const contractTotalError = validateContractTotal(contractTotal);
        if (contractTotalError) {
            formIsValid = false;
            errors.contractTotal = contractTotalError;
        }

        const documentError = validateUploadDocuments(null, contractDocument);
        if (!documentError.formIsValid) {
            formIsValid = false;
            errors.files = documentError.errors.files;
        }

        const itemErrors = validateItemDescription(itemDescription);
        if (itemErrors) {
            formIsValid = false;
            errors.itemDescription = itemErrors.itemDescription;
        }

        if (errors.itemDescription && errors.itemDescription.length === 0) {
            delete errors.itemDescription;
        }

        setErrors(errors);
        return formIsValid;
    };

    return { errors, setErrors, validateForm };
};

export default AddContractValidation;