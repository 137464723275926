import React, { useState, useEffect } from 'react'
import exporticon from "../../../assets/image/Excel_Download_Icon.png";
import { getApi } from '../../../utils/services';
import { PAYMENT_LIST_API, STATUS_LIST } from '../../../utils/apiRoutes';
import { Link } from 'react-router-dom';
import moment from "moment";
import { useTransactionContext } from '../../../utils/helpers/useTransaction';
import Loader from '../../common/Loader';

const PaymentReport = () => {

  const monthStartDate = moment().startOf('month');
  const monthEndDate = moment().endOf('month');

  const transactionList = useTransactionContext();
  const [statusList, setStatusList] = useState([])
  const [billNumber, setBillNumber] = useState('')
  const [paymentId, setPaymentId] = useState('');
  // const [batchNumber, setBatchNumber] = useState('');
  const [fromDate, setFromDate] = useState(monthStartDate.format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(monthEndDate.format('YYYY-MM-DD'));
  const [statusId, setStatusId] = useState('');
  const [paymentReportList, setPaymentReportList] = useState([])
  const [totalTds, setTotalTds] = useState()
  const [totalAmount, setTotalAmount] = useState()
  const [totalPaid, setTotalPaid] = useState()
  const [loader, setLoader] = useState(false)
  const [currencySymbol, setCurrencySymbol] = useState('');

  const handleStatusData = async () => {
    const result = await getApi(`${STATUS_LIST}?transactionId=${transactionList[0].id}`);
    setStatusList(result.res.status_list);
  };

  useEffect(() => {
    handleStatusData();
  }, []);

  useEffect(() => {
    const handlePaymentReport = async () => {
      setLoader(true)
      const result = await getApi(`${PAYMENT_LIST_API}?startDate=${fromDate}&endDate=${toDate}&type='report'`)
      setLoader(false)
      setPaymentReportList(result.res)
      const totalTdsAmount = result.res.payment_list.reduce((total, obj) => total + obj.TDSAmount, 0);
      const totalGrandAmount = result.res.payment_list.reduce((total, obj) => total + obj.GrandTotal, 0);
      const totalPaidAmount = result.res.payment_list.reduce((total, obj) => total + obj.TotalAmountPaid, 0);
      setTotalTds(totalTdsAmount);
      setTotalAmount(totalGrandAmount);
      setTotalPaid(totalPaidAmount);
      setCurrencySymbol(result.res.payment_list?.find(item => item.SubsidiaryId === 2)?.CurrencySymbol);
    }
    handlePaymentReport()
  }, [])

  const handleExportData = () => {
    const csvData = [
      ['Payment ID', 'Bill Number', 'Vendor', 'Payment Date', 'Grand Total', 'TDS Amount', 'Amount Paid', 'Status'],
      ...paymentReportList.payment_list.map(person => [person.PaymentId, person.DocumentNo, person.VendorName, person.PaymentDate ? moment(person.PaymentDate).format('D/MM/YYYY')?.slice(0, 10) : "N/A", person.GrandTotal, person.TDSAmount, person.TotalAmountPaid, person.StatusName]),
      [], ["", "", "", "Total", totalAmount, totalTds, totalPaid, ""],
    ];
    const csvContent = csvData.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Payment.csv';
    link.click();
    URL.revokeObjectURL(url);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    // const result = await getApi(`${PAYMENT_LIST_API}?startDate=${fromDate}&endDate=${toDate}&status=${statusId}&billNumber=${billNumber}&paymentId=${paymentId}&batchNumber=${batchNumber}&type='report'`);
    const result = await getApi(`${PAYMENT_LIST_API}?startDate=${fromDate}&endDate=${toDate}&status=${statusId}&billNumber=${billNumber}&paymentId=${paymentId}&type='report'`);
    setLoader(false);
    const paymentList = result.res?.payment_list || [];
    const totalTdsAmount = Array.isArray(paymentList) ? paymentList.reduce((total, obj) => total + obj.TDSAmount, 0) : 0;
    const totalGrandAmount = Array.isArray(paymentList) ? paymentList.reduce((total, obj) => total + obj.GrandTotal, 0) : 0;
    const totalPaidAmount = Array.isArray(paymentList) ? paymentList.reduce((total, obj) => total + obj.TotalAmountPaid, 0) : 0;
    const currency = Array.isArray(paymentList) ? paymentList.find(item => item.SubsidiaryId === 2)?.CurrencySymbol : null;
    setCurrencySymbol(currency);
    setTotalTds(totalTdsAmount);
    setTotalAmount(totalGrandAmount);
    setTotalPaid(totalPaidAmount);
    setPaymentReportList(result.res);
  };


  return (
    <form>
      <div className="form-style">
        <div className="report-inpfiled-container">

          <div className="field-flex mb-20">
            <label htmlFor="billNumber" className="form-label">
              Bill Number
            </label>
            <input type="text" className="form-control" id="billNumber" placeholder="Enter bill number" value={billNumber} onChange={(e) => setBillNumber(e.target.value)} />
          </div>

          <div className="field-flex mb-20">
            <label htmlFor="paymentId" className="form-label">
              Payment Id
            </label>
            <input type="text" className="form-control" id="paymentId" placeholder="Enter payment Id" value={paymentId} onChange={(e) => setPaymentId(e.target.value)} />
          </div>

          {/* <div className="field-flex mb-20">
            <label htmlFor="batchNumber" className="form-label">
              Batch Number
            </label>
            <input type="text" className="form-control" id="batchNumber" placeholder="Enter Batch Number" value={batchNumber} onChange={(e) => setBatchNumber(e.target.value)} />
          </div> */}

          <div className="field-flex mb-20">
            <label htmlFor="transactionStatus" className="form-label">
              Transaction Status
            </label>

            <select
              id="transactionStatus"
              className="form-select"
              name="transactionStatus"
              value={statusId}
              onChange={(e) => setStatusId(e.target.value)}
            >
              <option value="">Select</option>
              {statusList.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.Name}
                </option>
              ))}
            </select>
          </div>

          <div className="field-flex mb-20">
            <label htmlFor="fromDate" className="form-label">
              From Date
            </label>
            <input
              type="date"
              id="fromDate"
              className="form-control"
              name="fromDate"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              onKeyDown={(e) => e.preventDefault()}
            ></input>
          </div>

          <div className="field-flex mb-20">
            <label htmlFor="toDate" className="form-label">
              To Date
            </label>

            <input
              type="date"
              id="toDate"
              className="form-control"
              name="toDate"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              onKeyDown={(e) => e.preventDefault()}
            />
          </div>

          <div className="field-flex align-self-end mb-20">
            <div className="success-btn">
              <button type="submit" className="btn" onClick={handleSubmit}>
                Search
              </button>
            </div>
          </div>

        </div>
      </div>

      <hr className='m-0' />

      <Loader isLoading={loader} />
      <>
        {
          paymentReportList.record_count === 0 ?
            <div className="form-style">
              <div className='section-sub-heading'>
                <h3 className="text-center">{paymentReportList.message}</h3>
              </div>
            </div>
            :
            <div className="form-style pb-4">
              <div className='d-flex align-items-center justify-content-between'>
                <div className='section-sub-heading'>
                  <h3>Payment</h3>
                </div>
                <div className='Export-Icon' onClick={handleExportData}>
                  <img src={exporticon} alt="" className='cursor-pointer' />
                </div>
              </div>
              <div className='table-responsive tbl-body-shadow'>
                <table className="w-100 table-theme-1" >
                  <thead>
                    <tr>
                      <th className="text-right">Payment ID</th>
                      <th className="text-left">Bill Number</th>
                      {/* <th className="text-left">Batch Number</th> */}
                      <th className="text-left">Vendor</th>
                      <th className="text-center">Payment Date</th>
                      <th className="text-end">Grand Total</th>
                      <th className="text-end">TDS Amount</th>
                      <th className="text-end">Amount Paid</th>
                      <th className="text-left">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentReportList.payment_list?.map((row, index) => (
                      <tr key={index}>
                        <td className="text-right">{row?.PaymentTransactionId ? <><Link className="" to={`/view-payment/${row?.PaymentTransactionId}`} target="_blank">{row.PaymentId}</Link> </> : row.PaymentId}</td>
                        <td className="text-left">
                          {
                            row?.IndividualBill && <Link className="" to={`/view-individual-bill/${row?.InvoiceId}`} target="_blank">{row?.DocumentNo}</Link>
                          }
                          {
                            !row?.IndividualBill && <Link className="" to={`/view-bill/${row?.InvoiceId}`} target="_blank">{row?.DocumentNo}</Link>
                          }
                        </td>
                        {/* <td className="text-left">{row?.PaymentBatch ? row?.PaymentBatch : "N/A"}</td> */}
                        <td className="text-left">{row?.VendorName}</td>
                        <td className="text-center">{row?.PaymentDate ? moment(row?.PaymentDate).format('D/MM/YYYY')?.slice(0, 10) : "N/A"}</td>
                        <td className="text-end">{row.CurrencySymbol} {row?.GrandTotal?.toFixed(2)}</td>
                        <td className="text-end">{row.CurrencySymbol} {row?.TDSAmount?.toFixed(2)}</td>
                        <td className="text-end">{row.CurrencySymbol} {row?.TotalAmountPaid?.toFixed(2)}</td>
                        <td className="text-left">{row?.StatusName}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr className="total-show">
                      <td className="text-end text-dark table-footer fw-bold"></td>
                      <td className="text-end text-dark table-footer fw-bold"></td>
                      {/* <td className="text-end text-dark table-footer fw-bold"></td> */}
                      <td className="text-end text-dark table-footer fw-bold"></td>
                      <td className="text-left text-dark text-center fw-bold">Total</td>
                      <td className="text-end text-dark fw-bold">{currencySymbol} {totalAmount && totalAmount?.toFixed(2)}</td>
                      <td className="text-end text-dark fw-bold">{currencySymbol} {totalTds && totalTds?.toFixed(2)}</td>
                      <td className="text-end text-dark fw-bold">{currencySymbol} {totalPaid && totalPaid?.toFixed(2)}</td>
                      <td className="text-end text-dark fw-bold"></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
        }
      </>

    </form >
  )
}

export default PaymentReport;