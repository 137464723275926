import React, { useEffect, useState } from "react";
import { getApi } from "../../../utils/services";
import {
  CONTRACT_STATUS,
  BILL_STATUS,
} from "../../../utils/apiRoutes";
import { useFeedsColumnsVendor } from "../../../utils/helpers/tableHelpers/useFeedsColumnsVendor";
import { useFeedsColumnContracts } from "../../../utils/helpers/tableHelpers/useFeedColumnContracts";
import { useFeedsColumnBills } from "../../../utils/helpers/tableHelpers/useFeedColumnBills";
import DashboardCard from "../../common/DashboardCard";
import {
  VENDOR_MANAGER_PENDING,
} from "../../../utils/constant";
import { Helmet } from "react-helmet";
import RadialCircleChart from "../../charts/radialchart/RadialCircleChart";
import moment from "moment";
import DateRangeCalender from "../../Calender/DateRangeCalender";
import { mdiCalendar } from "@mdi/js";
import Icon from "@mdi/react";
import Loader from "../../common/Loader";
import { useFeedsColumnIndividualBills } from "../../../utils/helpers/tableHelpers/useFeedColumnIndividualBill";
import { fetchBillList, fetchIndividualBillList, fetchPurchaseContractList, fetchVendorList } from "../../../utils/helpers/globalMethod";

const AdminManagerDashboard = () => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const [pageNumber, setPageNumber] = useState(1);
  // Add state for pagination and search specific to each table
  const [vendorPageNumber, setVendorPageNumber] = useState(1);
  const [purchaseContractPageNumber, setPurchaseContractPageNumber] = useState(1);
  const [billPageNumber, setBillPageNumber] = useState(1);
  const [individualBillPageNumber, setIndividualBillPageNumber] = useState(1);

  const [totalPagesVendor, setTotalPagesVendor] = useState(1);
  const [totalPagesPurchase, setTotalPagesPurchase] = useState(1);
  const [totalPagesBill, setTotalPagesBill] = useState(1);
  const [totalPagesIndividualBill, setTotalPagesIndividualBill] = useState(1);

  const [pageSize] = useState(5);

  const [vendors, setVendor] = useState([]);

  const [purchaseContract, setPurchaseContarct] = useState([]);
  const [bills, setBills] = useState([]);
  const [individualBill, setIndividualBills] = useState([]);
  const [contractRadialChartData, setContractRadialChartData] = useState({
    series: [],
    labels: [],
    total: 0,
  });
  const [billRadialChartData, setBillRadialChartData] = useState({
    series: [],
    labels: [],
    total: 0,
  });
  const [loader, setLoader] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: moment().subtract(2, "months"),
    endDate: moment(),
  });

  useEffect(() => {
    handleVendorList();
    handlerPurchaseContractList();
    handlerBillList();
    handlerIndividualBillList();

  }, [vendorPageNumber, purchaseContractPageNumber, billPageNumber, individualBillPageNumber]);

  const handleVendorList = async () => {
    await fetchVendorList(vendorPageNumber, pageSize, VENDOR_MANAGER_PENDING, setLoader, setVendor, setTotalPagesVendor, user);
  };

  const handlerPurchaseContractList = async () => {
    await fetchPurchaseContractList(pageSize, purchaseContractPageNumber, VENDOR_MANAGER_PENDING, setPurchaseContarct, setLoader, setTotalPagesPurchase, user);
  }

  const handlerBillList = async () => {
    await fetchBillList(pageSize, billPageNumber, VENDOR_MANAGER_PENDING, setBills, setLoader, setTotalPagesBill, user);
  }

  const handlerIndividualBillList = async () => {
    await fetchIndividualBillList(pageSize, individualBillPageNumber, VENDOR_MANAGER_PENDING, setIndividualBills, setLoader, setTotalPagesIndividualBill, user);
  }

  const handlerContractRedicalChart = async (startDate, endDate) => {
    try {
      setLoader(true);
      let result = await getApi(
        `${CONTRACT_STATUS}?startDate=${startDate.format(
          "YYYY-MM-DD"
        )}&endDate=${endDate.format("YYYY-MM-DD")}`
      );
      setContractRadialChartData({
        series: result.res.series,
        labels: result.res.labels,
        total: result.res.total,
      });
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlerBillRedicalChart = async (startDate, endDate) => {
    try {
      setLoader(true);
      let result = await getApi(
        `${BILL_STATUS}?startDate=${startDate.format(
          "YYYY-MM-DD"
        )}&endDate=${endDate.format("YYYY-MM-DD")}`
      );
      setBillRadialChartData({
        series: result.res.series,
        labels: result.res.labels,
        total: result.res.total,
      });
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handlerContractRedicalChart(
      selectedDateRange.startDate,
      selectedDateRange.endDate
    );
    handlerBillRedicalChart(
      selectedDateRange.startDate,
      selectedDateRange.endDate
    );

  }, []);

  const columns = useFeedsColumnsVendor(handleVendorList);
  const billcolumns = useFeedsColumnBills(handlerBillList);
  const purchasecolumns = useFeedsColumnContracts(handlerPurchaseContractList);
  const individualbillcolumns = useFeedsColumnIndividualBills(
    handlerIndividualBillList
  );

  const handleVendorPageChange = (page) => {
    setVendorPageNumber(page);
  };

  const handlePurchaseContractPageChange = (page) => {
    setPurchaseContractPageNumber(page);
  };

  const handleBillPageChange = (page) => {
    setBillPageNumber(page);
  };

  const handleIndividualBillPageChange = (page) => {
    setIndividualBillPageNumber(page);
  };

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Home</title>
      </Helmet>

      <Loader isLoading={loader} />

      <div className="container-fluid p-0">
        <div className="col-12 mb-4">
          <div className="row g-4 mb-2">
            <div className=" col-md-6 col-lg-7 col-xl-8 "></div>
            <div className=" col-md-6 col-lg-5 col-xl-4">
              <div className="input-group">
                <span className="input-group-text">
                  <Icon path={mdiCalendar} size={1} />
                </span>
                <DateRangeCalender
                  setSelectedDateRange={setSelectedDateRange}
                  startDate={selectedDateRange.startDate}
                  endDate={selectedDateRange.endDate}
                  handlerContractRedicalChart={handlerContractRedicalChart}
                  handlerBillRedicalChart={handlerBillRedicalChart}
                />
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-12 col-lg-6">
              <div className="container-section h-100 mb-0">
                <div className="card-body min-card-body-height">
                  <RadialCircleChart
                    title="Contract By Status"
                    data={contractRadialChartData.series}
                    label={contractRadialChartData.labels}
                    total={contractRadialChartData.total}
                    startDate={selectedDateRange.startDate}
                    endDate={selectedDateRange.endDate}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="container-section mb-0">
                <div className="card-body min-card-body-height">
                  <RadialCircleChart
                    title="Bill By Status"
                    data={billRadialChartData.series}
                    label={billRadialChartData.labels}
                    total={billRadialChartData.total}
                    startDate={selectedDateRange.startDate}
                    endDate={selectedDateRange.endDate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <>
          <DashboardCard
            title={"Vendors"}
            columns={columns}
            data={vendors}
            vendorPageNumber={vendorPageNumber}
            setVendorPageNumber={setVendorPageNumber}
            totalPagesVendor={totalPagesVendor}
            handleVendorPageChange={handleVendorPageChange}
            handleVendorList={handleVendorList}
            pageSize={pageSize}
            mt="m-0"
            fn=""
          />
          <DashboardCard
            title={"Purchase Contracts"}
            columns={purchasecolumns}
            data={purchaseContract}
            handlerPurchaseContractList={handlerPurchaseContractList}
            purchaseContractPageNumber={purchaseContractPageNumber}
            setPurchaseContractPageNumber={setPageNumber}
            totalPagesPurchase={totalPagesPurchase}
            handlePurchaseContractPageChange={handlePurchaseContractPageChange}
            pageSize={pageSize}
            mt="mt-4"
            fn=""
            tableId="contracts"
            approvalType="contract"
          />
          <DashboardCard
            title={"Bills"}
            columns={billcolumns}
            data={bills}
            handlerBillList={handlerBillList}
            billPageNumber={billPageNumber}
            setBillPageNumber={setBillPageNumber}
            totalPagesBill={totalPagesBill}
            handleBillPageChange={handleBillPageChange}
            pageSize={pageSize}
            mt="mt-4"
            fn=""
          />
          <DashboardCard
            title={"Standalone Bill"}
            columns={individualbillcolumns}
            data={individualBill}
            mt="mt-4"
            fn=""
            tableId="invoices"
            handlerIndividualBillList={handlerIndividualBillList}
            approvalType="invoice"
            individualBillPageNumber={individualBillPageNumber}
            setIndividualBillPageNumber={setIndividualBillPageNumber}
            totalPagesIndividualBill={totalPagesIndividualBill}
            handleIndividualBillPageChange={handleIndividualBillPageChange}
            pageSize={pageSize}
          />
        </>
      </div>
    </>
  );
};

export default AdminManagerDashboard;
