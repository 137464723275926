import { useState } from 'react';
import { validateBankInfo } from '../utils/helpers/globalValidation';
import { validateEditBankDocument } from '../utils/helpers/globalValidation';

const EditFinanceInfoValidation = () => {
    const [errors, setErrors] = useState({});

    const validateForm = (paymentModeId, inputValues, bankDocument, bankDocumentObject) => {
        let { formIsValid, errors: bankInfoErrors } = validateBankInfo(inputValues);

        if (paymentModeId === 1) {
            const bankDocumentValidation = validateEditBankDocument(bankDocument, bankDocumentObject);
            formIsValid = formIsValid && bankDocumentValidation.formIsValid;
            bankInfoErrors = { ...bankInfoErrors, ...bankDocumentValidation.errors };
        }

        setErrors(bankInfoErrors);

        return formIsValid;
    };

    return { errors, setErrors, validateForm };
};

export default EditFinanceInfoValidation;
