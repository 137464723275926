import React, { useEffect, useState } from "react";
import { useFeedsColumnBills } from "../../../utils/helpers/tableHelpers/useFeedColumnBills";
import { BILLS_API } from "../../../utils/apiRoutes";
import { getApi } from "../../../utils/services";
import { VENDORID } from "../../../utils/constant";
import Bill from "../../Table/Bill";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";
import { useLocation, useNavigate } from "react-router-dom";

const BillList = () => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const location = useLocation();
  const navigate = useNavigate();
  const [bills, setBills] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 10;
  const [totalPages, setTotalPages] = useState(1);
  const [globalSearch, setGlobalSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const storedStatusId = location.state?.billStatusId || '';
  const [billStatusId, setBillStatusId] = useState(storedStatusId);
  const [statusQuery, setStatusQuery] = useState(storedStatusId);
  const [holdBillCount, setHoldBillCount] = useState(0);

  const handlerBillList = async (pageNumber, pageSize, searchQuery, statusQuery) => {
    try {
      let routeName;
      if (user.RoleId === VENDORID) {
        routeName = `${BILLS_API}?billType=0&vendorId=${user.id}&page=${pageNumber}&pageSize=${pageSize}&globalSearch=${searchQuery}&billStatusId=${statusQuery}`;
      } else {
        routeName = `${BILLS_API}?billType=0&page=${pageNumber}&pageSize=${pageSize}&globalSearch=${searchQuery}&billStatusId=${statusQuery}`;
      }
      setLoader(true);
      let result = await getApi(routeName);
      setLoader(false);
      setBills(result.res.bill_list);
      setHoldBillCount(result.res.hold_bill_count);
      const totalItems = result.res.record_count;
      setTotalPages(Math.ceil(totalItems / pageSize));
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    handlerBillList(pageNumber, pageSize, searchQuery, statusQuery);
  }, [pageNumber, pageSize, searchQuery, statusQuery]);

  const billcolumns = useFeedsColumnBills(handlerBillList, pageNumber, pageSize, searchQuery, statusQuery);

  const handlePageChange = (page) => {
    setPageNumber(page);
    navigate(`/invoice-listing`, { state: { billStatusId } });
  };

  const handleSearchSubmit = () => {
    if (globalSearch !== searchQuery || billStatusId !== statusQuery) {
      setSearchQuery(globalSearch);
      setStatusQuery(billStatusId);
      setPageNumber(1);
      navigate(`/invoice-listing`, { state: { billStatusId } });
    } else {
      handlerBillList(pageNumber, pageSize, searchQuery, statusQuery);
    }
  };

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Bill List</title>
      </Helmet>

      <Loader isLoading={loader} />
      <div className="container-section">
        <div className="section-header justify-content-between">
          <h3>Bills</h3>
          <div className="cstm-bill-recon-sec cstm-bill-legend">
            <div className="d-flex align-items-center">
              <span className="recon-StatusIcon2 blink"></span>
              <p className="me-1">{holdBillCount}</p>
              <p>Hold Bills</p>
            </div>
          </div>
        </div>
        <div className="form-style">
          <div className="">
            <Bill
              columns={billcolumns}
              data={bills}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              globalSearch={globalSearch}
              setGlobalSearch={setGlobalSearch}
              handlerBillList={handlerBillList}
              pageSize={pageSize}
              billStatusId={billStatusId}
              setBillStatusId={setBillStatusId}
              handleSearchSubmit={handleSearchSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BillList;