import React, { useState } from 'react';
import LockIcon from '../../../assets/image/LockIcon';
import BackButton from '../../../assets/image/BackButton';
import toast from 'react-hot-toast';
import { CHANGE_PASSWORD_STAFF, CHANGE_PASSWORD_VENDOR } from '../../../utils/apiRoutes';
import { postApi } from '../../../utils/services';
import { mdiEye, mdiEyeOff, mdiInformationVariantCircleOutline } from '@mdi/js';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from '@mdi/react';
import ChangePasswordFromValidation from '../../../middleware/changePasswordErrorValidation';
import { VENDORID } from '../../../utils/constant';
import { useNavigate } from 'react-router-dom';

const ChangePassword = () => {
  const [oldpass, setOldpass] = useState("");
  const [newpass, setNewpass] = useState("");
  const [confpass, setCofpass] = useState("");
  const [visibility, setVisibility] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [loader, setLoader] = useState(false);
  const { errors, setErrors, validateForm } = ChangePasswordFromValidation();
  const user = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();

  const handleChangePasswordApi = async () => {
    const isFormValid = validateForm(oldpass, newpass, confpass);
    if (!isFormValid) return;

    setLoader(true);
    try {
      const apiEndpoint = user.RoleId === VENDORID ? CHANGE_PASSWORD_VENDOR : CHANGE_PASSWORD_STAFF;
      const result = await postApi(apiEndpoint, {
        [`${user.RoleId === VENDORID ? "vendorId" : "staffId"}`]: user.id,
        "oldPassword": oldpass,
        "newPassword": newpass,
        "confirmPassword": confpass
      });

      if (result.res.success) {
        toast.success(result.res.message);
        navigate("/");
        setLoader(false);
        setOldpass("");
        setNewpass("");
        setCofpass("");
      } else {
        const updatedErrors = {};
        if (result.res.passwordMessage) {
          updatedErrors.oldpassword = result.res.passwordMessage;
        }
        setErrors(updatedErrors);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoader(false);
    }
  };

  const togglePasswordVisibility = (field) => {
    setVisibility((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  return (
    <>
      <div className="container-section">
        <div className="section-header">
          <div className='d-flex align-items-center'>
            <button onClick={() => navigate(-1)} className="back-viewbtn me-2">
              <div className="svgsize">
                <BackButton />
              </div>
            </button>
            <h3>Change password</h3>
          </div>
        </div>
        <div className="change_password_form">
          <div className="change_pasword_card">
            <div className="change_password_card_title">
              <div className='d-flex align-items-center'>
                <LockIcon />
                <h3 className='ms-2 my-0'>Change Password</h3>
              </div>
            </div>
            <div className="change_password_fild">
              <div className="form-group mb-3">
                <label className='form-label'>Current Password<span className="required">*</span></label>
                <div className="input-group">
                  <input
                    type={visibility.currentPassword ? "text" : "password"}
                    className="form-control" placeholder='Current password' autoComplete="off"
                    value={oldpass}
                    onChange={(e) => {
                      setOldpass(e.target.value);
                      setErrors((prevErrors) => ({ ...prevErrors, oldpassword: "" }));
                    }}
                  />
                  <div className="input-group-text input-pass-icon cstm-eye-icon cursor-pointer" onClick={() => togglePasswordVisibility('currentPassword')}>
                    <Icon path={visibility.currentPassword ? mdiEyeOff : mdiEye} size={0.8} color="black" />
                  </div>
                </div>
                {errors.oldpassword && <p className="validation-error">{errors.oldpassword}</p>}
              </div>
              <div className="form-group mb-3">
                <label className='form-label'>New Password
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip className="custom-tooltip">Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and no spaces</Tooltip>}
                  >
                    <span className="ms-1 infoIcon"><Icon path={mdiInformationVariantCircleOutline} size={0.6} /></span>
                  </OverlayTrigger>
                  <span className="required">*</span>
                </label>
                <div className="input-group">
                  <input
                    type={visibility.newPassword ? "text" : "password"}
                    className="form-control" autoComplete="off"
                    placeholder="New password"
                    value={newpass}
                    onChange={(e) => {
                      setNewpass(e.target.value);
                      setErrors((prevErrors) => ({ ...prevErrors, newpassword: "" }));
                    }}
                  />
                  <div className="input-group-text input-pass-icon cstm-eye-icon cursor-pointer" onClick={() => togglePasswordVisibility('newPassword')}>
                    <Icon path={visibility.newPassword ? mdiEyeOff : mdiEye} size={0.8} color="black" />
                  </div>
                </div>
                {errors.newpassword && <p className="validation-error">{errors.newpassword}</p>}
              </div>
              <div className="form-group mb-3">
                <label className='form-label'>Confirm Password<span className="required">*</span></label>
                <div className="input-group">
                  <input
                    type={visibility.confirmPassword ? "text" : "password"}
                    className="form-control" autoComplete="off"
                    placeholder="Confirm password"
                    value={confpass}
                    onChange={(e) => {
                      setCofpass(e.target.value);
                      setErrors((prevErrors) => ({ ...prevErrors, confirmpassword: "" }));
                    }}
                  />
                  <div className="input-group-text input-pass-icon cstm-eye-icon cursor-pointer" onClick={() => togglePasswordVisibility('confirmPassword')}>
                    <Icon path={visibility.confirmPassword ? mdiEyeOff : mdiEye} size={0.8} color="black" />
                  </div>
                </div>
                {errors.confirmpassword && <p className="validation-error">{errors.confirmpassword}</p>}
              </div>
            </div>
            <hr className='m-0' />
            <div className='form-style'>
              <div className="d-flex align-items-center justify-content-end">
                <div className="success-btn">
                  <button type="button" className="btn" onClick={handleChangePasswordApi} disabled={loader}>
                    {loader ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Update"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;