import { useState } from 'react';
import { PASSWORD_REGX } from '../utils/helpers/globalRegx';
import { validateOldPassword, validateNewPassword, validateConfirmPassword } from '../utils/helpers/globalValidation';

const ChangePasswordFromValidation = () => {
  const [errors, setErrors] = useState({});

  const validateForm = (oldpass, newpass, confpass) => {
    const _errors = {};

    const oldPasswordError = validateOldPassword(oldpass);
    if (oldPasswordError) {
      _errors["oldpassword"] = oldPasswordError;
    }

    const newPasswordError = validateNewPassword(newpass, PASSWORD_REGX);
    if (newPasswordError) {
      _errors["newpassword"] = newPasswordError;
    }

    const confirmPasswordError = validateConfirmPassword(newpass, confpass);
    if (confirmPasswordError) {
      _errors["confirmpassword"] = confirmPasswordError;
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  };

  return { errors, setErrors, validateForm };
};

export default ChangePasswordFromValidation;
