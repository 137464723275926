export const VENDOR = "Vendor";
export const STAFF = "Staff";
export const FINANCE = "Finance";

// ---------------------Role ID start---------------------------------------------------------//

export const SUPER_ADMIN_ID = 1;
export const VP_ID = 2;
export const ADMIN_EXECUTIVE_ID = 3;
export const ADMIN_MANAGER_ID = 4;
export const VENDORID = 5;
export const FINANCE_ID = 6;

// ---------------------Role ID end---------------------------------------------------------//
// ---------------------Status ID start---------------------------------------------------------//

export const VENDOR_APPROVE = 2;
export const VENDOR_VP_PENDING = 4;
export const VENDOR_MANAGER_PENDING = 1;
export const VENDOR_REJECT = 3;
export const FINANCE_PENDING = 5;
export const PAYMENT_PENDING = 6;
export const FULLY_PAID = 8;
export const VOID_STATUS_ID = 2;
export const HOLD_STATUS_ID = 1;
export const PARTIALLY_PAID_STATUS_ID = 5;
export const ADMIN_EXECUTIVE_PENDING = 14

// ---------------------Status ID start---------------------------------------------------------//

export const CONTRACT_SITE_CONFIGERATION_ID = 1;
export const BILL_SITE_CONFIGERATION_ID = 3;

// --------------------- approve/reject modals type and message-----------------------------------------------------//

export const PURCHASE_CONTRACT_APPROVE_ID = 1;
export const BILL_APPROVE_ID = 2
export const STANDALONE_BILL_APPROVE_ID = 3
export const VENDOR_APPROVE_ID = 4;
export const MAX_FILE_SIZE = 2 * 1024 * 1024;
export const RANDOM_CAPTCH_GENRATE = "ABCDEFGHIJKLMNOPQRSTUWXYZabcdefghijklmsuvwxyz1234567890!@#$%^&*()";
export const OTP_TIMER = 120;
export const THOUSAND_VALUE = 'K'


