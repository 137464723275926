import { EMAIL_REGX, PASSWORD_REGX, TEXT_ONLY_REGX } from './globalRegx';
import {
    EMAIL_ERROR, EMAIL_VAILD_ERROR, USER_TYPE_ERROR, USER_NAME_ERROR, PASSWORD_ERROR, CAPTCHA_ERROR, CAPTCHA_VERIFICATION_ERROR,
    CURRENT_PASSWORD_ERROR, NEW_PASSWORD_ERROR, PASSWORD_LENGTH_ERROR, PASSWORD_POLICY_ERROR, CONFIRM_PASSWORD_ERROR, PASSWORD_NOT_MATCH_ERROR, VENDOR_ERROR, START_DATE_ERROR, END_DATE_ERROR, MIN_AMOUNT_ERROR, UPLOAD_DOCUMENT_ERROR, UPLOAD_MAX_FILE_ERROR, UPLOAD_PDF_ERROR, UPLOAD_MAX_SIZE_ERROR, ITEM_ERROR, RATE_ERROR, MIN_RATE_ERROR, QUANTITY_ERROR, MIN_QUANTITY_ERROR, BILL_DATE_ERROR, SUBTOTAL_ERROR, DEPARTMENT_ERROR, GST_FIELD_REQUIRED_ERROR, CGST_ERROR, SGST_ERROR, SALES_TAX_ERROR, CGST_SGST_BOTH_REQURED, QUANTITY_REQUIRED_FOR_GST, GST_FIELD_REQUIRED_QUANTITY, UTGST_ERROR, IGST_ERROR,
    PENDING_APPROVAL_DETAIL, ALPHABETIC_ONLY_ERROR, ACCOUNT_NUMBER_LENGTH_ERROR, IFSC_CODE_LENGTH_ERROR, ONE_TIME_PASSWORD_ERROR, VALID_ONE_TIME_PASSWORD_ERROR, OTP_LENGTH_ERROR
} from './globalErrorMessage';
import { MAX_FILE_SIZE, VENDORID } from '../constant';

export const validateEmail = (email) => {
    if (!email) return EMAIL_ERROR;
    if (!EMAIL_REGX.test(email)) return EMAIL_VAILD_ERROR;
    return null;
};

export const validateUserType = (userType) => {
    if (!userType) return USER_TYPE_ERROR;
    return null;
};

export const validateUseName = (userName) => {
    if (!userName) return USER_NAME_ERROR;
    return null;
};

export const validatePassword = (password) => {
    if (!password) return PASSWORD_ERROR;
    return null;
};

export const validateCaptcha = (captcha, captchaInput) => {
    if (!captchaInput) return CAPTCHA_ERROR;
    if (captcha !== captchaInput) return CAPTCHA_VERIFICATION_ERROR;
    return null;
};

export const validateOldPassword = (oldpass) => {
    if (!oldpass) return CURRENT_PASSWORD_ERROR;
    return null;
};

export const validateNewPassword = (newPassword, passwordRegex) => {
    if (!newPassword) return NEW_PASSWORD_ERROR;
    if (newPassword.length < 8) return PASSWORD_LENGTH_ERROR;
    if (!passwordRegex.test(newPassword)) return PASSWORD_POLICY_ERROR;
    return null;
};

export const validateConfirmPassword = (newPassword, confirmPassword) => {
    if (!confirmPassword) return CONFIRM_PASSWORD_ERROR;
    if (newPassword !== confirmPassword) return PASSWORD_NOT_MATCH_ERROR;
    return null;
};

export const validateOTP = (otp) => {
    if (!otp) return ONE_TIME_PASSWORD_ERROR;

    if (isNaN(otp)) return VALID_ONE_TIME_PASSWORD_ERROR;

    if (otp.length !== 6) return OTP_LENGTH_ERROR;

    return null;
};


export const isValidFileType = (file) => {
    const allowedTypes = ["application/pdf"];
    return allowedTypes.includes(file.type);
};

export const validateVendor = (vendorId) => {
    if (!vendorId) return VENDOR_ERROR;
    return null;
};

export const validateStardDate = (startDate) => {
    if (!startDate) return START_DATE_ERROR;
    return null;
};

export const validateEndDate = (endDate) => {
    if (!endDate) return END_DATE_ERROR;
    return null;
};

export const validateContractTotal = (totalAmount) => {
    if (totalAmount === "0.00") {
        return MIN_AMOUNT_ERROR;
    }
    return null;
};

export function validateUploadDocuments(files, contractDocument) {
    let formIsValid = true;
    let errors = {};

    const filesArray = Array.isArray(files) ? files : [];
    const documents = Array.isArray(contractDocument) ? contractDocument : [];

    if (filesArray.length === 0 && documents.length === 0) {
        formIsValid = false;
        errors.files = UPLOAD_DOCUMENT_ERROR;
    }

    for (const file of documents) {
        if (!isValidFileType(file)) {
            formIsValid = false;
            errors.files = UPLOAD_PDF_ERROR;
            break;
        }
        if (file.size > MAX_FILE_SIZE) {
            formIsValid = false;
            errors.files = UPLOAD_MAX_SIZE_ERROR;
            break;
        }
    }

    const totalFiles = filesArray.length + documents.length;
    if (totalFiles >= 6) {
        formIsValid = false;
        errors.files = UPLOAD_MAX_FILE_ERROR;
    }

    return { formIsValid, errors };
}

export const validateItemDescription = (itemDescription, lineLevelCheck = false) => {
    let formIsValid = true;
    const errors = { itemDescription: {}, itemDescriptionLineLevel: {} };

    const validateItem = (item, lineItemErrors) => {
        if (!item.itemId) {
            formIsValid = false;
            lineItemErrors.itemId = ITEM_ERROR;
        }
        // if (item.description && item.description.length > 200) {
        //     formIsValid = false;
        //     lineItemErrors.description = TEXT_LENGTH;
        // }
        if (!item.rate) {
            formIsValid = false;
            lineItemErrors.rate = RATE_ERROR;
        }
        if (item.rate && item.rate === 0) {
            formIsValid = false;
            lineItemErrors.rate = MIN_RATE_ERROR;
        }
        if (!item.quantity) {
            formIsValid = false;
            lineItemErrors.quantity = QUANTITY_ERROR;
        }
        if (item.quantity && item.quantity === 0) {
            formIsValid = false;
            lineItemErrors.quantity = MIN_QUANTITY_ERROR;
        }
        return lineItemErrors;
    };

    if (!lineLevelCheck) {
        itemDescription.forEach((item, index) => {
            const lineItemErrors = validateItem(item, {});
            if (Object.keys(lineItemErrors).length > 0) {
                errors.itemDescription[index] = lineItemErrors;
            }
        });

        if (Object.keys(errors.itemDescription).length === 0) {
            delete errors.itemDescription;
        }
    } else {
        itemDescription.forEach((item, index) => {
            const lineItemErrors = validateItem(item, {});
            const hasGST = item.cgst || item.sgst || item.utgst || item.igst;

            if (!hasGST) {
                formIsValid = false;
                lineItemErrors.cgst = CGST_ERROR;
                lineItemErrors.sgst = SGST_ERROR;
                lineItemErrors.utgst = UTGST_ERROR;
                lineItemErrors.igst = IGST_ERROR;
            }
            if (item.cgst && !item.sgst) {
                formIsValid = false;
                lineItemErrors.sgst = SGST_ERROR;
            }
            if (!item.cgst && item.sgst) {
                formIsValid = false;
                lineItemErrors.cgst = CGST_ERROR;
            }

            if (Object.keys(lineItemErrors).length > 0) {
                errors.itemDescriptionLineLevel[index] = lineItemErrors;
            }
        });

        if (Object.keys(errors.itemDescriptionLineLevel).length === 0) {
            delete errors.itemDescriptionLineLevel;
        }
    }

    return formIsValid ? null : errors;
};

export const validateItemDescriptionLineLevel = (itemDescription) => {
    let formIsValid = true;
    const errors = {};

    itemDescription.forEach((item, index) => {
        const lineItemErrors = {};

        if (!item.itemId) {
            formIsValid = false;
            lineItemErrors.itemId = ITEM_ERROR;
        }
        if (!item.rate) {
            formIsValid = false;
            lineItemErrors.rate = RATE_ERROR
        }
        if (item.rate && item.rate === 0) {
            formIsValid = false;
            lineItemErrors.rate = MIN_RATE_ERROR;
        }
        if (!item.quantity) {
            formIsValid = false;
            lineItemErrors.quantity = QUANTITY_ERROR;
        }
        if (item.quantity && item.quantity === 0) {
            formIsValid = false;
            lineItemErrors.quantity = MIN_QUANTITY_ERROR;
        }

        const hasGST = item.cgst || item.sgst || item.utgst || item.igst;
        if (!hasGST) {
            formIsValid = false;
            lineItemErrors.cgst = CGST_ERROR;
            lineItemErrors.sgst = SGST_ERROR;
            lineItemErrors.utgst = UTGST_ERROR;
            lineItemErrors.igst = IGST_ERROR;
        }
        if (item.cgst && !item.sgst) {
            formIsValid = false;
            lineItemErrors.sgst = SGST_ERROR;
        }
        if (!item.cgst && item.sgst) {
            formIsValid = false;
            lineItemErrors.cgst = CGST_ERROR;
        }

        if (Object.keys(lineItemErrors).length > 0) {
            errors[index] = lineItemErrors;
        }
    });

    return formIsValid ? null : { itemDescription: errors };
};

export const PasswordValidator = (password) => {
    if (!password) return NEW_PASSWORD_ERROR;
    if (password.length < 8) return PASSWORD_LENGTH_ERROR;
    if (!PASSWORD_REGX.test(password)) {
        return PASSWORD_POLICY_ERROR;
    }
    return null;
};

export const validateBillDate = (billDate) => {
    if (!billDate) return BILL_DATE_ERROR;
    return null;
};

export const validateSubTotal = (finalTotalAmount) => {
    if (finalTotalAmount === "0.00" || finalTotalAmount === 0) {
        return SUBTOTAL_ERROR;
    }
    return null;
};

export const validateDepartmentId = (roleId, departmentId) => {
    if (roleId !== VENDORID && !departmentId) {
        return DEPARTMENT_ERROR;
    }
    return null;
};

export const validateGSTFields = (gstFields, subsidiaryId) => {
    let errors = {};
    let formIsValid = true;

    if (!gstFields.cgst && !gstFields.sgst && !gstFields.utgst && !gstFields.igst && subsidiaryId === 2) {
        formIsValid = false;
        errors.taxError = GST_FIELD_REQUIRED_ERROR;
    } else if (!gstFields.cgst && gstFields.sgst) {
        formIsValid = false;
        errors.taxError = CGST_ERROR;
    } else if (gstFields.cgst && !gstFields.sgst) {
        formIsValid = false;
        errors.taxError = SGST_ERROR;
    } if (!gstFields.salesTax && subsidiaryId === 1) {
        formIsValid = false;
        errors.taxError = SALES_TAX_ERROR;
    }

    return { formIsValid, errors };
};

export const validateTaxFields = (lineLevelGst, data, subsidiaryId, lines) => {
    let errors = {};
    let formIsValid = true;

    if (!lineLevelGst) {
        const { formIsValid: gstFormIsValid, errors: gstErrors } = validateGSTFields(data, subsidiaryId);
        formIsValid = formIsValid && gstFormIsValid;
        errors = { ...errors, ...gstErrors };
    } else {
        const missingQuantity = lines.some(item => (
            (item.quantity <= 0 || item.quantity === '' || !item.hasOwnProperty('quantity')) &&
            (item.cgst || item.sgst || item.utgst || item.igst)
        ));

        const missingGST = lines.some(item => (
            (item.quantity > 0) &&
            (!item.hasOwnProperty('cgst') || item.cgst === '') &&
            (!item.hasOwnProperty('sgst') || item.sgst === '') &&
            (!item.hasOwnProperty('utgst') || item.utgst === '') &&
            (!item.hasOwnProperty('igst') || item.igst === '')
        ));

        const cgstAndSgstValidation = lines.some(item => (
            ((item.hasOwnProperty('cgst') && item.cgst !== '') && (!item.hasOwnProperty('sgst') || item.sgst === '')) ||
            ((item.hasOwnProperty('sgst') && item.sgst !== '') && (!item.hasOwnProperty('cgst') || item.cgst === ''))
        ));

        if (missingGST) {
            alert(GST_FIELD_REQUIRED_QUANTITY);
            return { formIsValid: false, errors: { taxError: GST_FIELD_REQUIRED_QUANTITY } };
        } else if (missingQuantity) {
            alert(QUANTITY_REQUIRED_FOR_GST);
            return { formIsValid: false, errors: { taxError: QUANTITY_REQUIRED_FOR_GST } };
        } else if (cgstAndSgstValidation) {
            alert(CGST_SGST_BOTH_REQURED);
            return { formIsValid: false, errors: { taxError: CGST_SGST_BOTH_REQURED } };
        }
    }

    return { formIsValid, errors };
};

export const validateTaxEditFields = (lineGSTcheck, Cgst, Sgst, UTgst, Igst, Tax, subsidiaryId, lineItems) => {
    let errors = {};
    let formIsValid = true;

    if (!lineGSTcheck) {
        const { formIsValid: gstFormIsValid, errors: gstErrors } = validateGSTFields({ cgst: Cgst, sgst: Sgst, utgst: UTgst, igst: Igst }, subsidiaryId);
        formIsValid = formIsValid && gstFormIsValid;
        errors = { ...errors, ...gstErrors };

        if (!Tax && subsidiaryId === 1) {
            formIsValid = false;
            errors.taxError = SALES_TAX_ERROR;
        }
    } else {
        const missingQuantityLines = lineItems.filter(item => (item.Quantity < 0 || item.Quantity === '') && (item.CGST || item.SGST || item.UTGST || item.IGST));
        const missingGstLines = lineItems.filter(item => (item.Quantity > 0) && (!item.CGST && !item.SGST && !item.UTGST && !item.IGST));
        const cgstAndSgstValidation = lineItems.filter(item => (!item.CGST && item.SGST) || (item.CGST && !item.SGST));
        const zeroQuantityLines = lineItems.filter(item => item.Quantity <= 0 || item.Quantity === '');

        if (missingGstLines.length > 0) {
            alert(GST_FIELD_REQUIRED_QUANTITY);
            return { formIsValid: false, errors };
        } else if (missingQuantityLines.length > 0) {
            alert(QUANTITY_REQUIRED_FOR_GST);
            return { formIsValid: false, errors };
        } else if (zeroQuantityLines.length > 0) {
            alert(MIN_QUANTITY_ERROR);
            return { formIsValid: false, errors };
        } else if (cgstAndSgstValidation.length > 0) {
            alert(CGST_SGST_BOTH_REQURED);
            return { formIsValid: false, errors };
        }
    }

    return { formIsValid, errors };
};

export const validateVendorId = (isVendorIdPresent) => {
    if (!isVendorIdPresent) {
        return PENDING_APPROVAL_DETAIL;
    }
    return null;
};

export const validateSubtotalAndTax = (lineLevelCheck, subTotal, subTotalLineLevel, tax) => {
    const errors = {};
    let formIsValid = true;

    if (!lineLevelCheck) {
        if (subTotal === 0) {
            formIsValid = false;
            errors.subTotal = SUBTOTAL_ERROR;
        }
        if (!tax.cgst && !tax.sgst && !tax.utgst && !tax.igst) {
            formIsValid = false;
            errors.tax = GST_FIELD_REQUIRED_ERROR;
        } else if (tax.cgst && !tax.sgst) {
            formIsValid = false;
            errors.tax = SGST_ERROR;
        } else if (!tax.cgst && tax.sgst) {
            formIsValid = false;
            errors.tax = CGST_ERROR;
        }
    } else {
        if (subTotalLineLevel === 0) {
            formIsValid = false;
            errors.subTotalLineLevel = SUBTOTAL_ERROR;
        }
    }

    return { formIsValid, errors };
};

export const validateIndividualGSTFields = (cgst, sgst, utgst, igst) => {
    let formIsValid = true;
    const errors = {};

    if ((cgst === null || cgst === '') && (sgst === null || sgst === '') && (utgst === null || utgst === '') && (igst === null || igst === '')) {
        formIsValid = false;
        errors.tax = GST_FIELD_REQUIRED_ERROR;
    } else if (cgst && (sgst === null || sgst === '')) {
        formIsValid = false;
        errors.tax = SGST_ERROR;
    } else if ((cgst === null || cgst === '') && sgst) {
        formIsValid = false;
        errors.tax = CGST_ERROR;
    }

    return { formIsValid, errors };
};

export const validateBankInfo = (inputValues) => {
    let formIsValid = true;
    const errors = {};

    for (const fieldName in inputValues) {
        if (inputValues.hasOwnProperty(fieldName)) {
            const { fieldValue } = inputValues[fieldName];

            if (!fieldValue.trim()) {
                formIsValid = false;
                errors[fieldName] = `Enter ${fieldName}`;
            } else {
                switch (fieldName) {
                    case 'Bank Name':
                        if (!TEXT_ONLY_REGX.test(fieldValue)) {
                            formIsValid = false;
                            errors[fieldName] = ALPHABETIC_ONLY_ERROR;
                        }
                        break;
                    case 'Account Number':
                        if (fieldValue.length < 5 || fieldValue.length > 25) {
                            formIsValid = false;
                            errors[fieldName] = ACCOUNT_NUMBER_LENGTH_ERROR;
                        }
                        break;
                    case 'IFSC Code':
                        if (fieldValue.length !== 11) {
                            formIsValid = false;
                            errors[fieldName] = IFSC_CODE_LENGTH_ERROR;
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    }

    return { formIsValid, errors };
};


export const validateBankDocument = (bankDocument) => {
    let formIsValid = true;
    const errors = {};

    if (bankDocument.length === 0) {
        formIsValid = false;
        errors.bankDocument = UPLOAD_DOCUMENT_ERROR;
    } else {
        for (const item of bankDocument) {
            if (!isValidFileType(item)) {
                formIsValid = false;
                errors.bankDocument = UPLOAD_PDF_ERROR;
                break;
            }
            if (item.size > MAX_FILE_SIZE) {
                formIsValid = false;
                errors.bankDocument = UPLOAD_MAX_SIZE_ERROR;
                break;
            }
        }
    }

    return { formIsValid, errors };
};

export const validateEditBankDocument = (bankDocument, bankDocumentObject) => {
    let formIsValid = true;
    const errors = {};

    if (!bankDocumentObject && bankDocument.length === 0) {
        formIsValid = false;
        errors.bankDocument = UPLOAD_DOCUMENT_ERROR;
    }
    else if (!bankDocumentObject?.BankDocumentName && bankDocument.length === 0) {
        formIsValid = false;
        errors.bankDocument = UPLOAD_DOCUMENT_ERROR;
    } else {
        const areAllPdfs = bankDocument.every((file) => file.type === 'application/pdf');
        if (!areAllPdfs) {
            formIsValid = false;
            errors.bankDocument = UPLOAD_PDF_ERROR;
        } else {
            const isValidSize = bankDocument.every((file) => file.size <= 2 * 1024 * 1024);
            if (!isValidSize) {
                formIsValid = false;
                errors.bankDocument = UPLOAD_MAX_SIZE_ERROR;
            }
        }
    }

    return { formIsValid, errors };
};
