import React, { useState } from 'react';
import icon from "../../assets/image/streamlogo.png";
import bckicon from "../../assets/image/bck-circle.png";
import { useNavigate } from 'react-router-dom';
import ForgetFormValidation from '../../middleware/forgetErrorValidation';
import { postApi } from '../../utils/services';
import { FINANCE, STAFF, VENDOR } from '../../utils/constant';
import { FORGOT_LOGIN_API } from '../../utils/apiRoutes';
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
import LoginLeftContent from '../common/LoginContent';

const ForgetPage = () => {
  let navigate = useNavigate();
  const [loginType, setLoginType] = useState(2);
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false)
  const { errors, setErrors, validateForm } = ForgetFormValidation();

  const handlerChange = (e) => {
    setEmail(e.target.value);
    setErrors({})
  }

  const handleServerValidations = (type, errorMessage) => {
    setErrors({ [type]: errorMessage });
  };

  const handlerSubmit = async () => {
    try {
      const isFormValid = validateForm(loginType, email);
      if (isFormValid) {
        setLoader(true)
        let role = getUserType(loginType);
        const result = await postApi(FORGOT_LOGIN_API, { "email": email, "role": role })
        if (result.res.success) {
          toast.success(result.res.message)
          navigate(`/one-time-password/${role}`, { state: result.res.detail, role: role });
          setLoader(false)
        }
        else {
          handleServerValidations("email", result.res.message,)
          setLoader(false)
        }
      }
    } catch (error) {
      setLoader(false)
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handlerSubmit();
    }
  };

  return (
    <>

      <Helmet>
        <title>Vendor Portal || Forgot-Password</title>
      </Helmet>
      <div className='main-lgn-cntnr lgn-common'>
        <LoginLeftContent />
        <div className='lgn-cntnr-rgt'>
          <div className='lgn-rgt-cntn w-100'>
            <div className="text-center lgn-rgt-cntn-logo "><img className="lgn-logo" src={icon} alt="" /></div>
            <div className='bck-circle-icn'>
              <img className="logo cursor-pointer" src={bckicon} alt="" onClick={() => navigate(-1)} />
            </div>
            <h3>Forgot Password</h3>
            <p>Please enter your registered email address to get the OTP to reset the password of your account.</p>
            <div className='lgn-selection d-flex justify-content-between'>
              {['Vendor', 'Employee', 'Finance'].map((type, index) => (
                <label key={type} className="d-flex align-items-center">
                  <input
                    type="radio"
                    className="input-radio"
                    name="lgn"
                    checked={loginType === index + 1}
                    onChange={() => setLoginType(index + 1)}
                  />
                  {type}
                </label>
              ))}
            </div>
            {
              errors.logintype && <span className="validate_error-div">{errors.logintype}</span>
            }
            <div className='lgn-section-form  form'>
              <div className='forgot-pwd-form'>
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Email Id</label>
                  <input value={email} className="form-control" id="exampleFormControlInput1" placeholder="Enter your email id" onChange={(e) => handlerChange(e)} onKeyDown={handleKeyPress} />
                  {
                    errors.email && <p className="validate_error-div">{errors.email}</p>
                  }
                </div>
              </div>
              <div className='sign-in-btn'>
                <button type='button' className='btn btn-signin' disabled={loader} onClick={handlerSubmit}> {loader ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </>
                ) : (
                  "SUBMIT"
                )}</button>
                <a href='/login' className='justify-content-end d-flex' >Back to Login</a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default ForgetPage;

const getUserType = (loginType) => {
  switch (loginType) {
    case 1:
      return VENDOR;
    case 2:
      return STAFF;
    case 3:
      return FINANCE;
    default:
      return STAFF;
  }
};