import React, { useContext, useState } from "react";
import { ModalContext } from "../../utils/Context";
import { postApi } from "../../utils/services";
import { PAY_PAYMENT } from "../../utils/apiRoutes";
import toast from "react-hot-toast";
import Alert from "./Alert";
import { Link, useNavigate } from "react-router-dom";
import { DECIMAL_OPTIONAL_TWO_PLACES_REGX } from "../../utils/helpers/globalRegx";

const PaymentModal = ({ item, id, handleView, handlerPayList, pageNumber, pageSize, globalSearch, type }) => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [disable, setDisable] = useState(false)
  const [loading, setLoading] = useState(false);
  const [actualPaymentAmount, setActualPaymentAmount] = useState(item?.RemainingAmount || item?.Total || "");
  const modalContext = useContext(ModalContext);
  const { handleModalData } = modalContext;
  const { closeModal } = modalContext;

  const handleMinDate = () => {
    const minDate = new Date(item?.BillDate);
    minDate.setDate(minDate.getDate());
    const minDateFormatted = minDate.toISOString().split("T")[0];
    const minDate2 = new Date();
    minDate2.setDate(minDate2.getDate() - 7);
    const minDateFormatted2 = minDate2.toISOString().split("T")[0];

    if (minDate < minDate2) {
      return minDateFormatted2;
    } else {
      return minDateFormatted;
    }
  };

  const maxDateFormatted = new Date().toISOString().split("T")[0];
  const [selectedDate, setSelectedDate] = useState(maxDateFormatted);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    setErrors((prev) => (
      {
        ...prev,
        paymentDate: ""
      }));
  };

  const handleAmountChange = (event) => {
    const { value } = event.target;
    if (DECIMAL_OPTIONAL_TWO_PLACES_REGX.test(value)) {
      setActualPaymentAmount(value);
      setErrors((prev) => (
        {
          ...prev,
          paymentAmount: ""
        }));
    }
  };

  const handlePayment = async () => {
    try {
      setDisable(true);
      setLoading(true)
      const result = await postApi(PAY_PAYMENT, {
        paymentId: item?.PaymentId,
        paymentAmount: Number(actualPaymentAmount),
        paymentDate: selectedDate,
        useId: user.id,
        username: user.Name
      });
      if (result.res.success) {
        toast.success(result.res.message);
        if (handlerPayList) {
          handlerPayList(pageNumber, pageSize, globalSearch);
        } else if (handleView) {
          handleView(id);
        }
        closeModal();
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        if (result.res.paymentDatemessage) {
          errors.paymentDate = result.res.paymentDatemessage;
        }
        if (result.res.voidMessage) {
          let alert = <Alert message={result.res.voidMessage} />;
          handleModalData(alert, "md");
        }
        if (result.res.holdMessage) {
          let alert = <Alert message={result.res.holdMessage} />;
          handleModalData(alert, "md");
        }
        if (result.res.message) {
          errors.paymentAmount = result.res.message;
        }
      }
    } catch (error) {
      setDisable(false);
      setLoading(false)
    } finally {
      setDisable(false);
      setLoading(false)
    }
  };

  return (
    <div className="cstm-frm-mdl">
      <div className="modal-header d-flex align-item-center justify-content-between">
        <h4 className="modal-title">
          Pay Now
        </h4>

        {type === 'list' &&
          <>
            {item?.IndividualBill ?
              <Link to={`/view-individual-bill/${item.InvoiceId}`} target="_blank" className="text-white text-decoration-underline">
                {"View Bill >"}
              </Link> :
              <Link to={`/view-bill/${item.InvoiceId}`} target="_blank" className="text-white text-decoration-underline">
                {"View Bill >"}
              </Link>
            }
          </>
        }
      </div>

      <div className="modal-body">
        <div className="mb-20">
          <label htmlFor="VendorName" className="form-label">
            Vendor Name
          </label>
          <input
            type="Text"
            className="form-control"
            id="VendorName"
            value={item?.VendorName || item?.LegalName}
            disabled
          />
        </div>
        <div className="mb-20">
          <label htmlFor="VendorName" className="form-label">
            Amount
          </label>
          <input
            type="text"
            className="form-control"
            id="Amount"
            value={`${item?.CurrencySymbol} ${item.Total?.toFixed(2)}`}
            disabled
          />
        </div>

        <div className="row">
          <div className="col-md-6 mb-20">
            <label htmlFor="ActualPaymentAmount" className="form-label">
              Actual Payment Amount
            </label>
            <input
              type="text"
              className="form-control"
              id="ActualPaymentAmount"
              value={`${item?.CurrencySymbol} ${actualPaymentAmount?.toFixed(2)}`}
              onChange={handleAmountChange}
              disabled
            />
            {errors && <div className="validation-error">{errors.paymentAmount}</div>}
          </div>
          <div className="col-md-6 mb-20">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Payment Date
            </label>
            <input
              type="date"
              id="dateInput"
              className="form-control"
              name="dateInput"
              min={handleMinDate()}
              max={maxDateFormatted}
              value={selectedDate}
              onChange={handleDateChange}
            />
            {errors && <div className="validation-error">{errors.paymentDate}</div>}
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <div className=" d-flex align-items-center mb-20">
          <div className="danger-outline-btn">
            <button type="button" className="btn" onClick={() => closeModal()}>
              Cancel
            </button>
          </div>
          <div className="success-btn ms-2">
            <button
              type="submit"
              disabled={disable}
              className="btn"
              onClick={handlePayment}
            >
              {loading ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
                </>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
