import { useState } from 'react';
import { validateEmail, validateUserType } from '../utils/helpers/globalValidation';

const ForgetFormValidation = () => {
  const [errors, setErrors] = useState({});

  const validateForm = (loginType, email) => {
    const _errors = {};
    const roleType = validateUserType(loginType);
    if (roleType) {
      _errors.userType = roleType;
    }

    const emailError = validateEmail(email);
    if (emailError) {
      _errors.email = emailError;
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  };

  return { errors, setErrors, validateForm };
};

export default ForgetFormValidation;