import React, { useEffect, useState, useRef } from "react";
import BackButton from "../../../assets/image/BackButton";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { VENDORID } from "../../../utils/constant";
import { Helmet } from "react-helmet";
import moment from "moment";
import DeleteIcon from "../../../assets/image/DeleteIcon";
import { useTransactionContext } from "../../../utils/helpers/useTransaction";
import AddIndividuallBillValidation from "../../../middleware/addIndividuallBillValidation";
import { HAS_DECIMAL_VALUE_REGX, NON_NUMERIC_DOT_REGX, NON_DIGIT_REGX } from "../../../utils/helpers/globalRegx";
import { fetchItemDetails, fetchVendorData, handleCheckboxChange, handleDepartmentList, handleDescriptionChangeCommon, handleDiscountChange, handleFileInputChange, handleList, handleRemoveItem } from "../../../utils/helpers/globalMethod";
import { uploadFile } from "../../../utils/helpers/useFileUpload";
import Swal from "sweetalert2";
import { postApi } from "../../../utils/services";
import { CREATE_INDIVIDUAL_BILL } from "../../../utils/apiRoutes";

const IndividualBill = () => {

  const user = JSON.parse(localStorage.getItem("userData"));

  const [formState, setFormState] = useState({
    vendorId: user.RoleId === VENDORID ? user.id : '',
    refNo: '',
    billDate: '',
    paymentTypeId: '',
    departmentId: ''
  })

  const [itemDescription, setItemDescription] = useState([{
    itemId: '',
    description: '',
    rate: '',
    quantity: '',
    totalAmount: 0
  }])

  const [itemDescriptionLineLevel, setItemDescriptionLineLevel] = useState([{
    itemId: '',
    description: '',
    rate: '',
    quantity: '',
    cgst: '',
    sgst: '',
    utgst: '',
    igst: '',
    gstAmount: 0,
    totalAmount: 0
  }])

  const [tax, setTax] = useState({
    cgst: '',
    sgst: '',
    utgst: '',
    igst: ''
  })

  const navigate = useNavigate();
  const transactionList = useTransactionContext();
  const [vendorList, setVendorList] = useState([]);
  const [vendorName, setVendorName] = useState('')
  const [departmentList, setDepartmentList] = useState([])
  const [dueDate, setDueDate] = useState('')
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [termCount, setTermCount] = useState('');
  const [vendorItems, setVendorItems] = useState([]);
  const [lineLevelCheck, setLineLevelCheck] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [gstTotal, setGstTotal] = useState(0)
  const [grandTotal, setGrandTotal] = useState(0)
  const [subTotalLineLevel, setSubTotalLineLevel] = useState(0);
  const [gstTotalLineLevel, setGstTotalLineLevel] = useState(0)
  const [grandTotalLineLevel, setGrandTotalLineLevel] = useState(0)
  const [attachDocuments, setAttachDocuments] = useState(false)
  const [individualBillDocument, setIndividualBillDocument] = useState([])
  const [loader, setLoader] = useState(false)
  const [disable, setDisable] = useState(false)
  const { errors, setErrors, validateForm } = AddIndividuallBillValidation();
  const [discountAmount, setDiscountAmount] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState('');
  const fileInputRef = useRef(null);
  const minBillDate = moment().subtract(30, 'days')

  useEffect(() => {
    const fetchList = async () => {
      await handleList(setVendorList);
      await handleDepartmentList(setDepartmentList);
    };
    fetchList();
  }, []);

  useEffect(() => {
    setDiscountAmount('');
    setDiscountPercentage('');
  }, [subTotal]);


  useEffect(() => {
    const calculateTotal = () => {
      let subTotal = 0;
      let gstTotal = 0;
      let grandTotal = 0;
      if (!lineLevelCheck) {
        subTotal = itemDescription.reduce((acc, item) => acc + item.totalAmount, 0);
        setSubTotal(subTotal);
        setErrors(prevErrors => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors.subTotal;
          return updatedErrors;
        });
        const cgst = parseFloat(tax.cgst) || 0;
        const sgst = parseFloat(tax.sgst) || 0;
        const utgst = parseFloat(tax.utgst) || 0;
        const igst = parseFloat(tax.igst) || 0;
        const gstPercentage = cgst + sgst + utgst + igst;
        const discountAmountParsed = parseFloat(discountAmount) || 0;
        const gstWithDiscount = subTotal - discountAmountParsed;
        gstTotal = gstWithDiscount * (gstPercentage / 100);
        grandTotal = gstWithDiscount + gstTotal;
        setGstTotal(parseFloat(gstTotal?.toFixed(2)));
        setGrandTotal(parseFloat(grandTotal?.toFixed(2)));
        setDiscountPercentage(discountPercentage);
      } else {
        subTotal = itemDescriptionLineLevel.reduce((acc, item) => acc + item.totalAmount, 0);
        setSubTotalLineLevel(subTotal);
        setErrors(prevErrors => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors.subTotalLineLevel;
          return updatedErrors;
        });
        gstTotal = parseFloat(itemDescriptionLineLevel.reduce((acc, item) => acc + item.gstAmount, 0).toFixed(2));
        grandTotal = subTotal + gstTotal;
        setGstTotalLineLevel(gstTotal);
        setGrandTotalLineLevel(parseFloat(grandTotal?.toFixed(2)));
        const discountAmountParsed = parseFloat(discountAmount) || null;
        const discountPercentage = subTotal ? ((discountAmountParsed / subTotal) * 100).toFixed(2) : "";
        setDiscountPercentage(discountPercentage);
      }
    };

    calculateTotal();
  }, [itemDescription, itemDescriptionLineLevel, tax, lineLevelCheck, discountAmount]);


  const onCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    handleCheckboxChange(isChecked, setAttachDocuments, setIndividualBillDocument, setErrors, fileInputRef);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchVendorData(formState.vendorId, null, null, setCurrencySymbol, setVendorItems, setTermCount, setDueDate);
    };
    fetchData();
  }, [formState.vendorId]);

  const handleLineLevelCheck = (e) => {
    const check = e.target.checked;
    setLineLevelCheck(check);
  };

  const handleChange = (e) => {
    const { name, value } = e.target

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[name];
      return updatedErrors;
    });

    setFormState({
      ...formState,
      [name]: value,
    })

    if (name === 'vendorId') {
      if (value) {
        let vendorData = vendorList.find((i) => i.id == value);
        const words = vendorData.LegalName.split(" ");
        const vendorName = words[0].trim();
        const vendorNameLowerCase = vendorName.toLowerCase();
        setVendorName(vendorNameLowerCase);
      }
    }

    if (name === 'billDate') {
      const date = value;
      const updatedDueDate = new Date(date);
      updatedDueDate.setDate(updatedDueDate.getDate() + parseInt(termCount));
      setDueDate(updatedDueDate.toISOString().split("T")[0]);
    }

  };

  const handleItemDescriptionChange = async (e, index) => {
    const { name, value } = e.target;

    let newValue;
    if (name === "rate") {
      newValue = value.replace(NON_NUMERIC_DOT_REGX, "");
      const parts = newValue.split('.');

      if (parts.length > 2) {
        newValue = parts.shift() + '.' + parts.join('');
      }

      const dotindex = newValue.indexOf(".");
      if (dotindex !== -1 && newValue.length - dotindex > 3) {
        newValue = newValue.slice(0, dotindex + 3);
      }
    }
    else if (name === "quantity") {
      newValue = value.replace(NON_DIGIT_REGX, "");
    }
    else {
      newValue = value;
    }

    const updatedItemDescription = [...itemDescription];
    updatedItemDescription[index][name] = newValue;

    if (name === "itemId" && value) {
      const description = await fetchItemDetails(value);

      updatedItemDescription[index] = {
        ...updatedItemDescription[index],
        description: description || ''
      };
    }

    const rate = updatedItemDescription[index].rate;
    const quantity = updatedItemDescription[index].quantity;

    const totalAmount = (rate * quantity)
    updatedItemDescription[index].totalAmount = totalAmount;

    setItemDescription(updatedItemDescription)

    const updatedErrors = { ...errors };
    if (updatedErrors.itemDescription && updatedErrors.itemDescription[index]) {
      delete updatedErrors.itemDescription[index][name];
    }

    setErrors(updatedErrors);
  };

  const handleItemDescriptionLineLevelChange = async (e, index) => {
    const { name, value } = e.target;

    let newValue;
    if (name === "rate") {
      newValue = value.replace(/[^0-9.]/g, '');

      const parts = newValue.split('.');
      if (parts.length > 2) {
        newValue = parts.shift() + '.' + parts.join('');
      }
      const dotIndex = newValue.indexOf(".");
      if (dotIndex !== -1 && newValue.length - dotIndex > 3) {
        newValue = newValue.slice(0, dotIndex + 3);
      }
    } else if (name === "quantity") {
      newValue = value.replace(/[^0-9]/g, '');
      newValue = newValue.split('.')[0];
    } else if (["cgst", "sgst", "utgst", "igst"].includes(name)) {
      newValue = value.replace(NON_NUMERIC_DOT_REGX, "");

      const parts = newValue.split('.');
      if (parts.length > 2) {
        newValue = parts.shift() + '.' + parts.join('');
      }
      const dotIndex = newValue.indexOf(".");
      if (dotIndex !== -1 && newValue.length - dotIndex > 3) {
        newValue = newValue.slice(0, dotIndex + 3);
      }

      const isValid = parseFloat(newValue) >= 0 && parseFloat(newValue) <= 100;
      const hasValidDecimal = HAS_DECIMAL_VALUE_REGX.test(newValue);

      if (!isValid || !hasValidDecimal) {
        newValue = newValue.slice(0, -1);
      }
    } else {
      newValue = value;
    }

    const updatedItemDescription = [...itemDescriptionLineLevel];
    updatedItemDescription[index][name] = newValue;

    if (name === "itemId" && value) {
      const description = await fetchItemDetails(value);

      updatedItemDescription[index] = {
        ...updatedItemDescription[index],
        description: description || ''
      };
    }

    const rate = parseFloat(updatedItemDescription[index].rate) || 0;
    const quantity = parseFloat(updatedItemDescription[index].quantity) || 0;

    const totalAmount = rate * quantity;
    updatedItemDescription[index].totalAmount = totalAmount;

    const cgst = parseFloat(updatedItemDescription[index].cgst) || 0;
    const sgst = parseFloat(updatedItemDescription[index].sgst) || 0;
    const utgst = parseFloat(updatedItemDescription[index].utgst) || 0;
    const igst = parseFloat(updatedItemDescription[index].igst) || 0;

    const gstPercentage = cgst + sgst + utgst + igst;
    const gstAmount = parseFloat((totalAmount * (gstPercentage / 100)).toFixed(2));
    updatedItemDescription[index].gstAmount = gstAmount;

    setItemDescriptionLineLevel(updatedItemDescription);

    const updatedErrors = { ...errors };
    if (updatedErrors.itemDescriptionLineLevel && updatedErrors.itemDescriptionLineLevel[index]) {
      delete updatedErrors.itemDescriptionLineLevel[index][name];
    }

    setErrors(updatedErrors);
  };

  const handleAdd = () => {

    setItemDescription([...itemDescription, {
      itemId: '',
      description: '',
      rate: '',
      quantity: '',
      totalAmount: 0
    }])

  };

  const handleAddLineLevel = () => {

    setItemDescriptionLineLevel([...itemDescriptionLineLevel, {
      itemId: '',
      description: '',
      rate: '',
      quantity: '',
      cgst: '',
      sgst: '',
      utgst: '',
      igst: '',
      gstAmount: 0,
      totalAmount: 0
    }])

  };

  const handleRemove = (index) => {
    handleRemoveItem(index, itemDescription, setItemDescription, errors, setErrors);
  };

  const handleRemoveLineLevel = (index) => {

    if (itemDescriptionLineLevel.length === 1) {
      alert("Atleast one item should be there");
      return;
    }

    const updatedItemDescription = [...itemDescriptionLineLevel];
    updatedItemDescription.splice(index, 1);

    setItemDescriptionLineLevel(updatedItemDescription)

    const updatedErrors = { ...errors };
    if (updatedErrors.itemDescriptionLineLevel && updatedErrors.itemDescriptionLineLevel.length > index) {
      updatedErrors.itemDescriptionLineLevel.splice(index, 1);
    }

    setErrors(updatedErrors);
  };

  const onDiscountChange = (e) => {
    handleDiscountChange(e, subTotal, setErrors, setDiscountAmount, setDiscountPercentage);
  };

  const handleTax = (e) => {
    const { name, value } = e.target

    const isValid = value >= 0 && value <= 100;
    const hasValidDecimal = HAS_DECIMAL_VALUE_REGX.test(value);

    if (isValid && hasValidDecimal) {
      setTax({
        ...tax,
        [name]: value,
      })
    }

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors }
      delete updatedErrors.tax
      return updatedErrors
    })
  }

  const handleFileChange = (e) => {
    handleFileInputChange(e, setIndividualBillDocument, setErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const isVendorIdPresent = vendorList.some(item => item.id == user.id);

      const isValid = validateForm(formState.vendorId, formState.billDate, user.RoleId, formState.departmentId, individualBillDocument, itemDescription, tax, itemDescriptionLineLevel, subTotal, subTotalLineLevel, lineLevelCheck, isVendorIdPresent);

      if (isValid) {

        setLoader(true);
        setDisable(true);

        let individualBillDocumentArray = [];

        const fileUploadPromises = individualBillDocument.map(async (file) => {
          const result = await uploadFile(
            "individualbills",
            vendorName,
            formState.vendorId,
            file
          );
          const singlefile = {
            FileName: result.fileName,
            FilePath: result.filePath,
          };
          individualBillDocumentArray.push(singlefile);
        });

        await Promise.all(fileUploadPromises);

        let updatedItemDescription;

        if (lineLevelCheck) {
          updatedItemDescription = itemDescriptionLineLevel.map((row) => ({
            itemId: Number(row.itemId),
            description: row.description || null,
            rate: Number(row.rate),
            quantity: Number(row.quantity),
            cgst: row.cgst ? Number(row.cgst) : null,
            sgst: row.sgst ? Number(row.sgst) : null,
            utgst: row.utgst ? Number(row.utgst) : null,
            igst: row.igst ? Number(row.igst) : null,
            gstAmount: row.gstAmount,
            totalAmount: row.totalAmount
          }));
        } else {
          updatedItemDescription = itemDescription.map((row) => ({
            itemId: Number(row.itemId),
            description: row.description || null,
            rate: Number(row.rate),
            quantity: Number(row.quantity),
            totalAmount: row.totalAmount
          }));
        }

        const result = await postApi(CREATE_INDIVIDUAL_BILL, {
          vendorId: Number(formState.vendorId),
          userId: Number(user.id),
          username: user.LegalName || user.Name,
          transactionId: transactionList[4].id,
          roleId: user.RoleId,
          referenceNo: formState.refNo,
          departmentId: formState.departmentId ? Number(formState.departmentId) : null,
          billDate: formState.billDate,
          dueDate: dueDate,
          paymentTypeId: 2,
          isGstInclude: false,
          isLineLevelGst: lineLevelCheck,
          itemDescription: updatedItemDescription,
          individualBillDocument: individualBillDocumentArray,
          cgst: lineLevelCheck ? null : tax.cgst ? Number(tax.cgst) : null,
          sgst: lineLevelCheck ? null : tax.sgst ? Number(tax.sgst) : null,
          utgst: lineLevelCheck ? null : tax.utgst ? Number(tax.utgst) : null,
          igst: lineLevelCheck ? null : tax.igst ? Number(tax.igst) : null,
          individualBillTotalAmount: lineLevelCheck ? subTotalLineLevel : subTotal,
          gstTotal: lineLevelCheck ? gstTotalLineLevel : gstTotal,
          grandTotal: lineLevelCheck ? grandTotalLineLevel : grandTotal,
          discount: parseFloat(discountAmount),
          discountPercentage: parseFloat(discountPercentage)
        });
        if (result.res.success) {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: result.res.message,
            showConfirmButton: false,
            timer: 2000,
          });
          navigate("/individual-bill-list");
        }
        else {
          setLoader(false);
          setDisable(false);
        }
      }
    } catch (error) {
      setLoader(false);
      setDisable(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Standalone Bill</title>
      </Helmet>

      <div className="container-section">
        <div className="section-header">
          <button onClick={() => navigate(-1)} className="back-viewbtn me-2">
            <div className="svgsize">
              <BackButton />
            </div>
          </button>
          <h3>Standalone Bill</h3>
        </div>

        <form>

          <div className="form-style">
            <div className="row">

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="vendor" className="form-label">
                  Vendor<span className="required">*</span>
                </label>

                <select id="vendorId" className="form-select" name="vendorId" value={formState.vendorId}
                  onChange={handleChange} disabled={user.RoleId === VENDORID}
                >
                  <option value="">Select</option>
                  {vendorList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.LegalName}
                    </option>
                  ))}
                </select>
                {errors.vendorId && (
                  <span className="error-text">{errors.vendorId}</span>
                )}
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="refNo" className="form-label">
                  Reference Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter reference number"
                  name="refNo"
                  value={formState.refNo}
                  onChange={handleChange}
                />
              </div>

              {(user.RoleId !== VENDORID) && (

                <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                  <label htmlFor="departmentId" className="form-label">
                    Department<span className="required">*</span>
                  </label>
                  <select name="departmentId" id="departmentId" className="form-select" value={formState.departmentId} onChange={handleChange}>
                    <option value="">Select</option>
                    {departmentList.map((item, index) => (
                      <option key={index} value={item.DepartmentId}>
                        {item.DepartmentName}
                      </option>
                    ))}
                  </select>
                  {errors.departmentId && <span className="error-text">{errors.departmentId}</span>}
                </div>

              )}

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="billDate" className="form-label">
                  Bill Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  min={minBillDate.format('YYYY-MM-DD')}
                  className="form-control"
                  value={formState.billDate}
                  name="billDate"
                  onChange={handleChange}
                  onKeyDown={(e) => e.preventDefault()}
                  disabled={!formState.vendorId}
                />
                {errors.billDate && (
                  <span className="error-text">{errors.billDate}</span>
                )}
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="dueDate" className="form-label">
                  Due Date
                </label>
                <input
                  type="date"
                  name="dueDate"
                  id="dueDate"
                  className="form-control"
                  value={dueDate}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="form-style">
            <div className="col-xl-3 col-lg-4 col-md-3 mb-2">
              <div className="d-flex algin-item-center">
                <input
                  type="checkbox"
                  checked={lineLevelCheck}
                  className="form-check-input"
                  onChange={(e) => handleLineLevelCheck(e)}
                />
                <label className="ms-2 form-check-label">Line Level GST</label>
              </div>
            </div>
            <div className="">
              <div className="section-sub-heading">
                <h3>Item Description</h3>
              </div>
              <div className="pur-item-list table-responsive tbl-body-shadow">
                <table className="w-100 table table-theme-1 mb-0">

                  {!lineLevelCheck ?
                    <thead className="table-heads">
                      <tr>
                        <th className="text-center">Item</th>
                        <th className="text-center">Description</th>
                        <th className="text-center">Rate</th>
                        <th className="text-center">Quantity</th>
                        <th className="text-center">Amount</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    :
                    <thead className="table-heads">
                      <tr>
                        <th className="text-center">Item</th>
                        <th className="text-center">Description</th>
                        <th className="text-center">Rate</th>
                        <th className="text-center">Quantity</th>
                        <th className="text-center">CGST(%)</th>
                        <th className="text-center">SGST(%)</th>
                        <th className="text-center">UTGST(%)</th>
                        <th className="text-center">IGST(%)</th>
                        <th className="text-center">GST Amount</th>
                        <th className="text-center">Amount</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                  }

                  {!lineLevelCheck ?
                    <tbody>
                      {itemDescription.map((item, index) => (
                        <tr key={index}>
                           <td className="text-nowrap vertical-align" style={{width: '200px', minWidth: '200px' }}>
                            <div className="position-relative">
                              <select className="form-select mb-20" name="itemId"
                                value={item.itemId}
                                onChange={(e) => handleItemDescriptionChange(e, index)}
                              >
                                <option value="">Select</option>
                                {formState.vendorId && vendorItems.map((item, index) => (
                                  <option key={index} value={item.itemId}>
                                    {item.itemname}
                                  </option>
                                ))}
                              </select>
                              {errors.itemDescription &&
                                errors.itemDescription[index] &&
                                errors.itemDescription[index].itemId && (
                                  <span className="error-text">
                                    {errors.itemDescription[index].itemId}
                                  </span>
                                )}
                            </div>
                          </td>
                          <td className="align-top" style={{ width: '220px', minWidth: '220px' }}>
                            <div className="position-relative">
                              <textarea name="description" className="form-control" rows="1" cols="20" value={item.description}
                                onChange={(e) => handleDescriptionChangeCommon(e,
                                  index,
                                  'itemDescription',
                                  itemDescription,
                                  itemDescriptionLineLevel,
                                  errors,
                                  setItemDescription,
                                  setItemDescriptionLineLevel,
                                  setErrors)}>
                              </textarea>
                              {errors.itemDescription &&
                                errors.itemDescription[index] &&
                                errors.itemDescription[index].description && (
                                  <span className="error-text">
                                    {errors.itemDescription[index].description}
                                  </span>
                                )}
                            </div>
                          </td>
                          <td className="" style={{ minWidth: '120px' }}>
                            <div className="position-relative">
                              <input type="text" className="form-control text-end mb-20" name="rate"
                                autocomplete="off"
                                placeholder="Rate"
                                value={item.rate}
                                onChange={(e) => handleItemDescriptionChange(e, index)}
                              >
                              </input>
                              {errors.itemDescription &&
                                errors.itemDescription[index] &&
                                errors.itemDescription[index].rate && (
                                  <span className="error-text">
                                    {errors.itemDescription[index].rate}
                                  </span>
                                )}
                            </div>
                          </td>
                          <td className="" style={{ minWidth: '120px' }}>
                            <div className="position-relative">
                              <input type="text" className="form-control text-end mb-20" name="quantity"
                                autocomplete="off"
                                placeholder="Quantity"
                                value={item.quantity}
                                onChange={(e) => handleItemDescriptionChange(e, index)}
                              >
                              </input>
                              {errors.itemDescription &&
                                errors.itemDescription[index] &&
                                errors.itemDescription[index].quantity && (
                                  <span className="error-text">
                                    {errors.itemDescription[index].quantity}
                                  </span>
                                )}
                            </div>
                          </td>
                          <td className="" style={{ minWidth: '120px' }}>
                            <div className="position-relative">
                              <input type="text" className="form-control text-end mb-20" name="totalAmount"
                                value={item.totalAmount.toFixed(2)}
                                disabled
                              >
                              </input>
                            </div>
                          </td>
                          <td className="" style={{ minWidth: '120px' }}>
                            <div className="cursor-pointer d-flex align-items-center justify-content-center mb-20">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip
                                    className="custom-tooltip"
                                    id="tooltips1"
                                  >
                                    Delete
                                  </Tooltip>
                                }
                              ><span
                                className=""
                                onClick={(e) => handleRemove(index)}
                              >
                                  <DeleteIcon />
                                </span>
                              </OverlayTrigger>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    :
                    <tbody>
                      {itemDescriptionLineLevel.map((item, index) => (
                        <tr key={index * 20 + 1}>
                          <td className="" style={{ width: '200px', minWidth: '200px' }}>
                            <div className="position-relative">
                              <select className="form-select mb-20" name="itemId"
                                value={item.itemId}
                                onChange={(e) => handleItemDescriptionLineLevelChange(e, index)}
                              >
                                <option value="">Select</option>
                                {formState.vendorId && vendorItems.map((item, index) => (
                                  <option key={index} value={item.itemId}>
                                    {item.itemname}
                                  </option>
                                ))}
                              </select>
                              {errors.itemDescriptionLineLevel &&
                                errors.itemDescriptionLineLevel[index] &&
                                errors.itemDescriptionLineLevel[index].itemId && (
                                  <span className="error-text">
                                    {errors.itemDescriptionLineLevel[index].itemId}
                                  </span>
                                )}
                            </div>
                          </td>
                          <td className="" style={{ width: '220px', minWidth: '220px' }}>
                            <div className="position-relative">
                              <textarea name="description" className="form-control" rows="1" cols="20" value={item.description}
                                onChange={(e) => handleDescriptionChangeCommon(e,
                                  index,
                                  'itemDescriptionLineLevel',
                                  itemDescription,
                                  itemDescriptionLineLevel,
                                  errors,
                                  setItemDescription,
                                  setItemDescriptionLineLevel,
                                  setErrors)}
                              />
                              {
                                errors.itemDescriptionLineLevel &&
                                errors.itemDescriptionLineLevel[index] &&
                                errors.itemDescriptionLineLevel[index].description && (
                                  <span className="error-text">
                                    {errors.itemDescriptionLineLevel[index].description}
                                  </span>
                                )
                              }
                            </div>
                          </td>
                          <td className="" style={{ minWidth: '120px' }}>
                            <div className="position-relative">
                              <input type="text" className="form-control text-end mb-20" name="rate"
                                autocomplete="off"
                                placeholder="Rate"
                                value={item.rate}
                                onChange={(e) => handleItemDescriptionLineLevelChange(e, index)}
                              >
                              </input>
                              {errors.itemDescriptionLineLevel &&
                                errors.itemDescriptionLineLevel[index] &&
                                errors.itemDescriptionLineLevel[index].rate && (
                                  <span className="error-text">
                                    {errors.itemDescriptionLineLevel[index].rate}
                                  </span>
                                )}
                            </div>
                          </td>
                          <td className="" style={{ minWidth: '120px' }}>
                            <div className="position-relative">
                              <input type="text" className="form-control text-end mb-20" name="quantity"
                                autocomplete="off"
                                placeholder="Quantity"
                                value={item.quantity}
                                onChange={(e) => handleItemDescriptionLineLevelChange(e, index)}
                              >
                              </input>
                              {errors.itemDescriptionLineLevel &&
                                errors.itemDescriptionLineLevel[index] &&
                                errors.itemDescriptionLineLevel[index].quantity && (
                                  <span className="error-text">
                                    {errors.itemDescriptionLineLevel[index].quantity}
                                  </span>
                                )}
                            </div>
                          </td>
                          <td className="" style={{ minWidth: '120px' }}>
                            <div className="position-relative">
                              <input type="text" className="form-control text-end mb-20" name="cgst"
                                autocomplete="off"
                                placeholder="CGST(%)"
                                value={item.cgst}
                                onChange={(e) => handleItemDescriptionLineLevelChange(e, index)}
                                disabled={item.utgst || item.igst}
                              >
                              </input>
                              {errors.itemDescriptionLineLevel &&
                                errors.itemDescriptionLineLevel[index] &&
                                errors.itemDescriptionLineLevel[index].cgst && (
                                  <span className="error-text">
                                    {errors.itemDescriptionLineLevel[index].cgst}
                                  </span>
                                )}
                            </div>
                          </td>
                          <td className="" style={{ minWidth: '120px' }}>
                            <div className="position-relative">
                              <input type="text" className="form-control text-end mb-20" name="sgst"
                                autocomplete="off"
                                placeholder="SGST(%)"
                                value={item.sgst}
                                onChange={(e) => handleItemDescriptionLineLevelChange(e, index)}
                                disabled={item.utgst || item.igst}
                              >
                              </input>
                              {errors.itemDescriptionLineLevel &&
                                errors.itemDescriptionLineLevel[index] &&
                                errors.itemDescriptionLineLevel[index].sgst && (
                                  <span className="error-text">
                                    {errors.itemDescriptionLineLevel[index].sgst}
                                  </span>
                                )}
                            </div>
                          </td>
                          <td className="" style={{ minWidth: '120px' }}>
                            <div className="position-relative">
                              <input type="text" className="form-control text-end mb-20" name="utgst"
                                autocomplete="off"
                                placeholder="UTGST(%)"
                                value={item.utgst}
                                onChange={(e) => handleItemDescriptionLineLevelChange(e, index)}
                                disabled={item.cgst || item.sgst || item.igst}
                              >
                              </input>
                              {errors.itemDescriptionLineLevel &&
                                errors.itemDescriptionLineLevel[index] &&
                                errors.itemDescriptionLineLevel[index].utgst && (
                                  <span className="error-text">
                                    {errors.itemDescriptionLineLevel[index].utgst}
                                  </span>
                                )}
                            </div>
                          </td>
                          <td className="" style={{ minWidth: '120px' }}>
                            <div className="position-relative">
                              <input type="text" className="form-control text-end mb-20" name="igst"
                                autocomplete="off"
                                placeholder="IGST(%)"
                                value={item.igst}
                                onChange={(e) => handleItemDescriptionLineLevelChange(e, index)}
                                disabled={item.cgst || item.sgst || item.utgst}
                              >
                              </input>
                              {errors.itemDescriptionLineLevel &&
                                errors.itemDescriptionLineLevel[index] &&
                                errors.itemDescriptionLineLevel[index].igst && (
                                  <span className="error-text">
                                    {errors.itemDescriptionLineLevel[index].igst}
                                  </span>
                                )}
                            </div>
                          </td>
                          <td className="" style={{ minWidth: '120px' }}>
                            <div className="position-relative">
                              <input type="text" className="form-control text-end mb-20" name="gstAmount"
                                autocomplete="off"
                                value={item.gstAmount.toFixed(2)}
                                disabled
                              >
                              </input>
                            </div>
                          </td>
                          <td className="" style={{ minWidth: '120px' }}>
                            <div className="position-relative">
                              <input type="text" className="form-control text-end mb-20" name="totalAmount"
                                value={item.totalAmount.toFixed(2)}
                                disabled
                              >
                              </input>
                            </div>
                          </td>
                          <td className="" style={{ minWidth: '120px' }}>
                            <div className="cursor-pointer d-flex align-items-center justify-content-center mb-20">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip
                                    className="custom-tooltip"
                                    id="tooltips1"
                                  >
                                    Delete
                                  </Tooltip>
                                }
                              ><span
                                className=""
                                onClick={(e) => handleRemoveLineLevel(index)}
                              >
                                  <DeleteIcon />
                                </span>
                              </OverlayTrigger>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  }

                </table>
              </div>
            </div>

            {!lineLevelCheck ?
              <div className="Addmoreicon">
                <button type="button" className="addIcon" onClick={handleAdd}>
                  <p>+</p>
                </button>
              </div>
              :
              <div className="Addmoreicon">
                <button type="button" className="addIcon" onClick={handleAddLineLevel}>
                  <p>+</p>
                </button>
              </div>
            }

            {!lineLevelCheck ?
              <div className="d-flex justify-content-end inv-gst-tbl-cont mb-3 mt-4">
                <div className="GST-bill-width">

                  <div className="d-flex align-items-center mb-3">
                    <span className="gst-cont-label">Sub Total</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        type="text"
                        value={`${currencySymbol} ${subTotal.toFixed(2)}`}
                        className="form-control text-end"
                        disabled
                      />
                      {errors.subTotal && (
                        <div className="validation-error">
                          {errors.subTotal}
                        </div>
                      )}
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <span className="gst-cont-label">Discount</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        className="form-control text-end"
                        type="text"
                        id="discount"
                        name="discount"
                        placeholder="Enter Discount"
                        value={discountAmount}
                        onChange={onDiscountChange}
                        disabled={parseFloat(subTotal) === 0}
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <span className="gst-cont-label">Discount(%)</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        className="form-control text-end"
                        type="text"
                        id="discount"
                        name="discount"
                        placeholder="Discount (%)"
                        value={discountPercentage}
                        disabled
                      />
                    </span>
                  </div>

                  {discountAmount && (
                    <div className="d-flex align-items-center mb-3">
                      <span className="gst-cont-label">Net Amount</span>
                      <span className="gst-cont-semicolumn">:</span>
                      <span className="gst-cont-input">
                        <input
                          className="form-control text-end"
                          type="text"
                          id="discount"
                          name="discount"
                          placeholder="Enter Discount"
                          value={`${currencySymbol} ${(subTotal - discountAmount)?.toFixed(2)}`}
                          disabled
                        />
                      </span>
                    </div>
                  )}

                  <div className="d-flex align-items-center mb-2">
                    <span className="gst-cont-label">CGST(%)</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        type="text"
                        value={tax.cgst}
                        name="cgst"
                        className="form-control text-end"
                        placeholder="Enter CGST(%)"
                        onChange={handleTax}
                        disabled={tax.utgst || tax.igst}
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-2">
                    <span className="gst-cont-label">SGST(%)</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        type="text"
                        value={tax.sgst}
                        name="sgst"
                        className="form-control text-end"
                        placeholder="Enter SGST(%)"
                        onChange={handleTax}
                        disabled={tax.utgst || tax.igst}
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-2">
                    <span className="gst-cont-label">UTGST(%)</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        type="text"
                        value={tax.utgst}
                        name="utgst"
                        className="form-control text-end "
                        placeholder="Enter UTGST(%)"
                        onChange={handleTax}
                        disabled={tax.cgst || tax.sgst || tax.igst}
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <span className="gst-cont-label">IGST(%)</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        type="text"
                        value={tax.igst}
                        name="igst"
                        className="form-control text-end"
                        placeholder="Enter IGST(%)"
                        onChange={handleTax}
                        disabled={tax.cgst || tax.sgst || tax.utgst}
                      />
                      {errors.tax && (
                        <p className="validation-error">
                          {errors.tax}
                        </p>
                      )}
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-2">
                    <span className="gst-cont-label">GST Total</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        type="text"
                        value={`${currencySymbol} ${gstTotal.toFixed(2)}`}
                        className="form-control text-end"
                        disabled
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-2">
                    <span className="gst-cont-label">Grand Total</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        type="text"
                        value={`${currencySymbol} ${grandTotal.toFixed(2)}`}
                        className="form-control text-end"
                        disabled
                      />
                    </span>
                  </div>

                </div>
              </div>
              :
              <div className="d-flex justify-content-end inv-gst-tbl-cont mb-3 mt-4">
                <div className="GST-bill-width">

                  <div className="d-flex align-items-center mb-3">
                    <span className="gst-cont-label">Sub Total</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        type="text"
                        value={`${currencySymbol} ${subTotalLineLevel.toFixed(2)}`}
                        className="form-control text-end"
                        disabled
                      />
                      {errors.subTotalLineLevel && (
                        <div className="validation-error">
                          {errors.subTotalLineLevel}
                        </div>
                      )}
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-2">
                    <span className="gst-cont-label">GST Total</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        type="text"
                        value={`${currencySymbol} ${gstTotalLineLevel.toFixed(2)}`}
                        className="form-control text-end"
                        disabled
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-2">
                    <span className="gst-cont-label">Grand Total</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        type="text"
                        value={`${currencySymbol} ${grandTotalLineLevel.toFixed(2)}`}
                        className="form-control text-end"
                        disabled
                      />
                    </span>
                  </div>

                </div>
              </div>

            }

            <div className="cstm-checkbox mb-2">
              <div className="cstm-checkbox-inner d-flex align-items-center">
                <input
                  className="form-check-input m-0"
                  type="checkbox"
                  checked={attachDocuments}
                  onChange={onCheckboxChange}
                />
                <div className="cstm-checkbox-inr-lbl-field d-flex align-items-center">
                  <label htmlFor="" className="ms-2 form-check-label">
                    Check for relevant document
                  </label>
                  <span className="required">*</span>
                  <span className=" infoIcon"></span>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-xl-4 col-lg-4 col-md-5 position-relative">
                <div className="input-group file-input-container">
                  <input
                    type="file"
                    id="files"
                    className="form-control"
                    name="files"
                    disabled={!attachDocuments}
                    onChange={handleFileChange}
                    multiple
                    ref={fileInputRef}
                  />
                </div>
                {errors.files && (
                  <p className="validation-error">{errors.files}</p>
                )}
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-end mb-20">

              <div className="success-btn ms-2">
                <button
                  type="button"
                  className="btn"
                  onClick={(e) => handleSubmit(e)}
                  disabled={disable}
                >
                  {loader ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>

            </div>

          </div>

        </form >
      </div >
    </>
  );
};

export default IndividualBill;
