import React, { useEffect, useState } from 'react';
import { getApi } from '../../../utils/services';
import { BILLS_API } from '../../../utils/apiRoutes';
import { useFeedsColumnIndividualBills } from '../../../utils/helpers/tableHelpers/useFeedColumnIndividualBill';
import IndividualBill from '../../Table/IndividualBill';
import { Helmet } from "react-helmet";
import Loader from '../../common/Loader';
import { VENDORID } from '../../../utils/constant';
import { useLocation, useNavigate } from 'react-router-dom';

const IndividualBillList = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem("userData"));
    const [individualBill, setIndividualBills] = useState([]);
    const [loader, setLoader] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [globalSearch, setGlobalSearch] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const pageSize = 10;
    const storedStatusId = location.state?.billStatusId || ''
    const [billStatusId, setBillStatusId] = useState(storedStatusId);
    const [statusQuery, setStatusQuery] = useState(storedStatusId);
    const [holdBillCount, setHoldBillCount] = useState(0);

    const handlerIndividualBillList = async (pageNumber, pageSize, searchQuery, statusQuery) => {
        try {
            let routeName;
            if (user.RoleId === VENDORID) {
                routeName = `${BILLS_API}?billType=1&vendorId=${user.id}&page=${pageNumber}&pageSize=${pageSize}&globalSearch=${searchQuery}&billStatusId=${statusQuery}`;
            } else {
                routeName = `${BILLS_API}?billType=1&page=${pageNumber}&pageSize=${pageSize}&globalSearch=${searchQuery}&billStatusId=${statusQuery}`;
            }
            setLoader(true);
            let result = await getApi(routeName);
            setLoader(false);
            setIndividualBills(result.res.bill_list);
            setHoldBillCount(result.res.hold_bill_count);
            const totalItems = result.res.record_count;
            setTotalPages(Math.ceil(totalItems / pageSize));
        } catch (error) {
            setLoader(false);
        }
    };

    const individualBillcolumns = useFeedsColumnIndividualBills(handlerIndividualBillList, pageNumber, pageSize, searchQuery, statusQuery);

    useEffect(() => {
        handlerIndividualBillList(pageNumber, pageSize, searchQuery, statusQuery);
    }, [pageNumber, pageSize, searchQuery, statusQuery]);

    const handlePageChange = (page) => {
        setPageNumber(page);
        navigate(`/individual-bill-list`, { state: { billStatusId } });
    };

    const handleSearchSubmit = () => {
        if (globalSearch !== searchQuery || billStatusId !== statusQuery) {
            setSearchQuery(globalSearch);
            setStatusQuery(billStatusId);
            setPageNumber(1);
            navigate(`/individual-bill-list`, { state: { billStatusId } });
        } else {
            handlerIndividualBillList(pageNumber, pageSize, searchQuery, statusQuery);
        }
    };

    return (
        <>
            <Helmet>
                <title>Vendor Portal || Standalone Bill List</title>
            </Helmet>
            <Loader isLoading={loader} />
            <div className="container-section">
                <div className="section-header justify-content-between">
                    <h3>Standalone Bill</h3>
                    <div className="cstm-bill-recon-sec cstm-bill-legend">
                        <div className="d-flex align-items-center">
                            <span className="recon-StatusIcon2 blink"></span>
                            <p className="me-1">{holdBillCount}</p>
                            <p>Hold Standalone Bill</p>
                        </div>
                    </div>
                </div>
                <div className="form-style">
                    <div className="">
                        <IndividualBill
                            columns={individualBillcolumns}
                            data={individualBill}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            globalSearch={globalSearch}
                            setGlobalSearch={setGlobalSearch}
                            handlerIndividualBillList={handlerIndividualBillList}
                            pageSize={pageSize}
                            billStatusId={billStatusId}
                            setBillStatusId={setBillStatusId}
                            handleSearchSubmit={handleSearchSubmit} // Pass the new handler
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default IndividualBillList;