import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { postApi } from '../../utils/services';
import toast from 'react-hot-toast';
import { RESEND_OTP, VERIFY_OTP } from '../../utils/apiRoutes';
import AuthenContext from '../../utils/helpers/AuthContext';
import OtpFormValidation from '../../middleware/otpErrorValidation';
import { Helmet } from "react-helmet";
import icon from "../../assets/image/streamlogo.png"
import bckicon from "../../assets/image/bck-circle.png"
import OtpInput from 'react-otp-input';
import { OTP_TIMER } from '../../utils/constant';
import LoginLeftContent from '../common/LoginContent';

const OtpPage = () => {
  const [otp, setOtp] = useState('');
  const location = useLocation();
  const vendorCred = location?.state;
  const [resendCountdown, setResendCountdown] = useState(OTP_TIMER);
  const { errors, setErrors, validateForm } = OtpFormValidation();
  const [loader, setLoader] = useState(false)
  const authCtx = useContext(AuthenContext);
  const { id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setResendCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, []);

  const handleServerValidations = (type, errorMessage) => {
    setErrors({ [type]: errorMessage });
  };

  const resendOtp = async () => {
    let result = await postApi(RESEND_OTP, { "userId": vendorCred?.id, "role": id })
    if (result.res.success) {
      toast.success(result.res.message)
      setResendCountdown(OTP_TIMER);
    }
    else {
      handleServerValidations("otp", result.res.message)
    }
  }

  const verifyOtp = async () => {
    try {
      if (validateForm(otp)) {
        setLoader(true);
        const result = await postApi(VERIFY_OTP, { "userId": vendorCred?.id, "role": id, "otp": Number(otp) });
        if (result.res.success) {
          authCtx.resetpwd(true);
          navigate("/rest-password", { state: { data: vendorCred, role: id } });
        } else {
          handleServerValidations("otp", result.res.message);
        }
      }
    } catch (error) {
      handleServerValidations("otp", error.message);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Vendor Portal || OneTime-Password</title>
      </Helmet>
      <div className='main-lgn-cntnr lgn-common'>
        <LoginLeftContent />
        <div className='lgn-cntnr-rgt'>
          <div className='lgn-rgt-cntn w-100'>
            <div className="text-center lgn-rgt-cntn-logo "><img className="lgn-logo" src={icon} alt="" /></div>
            <div className='bck-circle-icn'>
              <img className="logo cursor-pointer" src={bckicon} alt="" onClick={() => navigate(-1)} />
            </div>
            <h3>ONE TIME PASSWORD</h3>
            <p>Please create a new password of your account for the future sign in.</p>

            <div className='lgn-section-form form'>
              <div className='verify-otp-form'>
                <label htmlFor="exampleFormControlInput1" className="form-label">OTP</label>
                <OtpInput
                  value={otp} className="w-100"
                  onChange={setOtp}
                  numInputs={6}
                  renderInput={(props) => <input {...props} className="otp-field" />}
                />
                {
                  errors.otp && <p className="validate_error-div">{errors.otp}</p>
                }
                <div className='rsnd-otp d-flex justify-content-end'>
                  <p><span> {resendCountdown > 0 ? `Resend OTP in ${Math.floor(resendCountdown / 60)}:${("0" + (resendCountdown % 60)).slice(-2)}` : <span className="cursor-pointer" onClick={resendOtp}>Resend OTP</span>}</span></p>
                </div>
              </div>
              <div className='sign-in-btn'>
                <button type='button' className='btn btn-signin' onClick={verifyOtp}>
                  {loader ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </>
                  ) : (
                    "VERIFY OTP"
                  )}

                </button>
                <a href='/login' className='justify-content-end d-flex'>Back to Login</a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default OtpPage