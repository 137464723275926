import React, { useEffect, useState } from 'react';
import { getApi } from '../../../utils/services';
import { SPEND_ITEM, VENDOR_SPEND, TOTAL_EXPENSE } from '../../../utils/apiRoutes';
import PieDonutChart from '../../charts/piedonutchart/PieDonutChart';
import MultipleBarChart from '../../charts/multiplebarchart/MultipleBarChart';
import { Helmet } from "react-helmet";
import Loader from '../../common/Loader';
import Icon from '@mdi/react';
import { mdiCalendar } from '@mdi/js';
import DateRangeCalender from '../../Calender/DateRangeCalender';
import moment from 'moment';
import lastMonthExpenseCardImage from '../../../assets/image/Totalexp1.png';
import currentMonthExpenseCardImage from '../../../assets/image/Totalexp2.png';
import yearlyExpenseCardImage from '../../../assets/image/Totalexp3.png';
import lastMonthPendingPaymentCardImage from '../../../assets/image/PaymentPending1.png';
import currentMonthPendingPaymentCardImage from '../../../assets/image/PaymentPending2.png';
import yearlyPendingPaymentCardImage from '../../../assets/image/PaymentPending3.png';

const FinanceStatus = () => {
    const [vendorName, setVendorName] = useState([]);
    const [expenseData, setExpencePayment] = useState({});
    const [vendorSpend, setVendorSpend] = useState([]);
    const [spendTotalAmout, setSpendTotalAmout] = useState([]);
    const [spendItemName, setSpendItemName] = useState([]);
    const [loader, setLoader] = useState(false);
    const [itemLimit, setItemLimit] = useState(5);
    const [vendorLimit, setVendorLimit] = useState(5);


    const endDate = moment();

    function getFinancialYearStart() {
        const currentDate = moment();
        const year = currentDate.month() >= 3 ? currentDate.year() : currentDate.year() - 1;
        return moment([year, 3, 1]);
    }

    const startDate = getFinancialYearStart();

    const [itemSelectedDateRange, setItemSelectedDateRange] = useState({
        startDate: startDate,
        endDate: endDate,
    });

    const [vendorSelectedDateRange, setVendorSelectedDateRange] = useState({
        startDate: startDate,
        endDate: endDate,
    });

    useEffect(() => {
        handlerTotalExpense();
        handlerSpendPieDonutChart(itemSelectedDateRange.startDate, itemSelectedDateRange.endDate, itemLimit);
        handlerVendorSpendChart(vendorSelectedDateRange.startDate, vendorSelectedDateRange.endDate, vendorLimit);
    }, []);

    const handlerTotalExpense = async () => {
        try {
            setLoader(true)
            let result = await getApi(TOTAL_EXPENSE);
            let data = result.res.series;
            setExpencePayment(data)
            setLoader(false)
        } catch (error) {
            console.log(error);
        }
    }

    const handlerSpendPieDonutChart = async (startDate, endDate, limit) => {
        try {
            setLoader(true);
            const result = await getApi(`${SPEND_ITEM}?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}&limit=${limit}`);
            const { totalAmount, itemName } = result.res;
            setSpendItemName(itemName);
            setSpendTotalAmout(totalAmount);
        } catch (error) {
            console.error(error);
        } finally {
            setLoader(false);
        }
    };


    const handlerVendorSpendChart = async (startDate, endDate, limit) => {
        try {
            setLoader(true);
            const result = await getApi(`${VENDOR_SPEND}?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}&limit=${limit}`);
            const { totalSpend, vendorName } = result.res;
            setVendorSpend([{ name: 'Spend', data: totalSpend }]);
            setVendorName(vendorName);
        } catch (error) {
            console.error(error);
        } finally {
            setLoader(false);
        }
    };


    const handleSpendVendorLimitChange = (e) => {
        const newLimit = parseFloat(e.target.value);
        setVendorLimit(newLimit);
        handlerVendorSpendChart(vendorSelectedDateRange.startDate, vendorSelectedDateRange.endDate, newLimit);
    };

    const handleItemLimitChange = (e) => {
        const newLimit = parseFloat(e.target.value);
        setItemLimit(newLimit);
        handlerSpendPieDonutChart(itemSelectedDateRange.startDate, itemSelectedDateRange.endDate, newLimit);
    };

    const handleItemDateRangeChange = (range) => {
        setItemSelectedDateRange(range);
        handlerSpendPieDonutChart(range.startDate, range.endDate, itemLimit);
    };

    const handleVendorDateRangeChange = (range) => {
        setVendorSelectedDateRange(range);
        handlerVendorSpendChart(range.startDate, range.endDate, vendorLimit);
    };

    return (
        <>
            <Helmet>
                <title>Vendor Portal || Ap Payment Health</title>
            </Helmet>
            <Loader isLoading={loader} />
            <div className='col-12 mb-2'>
                <div className="row g-2">
                    <div className="col-12 col-lg-4">
                        <div className='container-section mb-0'>
                            <div className="card-body cstm-card-color1">
                                <div className='d-flex justify-content-between'>
                                    <div className='mb'>
                                        <h4><span className="me-1">₹</span>{expenseData.LastMonthExpense ? parseFloat(expenseData.LastMonthExpense).toLocaleString('en-IN', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }) : '0.00'}</h4>
                                        <p>Total Expenses</p>
                                        <div className='d-flex align-items-center'>
                                            <span>Last Month</span>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={lastMonthExpenseCardImage} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className='container-section mb-0'>
                            <div className="card-body cstm-card-color2">
                                <div className='d-flex justify-content-between'>
                                    <div className=''><h4><span className='me-1'>₹</span>{expenseData.CurrentMonthExpense ? parseFloat(expenseData.CurrentMonthExpense).toLocaleString('en-IN', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }) : '0.00'}</h4>
                                        <p>Total Expenses</p>
                                        <div className='d-flex  align-items-center'>
                                            <span>Current Month</span>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={currentMonthExpenseCardImage} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className='container-section mb-0'>
                            <div className="card-body cstm-card-color3">
                                <div className='d-flex justify-content-between'>
                                    <div className=''><h4><span className='me-1'>₹</span>{expenseData.CurrentYearExpense ? parseFloat(expenseData.CurrentYearExpense).toLocaleString('en-IN', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }) : '0.00'}</h4>
                                        <p>Total Expenses</p>
                                        <div className='d-flex  align-items-center'>
                                            <span>Yearly</span>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={yearlyExpenseCardImage} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className='container-section mb-0'>
                            <div className="card-body cstm-card-color4">
                                <div className='d-flex justify-content-between'>
                                    <div className=''><h4><span className='me-1'>₹</span>{expenseData.LastMonthPendingPayment ? parseFloat(expenseData.LastMonthPendingPayment).toLocaleString('en-IN', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }) : '0.00'}</h4>
                                        <p>Total Pending Payments</p>
                                        <div className='d-flex  align-items-center'>
                                            <span>Last Month</span>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={lastMonthPendingPaymentCardImage} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className='container-section mb-0'>
                            <div className="card-body cstm-card-color5">
                                <div className='d-flex justify-content-between'>
                                    <div className=''><h4>
                                        <span className='me-1'>₹</span>{expenseData.CurrentMonthPendingPayment ? parseFloat(expenseData.CurrentMonthPendingPayment).toLocaleString('en-IN', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }) : '0.00'}</h4>
                                        <p>Total Pending Payments</p>
                                        <div className='d-flex  align-items-center'>
                                            <span>Current Month</span>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={currentMonthPendingPaymentCardImage} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className='container-section mb-0'>
                            <div className="card-body cstm-card-color6">
                                <div className='d-flex justify-content-between'>
                                    <div className=''>
                                        <h4><span className='me-1'>₹</span>{expenseData.CurrentYearPendingPayment ? parseFloat(expenseData.CurrentYearPendingPayment).toLocaleString('en-IN', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }) : '0.00'}</h4>
                                        <p>Total Pending Payments</p>
                                        <div className='d-flex  align-items-center'>
                                            <span>Yearly</span>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={yearlyPendingPaymentCardImage} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 mb-2'>
                <div className="row g-2">
                    <div className="col-12 col-lg-6">
                        <div className='container-section h-100 mb-0'>
                            <div className="card-body position-relative">
                                <div className="dashboard-chart-section row" >
                                    <div className='dashboard-chart-title'>Spend on Vendor</div>
                                    <div className="col-md-3 mb-2" >
                                        <select className="form-select" value={vendorLimit} onChange={handleSpendVendorLimitChange}>
                                            <option value={5}>TOP 5</option>
                                            <option value={10}>TOP 10</option>
                                        </select>
                                    </div>
                                    <div className="col-md-1 mb-2" ></div>
                                    <div className="col-md-8 mb-2" >
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><Icon path={mdiCalendar} size={1} /></span>
                                            </div>
                                            <DateRangeCalender
                                                setSelectedDateRange={handleVendorDateRangeChange}
                                                startDate={vendorSelectedDateRange.startDate}
                                                endDate={vendorSelectedDateRange.endDate}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <MultipleBarChart
                                    categories={vendorName}
                                    data={vendorSpend}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-6">
                        <div className='container-section h-100 mb-0'>
                            <div className="card-body position-relative">
                                <div className="dashboard-chart-section row" >

                                    <div className='dashboard-chart-title'>Spend By Product/Service</div>
                                    <div className="col-md-3 mb-2" >
                                        <select className="form-select" value={itemLimit} onChange={handleItemLimitChange}>
                                            <option value={5}>TOP 5</option>
                                            <option value={10}>TOP 10</option>
                                        </select>
                                    </div>

                                    <div className="col-md-1 mb-2" ></div>
                                    <div className="col-md-8 mb-2" >
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><Icon path={mdiCalendar} size={1} /></span>
                                            </div>
                                            <DateRangeCalender
                                                setSelectedDateRange={handleItemDateRangeChange}
                                                startDate={itemSelectedDateRange.startDate}
                                                endDate={itemSelectedDateRange.endDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <PieDonutChart
                                    data={spendTotalAmout}
                                    label={spendItemName}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FinanceStatus;