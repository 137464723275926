import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../../assets/image/BackButton";
import BillButton from "../../assets/image/bill.jpg";

const BillType = () => {
    const navigate = useNavigate();
    
    const [selectedBill, setSelectedBill] = useState("fromPO"); 
    
    const handleRadioChange = (event) => {
        setSelectedBill(event.target.value);
    };

    const handleCreate = () => {
        if (selectedBill === "fromPO") {
            navigate("/contract-listing");
        } else if (selectedBill === "fromStandalone") {
            navigate("/individual-bill");
        }
    };

    return (
        <>
            <div className="container-section">
                <div className="section-header">
                    <button className="back-viewbtn me-2" onClick={() => navigate(-1)}>
                        <div className="svgsize">
                            <BackButton />
                        </div>
                    </button>
                    <h3>Bill</h3>
                </div>

                <div className="cstm-stepper">
                    <div className="container-section mt-4 mb-3 d-flex justify-content-center">
                        <div className="stepper-bill-slct">
                            <div className="bill-slct-header d-flex align-items-center justify-content-center mt-5">
                                <img src={BillButton} alt="Bill" />
                                <h2 className="ms-3">BILLS</h2>
                            </div>
                            <p className="text-center mt-3">Please select the bill below by clicking on the radio button to proceed.</p>

                            <div className="radio-bill-selection d-flex justify-content-between mt-4">
                                <div className="radio-slct-one form-check d-flex align-items-center m-0">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault1"
                                        value="fromPO"
                                        checked={selectedBill === "fromPO"}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="m-0 ms-2 cursor-pointer" for="flexRadioDefault1" >From PO</label>
                                </div>

                                <div className="radio-slct-two form-check d-flex align-items-center m-0">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault2"
                                        value="fromStandalone"
                                        checked={selectedBill === "fromStandalone"}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="m-0 ms-2 cursor-pointer" for="flexRadioDefault2" >From Standalone</label>
                                </div>
                            </div>

                            <div className="cstm-stepper-bill-btn mt-4 d-flex m-auto" onClick={handleCreate}>
                                <button
                                    type="button"
                                    className="btn"
                                >
                                    Create
                                </button>
                            </div>

                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BillType;
