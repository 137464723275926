import { useContext, useMemo } from 'react';
import { useDateFormat } from '../useDateFormate';
import { useStatusRenderer } from '../useStatus';
import eye from "../../../assets/image/eye.png"
import edit from "../../../assets/image/edit2.png"
import { useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ApproveButton from '../../../assets/image/ApproveButton';
import { ADMIN_EXECUTIVE_ID, ADMIN_EXECUTIVE_PENDING, ADMIN_MANAGER_ID, FINANCE_ID, FINANCE_PENDING, HOLD_STATUS_ID, STANDALONE_BILL_APPROVE_ID, SUPER_ADMIN_ID, VENDOR_MANAGER_PENDING, VENDOR_REJECT, VENDOR_VP_PENDING, VOID_STATUS_ID, VP_ID } from '../../constant';
import { ModalContext } from '../../Context';
import RejectButton from '../../../assets/image/RejectButton';
import FinanceApporveIcon from '../../../assets/image/FinanceApporveIcon';
import ApproveModal from '../../../components/modal/ApproveModal';
import Alert from '../../../components/modal/Alert';
import RejectionModal from '../../../components/modal/RejectionModal';
import { HOLD_BILL_MESSAGE, PENDING_DETAILS_MESSAGE } from '../globalErrorMessage';
import { BILL_NUMBER, CREATE_DATE, VENDOR_NAME } from './TableHeader';

// Custom hook for defining FeedsColumns
export const useFeedsColumnIndividualBills = (handlerIndividualBillList, pageNumber, pageSize, globalSearch, billStatusId) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userData"));
  const modalContext = useContext(ModalContext);
  const { handleModalData } = modalContext;

  const handlerView = (id) => {
    navigate(`/view-individual-bill/${id}`)
  }

  const handlerApprove = (data) => {
    if (data.BillStatusId === HOLD_STATUS_ID) {
      let alert = <Alert message={HOLD_BILL_MESSAGE} />;
      handleModalData(alert, "md");
    }
    else {
      let app = <ApproveModal data={data} handlerList={handlerIndividualBillList} pageNumber={pageNumber} pageSize={pageSize} globalSearch={globalSearch} type={STANDALONE_BILL_APPROVE_ID} billStatusId={billStatusId} />
      handleModalData(app, "md");
    }
  }

  const handleReject = (data) => {
    if (data.BillStatusId === HOLD_STATUS_ID) {
      let alert = <Alert message={HOLD_BILL_MESSAGE} />;
      handleModalData(alert, "md");
    }
    else {
      let reject = <RejectionModal data={data} handlerIndividualBillList={handlerIndividualBillList} pageNumber={pageNumber} pageSize={pageSize} globalSearch={globalSearch} billStatusId={billStatusId} />
      handleModalData(reject, "md");
    }
  }

  const handlerTds = (data) => {
    if (data.BillStatusId === HOLD_STATUS_ID) {
      let alert = <Alert message={HOLD_BILL_MESSAGE} />;
      handleModalData(alert, "md");
    }
    else {
      navigate(`/add-individual-bill-tds/${data.id}`)
    }
  }

  const editIndividualBill = (data) => {
    if (data?.VendorStatus === VENDOR_MANAGER_PENDING && data?.IsSelfService === true) {
      let alert = <Alert message={PENDING_DETAILS_MESSAGE} />;
      handleModalData(alert, "md");
    }
    else if (data.BillStatusId === HOLD_STATUS_ID) {
      let alert = <Alert message={HOLD_BILL_MESSAGE} />;
      handleModalData(alert, "md");
    }
    else {
      navigate(`/edit-individual-bill/${data.id}`)
    }
  }

  const columns = useMemo(() => [
    {
      Header: BILL_NUMBER,
      accessor: "DocumentNo",
    },

    {
      Header: CREATE_DATE,
      accessor: "CreatedAt",
      Cell: ({ row }) => {
        return <> {useDateFormat(row.original.CreatedAt)}</>;
      },
    },
    {
      Header: VENDOR_NAME,
      accessor: "LegalName",
    },
    {
      Header: <div className='ms-auto'>Grand Total</div>,
      accessor: "Total",
      Cell: ({ row }) => {
        return <> <div className='text-end'>{row?.original?.CurrencySymbol} {row?.original?.Total?.toFixed(2)}</div> </>;
      },
    },
    {
      Header: <div className='ms-auto'>TDS Amount</div>,
      accessor: "TDSAmount",
      Cell: ({ row }) => {
        return <><div className='text-end'>{row?.original?.TDSAmount ? <>{row?.original?.CurrencySymbol} {row?.original?.TDSAmount?.toFixed(2)} </> : "N/A"} </div> </>;
      },
    },
    {
      Header: <div className='m-auto'>Status</div>,
      accessor: "Name",
      Cell: ({ row }) => useStatusRenderer(row),
      disableSortBy: true,
    },
    {
      Header: "Action",
      accessor: (data) => {
        return (
          <>
            <div className="d-flex align-item-center w-100">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips1">
                    View
                  </Tooltip>
                }
              >
                <img src={eye} alt="" onClick={() => handlerView(data.id)} className='cursor-pointer' />
              </OverlayTrigger>

              {((data.Status === VENDOR_MANAGER_PENDING &&
                user.RoleId === ADMIN_MANAGER_ID) ||
                (data.Status === VENDOR_VP_PENDING && user.RoleId === VP_ID) ||
                (data.Status === FINANCE_PENDING &&
                  user.RoleId === FINANCE_ID)) && (
                  <span className="d-flex">
                    {data.Status === FINANCE_PENDING ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips2">
                            Add TDS
                          </Tooltip>
                        }
                      >
                        <span
                          onClick={() => handlerTds(data)}
                          className="cursor-pointer cstm-icon-gap"
                        >
                          <FinanceApporveIcon />
                        </span>
                      </OverlayTrigger>
                    ) : (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips3">
                            Approve
                          </Tooltip>
                        }
                      >
                        <span
                          onClick={() => handlerApprove(data)}
                          className="cursor-pointer cstm-icon-gap"
                        >
                          <ApproveButton />
                        </span>
                      </OverlayTrigger>
                    )}

                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips4">
                          Reject
                        </Tooltip>
                      }
                    >
                      <span
                        onClick={() => handleReject(data)}
                        className="cursor-pointer cstm-icon-gap"
                      >
                        <RejectButton />
                      </span>
                    </OverlayTrigger>
                  </span>
                )}

              {(data.Status === VENDOR_REJECT ||
                (data.Status === ADMIN_EXECUTIVE_PENDING && user.RoleId === ADMIN_EXECUTIVE_ID) ||
                (data.Status === VENDOR_MANAGER_PENDING &&
                  user.RoleId === ADMIN_MANAGER_ID)) && data.BillStatusId !== VOID_STATUS_ID && (
                  <>
                    {(user.RoleId !== VP_ID && user.RoleId !== FINANCE_ID && user.RoleId !== SUPER_ADMIN_ID) && (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips5">
                            Edit
                          </Tooltip>
                        }
                      >
                        <img src={edit} alt="" onClick={() => editIndividualBill(data)} className="cstm-icon-gap cursor-pointer" />
                      </OverlayTrigger>
                    )}
                  </>
                )}
            </div>
          </>
        );
      },
      disableSortBy: true,
    },
  ], [handlerIndividualBillList, pageNumber, pageSize, globalSearch, user]);

  return columns;
};