import React, { useState, useEffect, useContext } from "react";
import pinicon from "../../../assets/image/pinIcon.png";
import { useParams, useNavigate, Link } from "react-router-dom";
import BackButton from "../../../assets/image/BackButton";
import PreviewIcon from "../../../assets/image/PreviewIcon";
import CreateBillIcon from "../../../assets/image/CreateBillIcon";
import StatusViewIcon from "../../../assets/image/StatusViewIcon";
import { VIEW_CONTRACT } from "../../../utils/apiRoutes";
import { BASE_IMAGE_URL, getApi } from "../../../utils/services";
import moment from "moment/moment";
import { ModalContext } from "../../../utils/Context";
import Modal from "react-bootstrap/Modal";
import {
  ADMIN_MANAGER_ID,
  VP_ID,
  VENDOR_VP_PENDING,
  VENDOR_MANAGER_PENDING,
  VENDOR_APPROVE,
  ADMIN_EXECUTIVE_ID,
  VENDORID,
  PURCHASE_CONTRACT_APPROVE_ID
} from "../../../utils/constant";
import FileIcon from "../../../assets/image/FileIcon";
import DownloadSvg from "../../../assets/image/DownloadSvg";
import PreviewPdf from "../previewPdf/PreviewFile";
import eyeicon from "../../../assets/image/eye.png";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader"
import ApproveModal from "../../modal/ApproveModal";
import Alert from "../../modal/Alert";
import RejectionModal from "../../modal/RejectionModal";
import { FULLY_PAID_MESSAGE } from "../../../utils/helpers/globalErrorMessage";
import { PENDING_DETAILS_MESSAGE } from "../../../utils/helpers/globalErrorMessage";
import SystemInfo from "../vendor/SystemInfo";

const ViewContract = () => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const modalContext = useContext(ModalContext);
  const { handleModalData } = modalContext;
  const [loader, setLoader] = useState(false);
  const [Viewdetails, setViewDetails] = useState({});
  const [contractHistory, setContractHistory] = useState([]);
  let totalQuantity = 0;
  let totalAmount = 0;
  const { id } = useParams();
  let data = {
    id: Number(id),
  };
  const navigate = useNavigate();

  const handleViewdetails = async () => {
    try {
      setLoader(true);
      const result = await getApi(`${VIEW_CONTRACT}/${id}`);
      setLoader(false);
      setViewDetails(result.res.contract_details);

      const updatedHistory = result?.res.contractHistory.map((item) => {
        const utcTime = item.ModifiedAt;
        const date = new Date(utcTime);
        const formattedTime = date.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
        return { ...item, ModifiedAt: formattedTime };
      });

      setContractHistory(updatedHistory);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleViewdetails();
  }, []);

  const handleApprove = () => {
    let app = (
      <ApproveModal
        handlerList={handleViewdetails}
        data={data}
        type={PURCHASE_CONTRACT_APPROVE_ID}
      />
    );
    handleModalData(app, "md");
  };

  const handleReject = () => {
    let reject = (
      <RejectionModal
        data={data}
        handlerPurchaseContractList={handleViewdetails}
      />
    );
    handleModalData(reject, "md");
  };

  const hasBackHistory = window.history.length > 1;

  const handleInvoiceClick = (item) => {
    if (item?.Difference === 0) {
      const alert = <Alert message={FULLY_PAID_MESSAGE} />
      handleModalData(alert, "md");
    }
    else if (item?.VendorStatus === VENDOR_MANAGER_PENDING && item?.IsSelfService === true) {
      let alert = <Alert message={PENDING_DETAILS_MESSAGE} />;
      handleModalData(alert, "md");
    }
    else {
      navigate(`/add-bill/${id}`);
    }
  };

  //for file modal
  const handlePreview = (url) => {
    const preview = <PreviewPdf url={url} />;
    const encodedText = btoa(url);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Helmet>
        <title>Vendor Portal || View Contract</title>
      </Helmet>
      <Loader isLoading={loader} />
      <>
        <div className="container-section">

          <div className="section-header section-header-btn-box flex-wrap ">

            <div className="d-flex align-items-center">
              {hasBackHistory ? (
                <button
                  onClick={() => navigate(-1)}
                  className="back-viewbtn me-2"
                >
                  <BackButton />
                </button>
              ) : (
                <span></span>
              )}
              <h3>
                Purchase Contract -
                <span className="value-color ms-2 me-2">
                  {Viewdetails.DocumentNo}
                </span>
              </h3>
            </div>

            <div className="d-flex align-items-center cstm-prvbtn-box">

              <div className="preview-btn">
                <button type="button" className="btn" onClick={handleShow}>
                  <PreviewIcon /> <span className="ms-2">Preview</span>
                </button>
              </div>

              {(user?.RoleId === ADMIN_EXECUTIVE_ID ||
                user?.RoleId === ADMIN_MANAGER_ID || user?.RoleId === VENDORID) &&
                Viewdetails?.StatusId === VENDOR_APPROVE && (

                  <div className="create-btn ms-2">
                    <button
                      type="button"
                      className="btn"
                      onClick={() => handleInvoiceClick(Viewdetails)}
                    >
                      <CreateBillIcon />{" "}
                      <span className="ms-2">Create Bill</span>
                    </button>
                  </div>

                )}

            </div>
          </div>

          <div className="form-style">
            <div className="data-info-box">
              <div className="imgpinicon">
                <img src={pinicon} alt=""></img>
              </div>
              <div className="data-info">
                <div className="data-group">
                  <div className="data-item">
                    <span className="label">Vendor</span>
                    <span className="semicolon">:</span>
                    <span className="value">{Viewdetails.LegalName}</span>
                  </div>
                  <div className="data-item">
                    <span className="label">Purchase Contract</span>
                    <span className="semicolon">:</span>
                    <span className="value">{Viewdetails.id}</span>
                  </div>
                  <div className="data-item">
                    <span className="label">Terms</span>
                    <span className="semicolon">:</span>
                    <span className="value">{Viewdetails.Term}</span>
                  </div>
                  <div className="data-item">
                    <span className="label">Subsidiary</span>
                    <span className="semicolon">:</span>
                    <span className="value">{Viewdetails.Name}</span>
                  </div>
                  <div className="data-item">
                    <span className="label">Start Date</span>
                    <span className="semicolon">:</span>
                    <span className="value">
                      {moment(Viewdetails?.StartDate)
                        .format("D/MM/YYYY")
                        ?.slice(0, 10)}
                    </span>
                  </div>

                  <div className="data-item">
                    <span className="label">Created At</span>
                    <span className="semicolon">:</span>
                    <span className="value">
                      {moment(Viewdetails?.CreatedAt)
                        .format("D/MM/YYYY")
                        ?.slice(0, 10)}
                    </span>
                  </div>
                  <div className="data-item">
                    <span className="label">End Date</span>
                    <span className="semicolon">:</span>
                    <span className="value">
                      {" "}
                      {moment(Viewdetails?.EndDate)
                        .format("D/MM/YYYY")
                        ?.slice(0, 10)}
                    </span>
                  </div>
                  <div className="data-item">
                    <span className="label">Status</span>
                    <span className="semicolon">:</span>
                    <span className="value d-flex align-items-center">
                      <StatusViewIcon status={Viewdetails?.StatusName} />
                      {Viewdetails?.StatusName}
                    </span>
                  </div>
                  <div className="data-item">
                    <span className="label">Total</span>
                    <span className="semicolon">:</span>
                    <span className="value">
                      {Viewdetails?.Symbol} {Viewdetails.ContractTotal?.toFixed(2)}
                    </span>
                  </div>
                  <div className="data-item">
                    <span className="label">Rejection Reason</span>
                    <span className="semicolon">:</span>
                    <span className="value">
                      {Viewdetails?.RejectionReason
                        ? Viewdetails.RejectionReason
                        : "N/A"}
                    </span>
                  </div>
                  {user.RoleId !== VENDORID && (
                    <div className="data-item">
                      <span className="label">NetSuite Id</span>
                      <span className="semicolon">:</span>
                      <span className="value">
                        {Viewdetails?.ExternalId
                          ? Viewdetails.ExternalId
                          : "N/A"}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="form-style">
            <div className="section-sub-heading">
              <h3>Item Description</h3>
            </div>
            <div className="table-responsive tbl-body-shadow mb-4">
              <table className="w-100 table-theme-1">
                <thead>
                  <tr>
                    <th className="text-left">Item</th>
                    <th className="text-left">Description</th>
                    <th className="text-end">Rate</th>
                    <th className="text-end">Quantity</th>
                    <th className="text-end">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {Viewdetails?.LineItems?.map((row, index) => {
                    totalQuantity += row.Quantity;
                    totalAmount += row.Amount;
                    return (
                      <tr key={index}>
                        <td className="text-left">  <p className=" text-nowrap">{row.ItemName}</p></td>
                        <td className="text-left">  <p className=" text-wrap">{row.Description ? row.Description : "N/A"}</p></td>
                        <td className="text-end ">
                          <p className=" text-nowrap"> {Viewdetails?.Symbol} {row?.Rate?.toFixed(2)}</p>
                        </td>
                        <td className="text-end">
                          {row?.Quantity?.toFixed(2)}
                        </td>
                        <td className="text-end ">
                          <p className=" text-nowrap">{Viewdetails?.Symbol} {row?.Amount?.toFixed(2)}</p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot className="table-tfoot">
                  <tr>
                    <td></td>
                    <td></td>
                    <td className=" shadowclass ">Total</td>
                    <td className=" shadowclass">
                      {totalQuantity.toFixed(2)}
                    </td>
                    <td className="shadowclass">
                      <p className=" text-nowrap">{Viewdetails?.Symbol} {totalAmount.toFixed(2)}</p>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>

          {contractHistory?.length > 0 && (
            <div className="">
              <SystemInfo viewHistory={contractHistory} />
            </div>
          )}

          <div className="d-flex align-items-center justify-content-end mb-20 form-style">
            {(user?.RoleId === VP_ID &&
              Viewdetails?.StatusId === VENDOR_VP_PENDING) ||
              (user?.RoleId === ADMIN_MANAGER_ID &&
                Viewdetails?.StatusId === VENDOR_MANAGER_PENDING) ? (
              <span className="d-flex align-items-center">
                <span className="cursor-pointer" onClick={handleReject}>
                  <div className="danger-outline-btn ">
                    <button type="button" className="btn">
                      Reject
                    </button>
                  </div>
                </span>
                <span className="cursor-pointer" onClick={handleApprove}>
                  <div className="success-btn ms-2">
                    <button type="submit" className="btn">
                      Approve
                    </button>
                  </div>
                </span>
              </span>
            ) : null}
          </div>

        </div>

        <>
          <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            animation={false}
            className="modal-wrap"
            backdrop="static"
            keyboard={false}
          >
            <div className="file-preview-modal-title">
              <div className="svg-size me-2">
                <FileIcon />
              </div>
              <h3 className="m-0">File Details</h3>
            </div>
            <div className="file-preview-modal-body">
              {Viewdetails?.Files?.map((row, index) => (
                <>
                  <div className="files-card ">
                    <div className="d-flex align-items-center">
                      <p>{index + 1}.</p>
                      <p className="ms-3 me-3 break-all">{row.FileName}</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="me-2">
                        <Link
                          to={`${BASE_IMAGE_URL}/${row.FilePath}`}
                          download
                          target="_blank"
                        >
                          <DownloadSvg />
                        </Link>
                      </span>
                      <span
                        onClick={() =>
                          handlePreview(`${BASE_IMAGE_URL}/${row.FilePath}`)
                        }
                      >
                        <Link
                          to={`/preview-pdf/${btoa(row.FilePath)}`}
                          target="_blank"
                        >
                          <span className="eye-icon">
                            <img src={eyeicon} alt="" />
                          </span>
                        </Link>
                      </span>
                    </div>
                  </div>
                </>
              ))}
            </div>
            <hr className="m-0" />
            <div className=" d-flex justify-content-end  file-preview-modal-footer">
              <div className="danger-outline-btn ">
                <button type="button" onClick={handleClose} className="btn">
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </>

      </>

    </>
  );
};

export default ViewContract;
