import React, { useContext, useState } from "react";
import { ModalContext } from "../../utils/Context";
import toast from "react-hot-toast";
import { postApi } from "../../utils/services";
import {
    VENDOR_DETAILS_APPROVE,
    PURCHASE_CONTRACT_APROVE,
    APPROVE_INDIVIDUAL_BILL_API,
    APPROVE_BILL_API,
} from "../../utils/apiRoutes";
import { useTransactionContext } from "../../utils/helpers/useTransaction";
import { useNavigate, useLocation } from "react-router-dom";
import Alert from "./Alert";

const ApproveModal = ({ data, handlerList, type, pageNumber, pageSize, globalSearch, billStatusId }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const transactionList = useTransactionContext();
    const { closeModal } = useContext(ModalContext);
    const [disable, setDisable] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const modalContext = useContext(ModalContext);
    const { handleModalData } = modalContext;

    const config = {
        1: {
            apiRoute: PURCHASE_CONTRACT_APROVE,
            payload: {
                contractId: data.id,
                transactionId: transactionList[2]?.id,
                roleId: user.RoleId,
            },
            title: "approve this Purchase Contract? This action cannot be revert",
        },
        2: {
            apiRoute: APPROVE_BILL_API,
            payload: {
                billId: data.id,
                transactionId: transactionList[1].id,
                roleId: user.RoleId,
            },
            title: "approve this Bill? This action cannot be revert",
        },
        3: {
            apiRoute: APPROVE_INDIVIDUAL_BILL_API,
            payload: {
                billId: data.id,
                transactionId: transactionList[4]?.id,
                roleId: user.RoleId,
            },
            title: "approve this Standalone Bill? This action cannot be revert",
        },
        4: {
            apiRoute: VENDOR_DETAILS_APPROVE,
            payload: {
                vendorId: data.id,
                vendorBankInfoIds: data.vendorBankInfoIds,
                selfService: data.selfService ? data.selfService : null,
            },
            title: "approve this Vendor? This action cannot be revert",
        },
    };

    const currentModalConfig = config[type];

    const handleApprove = async () => {
        try {
            setDisable(true);
            const result = await postApi(currentModalConfig.apiRoute, currentModalConfig.payload);
            if (result.res.success) {
                handlerList(pageNumber, pageSize, globalSearch, billStatusId);
                toast.success(result.res.message, { className: "custom-toast-class" });

                const currentPath = location.pathname;

                if (currentPath === "/") {
                    navigate("/");
                } else if (currentPath.includes("contract-listing")) {
                    navigate("/contract-listing");
                } else if (currentPath.includes("invoice-listing")) {
                    navigate("/invoice-listing");
                } else if (currentPath.includes("individual-bill-list")) {
                    navigate("/individual-bill-list");
                } else if (currentPath.includes("vendors")) {
                    navigate("/vendors");
                } else {
                    navigate(-1);
                }
                closeModal();
            }
            if (result.res.holdMessage) {
                const alert = <Alert message={result.res.holdMessage} />;
                handleModalData(alert, "md");
            }
        } catch (error) {
            setDisable(false);
            toast.error(error.message);
        } finally {
            setDisable(false);
        }
    };

    return (
        <div className="custom-cnfm-mdl">
            <div className="modal-body">
                <h2>Are you sure?</h2>
                <h6>{`Are you sure you want to ${currentModalConfig.title}.`}</h6>
            </div>
            <div className="modal-footer">
                <div className="d-flex align-items-center mb-20">
                    <div className="danger-btn">
                        <button type="button" className="btn" onClick={closeModal}>
                            Cancel
                        </button>
                    </div>
                    <div className="success-btn ms-2">
                        <button
                            type="submit"
                            className="btn"
                            onClick={handleApprove}
                            disabled={disable}
                        >
                            Approve
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApproveModal;
