import React from 'react'

const CommonPendingIcon = ({ statusName }) => {

  return (
    <div className='text-center'>
      <svg width="165" height="48" viewBox="0 0 165 48" fill="none">
        <rect y="23" width="165" height="25" rx="6" fill="#FCA510" />
        <text x="50%" y="77%" dominantBaseline="central" textAnchor="middle" fill="white">{statusName}</text>
        <circle cx="82" cy="15" r="14" fill="white" stroke="#FCA510" strokeWidth="2" />
        <path d="M77 9H87M77 22H87M78.3889 22V18.6087L79.9756 17.6399C80.3388 17.4182 80.6394 17.1046 80.8481 16.7296C81.0568 16.3546 81.1665 15.931 81.1665 15.5C81.1665 15.069 81.0568 14.6454 80.8481 14.2704C80.6394 13.8954 80.3388 13.5818 79.9756 13.3601L78.3889 12.3913V9M85.6111 9V12.3913L84.0244 13.3601C83.6613 13.5819 83.3609 13.8956 83.1522 14.2706C82.9436 14.6455 82.834 15.0691 82.834 15.5C82.834 15.9309 82.9436 16.3545 83.1522 16.7294C83.3609 17.1044 83.6613 17.4181 84.0244 17.6399L85.6111 18.6087V22" stroke="#FCA510" />
      </svg>
    </div>
  )
}

export default CommonPendingIcon