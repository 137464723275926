import React, { useContext, useEffect, useState } from 'react'
import Header from '../common/Header.jsx'
import useAuthenticationRedirect from '../../utils/helpers/useAuthenticationRedirect.jsx';
import AuthenContext from '../../utils/helpers/AuthContext.jsx';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidenav.jsx';
import InnerContainer from './InnerContainer.jsx';
import { VIEW_VENDOR } from '../../utils/apiRoutes.jsx';
import { getApi } from '../../utils/services.jsx';
import { VENDORID } from '../../utils/constant.jsx';

const Layout = () => {
  let navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userData"));
  const authCtx = useContext(AuthenContext);
  useAuthenticationRedirect(authCtx.isLoggedIn, navigate);
  const [ViewVendordetails, setViewVendorDetails] = useState({});

  useEffect(() => {
    if (user.id && user.RoleId === VENDORID) {
      const handleViewVendordetails = async (id) => {
        try {
          const result = await getApi(`${VIEW_VENDOR}/${id}`);
          setViewVendorDetails(result.res.vendor_details);
        } catch (error) {
          console.log(error);
        }
      };
      handleViewVendordetails(user.id);
    }
  }, [user.id, user.RoleId]);


  const [togglerBar, setTogglerBar] = useState(true);
  const handleSidebarToggle = () => {
    setTogglerBar(!togglerBar);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 992) {
        setTogglerBar(false);
      } else {
        setTogglerBar(true);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);
  return (
    <div className={togglerBar ? 'sidebar-toggle-active d-flex layout' : 'd-flex layout'}>
      <div className='sidebar-container'>
        <Sidebar ViewVendordetails={ViewVendordetails} setTogglerBar={handleSidebarToggle} />
      </div>
      <div className='page-container'>
        <Header setTogglerBar={handleSidebarToggle} togglerBar={togglerBar} />
        <div className="page-inner-container">
          <InnerContainer ViewVendordetails={ViewVendordetails} />
        </div>
      </div>
    </div>
  )
}

export default Layout