import React from "react";
import DashboardTable from "./DashboardTable";
import CreateBillIcon from "../../assets/image/CreateBillIcon";
import { useNavigate } from "react-router-dom";
import { ADMIN_EXECUTIVE_ID, ADMIN_MANAGER_ID, VENDORID } from "../../utils/constant";

const DashboardCard = ({
  title,
  columns,
  data,
  mt,
  handlerBillList,
  handlerPurchaseContractList,
  IndividualBillTrue,
  handleVendorList,
  allIndividualBillFalse,
  handlerIndividualBillList,
  pageNumber,
  individualBillPageNumber,
  billPageNumber,
  purchaseContractPageNumber,
  vendorPageNumber,
  setPurchaseContractPageNumber,
  setVendorPageNumber,
  setIndividualBillPageNumber,
  setBillPageNumber,
  totalPages,
  totalPagesIndividualBill,
  totalPagesBill,
  totalPagesPurchase,
  totalPagesVendor,
  onPageChange,
  handleVendorPageChange,
  handleBillPageChange,
  handleIndividualBillPageChange,
  handlePurchaseContractPageChange,
}) => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();

  const handleVendorAdd = () => {
    navigate(`/add-vendor`);
  };

  const handleCreateContract = () => {
    if (user?.RoleId === ADMIN_EXECUTIVE_ID) {
      navigate(`/add-executive-contract`);
    } else if (user?.RoleId === ADMIN_MANAGER_ID) {
      navigate(`/add-manager-contract`);
    } else {
      console.log('Unauthorized role');
    }
  };

  const handleCreateBill = () => {
    navigate(`/contract-listing`);
  };

  const handleCreateIndividualBill = () => {
    navigate(`/individual-bill`);
  };

  return (
    <div className={`container-section ${mt}`}>
      <div className="section-header section-header-btn-box d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex align-items-center">
          <h3>{title}</h3>
        </div>

        <div className="d-flex align-items-center">
          {title === "Vendors" && (user?.RoleId === ADMIN_EXECUTIVE_ID || user?.RoleId === VENDORID) && (
            <div className="create-btn ms-2">
              <button
                type="button"
                className="btn"
                onClick={handleVendorAdd}
              >
                <CreateBillIcon />
                <span className="ms-2">Add Vendor</span>
              </button>
            </div>
          )}

          {title === "Purchase Contracts" && (user?.RoleId === ADMIN_EXECUTIVE_ID || user?.RoleId === ADMIN_MANAGER_ID) && (
            <div className="create-btn ms-2">
              <button
                type="button"
                className="btn"
                onClick={handleCreateContract}
              >
                <CreateBillIcon />
                <span className="ms-2">Create Contract</span>
              </button>
            </div>
          )}

          {title === "Bills" && (user?.RoleId === ADMIN_EXECUTIVE_ID || user?.RoleId === ADMIN_MANAGER_ID || user?.RoleId === VENDORID) && (
            <div className="create-btn ms-2">
              <button
                type="button"
                className="btn"
                onClick={handleCreateBill}
              >
                <CreateBillIcon />
                <span className="ms-2">Create Bill</span>
              </button>
            </div>
          )}

          {title === "Standalone Bill" && (user?.RoleId === ADMIN_EXECUTIVE_ID || user?.RoleId === ADMIN_MANAGER_ID || user?.RoleId === VENDORID) && (
            <div className="create-btn ms-2">
              <button
                type="button"
                className="btn"
                onClick={handleCreateIndividualBill}
              >
                <CreateBillIcon />
                <span className="ms-2">Create Standalone Bill</span>
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="form-style">
        <DashboardTable
          columns={columns}
          data={data}
          title={title}
          handlerBillList={handlerBillList}
          handlerPurchaseContractList={handlerPurchaseContractList}
          IndividualBillTrue={IndividualBillTrue}
          allIndividualBillFalse={allIndividualBillFalse}
          handlerIndividualBillList={handlerIndividualBillList}
          totalPages={totalPages}
          setIndividualBillPageNumber={setIndividualBillPageNumber}
          setBillPageNumber={setBillPageNumber}
          setVendorPageNumber={setVendorPageNumber}
          setPurchaseContractPageNumber={setPurchaseContractPageNumber}
          pageNumber={pageNumber}
          onPageChange={onPageChange}
          handlePurchaseContractPageChange={handlePurchaseContractPageChange}
          handleVendorPageChange={handleVendorPageChange}
          handleBillPageChange={handleBillPageChange}
          handleIndividualBillPageChange={handleIndividualBillPageChange}
          totalPagesIndividualBill={totalPagesIndividualBill}
          totalPagesBill={totalPagesBill}
          totalPagesPurchase={totalPagesPurchase}
          totalPagesVendor={totalPagesVendor}
          individualBillPageNumber={individualBillPageNumber}
          billPageNumber={billPageNumber}
          purchaseContractPageNumber={purchaseContractPageNumber}
          vendorPageNumber={vendorPageNumber}
          handleVendorList={handleVendorList}
        />
      </div>
    </div>
  );
};

export default DashboardCard;
