import React, { useContext } from "react";
import { ModalContext } from "../../utils/Context";
import cross from "../../assets/image/cross.png";

const Alert = ({ message }) => {
    const modalContext = useContext(ModalContext);
    const { closeModal } = modalContext;

    return (
        <div className="custom-cnfm-mdl">
            <span className="cross" onClick={closeModal}>
                <img src={cross} alt="" />
            </span>
            <div className="modal-body fully-paid-div">
                <h2>{message}</h2>
            </div>
        </div>
    );
};

export default Alert;
