import React, { useState } from 'react'
import icon from "../../assets/image/streamlogo.png"
import bckicon from "../../assets/image/bck-circle.png"
import { useLocation, useNavigate } from 'react-router-dom';
import { postApi } from '../../utils/services';
import toast from 'react-hot-toast';
import ResetPasswordFormValidation from '../../middleware/resetPasswordValidation';
import { RESET_PASSWORD } from '../../utils/apiRoutes';
import { Helmet } from "react-helmet";
import Icon from "@mdi/react";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { mdiInformationVariantCircleOutline } from '@mdi/js';
import LoginLeftContent from '../common/LoginContent';

const RestpwdPage = () => {

  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [confpassword, setconfPassword] = useState();
  const [loader, setloader] = useState(false)
  const { errors, setErrors, validateForm } = ResetPasswordFormValidation();
  const location = useLocation();
  const vendorCred = location?.state;
  const navigate = useNavigate();

  const togglePasswordVisibility1 = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleServerValidations = (type, errorMessage) => {
    let _errors = {};
    _errors[type] = errorMessage;
    setErrors(_errors);
  };

  const handleResetApi = async () => {
    try {
      const isFormValid = validateForm(password, confpassword);
      if (isFormValid) {
        setloader(true)
        let result = await postApi(RESET_PASSWORD, { "userId": vendorCred.data?.id, "password": password, "confirmPassword": confpassword, "role": vendorCred.role })
        if (result.res.success) {
          toast.success(result.res.message)
          setTimeout(() => { navigate("/login") }, 1000);
          setloader(false)
        }
        else {
          handleServerValidations("confirmpassword", result.res.message)
          setloader(false)
        }
      }

    } catch (error) {
      toast.error(error.res.message)
      setloader(false)
    }

  }

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Reset-Password</title>
      </Helmet>
      <div className='main-lgn-cntnr lgn-common'>
        <LoginLeftContent />
        <div className='lgn-cntnr-rgt'>
          <div className='lgn-rgt-cntn w-100'>
            <div className="text-center lgn-rgt-cntn-logo "><img className="lgn-logo" src={icon} alt="" /></div>
            <div className='bck-circle-icn'>
              <img className="logo cursor-pointer" src={bckicon} alt="" onClick={() => navigate(-1)} />
            </div>
            <h3>Reset Password</h3>
            <p>Please create a new password of your account for the future sign in.</p>

            <div className='lgn-section-form form'>
              <div className='reset-pwd-form'>
                <div className=' mb-3'>
                  <label htmlFor="exampleFormControlInput1" className='form-label'>New Password<OverlayTrigger
                    placement="top"
                    overlay={<Tooltip className="custom-tooltip" id="tooltips1"
                    >Password must contain at least one uppercase letter,
                      one lowercase letter, one number, one special character,
                      and no spaces</Tooltip>
                    }
                  >
                    <span className="ms-1 infoIcon"><Icon path={mdiInformationVariantCircleOutline} size={0.6} /></span>
                  </OverlayTrigger></label>
                  <div className="input-group">
                    <input type={showPassword ? "text" : "password"} className="form-control" aria-label="Amount (to the nearest dollar)" placeholder='Enter new password' value={password} autoComplete="off" onChange={(e) => setPassword(e.target.value)} />
                    <span
                      className="input-group-text cstm-eye-icon cursor-pointer input-pass-icon"
                      onClick={togglePasswordVisibility1}
                    >
                      <Icon
                        path={showPassword ? mdiEyeOff : mdiEye}
                        size={0.8}
                        color="black"
                      />
                    </span>
                  </div>

                  {
                    errors.newpassword && <p className="validation-error">{errors.newpassword}</p>
                  }
                </div>
                <div className=' mb-3'>
                  <label htmlFor="exampleFormControlInput1" className="form-label">Confirm Password</label>
                  <div className="input-group">
                    <input type={showPassword2 ? "text" : "password"} className="form-control" aria-label="Amount (to the nearest dollar)" placeholder='Enter confirm password' value={confpassword} onChange={(e) => setconfPassword(e.target.value)} />
                    <span
                      className="input-group-text cstm-eye-icon cursor-pointer input-pass-icon"
                      onClick={togglePasswordVisibility2}
                    >
                      <Icon
                        path={showPassword2 ? mdiEyeOff : mdiEye}
                        size={0.8}
                        color="black"
                      />
                    </span>
                  </div>
                  {
                    errors.confirmpassword && <p className="validation-error">{errors.confirmpassword}</p>
                  }
                </div>
              </div>
              <div className='sign-in-btn'>
                <button type='button' disabled={loader} className='btn btn-signin' onClick={handleResetApi}>
                  {loader ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </>
                  ) : (
                    "CHANGE PASSWORD"
                  )}

                </button>
                <a href='/login' className='justify-content-end d-flex'>Back to Login</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RestpwdPage