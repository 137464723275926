import Accordion from "react-bootstrap/Accordion";
import React from "react";

const Integration = ({ viewData }) => {

    return (
        <>
            <div className="bank-accordian-box">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <h3>Integration</h3>
                        </Accordion.Header>
                        <div className="">
                            <Accordion.Body className="p-0">
                                <div className="finance-img-with-fileds-div mt-0">
                                    <div className="finance-view-accordian">
                                        <div className="row">
                                            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-md-4 ">
                                                <label htmlFor="netsuiteId" className="form-label">
                                                    NetSuite Id
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="netsuiteId"
                                                    value={viewData.ExternalId ?? "N/A"}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </div>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
};

export default Integration;
