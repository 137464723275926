import React, { useEffect, useState, useContext } from "react";
import DownloadIcon from "../../../assets/image/DownloadIcon";
import EyeIcon from "../../../assets/image/EyeIcon";
import BackButtonWhite from "../../../assets/image/BackButtonWhite"
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { ModalContext } from "../../../utils/Context";
import { VIEW_BILL } from "../../../utils/apiRoutes";
import { BASE_IMAGE_URL, getApi } from "../../../utils/services";
import {
  ADMIN_MANAGER_ID,
  FINANCE_ID,
  FINANCE_PENDING,
  PAYMENT_PENDING,
  SUPER_ADMIN_ID,
  VENDORID,
  VENDOR_MANAGER_PENDING,
  VENDOR_VP_PENDING,
  VP_ID,
  FULLY_PAID,
  VENDOR_REJECT,
  VOID_STATUS_ID,
  HOLD_STATUS_ID,
  BILL_APPROVE_ID,
  PARTIALLY_PAID_STATUS_ID
} from "../../../utils/constant";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";
import PaymentModal from "../../modal/PaymentModal";
import RejectionModal from "../../modal/RejectionModal";
import HoldReleaseVoidPage from "../../modal/HoldReleaseVoidModal";
import { handleApproval } from "../../../utils/helpers/globalMethod";
import BillToContent from "../../common/BillToContent";
import SystemInfo from "../vendor/SystemInfo";

const ViewBill = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userData"));
  const [viewBill, setViewBill] = useState({});
  const [loader, setLoader] = useState(false);
  const [showbackButton, setShowbackButton] = useState(false);
  const modalContext = useContext(ModalContext);
  const { handleModalData } = modalContext;
  const [billHistory, setBillHistory] = useState([]);

  useEffect(() => {
    if (id) {
      handleViewBill();
    }
  }, [id]);

  useEffect(() => {
    if (window.history.length > 1) {
      setShowbackButton(true);
    } else {
      setShowbackButton(false)
    }
  }, []);

  const handleGoBack = () => {
    navigate(-1)
  }

  const handleViewBill = async () => {
    setLoader(true);
    try {
      const result = await getApi(`${VIEW_BILL}/${id}`);
      setLoader(false);
      setViewBill(result.res.bill_details);
      const updatedHistory = result?.res.billHistory.map((item) => {
        const utcTime = item.ModifiedAt;
        const date = new Date(utcTime);
        const formattedTime = date.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
        return { ...item, ModifiedAt: formattedTime };
      });
      setBillHistory(updatedHistory);
    } catch (error) {
      console.error(error);
    }
  };

  let data = {
    id: Number(id),
  };

  const handleApprove = () => {
    handleApproval(data, handleModalData, handleViewBill, BILL_APPROVE_ID);
  };

  const isHold = viewBill?.BillStatusId !== HOLD_STATUS_ID;

  const actionType = isHold ? "hold" : "release";

  const handleButtonClick = () => {
    const holdBillModal = <HoldReleaseVoidPage data={viewBill} type={actionType} />;
    handleModalData(holdBillModal, "md");
  };

  const handleReject = () => {
    let reject = <RejectionModal data={viewBill} handlerBillList={handleViewBill} />;
    handleModalData(reject, "reject");
  };

  const handleTDSCreation = () => {
    navigate(`/add-tds/${id}`);
  };

  const handlePayment = () => {
    const payment = <PaymentModal item={viewBill} id={id} handleView={handleViewBill} />;
    handleModalData(payment, "md");
  }

  const handlerVoidBill = () => {
    let voidbill = <HoldReleaseVoidPage data={viewBill} type={VOID_STATUS_ID} />;
    handleModalData(voidbill, "md");
  };

  return (
    <>
      <Helmet>
        <title>Vendor Portal || View Bill</title>
      </Helmet>
      <Loader isLoading={loader} />
      {
        (user?.RoleId === SUPER_ADMIN_ID && viewBill.StatusId === PAYMENT_PENDING && viewBill?.BillStatusId !== VOID_STATUS_ID && viewBill?.BillStatusId !== HOLD_STATUS_ID) && (
          <div className="d-flex justify-content-end">
            <span className="success-btn mb-2">
              <button className="btn" onClick={handlePayment}>Make Payment</button>
            </span>
          </div>)
      }
      <div className="container-section">

        <div className="section-inv-header">
          {showbackButton && (
            <button onClick={handleGoBack} className="back-viewbtn me-1">
              <div className="svgsize">
                <BackButtonWhite />
              </div>
            </button>
          )}

          <h3 className="bill-header-title">Bill</h3>
          <div className="view-bill-container d-flex">
            {loader && <Loader />}
            <span className="">
              {user.RoleId === ADMIN_MANAGER_ID && (
                viewBill?.StatusId !== VENDOR_REJECT &&
                viewBill?.StatusId !== FULLY_PAID && viewBill?.BillStatusId !== VOID_STATUS_ID) && (
                  <div>
                    {viewBill?.BillStatusId !== HOLD_STATUS_ID ? (
                      <span className="hold-btn hold-bill-button me-2">
                        <button
                          id="holdButton"
                          className="btn"
                          onClick={handleButtonClick}
                        >
                          Hold
                        </button>
                      </span>
                    ) : (
                      <span className="success-btn release-bill-button me-2">
                        <button
                          id="releaseButton"
                          className="btn"
                          onClick={handleButtonClick}
                        >
                          Release
                        </button>
                      </span>
                    )}
                  </div>
                )}
            </span>
            <span className="">
              {(viewBill.StatusId === PAYMENT_PENDING || viewBill.StatusId === VENDOR_REJECT) &&
                user.RoleId === ADMIN_MANAGER_ID && viewBill.BillStatusId !== VOID_STATUS_ID && (
                  <span className="danger-btn void-bill-button">
                    <button
                      type="button"
                      onClick={() => handlerVoidBill()}
                      className="btn"
                    >
                      Void
                    </button>
                  </span>
                )}
            </span>
          </div>
        </div>

        <div className="form-style">
          <div className="data-inv-info">
            <div className="data-inv-group-1">
              <BillToContent />
              <div className="d-flex row flex-column align-items-between">
                <div className="col-md-9">
                  <div className="data-details-4 mb-3">
                    <div className="section-sub-heading">
                      <h4>Vendor Details:</h4>
                    </div>
                    <div className="d-flex align-items-center mb-1">
                      <span className=" data-label">Legal Name</span>
                      <span className="data-semicolon">:</span>
                      <span className="data-value">
                        {viewBill?.LegalName}
                      </span>
                    </div>
                    <div className="d-flex align-items-center mb-1">
                      <span className="data-label">Purchase Contract</span>
                      <span className="data-semicolon">:</span>
                      <span className="data-value">
                        <Link
                          className=""
                          to={`/view-purchase-contract/${viewBill?.ContractId}`}
                          target="_blank"
                        >
                          {viewBill?.DocumentNo}
                        </Link>
                      </span>
                    </div>
                  </div>
                  {user.RoleId !== VENDORID && (
                    <div className="data-details-4">
                      <div className="section-sub-heading">
                        <h4>Integration:</h4>
                      </div>
                      <div className="d-flex align-items-center mb-1">
                        <span className=" data-label">NetSuite Id</span>
                        <span className="data-semicolon">:</span>
                        <span className="data-value">
                          {viewBill?.ExternalId ? viewBill?.ExternalId : " N/A"}
                        </span>
                      </div>

                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="data-inv-group-2">
              <div className="data-details-4">
                <div className="d-flex align-items-center mb-1">
                  <span className=" data-label">Bill#</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">
                    {viewBill?.InvDocumentNo}
                  </span>
                </div>
                <div className="d-flex align-items-center  mb-1">
                  <span className=" data-label">Payment Id</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">
                    {viewBill?.PaymentTransactionId ? (
                      <Link
                        to={`/view-payment/${viewBill?.PaymentTransactionId}`}
                        target="_blank"
                        className=""
                      >
                        {viewBill?.PaymentId}{" "}
                      </Link>
                    ) : viewBill?.PaymentId ? (
                      viewBill?.PaymentId
                    ) : (
                      "N/A"
                    )}
                  </span>
                </div>

                <div className="d-flex align-items-center  mb-1">
                  <span className=" data-label">Reference Number</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">
                    {viewBill?.ReferenceNo ? viewBill?.ReferenceNo : "N/A"}
                  </span>
                </div>
                <div className="d-flex align-items-center  mb-1">
                  <span className=" data-label">Bill Date</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">
                    {moment(viewBill?.BillDate)
                      .format("D/MM/YYYY")
                      ?.slice(0, 10)}
                  </span>
                </div>
                <div className="d-flex align-items-center  mb-1">
                  <span className=" data-label">Due Date</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">
                    {moment(viewBill?.DueDate)
                      .format("D/MM/YYYY")
                      ?.slice(0, 10)}
                  </span>
                </div>
                <div className="d-flex align-items-center  mb-1">
                  <span className=" data-label">Status</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">{viewBill?.BillStatusId === PARTIALLY_PAID_STATUS_ID ? "Partially Paid" : viewBill?.BillStatusId === VOID_STATUS_ID ? "Void" : viewBill?.StatusName}</span>
                </div>
                {viewBill?.VoidReason ? (
                  <div className="d-flex rej-reason-box mb-1">
                    <span className="data-label">Void Reason</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value pe-0">
                      {viewBill.VoidReason}
                    </span>
                  </div>
                ) : viewBill?.RejectionReason ? (
                  <div className="d-flex rej-reason-box mb-1">
                    <span className="data-label">Rejection Reason</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value pe-0">
                      {viewBill.RejectionReason}
                    </span>
                  </div>
                ) : (
                  <div className="d-flex rej-reason-box mb-1">
                    <span className="data-label">Rejection Reason</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value pe-0">N/A</span>
                  </div>
                )}
                {viewBill?.DepartmentName && (
                  <div className="d-flex align-items-center mb-1">
                    <span className=" data-label">Department</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value pe-0">{viewBill?.DepartmentName}</span>
                  </div>
                )}
                <div className="d-flex align-items-center mb-1">
                  <span className=" data-label">Hold</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">{viewBill.BillStatusId === HOLD_STATUS_ID ? "Yes" : "No"}</span>
                </div>
                {viewBill?.HoldReason && (
                  <div className="d-flex align-items-center mb-1">
                    <span className=" data-label">Hold Reason</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value pe-0">{viewBill?.HoldReason}</span>
                  </div>
                )}
                {viewBill?.ReleaseReason && (
                  <div className="d-flex align-items-center mb-1">
                    <span className=" data-label">Release Reason</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value pe-0">{viewBill?.ReleaseReason}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="form-style">
          <div className="section-sub-heading">
            <h3>Item Description</h3>
          </div>
          <div className="table-responsive tbl-body-shadow">
            <table className="w-100  table-theme-1">
              <thead>
                <tr>
                  <th className="text-left">Item</th>
                  <th className="text-left">Description</th>
                  <th className="text-end">Rate</th>
                  <th className="text-end">Quantity</th>
                  {viewBill?.LineGST && (
                    <>
                      <th className="text-end">CGST(%)</th>
                      <th className="text-end">SGST(%)</th>
                      <th className="text-end">UTGST(%)</th>
                      <th className="text-end">IGST(%)</th>
                      <th className="text-end">GST Amount</th>
                    </>
                  )}
                  <th className="text-end">Amount</th>
                </tr>
              </thead>
              <tbody>
                {viewBill?.LineItems && viewBill?.LineItems.length > 0 ? (
                  viewBill.LineItems.map((row, index) => (
                    <tr key={index}>
                      <td className="text-left"><p className=" text-nowrap">{row.ItemName}</p></td>
                      <td className="text-left">{row.ItemDescription ? row.ItemDescription : "N/A"}</td>
                      <td className="text-end">
                        <p className=" text-nowrap">{viewBill?.CurrencySymbol} {row?.Rate?.toFixed(2)}</p>
                      </td>
                      <td className="text-end">
                        {row?.Quantity?.toFixed(2)}
                      </td>
                      {viewBill?.LineGST && (
                        <>
                          <td className="text-end">
                            {row?.CGST ? row?.CGST : 0}
                          </td>
                          <td className="text-end">
                            {row?.SGST ? row?.SGST : 0}
                          </td>
                          <td className="text-end">
                            {row?.UTGST ? row?.UTGST : 0}
                          </td>
                          <td className="text-end">
                            {row?.IGST ? row?.IGST : 0}
                          </td>
                          <td className="text-end">
                            {viewBill?.CurrencySymbol} {row?.GST_Total?.toFixed(2)}
                          </td>
                        </>
                      )}
                      <td className="text-end">
                        <p className=" text-nowrap">{viewBill?.CurrencySymbol} {row?.Amount?.toFixed(2)}</p>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={viewBill?.LineGST ? "9" : "7"}
                      className="text-center"
                    >
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="form-style">
          <div className="section-sub-heading">
            <h3>Files</h3>
          </div>

          <div className="pur-item-list table-responsive tbl-body-shadow mb-2">
            <table className="w-100  table-theme-1">
              <thead className="table-heads">
                <tr>
                  <th className="text-left">File Name</th>
                  <th className="text-left">File Type</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>

              <tbody>
                {viewBill?.Files && viewBill?.Files.length > 0 ? (
                  viewBill.Files.map((row, index) => (
                    <tr key={index}>
                      <td className="text-left  text-nowrap">{row.FileName}</td>
                      <td className="text-left">Pdf</td>
                      <td>
                        <div className="cursor-pointer d-flex align-items-center justify-content-center">
                          <div className="download-btn-container">
                            <Link
                              to={`${BASE_IMAGE_URL}/${row.FilePath}`}
                              download
                              target="_blank"
                            >
                              <button type="button" className="btn">
                                <DownloadIcon /> Download
                              </button>
                            </Link>
                          </div>
                          <div className="preview-btn-container ms-2">
                            <Link
                              className="ms-2"
                              to={`/preview-pdf/${btoa(row.FilePath)}`}
                              target="_blank"
                            >
                              <button type="button" className="btn">
                                <EyeIcon /> Preview
                              </button>
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="view-footer d-flex justify-content-end mb-20">
            <div className="data-details-3">
              <div className="d-flex align-items-center">
                <span className="data-label">Sub Total</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">
                  {viewBill?.CurrencySymbol} {viewBill?.InvoiceTotal?.toFixed(2)}
                </span>
              </div>
              {viewBill?.Discount !== null && viewBill?.Discount > 0 && (
                <>
                  <div className="d-flex align-items-center">
                    <span className="data-label">Discount</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value">
                      {viewBill?.CurrencySymbol} {viewBill?.Discount?.toFixed(2)}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="data-label">Discount(%)</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value">
                      {viewBill?.DiscountPercentage}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="data-label">Net Amount</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value">
                      {viewBill?.CurrencySymbol} {(viewBill?.InvoiceTotal - viewBill?.Discount)?.toFixed(2)}
                    </span>
                  </div>
                </>
              )}
              {(viewBill?.CGST !== undefined && viewBill?.CGST !== null) && (
                <div className="d-flex align-items-center">
                  <span className="data-label">CGST(%)</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">{viewBill?.CGST}</span>
                </div>
              )}
              {(viewBill?.SGST !== undefined && viewBill?.SGST !== null) && (
                <div className="d-flex align-items-center">
                  <span className="data-label">SGST(%)</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">{viewBill?.SGST}</span>
                </div>
              )}
              {(viewBill?.UTGST !== undefined && viewBill?.UTGST !== null) && (
                <div className="d-flex align-items-center">
                  <span className="data-label">UTGST(%)</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">{viewBill?.UTGST}</span>
                </div>
              )}
              {(viewBill?.IGST !== undefined && viewBill?.IGST !== null) && (
                <div className="d-flex align-items-center">
                  <span className="data-label">IGST(%)</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">{viewBill?.IGST}</span>
                </div>
              )}
              {viewBill?.Tax && (
                <div className="d-flex align-items-center">
                  <span className="data-label">Sales Tax(%)</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">{viewBill?.Tax}</span>
                </div>
              )}

              {viewBill?.SubsidiaryId === 2 ?
                <div className="d-flex align-items-center">
                  <span className="data-label">GST Total</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">
                    {viewBill?.CurrencySymbol} {viewBill?.TaxSubtotal?.toFixed(2)}
                  </span>
                </div>
                :
                <div className="d-flex align-items-center">
                  <span className="data-label">VAT Total</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">
                    {viewBill?.CurrencySymbol} {viewBill?.TaxSubtotal?.toFixed(2)}
                  </span>
                </div>
              }

              {(viewBill?.TDSAmount !== undefined && viewBill?.TDSAmount !== null) && (
                <div className="d-flex align-items-center">
                  <span className="data-label">TDS Amount</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">{viewBill?.CurrencySymbol} {viewBill?.TDSAmount}</span>
                </div>
              )}
              <div className="view-total-amount d-flex align-items-center mt-3">
                <span className="data-label text-align-left">Total</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">
                  {viewBill?.CurrencySymbol} {viewBill?.Total?.toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </div>

        {billHistory?.length > 0 && (
          <div className="">
            <SystemInfo viewHistory={billHistory} />
          </div>
        )}

        {user?.RoleId === ADMIN_MANAGER_ID && viewBill.BillStatusId !== HOLD_STATUS_ID &&
          viewBill.StatusId === VENDOR_MANAGER_PENDING && (
            <div className="d-flex align-items-center justify-content-end mb-20 form-style pt-2">
              <div className="danger-outline-btn">
                <button
                  type="button"
                  className="btn"
                  onClick={handleReject}
                >
                  Reject
                </button>
              </div>
              <div className="success-btn ms-2">
                <button
                  type="submit"
                  className="btn"
                  onClick={() => handleApprove()}
                >
                  Approve
                </button>
              </div>
            </div>
          )}

        {user?.RoleId === VP_ID && viewBill.BillStatusId !== HOLD_STATUS_ID &&
          viewBill.StatusId === VENDOR_VP_PENDING && (
            <div className="d-flex align-items-center justify-content-end mb-20 form-style pt-2">
              <div className="danger-outline-btn">
                <button
                  type="button"
                  className="btn"
                  onClick={handleReject}
                >
                  Reject
                </button>
              </div>
              <div className="success-btn ms-2">
                <button
                  type="submit"
                  className="btn"
                  onClick={() => handleApprove(viewBill?.DepartmentName)}
                >
                  Approve
                </button>
              </div>
            </div>
          )}

        {user?.RoleId === FINANCE_ID && viewBill.BillStatusId !== HOLD_STATUS_ID &&
          viewBill.StatusId === FINANCE_PENDING && (
            <div className="d-flex align-items-center justify-content-end mb-20 form-style pt-2">
              <div className="danger-outline-btn ">
                <button
                  type="button"
                  className="btn"
                  onClick={handleReject}
                >
                  Reject
                </button>
              </div>
              <div className="success-btn ms-2">
                <button
                  type="submit"
                  className="btn"
                  onClick={handleTDSCreation}
                >
                  Add TDS
                </button>
              </div>
            </div>
          )}

      </div>
    </>
  );
};

export default ViewBill;
