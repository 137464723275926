import React, { useEffect, useState } from "react";
import { uploadFile } from "../../../../utils/helpers/useFileUpload";
import { getApi, postApi } from "../../../../utils/services";
import { ADD_BANK_DETAILS, BANK_DETAILS_LIST, PAYMENT_MODE_LIST } from "../../../../utils/apiRoutes";
import AddBankInfoValidation from "../../../../middleware/addBankInfoValidation";
import { useTransactionContext } from "../../../../utils/helpers/useTransaction";

const FinanceInfo = ({ activeStep, steps, setActiveStep, viewVendorData }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const transactionList = useTransactionContext();

    const [bankDetailsList, setBankDetailsList] = useState([])
    const [bankDocument, setBankDocument] = useState([]);
    const [submitDisable, setSubmitDisable] = useState(false);
    const [loader, setLoader] = useState(false);
    const [documentFlag, setDocumentFlag] = useState(false);
    const [paymentModeList, setPaymentModeList] = useState([])
    const [paymentModeId, setPaymentModeId] = useState('')
    const { errors, setErrors, validateForm } = AddBankInfoValidation();
    const [inputValues, setInputValues] = useState({})

    useEffect(() => {
        const handleViewPaymentMode = async () => {
            let result = await getApi(`${PAYMENT_MODE_LIST}?subsidiaryId=${viewVendorData.SubsidiaryId}`)
            setPaymentModeList(result.res.paymentMode_list)
        }
        handleViewPaymentMode()
    }, [viewVendorData])

    const handleBankDetails = async () => {
        if (paymentModeId) {
            let result = await getApi(`${BANK_DETAILS_LIST}/${paymentModeId}`)
            setBankDetailsList(result.res.bank_details_list)
            const initialValues = result.res.bank_details_list.reduce((acc, curr) => {
                acc[curr.FieldName] = { fieldId: curr.id, fieldValue: '' };
                return acc;
            }, {});
            setInputValues(initialValues)
        }
    }

    useEffect(() => {
        handleBankDetails()
    }, [paymentModeId])

    const handleFileChange = (e) => {
        const newfile = e.target.files;
        setBankDocument(Array.from(newfile));

        const updatedErrors = { ...errors };
        delete updatedErrors.bankDocument;

        setErrors(updatedErrors);

    };

    const handlePayMode = (e) => {
        setPaymentModeId(e.target.value)
        setDocumentFlag(true)
        if (errors.paymentModeId) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                paymentModeId: "",
            }));
        }

        setErrors({});
    }

    const handleInputChange = (fieldName, id, value) => {

        if (fieldName === 'Account Number') {
            // const newVal = value.replace(/[^0-9]/g, '');

            setInputValues((prevValues) => ({
                ...prevValues,
                [fieldName]: { fieldId: id, fieldValue: value },
            }));
        }

        else {
            setInputValues((prevValues) => ({
                ...prevValues,
                [fieldName]: { fieldId: id, fieldValue: value },
            }));
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: '',
        }));

    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isValid = validateForm(paymentModeId, inputValues, bankDocument);

        if (isValid) {

            setLoader(true);
            setSubmitDisable(true);

            let bankFile = {}
            if (bankDocument.length <= 0) {
                bankFile = { BankDocumentName: null, BankDocumentPath: null }
            }

            const filePromises = bankDocument.map(async (file) => {
                if (!!file) {
                    const result = await uploadFile("document", 'finance', 'vendor', file);
                    bankFile = { BankDocumentName: result.fileName, BankDocumentPath: result.filePath }
                }
            });

            await Promise.all(filePromises);

            const updatedValues = Object.entries(inputValues).reduce((acc, [key, value]) => {
                const newKey = key.replace(/\s+/g, '');
                acc[newKey] = value;
                return acc;
            }, {});

            const result = await postApi(ADD_BANK_DETAILS, {
                "vendorId": user.id,
                "transactionId": transactionList[3].id,
                "roleId": user.RoleId,
                "bankDetails": updatedValues,
                "paymentModeId": paymentModeId,
                "bankDocument": bankFile,
            })

            if (result.res.success) {
                setActiveStep(activeStep + 1)
            }
        }
    };

    return (
        <>
            <div className="edit-vendor-container mt-4 mb-3">
                <div className="section-header">
                    <h3>Financial Information</h3>
                </div>
                <div className="form-style">
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 mb-20 position-relative">
                            <label htmlFor="paymentMode" className="form-label">Payment Mode<span className="required">*</span></label>
                            <select name="paymentMode" id="paymentMode" className="form-select" value={paymentModeId} onChange={handlePayMode} >
                                <option value="">Select</option>
                                {
                                    paymentModeList?.map((item, index) => (
                                        <option key={index} value={item?.id}>{item?.Name}</option>))
                                }
                            </select>
                            {errors.paymentModeId && <span className="error-text">{errors.paymentModeId}</span>}
                        </div>
                        {paymentModeId && bankDetailsList.map((row, index) => (
                            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 mb-20 position-relative" key={index}>
                                <label htmlFor="" className="form-label">{row.FieldName}<span className="required">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={`Enter ${row.FieldName}`}
                                    value={inputValues[row.FieldName]?.fieldValue || ''}
                                    onChange={(e) => handleInputChange(row.FieldName, row.id, e.target.value)}
                                />
                                {errors[row.FieldName] && (
                                    <span className="error-text">{errors[row.FieldName]}</span>
                                )}
                            </div>
                        ))}
                        {paymentModeId == 1 && documentFlag && (
                            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6">
                                <label htmlFor="fileInput" className="form-label">Upload Document<span className="required">*</span></label>
                                <div className="upload-documents-fields">
                                    <div className="input-group file-input-container">
                                        <input
                                            type="file"
                                            id="files"
                                            className="form-control input-fields-cstm"
                                            onChange={handleFileChange}
                                            multiple
                                        />
                                        {errors.bankDocument && <span className="error-text">{errors.bankDocument}</span>}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="stepper-next-btn">
                {activeStep !== 0 && activeStep !== steps.length - 1 && (
                    <span className="danger-outline-btn me-2">
                        <button
                            onClick={() => setActiveStep(activeStep - 1)}
                            className="btn previousstepper"
                        >
                            Previous
                        </button>
                    </span>
                )}
                <div className="success-btn ">
                    <button
                        onClick={(e) => handleSubmit(e)}
                        className="btn"
                        disabled={submitDisable}
                    >
                        {loader ?
                            <>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </>
                            :
                            "Submit"
                        }
                    </button>
                </div>
            </div>
        </>
    )
}

export default FinanceInfo