import { useState } from 'react';
import { validateOTP } from '../utils/helpers/globalValidation';

const OTPFormValidation = () => {
  const [errors, setErrors] = useState({});

  const validateForm = (otp) => {
    const _errors = {};

    const otpError = validateOTP(otp);
    if (otpError) {
      _errors["otp"] = otpError;
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  };

  return { errors, setErrors, validateForm };
};

export default OTPFormValidation;
