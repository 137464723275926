import React, { useState, useEffect } from 'react'
import exporticon from "../../../assets/image/Excel_Download_Icon.png";
import { getApi } from '../../../utils/services';
import { BILLS_API, STATUS_LIST, VENDOR } from '../../../utils/apiRoutes';
import { Link } from 'react-router-dom';
import moment from "moment";
import { useTransactionContext } from '../../../utils/helpers/useTransaction';
import { VENDOR_APPROVE, VOID_STATUS_ID } from "../../../utils/constant";
import Select from "react-select";
import Loader from '../../common/Loader';
import { handleList } from '../../../utils/helpers/globalMethod';

const BillReport = () => {

  const monthStartDate = moment().startOf('month');
  const monthEndDate = moment().endOf('month');

  const transactionList = useTransactionContext();
  const [statusList, setStatusList] = useState([])
  const [statusId, setStatusId] = useState('');
  const [fromDate, setFromDate] = useState(monthStartDate.format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(monthEndDate.format('YYYY-MM-DD'));
  const [billReportList, setBillReportList] = useState([])
  const [loader, setLoader] = useState(false);
  const [totalTds, setTotalTds] = useState()
  const [totalAmount, setTotalAmount] = useState()
  const [vendorList, setVendorList] = useState([]);
  const [vendorName, setVendorName] = useState('');
  const [currencySymbol, setCurrencySymbol] = useState('');

  useEffect(() => {
    const fetchList = async () => {
      await handleList(setVendorList);
    };

    fetchList();
  }, []);

  const handleStatusData = async () => {
    const result = await getApi(`${STATUS_LIST}?transactionId=${transactionList[1].id}`);
    setStatusList(result.res.status_list);
  };

  useEffect(() => {
    handleStatusData();
  }, []);

  useEffect(() => {
    const handleBillReport = async () => {
      setLoader(true)
      const result = await getApi(`${BILLS_API}?billType=0&startDate=${fromDate}&endDate=${toDate}&type='report'`)
      const updatedResult = result.res.bill_list.filter((i) => i.BillStatusId !== VOID_STATUS_ID)
      setLoader(false)
      setBillReportList({ ...result.res, bill_list: updatedResult })
      const totalTdsAmount = updatedResult.reduce((total, obj) => total + obj.TDSAmount, 0);
      const totalGrandAmount = updatedResult.reduce((total, obj) => total + obj.Total, 0);
      setTotalTds(totalTdsAmount);
      setTotalAmount(totalGrandAmount);
      setCurrencySymbol(updatedResult?.find(item => item.SubsidiaryId === 2)?.CurrencySymbol);
    }
    handleBillReport()
  }, [])

  const handleExportData = () => {
    const csvData = [
      ['Bill Number', 'Purchase Contract', 'Vendor Name', 'Created Date', 'GrandTotal', 'TDS Amount', 'Status'],
      ...billReportList.bill_list.map(person => [person.DocumentNo, person.ContractDocumentId, person.LegalName, moment(person.CreatedAt).format('D/MM/YYYY')?.slice(0, 10), person.Total, person.TDSAmount, person.StatusName]),
      [], ["", "", "", "Total", totalAmount, totalTds, "", ""]
    ];
    const csvContent = csvData.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Bill.csv';
    link.click();
    URL.revokeObjectURL(url);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    const result = await getApi(`${BILLS_API}?billType=0&startDate=${fromDate}&endDate=${toDate}&status=${statusId}&vendorName=${vendorName}&type='report'`);
    const updatedResult = result.res.bill_list.filter((i) => i.BillStatusId !== VOID_STATUS_ID)
    setLoader(false);
    setBillReportList({ ...result.res, bill_list: updatedResult });
    const totalTdsAmount = updatedResult.reduce((total, obj) => total + obj.TDSAmount, 0);
    const totalGrandAmount = updatedResult.reduce((total, obj) => total + obj.Total, 0);
    const currency = updatedResult.find(item => item.SubsidiaryId === 2)?.CurrencySymbol;
    setCurrencySymbol(currency);
    setTotalTds(totalTdsAmount);
    setTotalAmount(totalGrandAmount);
  };

  const options = [
    { value: '', label: 'Select Vendor' },
    ...vendorList.map((item) => ({
      value: item.LegalName,
      label: item.LegalName,
    })),
  ];

  return (
    <form>
      <div className="form-style">
        <div className="report-inpfiled-container">

          <div className="field-flex mb-20">
            <label htmlFor="vendorName" className="form-label">
              Vendor Name
            </label>
            <Select
              id="vendorName"
              className=""
              name="vendorName"
              value={vendorList.find((item) => item.id === vendorName)}
              onChange={(item) => setVendorName(item?.value || "")}
              options={options}
              isClearable={vendorName}
            />
          </div>

          <div className="field-flex mb-20">
            <label htmlFor="transactionStatus" className="form-label">
              Transaction Status
            </label>

            <select
              id="transactionStatus"
              className="form-select"
              name="transactionStatus"
              value={statusId}
              onChange={(e) => setStatusId(e.target.value)}
            >
              <option value="">Select </option>
              {statusList.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.Name}
                </option>
              ))}
            </select>
          </div>

          <div className="field-flex mb-20">
            <label htmlFor="fromDate" className="form-label">
              From Date
            </label>
            <input
              type="date"
              id="fromDate"
              className="form-control"
              name="fromDate"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              onKeyDown={(e) => e.preventDefault()}
            ></input>
          </div>

          <div className="field-flex mb-20">
            <label htmlFor="toDate" className="form-label">
              To Date
            </label>
            <input
              type="date"
              id="toDate"
              className="form-control"
              name="toDate"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              onKeyDown={(e) => e.preventDefault()}
            />
          </div>

          <div className="field-flex align-self-end mb-20">
            <div className="success-btn ">
              <button type="submit" className="btn" onClick={handleSubmit}>
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      <hr className='m-0' />

      <Loader isLoading={loader} />
      <>
        {
          billReportList.record_count === 0 ?
            <div className="form-style">
              <div className='section-sub-heading'>
                <h3 className="text-center">{billReportList.message}</h3>
              </div>
            </div>
            :
            <div className="form-style pb-4">
              <div className='d-flex align-items-center justify-content-between'>
                <div className='section-sub-heading'>
                  <h3>Bill</h3>
                </div>
                <div className='Export-Icon' onClick={handleExportData}>
                  <img src={exporticon} alt="" className='cursor-pointer' />
                </div>
              </div>
              <div className='table-responsive tbl-body-shadow'>
                <table className="w-100 table-theme-1" >
                  <thead>
                    <tr>
                      <th className="text-right">Bill Number</th>
                      <th className="text-left">Purchase Contract</th>
                      <th className="text-left">Vendor Name</th>
                      <th className="text-center">Created Date</th>
                      <th className="text-end">Grand Total</th>
                      <th className="text-end">TDS Amount</th>
                      <th className="text-left">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {billReportList.bill_list?.map((row, index) => (
                      <tr key={index}>
                        <td className="text-right"><Link to={`/view-bill/${row?.id}`} target="_blank" className="">{row.DocumentNo} </Link></td>
                        <td className="text-left"><Link to={`/view-purchase-contract/${row?.ContractId}`} target="_blank" className="">{row?.ContractDocumentId ? row?.ContractDocumentId : "N/A"} </Link></td>
                        <td className="text-left">{row?.LegalName}</td>
                        <td className="text-center">{moment(row?.CreatedAt).format('D/MM/YYYY')?.slice(0, 10)}</td>
                        <td className="text-end">{row?.CurrencySymbol} {row?.Total?.toFixed(2)}</td>
                        <td className="text-end">{row?.CurrencySymbol} {row?.TDSAmount?.toFixed(2)}</td>
                        <td className="text-left">{row?.StatusName}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr className="total-show">
                      <td className="text-end text-dark table-footer fw-bold"></td>
                      <td className="text-end text-dark table-footer fw-bold"></td>
                      <td className="text-end text-dark table-footer fw-bold"></td>
                      <td className="text-left text-dark fw-bold text-center">Total</td>
                      <td className="text-end text-dark fw-bold">{currencySymbol} {totalAmount && totalAmount?.toFixed(2)}</td>
                      <td className="text-end text-dark fw-bold">{currencySymbol} {totalTds && totalTds?.toFixed(2)}</td>
                      <td className="text-end text-dark fw-bold"></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
        }
      </>

    </form>
  )
}

export default BillReport;