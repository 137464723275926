import React, { useState, useEffect } from "react";
import exporticon from "../../../assets/image/Excel_Download_Icon.png";
import { getApi } from "../../../utils/services";
import { CONTRACT_LIST, STATUS_LIST, VENDOR } from "../../../utils/apiRoutes";
import { Link } from "react-router-dom";
import moment from "moment";
import { useTransactionContext } from "../../../utils/helpers/useTransaction";
import Select from "react-select";
import Loader from "../../common/Loader";
import { handleList } from "../../../utils/helpers/globalMethod";

const ContractReport = () => {

  const yearStartDate = moment().startOf('year');
  const yearEndDate = moment().endOf('year');

  const transactionList = useTransactionContext();
  const [statusList, setStatusList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [vendorName, setVendorName] = useState('');
  const [startDate, setStartDate] = useState(yearStartDate.format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(yearEndDate.format('YYYY-MM-DD'));
  const [statusId, setStatusId] = useState("");
  const [loader, setLoader] = useState(false);
  const [contractReportList, setContractReportList] = useState([]);

  useEffect(() => {
    const fetchList = async () => {
      await handleList(setVendorList);
    };

    fetchList();
  }, []);

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleStatusData = async () => {
    if (
      transactionList &&
      transactionList.length > 0 &&
      transactionList[2].id
    ) {
      const result = await getApi(
        `${STATUS_LIST}?transactionId=${transactionList[2].id}`
      );
      setStatusList(result.res.status_list);
    }
  };

  useEffect(() => {
    handleStatusData();
  }, [transactionList]);

  useEffect(() => {
    const handleContractReport = async (e) => {
      setLoader(true);
      const result = await getApi(
        `${CONTRACT_LIST}?startDate=${startDate}&endDate=${endDate}&type='report'`
      );
      setLoader(false);
      setContractReportList(result.res);
    };

    handleContractReport();
  }, []);

  const handleExportData = () => {
    const csvData = [
      ["Purchase Contract", "Vendor", "StartDate", "EndDate", "Status"],
      ...contractReportList.contract_list.map((person) => [
        person.DocumentNo,
        person.LegalName,
        moment(person.StartDate).format("D/MM/YYYY")?.slice(0, 10),
        moment(person.EndDate).format("D/MM/YYYY")?.slice(0, 10),
        person.StatusName,
      ]),
    ];
    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "PurchaseContract.csv";
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    const result = await getApi(`${CONTRACT_LIST}?startDate=${startDate}&endDate=${endDate}&status=${statusId}&vendorName=${vendorName}&type='report'`);
    setLoader(false);
    setContractReportList(result.res);
  };

  const options = [
    { value: '', label: 'Select Vendor' },
    ...vendorList.map((item) => ({
      value: item.LegalName,
      label: item.LegalName,
    })),
  ];

  return (
    <form>
      <div className="form-style">
        <div className="report-inpfiled-container">

          <div className="field-flex mb-20">
            <label htmlFor="VendorName" className="form-label">
              Vendor Name
            </label>
            <Select
              id="vendorName"
              className=""
              name="vendorName"
              value={vendorList.find((item) => item.id === vendorName)}
              onChange={(item) => setVendorName(item?.value || "")}
              options={options}
              isClearable={vendorName}
            />
          </div>

          <div className="field-flex mb-20">
            <label htmlFor="transactionStatus" className="form-label">
              Transaction Status
            </label>

            <select
              id="transactionStatus"
              className="form-select"
              name="transactionStatus"
              value={statusId}
              onChange={(e) => setStatusId(e.target.value)}
            >
              <option value="">Select</option>
              {statusList.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.Name}
                </option>
              ))}
            </select>
          </div>

          <div className="field-flex mb-20">
            <label htmlFor="startDate" className="form-label">
              Start Date
            </label>
            <input
              type="date"
              id="startDate"
              className="form-control"
              name="startDate"
              value={startDate}
              onChange={handleStartDate}
              onKeyDown={(e) => e.preventDefault()}
            />
          </div>

          <div className="field-flex mb-20">
            <label htmlFor="endDate" className="form-label">
              End Date
            </label>
            <input
              type="date"
              id="endDate"
              className="form-control"
              name="endDate"
              value={endDate}
              onChange={handleEndDate}
              min={startDate}
              onKeyDown={(e) => e.preventDefault()}
            ></input>
          </div>

          <div className="field-flex align-self-end mb-20">
            <div className="success-btn ">
              <button type="submit" className="btn" onClick={handleSubmit}>
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      <hr className="m-0" />

      <Loader isLoading={loader} />

      <>
        {contractReportList.record_count === 0 ? (
          <div className="form-style">
            <div className="section-sub-heading">
              <h3 className="text-center">{contractReportList.message}</h3>
            </div>
          </div>
        ) : (
          <div className="form-style pb-4">
            <div className="d-flex align-items-center justify-content-between">
              <div className="section-sub-heading">
                <h3>Purchase Contract</h3>
              </div>
              <div className="Export-Icon" onClick={handleExportData}>
                <img src={exporticon} alt="" className="cursor-pointer" />
              </div>
            </div>
            <div className="table-responsive tbl-body-shadow">
              <table className="w-100 table-theme-1">
                <thead>
                  <tr>
                    <th className="text-right">Purchase Contract</th>
                    <th className="text-left">Vendor</th>
                    <th className="text-center">StartDate</th>
                    <th className="text-center">EndDate</th>
                    <th className="text-left">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {contractReportList.contract_list?.map((row, index) => (
                    <tr key={index}>
                      <td className="text-right">
                        <Link
                          to={`/view-purchase-contract/${row?.id}`}
                          className=""
                          target="_blank"
                        >
                          {row.DocumentNo}
                        </Link>
                      </td>
                      <td className="text-left">{row?.LegalName}</td>
                      <td className="text-center">
                        {moment(row.StartDate)
                          .format("D/MM/YYYY")
                          ?.slice(0, 10)}
                      </td>
                      <td className="text-center">
                        {moment(row.EndDate)
                          .format("D/MM/YYYY")
                          ?.slice(0, 10)}
                      </td>
                      <td className="text-left">{row?.StatusName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </>

    </form>
  );
};

export default ContractReport;
