import React, { useEffect, useState } from "react";
import { CONTRACT_LIST } from "../../../utils/apiRoutes";
import { getApi } from "../../../utils/services";
import { useFeedsColumnContracts } from "../../../utils/helpers/tableHelpers/useFeedColumnContracts";
import { VENDORID } from "../../../utils/constant";
import Contract from "../../Table/Contract";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";

const PurchaseContactList = () => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const [purchaseContract, setPurchaseContract] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [globalSearch, setGlobalSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState(""); 
  const pageSize = 10;

  const handlerPurchaseContractList = async (pageNumber, pageSize, searchQuery) => {
    try {
      let routeName;
      if (user.RoleId === VENDORID) {
        routeName = `${CONTRACT_LIST}?vendorId=${user.id}&page=${pageNumber}&pageSize=${pageSize}&globalSearch=${searchQuery}`;
      } else {
        routeName = `${CONTRACT_LIST}?page=${pageNumber}&pageSize=${pageSize}&globalSearch=${searchQuery}`;
      }
      setLoader(true);
      let result = await getApi(routeName);

      const totalItems = result.res.record_count;
      setTotalPages(Math.ceil(totalItems / pageSize));
      setPurchaseContract(result.res.contract_list);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const purchasecolumns = useFeedsColumnContracts(handlerPurchaseContractList, pageNumber, pageSize, searchQuery);

  useEffect(() => {
    handlerPurchaseContractList(pageNumber, pageSize, searchQuery);
  }, [pageNumber, pageSize, searchQuery]);

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const handleSearchSubmit = () => {
    if (globalSearch !== searchQuery) {
      setSearchQuery(globalSearch);
      setPageNumber(1);
    } else {
      handlerPurchaseContractList(pageNumber, pageSize, searchQuery);
    }
  };

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Contract List</title>
      </Helmet>

      <Loader isLoading={loader} />

      <div className="container-section">
        <div className="section-header">
          <h3>Purchase Contracts</h3>
        </div>
        <div className="form-style">
          <Contract
            columns={purchasecolumns}
            data={purchaseContract}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            globalSearch={globalSearch}
            setGlobalSearch={setGlobalSearch}
            handlerPurchaseContractList={handlerPurchaseContractList}
            pageSize={pageSize}
            handleSearchSubmit={handleSearchSubmit} 
          />
        </div>
      </div>
    </>
  );
};

export default PurchaseContactList;