import { useContext, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStatusRenderer } from '../useStatus';
import ApproveButton from '../../../assets/image/ApproveButton';
import RejectButton from '../../../assets/image/RejectButton';
import edit from "../../../assets/image/edit2.png"
import eyeIcon from "../../../assets/image/eye.png"
import { ADMIN_MANAGER_ID, FINANCE_ID, FINANCE_PENDING, VENDOR_MANAGER_PENDING, VENDOR_REJECT, VENDOR_VP_PENDING, VP_ID, SUPER_ADMIN_ID, HOLD_STATUS_ID, BILL_APPROVE_ID, ADMIN_EXECUTIVE_PENDING, ADMIN_EXECUTIVE_ID, VOID_STATUS_ID } from '../../constant';
import { ModalContext } from '../../Context';
import FinanceApporveIcon from '../../../assets/image/FinanceApporveIcon';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ApproveModal from '../../../components/modal/ApproveModal';
import Alert from '../../../components/modal/Alert';
import RejectionModal from '../../../components/modal/RejectionModal';
import { HOLD_BILL_MESSAGE, PENDING_DETAILS_MESSAGE } from '../globalErrorMessage';
import { BILL_NUMBER, PURCHASE_CONTRACT, VENDOR_NAME } from './TableHeader';

// Custom hook for defining FeedsColumns
export const useFeedsColumnBills = (handlerBillList, pageNumber, pageSize, globalSearch, billStatusId) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const modalContext = useContext(ModalContext);
    const { handleModalData } = modalContext;
    const navigate = useNavigate();

    const handlerApprove = (data) => {
        if (data.BillStatusId === HOLD_STATUS_ID) {
            let pendingModal = <Alert message={HOLD_BILL_MESSAGE} />;
            handleModalData(pendingModal, "md");
        }
        else {
            let app = <ApproveModal data={data} handlerList={handlerBillList} pageNumber={pageNumber} pageSize={pageSize} globalSearch={globalSearch} type={BILL_APPROVE_ID} billStatusId={billStatusId} />
            handleModalData(app, "md");
        }
    }

    const handlerReject = (data) => {
        if (data.BillStatusId === HOLD_STATUS_ID) {
            let pendingModal = <Alert message={HOLD_BILL_MESSAGE} />;
            handleModalData(pendingModal, "md");
        }
        else {
            let reject = <RejectionModal data={data} handlerBillList={handlerBillList} pageNumber={pageNumber} pageSize={pageSize} globalSearch={globalSearch} billStatusId={billStatusId} />;
            handleModalData(reject, "md");
        }
    }

    const handlerTds = (data) => {
        if (data.BillStatusId === HOLD_STATUS_ID) {
            let pendingModal = <Alert message={HOLD_BILL_MESSAGE} />;
            handleModalData(pendingModal, "md");
        }
        else {
            navigate(`/add-tds/${data.id}`)
        }
    }

    const handlerBillView = (data) => {
        if (data.IndividualBill) {
            navigate(`/view-individual-bill/${data.id}`);
        } else {
            navigate(`/view-bill/${data.id}`);
        }
    }

    const editBill = (data) => {
        if (data?.VendorStatus === VENDOR_MANAGER_PENDING && data?.IsSelfService === true) {
            let alert = <Alert message={PENDING_DETAILS_MESSAGE} />;
            handleModalData(alert, "md");
        }
        else if (data.BillStatusId === HOLD_STATUS_ID) {
            let pendingModal = <Alert message={HOLD_BILL_MESSAGE} />;
            handleModalData(pendingModal, "md");
        }
        else {
            navigate(`/edit-bill/${data.id}`);
        }
    }

    const columns = useMemo(() => [
        {
            Header: BILL_NUMBER,
            accessor: "DocumentNo",
        },
        {
            Header: PURCHASE_CONTRACT,
            accessor: "ContractDocumentId",
            Cell: ({ row }) => {
                const contractId = row?.original?.ContractDocumentId;
                return contractId ? (
                    <Link className='' to={`/view-purchase-contract/${row?.original?.ContractId}`} target='_blank'>
                        {contractId}
                    </Link>
                ) : (
                    "N/A"
                );
            },
        },

        {
            Header: VENDOR_NAME,
            accessor: "LegalName",
        },
        {
            Header: <div className="ms-auto">Grand Total</div>,
            accessor: "Total",
            Cell: ({ row }) => {
                return <><div className='text-end '>{row?.original?.CurrencySymbol} {row?.original?.Total?.toFixed(2)}</div> </>;
            },
        },
        {
            Header: <div className="ms-auto">TDS Amount</div>,
            accessor: "TDSAmount",
            Cell: ({ row }) => {
                return <><div className='text-end '>{row?.original?.TDSAmount ? <>{row?.original?.CurrencySymbol} {row?.original?.TDSAmount?.toFixed(2)} </> : "N/A"} </div> </>;
            },
        },
        {
            Header: <div className='m-auto'>Status</div>,
            accessor: "Name",
            Cell: ({ row }) => {
                return (
                    <>
                        {useStatusRenderer(row)}
                    </>
                );
            },
            disableSortBy: true,
        },
        {
            Header: "Action",
            accessor: (data) => {
                return (
                    <div className="d-flex align-item-center w-100 ">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips1">
                                    View
                                </Tooltip>
                            }
                        >
                            <img
                                src={eyeIcon}
                                alt=" "
                                className="cursor-pointer cstm-eye-icon-gap"
                                onClick={() => handlerBillView(data)}
                            />
                        </OverlayTrigger>

                        {((data.Status === VENDOR_MANAGER_PENDING &&
                            user.RoleId === ADMIN_MANAGER_ID) ||
                            (data.Status === VENDOR_VP_PENDING && user.RoleId === VP_ID) ||
                            (data.Status === FINANCE_PENDING &&
                                user.RoleId === FINANCE_ID)) && (
                                <span className="d-flex">
                                    {data.Status === FINANCE_PENDING ? (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips2">
                                                    Add TDS
                                                </Tooltip>
                                            }
                                        >
                                            <span
                                                onClick={() => handlerTds(data)}
                                                className="cursor-pointer cstm-icon-gap"
                                            >
                                                <FinanceApporveIcon />
                                            </span>
                                        </OverlayTrigger>
                                    ) : (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips3">
                                                    Approve
                                                </Tooltip>
                                            }
                                        >
                                            <span
                                                onClick={() => handlerApprove(data)}
                                                className="cursor-pointer cstm-icon-gap"
                                            >
                                                <ApproveButton />
                                            </span>
                                        </OverlayTrigger>
                                    )}

                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips4">
                                                Reject
                                            </Tooltip>
                                        }
                                    >
                                        <span
                                            onClick={() => handlerReject(data)}
                                            className="cursor-pointer cstm-icon-gap"
                                        >
                                            <RejectButton />
                                        </span>
                                    </OverlayTrigger>
                                </span>
                            )}

                        {(data.Status === VENDOR_REJECT ||
                            (data.Status === ADMIN_EXECUTIVE_PENDING && user.RoleId === ADMIN_EXECUTIVE_ID) ||
                            (data.Status === VENDOR_MANAGER_PENDING &&
                                user.RoleId === ADMIN_MANAGER_ID)) && data.BillStatusId !== VOID_STATUS_ID && (
                                <>
                                    {(user.RoleId !== VP_ID && user.RoleId !== FINANCE_ID && user.RoleId !== SUPER_ADMIN_ID) && (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips5">
                                                    Edit
                                                </Tooltip>
                                            }
                                        >
                                            <img src={edit} alt="" onClick={() => editBill(data)} className="cstm-icon-gap cursor-pointer" />
                                        </OverlayTrigger>
                                    )}
                                </>
                            )}

                    </div>
                );
            },
            disableSortBy: true,
        },
    ], [handlerBillList, pageNumber, pageSize, globalSearch, user]);

    return columns;
};