import { useState } from 'react';
import { validateConfirmPassword, PasswordValidator } from '../utils/helpers/globalValidation';

const ResetPasswordFormValidation = () => {
  const [errors, setErrors] = useState({});

  const validateForm = (password, confpassword) => {
    let _errors = {};
    let formIsValid = true;

    const passwordError = PasswordValidator(password);
    
    if (passwordError) {
      formIsValid = false;
      _errors.newpassword = passwordError;
    }

    const confirmPasswordError = validateConfirmPassword(password, confpassword);
    if (confirmPasswordError) {
      formIsValid = false;
      _errors.confirmpassword = confirmPasswordError;
    }

    setErrors(_errors);
    return formIsValid;
  };

  return { errors, setErrors, validateForm };
};

export default ResetPasswordFormValidation;