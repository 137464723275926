import { useState } from 'react';
import { validateBillDate, validateDepartmentId, validateSubTotal, validateUploadDocuments } from '../utils/helpers/globalValidation';
import { validateTaxFields } from '../utils/helpers/globalValidation';

const AddBillValidation = () => {
  const [errors, setErrors] = useState({});

  const validateForm = (billDate, finalTotalAmount, lineLevelGst, data, subsidiaryId, Files, lines, roleId, departmentId) => {
    let formIsValid = true;
    let errors = {};

    const billDateError = validateBillDate(billDate);
    if (billDateError) {
      formIsValid = false;
      errors.billDate = billDateError;
    }

    const subtotalError = validateSubTotal(finalTotalAmount);
    if (subtotalError) {
      formIsValid = false;
      errors.subtotal = subtotalError;
    }

    const { formIsValid: gstFormIsValid, errors: gstErrors } = validateTaxFields(lineLevelGst, data, subsidiaryId, lines);
    formIsValid = formIsValid && gstFormIsValid;
    errors = { ...errors, ...gstErrors };


    const documentError = validateUploadDocuments(null, Files);
    if (!documentError.formIsValid) {
      formIsValid = false;
      errors.files = documentError.errors.files;
    }

    const departmentError = validateDepartmentId(roleId, departmentId);
    if (departmentError) {
      formIsValid = false;
      errors.departmentId = departmentError;
    }

    setErrors(errors);
    return formIsValid;
  };

  return { errors, setErrors, validateForm };
};

export default AddBillValidation;
