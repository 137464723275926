import React from 'react'

const ReportIcon = () => {
  return (
    <>
      <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.62398 9.92847H2.98682V10.9285H9.62398V9.92847Z" fill="white" />
        <path d="M12.0134 7H2.98682V7.92847H12.0134V7Z" fill="white" />
        <path d="M7.83195 12.9285H2.98682V13.9285H7.83195V12.9285Z" fill="white" />
        <path d="M13.8053 1.92857H11.0841V1.28571C11.0841 0.944722 10.9582 0.617695 10.7342 0.376577C10.5101 0.135459 10.2062 0 9.88938 0H5.11062C4.79377 0 4.48989 0.135459 4.26585 0.376577C4.0418 0.617695 3.91593 0.944722 3.91593 1.28571L3.91593 1.92857H1.19469C0.877839 1.92857 0.573965 2.06403 0.349917 2.30515C0.125869 2.54627 0 2.87329 0 3.21429V16.7143C0 17.0553 0.125869 17.3823 0.349917 17.6234C0.573965 17.8645 0.877839 18 1.19469 18H13.8053C14.1222 18 14.426 17.8645 14.6501 17.6234C14.8741 17.3823 15 17.0553 15 16.7143V3.21429C15 2.87329 14.8741 2.54627 14.6501 2.30515C14.426 2.06403 14.1222 1.92857 13.8053 1.92857ZM5.11062 1.28571H9.88938V3.85714H5.11062V1.28571ZM13.8053 16.7143H1.19469V3.21429H3.91593V5.14286H11.0841V3.21429H13.8053V16.7143Z" fill="white" />
      </svg>
    </>
  )
}

export default ReportIcon