import React, { useContext, useState } from "react";
import { ModalContext } from "../../utils/Context";
import { postApi } from "../../utils/services";
import {
    REJECT_INDIVIDUAL_BILL_API,
    REJECT_PURCHASE_CONTRACT,
    REJECTED_BILL_API,
} from "../../utils/apiRoutes";
import toast from "react-hot-toast";
import { useTransactionContext } from "../../utils/helpers/useTransaction";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "./Alert";

const RejectedAtModal = ({ data, levelValue, handlerList, rejectionType, pageNumber, pageSize, globalSearch, billStatusId }) => {
    const [rejectReason, setRejectReason] = useState("");
    const [level, setLevel] = useState("");
    const modalContext = useContext(ModalContext);
    const { handleModalData } = modalContext;
    const user = JSON.parse(localStorage.getItem("userData"));
    const transactionList = useTransactionContext();
    const [errors, setErrors] = useState({});
    const { closeModal } = modalContext;
    const [disable, setDisable] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleRole = (e) => {
        setLevel(e.target.value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            role: "",
        }));
    };

    const handleRejectionReason = (e) => {
        setRejectReason(e.target.value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            rejectionReason: "",
        }));
    };

    const validateModal = () => {
        let errors = {};
        let isModalValid = true;

        if (!rejectReason) {
            errors.rejectionReason = "Please provide a rejection reason!";
            isModalValid = false;
        }
        if (levelValue.length > 0 && rejectionType === "RejectAt" && !level) {
            errors.role = "Select a role!";
            isModalValid = false;
        }

        setErrors(errors);
        return isModalValid;
    };

    const handleReject = async () => {
        try {
            const isValid = validateModal();

            if (isValid) {
                setDisable(true);

                const isBulk = Array.isArray(data);
                const CurrentData = isBulk ? data[0] : data;

                const payload = {
                    transactionId:
                        CurrentData.IndividualBill === undefined
                            ? transactionList[2].id
                            : CurrentData.IndividualBill
                                ? transactionList[4].id
                                : transactionList[1].id,
                    roleId: user.RoleId,
                    rejectionReason: rejectReason,
                    level: levelValue.length > 0 && rejectionType === "RejectAt" ? Number(level) : "",
                };

                if (CurrentData.IndividualBill === undefined) {
                    payload.contractId = isBulk ? data.map(item => item.id) : [CurrentData.id];
                } else {
                    payload.billId = isBulk ? data.map(item => item.id) : [CurrentData.id];
                }

                const apiRoute =
                    CurrentData.IndividualBill === undefined
                        ? REJECT_PURCHASE_CONTRACT
                        : CurrentData.IndividualBill
                            ? REJECT_INDIVIDUAL_BILL_API
                            : REJECTED_BILL_API;

                const result = await postApi(apiRoute, payload);

                if (result.res.success) {
                    toast.success(result.res.message);
                    handlerList(pageNumber, pageSize, globalSearch, billStatusId);
                    closeModal();
                    const currentPath = location.pathname;

                    if (currentPath === "/") {
                        navigate("/");
                    } else if (currentPath.includes("contract-listing")) {
                        navigate("/contract-listing");
                    } else if (currentPath.includes("invoice-listing")) {
                        navigate("/invoice-listing");
                    } else if (currentPath.includes("individual-bill-list")) {
                        navigate("/individual-bill-list");
                    } else {
                        navigate(-1);
                    }
                }
                if (result.res.holdMessage) {
                    const alert = <Alert message={result.res.holdMessage} />;
                    handleModalData(alert, "md");
                }
            }
        } catch (error) {
            setDisable(false);
            toast.error(error.message);
        } finally {
            setDisable(false);
        }
    };

    return (
        <div className="cstm-frm-mdl">
            <div className="modal-header">
                <h4 className="modal-title">Rejection Reason</h4>
            </div>

            <div className="modal-body">
                <label className="form-label">
                    Are you sure you want to reject {Array.isArray(data) ? "these" : "this"}{" "}
                    {Array.isArray(data)
                        ? data[0].IndividualBill === undefined
                            ? "Purchase Contracts"
                            : data[0].IndividualBill
                                ? "Standalone Bills"
                                : "Bills"
                        : data.IndividualBill === undefined
                            ? "Purchase Contract"
                            : data.IndividualBill
                                ? "Standalone Bill"
                                : "Bill"}{" "}
                    ?
                </label>

                {levelValue.length > 0 && rejectionType === "RejectAt" && (
                    <div className="form-group mb-20 mt-2 position-relative">
                        <select value={level} className="form-select" onChange={handleRole}>
                            <option value="">Select</option>
                            {levelValue?.map((option, index) => (
                                <option key={index} value={option?.level}>
                                    {option?.RoleName}
                                </option>
                            ))}
                        </select>
                        {errors.role && <span className="validation-error">{errors.role}</span>}
                    </div>
                )}

                <div className="form-group">
                    <textarea
                        value={rejectReason}
                        className="form-control reject-modal-text-area"
                        placeholder="Enter the reason here..."
                        id="floatingTextarea"
                        rows={3}
                        maxLength={100}
                        onChange={handleRejectionReason}
                    ></textarea>
                    {errors.rejectionReason && <p className="validation-error">{errors.rejectionReason}</p>}
                </div>
            </div>

            <div className="modal-footer">
                <div className="d-flex align-items-center mb-20">
                    <div className="danger-outline-btn">
                        <button type="button" className="btn" onClick={closeModal}>
                            Cancel
                        </button>
                    </div>
                    <div className="danger-btn ms-2">
                        <button type="submit" className="btn" onClick={handleReject} disabled={disable}>
                            Reject
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RejectedAtModal;