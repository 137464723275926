import React, { useEffect, useState } from "react";
import { useFeedsColumnsVendor } from "../../../utils/helpers/tableHelpers/useFeedsColumnsVendor";
import { useFeedsColumnContracts } from "../../../utils/helpers/tableHelpers/useFeedColumnContracts";
import { useFeedsColumnBills } from "../../../utils/helpers/tableHelpers/useFeedColumnBills";
import DashboardCard from "../../common/DashboardCard";
import { VENDOR_VP_PENDING } from "../../../utils/constant";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";
import { useFeedsColumnIndividualBills } from "../../../utils/helpers/tableHelpers/useFeedColumnIndividualBill";
import { fetchBillList, fetchIndividualBillList, fetchPurchaseContractList, fetchVendorList } from "../../../utils/helpers/globalMethod";
import PieChart from "../../charts/piechart/PieChart";
import GroupedBarChart from "../../charts/barchart/GroupedBarChart";
import { getApi } from "../../../utils/services";
import { AP_AGING, PENDING_REPORTS } from "../../../utils/apiRoutes";

const VpDashboard = () => {
  const [vendorPageNumber, setVendorPageNumber] = useState(1);
  const [purchaseContractPageNumber, setPurchaseContractPageNumber] = useState(1);
  const [billPageNumber, setBillPageNumber] = useState(1);
  const [individualBillPageNumber, setIndividualBillPageNumber] = useState(1);

  const [totalPagesVendor, setTotalPagesVendor] = useState(1);
  const [totalPagesPurchase, setTotalPagesPurchase] = useState(1)
  const [totalPagesBill, setTotalPagesBill] = useState(1);
  const [totalPagesIndividualBill, setTotalPagesIndividualBill] = useState(1);

  const [pageSize] = useState(5);
  const [vendors, setVendor] = useState([]);
  const [purchaseContract, setPurchaseContract] = useState([]);
  const [bills, setBills] = useState([]);
  const [individualBill, setIndividualBills] = useState([]);
  const [loader, setLoader] = useState(false);
  const [reportsPieChart, setReportsPieChart] = useState();
  const [label, setLabel] = useState();
  const [totalAmount, setTotalAmount] = useState([]);
  const [agingBuckets, setAgingBuckets] = useState([]);
  const user = JSON.parse(localStorage.getItem("userData"));


  const handleVendorList = async () => {
    await fetchVendorList(vendorPageNumber, pageSize, VENDOR_VP_PENDING, setLoader, setVendor, setTotalPagesVendor);
  };

  const handlerPurchaseContractList = async () => {
    fetchPurchaseContractList(pageSize, purchaseContractPageNumber, VENDOR_VP_PENDING, setPurchaseContract, setLoader, setTotalPagesPurchase);
  }

  const handlerBillList = async () => {
    fetchBillList(pageSize, billPageNumber, VENDOR_VP_PENDING, setBills, setLoader, setTotalPagesBill, user);
  }

  const handlerIndividualBillList = async () => {
    fetchIndividualBillList(pageSize, individualBillPageNumber, VENDOR_VP_PENDING, setIndividualBills, setLoader, setTotalPagesIndividualBill, user);
  }

  const columns = useFeedsColumnsVendor(handleVendorList);
  const purchasecolumns = useFeedsColumnContracts(handlerPurchaseContractList)
  const billcolumns = useFeedsColumnBills(handlerBillList)
  const individualbillcolumns = useFeedsColumnIndividualBills(handlerIndividualBillList)

  useEffect(() => {
    handlerReportsPieChart();
    handlerApAging()
  }, []);

  useEffect(() => {
    handleVendorList();
    handlerPurchaseContractList();
    handlerBillList();
    handlerIndividualBillList();

  }, [vendorPageNumber, purchaseContractPageNumber, billPageNumber, individualBillPageNumber]);

  const handlerReportsPieChart = async () => {
    try {
      setLoader(true)
      let result = await getApi(PENDING_REPORTS);
      let data = result.res.series;
      let label = result.res.labels;
      setLoader(false)
      setReportsPieChart(data)
      setLabel(label)
    } catch (error) {
      console.log(error);
    }
  }

  const handlerApAging = async () => {
    try {
      setLoader(true)
      let result = await getApi(AP_AGING);
      const { agingBuckets, totalAmount } = result.res
      let seriesData = [{
        name: 'Aging',
        data: totalAmount
      }];
      setLoader(false)
      setTotalAmount(seriesData)
      setAgingBuckets(agingBuckets)
    } catch (error) {
      console.log(error);
    }
  }

  const handleVendorPageChange = (page) => {
    setVendorPageNumber(page);
  };

  const handlePurchaseContractPageChange = (page) => {
    setPurchaseContractPageNumber(page);
  };

  const handleBillPageChange = (page) => {
    setBillPageNumber(page);
  };

  const handleIndividualBillPageChange = (page) => {
    setIndividualBillPageNumber(page);
  };

  return (
    <>
      <Helmet>
        <title>Vendor Portal || VP Dashboard</title>
      </Helmet>

      <Loader isLoading={loader} />
      <>
        <div className='col-12 mb-2'>
        </div>
        <div className='col-12 mb-2'>
          <div className="row g-2">
            <div className="col-12 col-lg-6">
              <div className='container-section mb-0'>
                <div className="card-body  min-card-body-height">
                  <h5>Approval Queue</h5>
                  <PieChart
                    // title='Approval Queue'
                    data={reportsPieChart}
                    label={label} />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className='container-section mb-0'>
                <div className="card-body  min-card-body-height">
                  <h5>AP Aging</h5>
                  <GroupedBarChart
                    // title='AP Aging'
                    categories={agingBuckets}
                    data={totalAmount}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <DashboardCard
          title={"Vendors"}
          columns={columns}
          data={vendors}
          vendorPageNumber={vendorPageNumber}
          setVendorPageNumber={setVendorPageNumber}
          totalPagesVendor={totalPagesVendor}
          handleVendorPageChange={handleVendorPageChange}
          handleVendorList={handleVendorList}
          pageSize={pageSize}
          mt="m-0"
          fn=""
        />

        <DashboardCard
          title={"Purchase Contracts"}
          columns={purchasecolumns}
          data={purchaseContract}
          handlerPurchaseContractList={handlerPurchaseContractList}
          purchaseContractPageNumber={purchaseContractPageNumber}
          setPurchaseContractPageNumber={setPurchaseContractPageNumber}
          totalPagesPurchase={totalPagesPurchase}
          handlePurchaseContractPageChange={handlePurchaseContractPageChange}
          pageSize={pageSize}
          mt="mt-4"
          fn=""
          tableId="contracts"
          approvalType="contract"
        />

        <DashboardCard
          title={"Bills"}
          columns={billcolumns}
          data={bills}
          handlerBillList={handlerBillList}
          billPageNumber={billPageNumber}
          setBillPageNumber={setBillPageNumber}
          totalPagesBill={totalPagesBill}
          handleBillPageChange={handleBillPageChange}
          pageSize={pageSize}
          mt="mt-4"
          fn=""
        />

        <DashboardCard
          title={"Standalone Bill"}
          columns={individualbillcolumns}
          data={individualBill}
          mt="mt-4"
          fn=""
          tableId="invoices"
          handlerIndividualBillList={handlerIndividualBillList}
          approvalType="invoice"
          individualBillPageNumber={individualBillPageNumber}
          setIndividualBillPageNumber={setIndividualBillPageNumber}
          totalPagesIndividualBill={totalPagesIndividualBill}
          handleIndividualBillPageChange={handleIndividualBillPageChange}
          pageSize={pageSize}
        />
      </>

    </>
  );
};

export default VpDashboard;













