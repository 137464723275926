export const CHARACTERS = "ABCDEFGHIJKLMNOPQRSTUWXYZabcdefghijklmsuvwxyz1234567890!@#$%^&*()";
export const EMAIL_ERROR = "Please enter email";
export const EMAIL_VAILD_ERROR = "Please enter a valid email address !";
export const USER_TYPE_ERROR = "Please select any user type !";
export const USER_NAME_ERROR = "Please enter username !";
export const PASSWORD_ERROR = "Please enter password !";
export const CAPTCHA_ERROR = "Please enter Captcha !";
export const CAPTCHA_VERIFICATION_ERROR = "Invalid captcha !";
export const CURRENT_PASSWORD_ERROR = "Enter current password";
export const NEW_PASSWORD_ERROR = "Enter new password";
export const PASSWORD_LENGTH_ERROR = "New password must have at least 8 characters !";
export const PASSWORD_POLICY_ERROR = "New password does not match with password policy !";
export const CONFIRM_PASSWORD_ERROR = "Enter confirm password";
export const PASSWORD_NOT_MATCH_ERROR = "Confirm password does not match with new password !";
export const OTP_ERROR = "Please enter OTP !";
export const OTP_LENGTH_ERROR = "OTP should have 6 digits !";
export const VENDOR_ERROR = "Select Vendor";
export const START_DATE_ERROR = "Select Start Date";
export const END_DATE_ERROR = "Select End Date";
export const MIN_AMOUNT_ERROR = "Total Amount can't be zero !";
export const UPLOAD_DOCUMENT_ERROR = "Please upload a document !";
export const UPLOAD_MAX_FILE_ERROR = "Only 5 files are allowed !";
export const UPLOAD_PDF_ERROR = "Only PDF is allowed !";
export const UPLOAD_MAX_SIZE_ERROR = "Maximum allowed file size is 2 MB !";
export const ITEM_ERROR = "Select Item";
export const RATE_ERROR = "Enter Rate";
export const TEXT_LENGTH = "Only 200 characters allowed";
export const MIN_RATE_ERROR = "Rate can't be zero";
export const QUANTITY_ERROR = "Enter Quantity";
export const MIN_QUANTITY_ERROR = "Quantity can't be zero";
export const FULLY_PAID_MESSAGE = "This Purchase Contract is Fully Billed";
export const PENDING_DETAILS_MESSAGE = "Details are pending for approval";
export const HOLD_BILL_MESSAGE = "This bill is on hold";
export const BILL_DATE_ERROR = "Select Bill Date";
export const SUBTOTAL_ERROR = "Subtotal cannot be zero !";
export const GST_FIELD_REQUIRED_ERROR = "One of the GST Field is required!";
export const CGST_ERROR = "Enter CGST";
export const SGST_ERROR = "Enter SGST";
export const UTGST_ERROR = "Enter UTGST"
export const IGST_ERROR = "Enter IGST";
export const SALES_TAX_ERROR = "Sales Tax is required!";
export const DEPARTMENT_ERROR = "Select Department";
export const GST_FIELD_REQUIRED_QUANTITY = "One of the GST field is required if quantity is there";
export const QUANTITY_REQUIRED_FOR_GST = "Quantity is required if any GST field is there";
export const CGST_SGST_BOTH_REQURED = "Enter both CGST and SGST if one is there";
export const PENDING_APPROVAL_DETAIL = "Details are pending for approval";
export const TDS_ERROR = "Enter TDS Amount !";
export const ALL_REQUIRED_FIELDS = "please enter all field";
export const RATE_QUANTITY_MIN_ERROR = "Rate, Quantity, Amount, Field must be greater then zero";
export const RATE_QUANTITY_MUST_NUMERIC = "Rate, Quantity must be number";
export const GST_MIN_ERROR = " gst must be greater then zero !";
export const SGST_ALERT_ERROR = "please enter sgst !";
export const CGST_ALERT_ERROR = "please enter cgst !"
export const DESCRIPTION_ERROR = "Enter Description";
// export const BATCH_NUMBER_ERROR = "Enter Batch Number";
export const PAYMENT_DATE_ERROR = "Select Payment Date";
export const EXTERNAL_ID_ERROR = "Enter External Id";
export const DUPLICATE_EXTERNAL_ID_ERROR = "Enter External Id";
export const ALPHABETIC_ONLY_ERROR = "Only alphabetic keywords allowed !";
export const PAYMENT_MODE_ERROR = "Select Payment Mode";
export const ACCOUNT_NUMBER_LENGTH_ERROR = "Account number should have 5-25 digits";
export const IFSC_CODE_LENGTH_ERROR = "IFSC Code should have 11 digits";
export const ONE_TIME_PASSWORD_ERROR = "Please enter one time password";
export const VALID_ONE_TIME_PASSWORD_ERROR = "Please enter valid one time password";
export const ITEM_NAME_ERROR = "Enter Item Name";
export const DUPLICATE_ITEM_NAME_ERROR = "Duplicate Item Name";
export const INVALID_ITEM_NAME_ERROR = "Invalid Item  Name";
export const LEGAL_NAME_ERROR = "Enter Legal Name";
export const SUBSIDIARY_ERROR = "Select Subsidiary";
export const CURRENCY_ERROR = "Select Currency";
export const PAYMENT_TERMS_ERROR = "Select Payment Terms";
export const ENTER_EMAIL_ERROR = "Enter Email";
export const VALID_FORMAT_ERROR = "Enter valid format";
export const ADDRESS_LINE1_ERROR = "Enter Address Line1";
export const COUNTRY_ERROR = "Select Country";
export const STATE_ERROR = "Select State";
export const CITY_ERROR = "Select City";
export const PINCODE_ERROR = "Enter Pincode";
export const INVALID_PINCODE_ERROR = "Invalid Pincode";
export const ALLOWED_IMAGE_TYPES = "Only PNG,JPEG,JPG files are allowed !";
export const FIRST_NAME_ERROR = "Enter First Name";
export const LAST_NAME_ERROR = "Enter Last Name";
export const INVALID_PHONE_ERROR = "Invalid Phone Number";
export const TERMS_ERROR = "Select Terms";
export const MIN_RATE_QUATITY_AND_ALL_REQUIRED_FIELDS_ERROR = "Please enter all required fields, and make sure rate and quantity are greater then zero.";
export const RATE_QUANTITY_VALID_ERROR = "Rate and quantity must be valid numbers.";
export const ENTER_VENDOR_NAME_ERROR = "Enter Vendor Name";
