import React, { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { ModalContext } from '../../utils/Context';
import { postApi } from '../../utils/services';
import { VENDOR_DETAILS_REJECT } from '../../utils/apiRoutes';
import { useLocation, useNavigate } from 'react-router-dom';

const RejectVendor = ({ data, handleVendorList, pageNumber, pageSize, globalSearch }) => {
  const [disable, setDisable] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [error, setError] = useState("");
  const modalContext = useContext(ModalContext);
  const { closeModal } = modalContext;
  const navigate = useNavigate();
  const location = useLocation();

  const handleReject = async () => {
    try {
      if (!rejectReason) {
        setError("Please provide a rejection reason!");
        return;
      }

      setDisable(true);
      const result = await postApi(VENDOR_DETAILS_REJECT, {
        "vendorId": data.id,
        "rejectionReason": rejectReason,
        "selfService": data.selfService ? data.selfService : null
      });

      handleVendorList(pageNumber, pageSize, globalSearch);
      closeModal();
      const currentPath = location.pathname;
      toast.success(result.res.message);
      if (currentPath === "/") {
        navigate("/");
      } else if (currentPath.includes("vendors")) {
        navigate("/vendors");
      } else {
        navigate(-1);
      }
    } catch (error) {

    } finally {
      setDisable(false);
    }
  };

  const handleChangeRejectReason = (e) => {
    setRejectReason(e.target.value);
    setError("");
  };

  return (
    <div className='cstm-frm-mdl'>
      <div className="modal-header">
        <h4 className="modal-title">Rejection Reason</h4>
      </div>
      <div className="modal-body">
        <div className="form-group mt-2">
          <label htmlFor="" className='form-label'>Are you sure you want to reject this Vendor?</label>
          <textarea
            value={rejectReason}
            className="form-control reject-modal-text-area"
            placeholder="Enter the reason here..."
            id="floatingTextarea"
            rows={3}
            maxLength={100}
            onChange={handleChangeRejectReason}
          />
        </div>
        {error && <p className="validation-error">{error}</p>}
      </div>

      <div className="modal-footer">
        <div className="d-flex align-items-center mb-20">
          <div className="danger-outline-btn">
            <button type="button" className="btn" onClick={() => closeModal()}>Cancel</button>
          </div>
          <div className="danger-btn ms-2">
            <button
              type="button"
              className="btn"
              onClick={handleReject}
              disabled={disable}
            >
              Reject
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectVendor;
