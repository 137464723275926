//------- Table header Name------//
export const VENDOR_NAME = "Vendor Name";
export const PURCHASE_CONTRACT = "Purchase Contract";
export const START_DATE = "Start Date";
export const END_DATE = "End Date";
export const CONTRACT_TOTAL =" Contract Total";
export const STATUSNAME = "Status";
export const BILL_NUMBER = "Bill Number";
export const GRAND_TOTAL = "Grand Total";
export const TDS_AMOUNT = "TDS Amount";
export const CREATE_DATE = "Created Date";
export const VENDOR_ID = "Vendor ID";
export const PRIMARY_EMAIL_ID = "Primary Email ID";
export const STATE = "State";
export const  COUNTRY = "Country";
export const DUE_DATE = "Due Date";
export const PAYMENT_AMOUNT = "Payment Amount";
export const TOTAL_PAID_AMOUNT = "Total Paid Amount";
export const SERIAL_NUMBER = "#";
export const ROLE_NAME = "Role Name";
export const ITEM_NAME = "Item Name";
export const DESCRIPTION = "Description";
export const EXTERNAL_SYSTEM_ID = "External System Id";





