import React from 'react'

function BackButton() {
  return (
    <>
      <div className='me-2'>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="auto"
          viewBox="0 0 28 17"
          fill="none"
        >
          <path
            d="M0.521484 8.70039L10.4531 0.400391V17.0004L0.521484 8.70039Z"
            fill="#232940"
          />
          <path
            d="M8.3252 5.86328H27.479V11.5385H8.3252V5.86328Z"
            fill="#232940"
          />
        </svg>
      </div>
    </>
  )
}

export default BackButton