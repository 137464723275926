import { useState } from 'react';
import { EMAIL_REGX, WEBSITE_REGX, TEXT_ONLY_REGX } from '../utils/helpers/globalRegx';
import { ACCOUNT_NUMBER_LENGTH_ERROR, ADDRESS_LINE1_ERROR, ALLOWED_IMAGE_TYPES, ALPHABETIC_ONLY_ERROR, CITY_ERROR, COUNTRY_ERROR, CURRENCY_ERROR, ENTER_EMAIL_ERROR, FIRST_NAME_ERROR, IFSC_CODE_LENGTH_ERROR, INVALID_PHONE_ERROR, INVALID_PINCODE_ERROR, LAST_NAME_ERROR, LEGAL_NAME_ERROR, PAYMENT_MODE_ERROR, PINCODE_ERROR, STATE_ERROR, SUBSIDIARY_ERROR, TERMS_ERROR, UPLOAD_DOCUMENT_ERROR, UPLOAD_MAX_SIZE_ERROR, UPLOAD_PDF_ERROR, VALID_FORMAT_ERROR } from '../utils/helpers/globalErrorMessage';
import { IMAGES_TYPE, PDF_TYPE } from '../utils/helpers/globalMethod';

const EditProfileValidation = () => {
    const [errors, setErrors] = useState({});

    const isValidLogoType = (file) => {
        return IMAGES_TYPE.includes(file.type);
    };

    const isValidBankDocument = (file) => {
        return PDF_TYPE.includes(file.type);
    };

    const MAX_FILE_SIZE = 2 * 1024 * 1024;

    const validateForm = (vendor, subsidiary, currency, terms, pEmail, file, website, addressLine1, country, state, city, pincode, rows, paymentModeId, inputValues, bankDocumentObject, bankDocument) => {

        let errors = {};
        errors.rows = []
        let formIsValid = true;

        if (!vendor.trim()) {
            formIsValid = false;
            errors.vendor = LEGAL_NAME_ERROR;
        }
        if (!subsidiary) {
            formIsValid = false;
            errors.subsidiary = SUBSIDIARY_ERROR;
        }
        if (!currency) {
            formIsValid = false;
            errors.currency = CURRENCY_ERROR;
        }
        if (!terms) {
            formIsValid = false;
            errors.terms = TERMS_ERROR;
        }
        if (!pEmail) {
            formIsValid = false;
            errors.pEmail = ENTER_EMAIL_ERROR;
        }
        else {
            if (!EMAIL_REGX.test(pEmail)) {
                formIsValid = false;
                errors.pEmail = VALID_FORMAT_ERROR;
            }
        }
        if (website) {
            if (!WEBSITE_REGX.test(website)) {
                formIsValid = false;
                errors.website = VALID_FORMAT_ERROR;
            }
        }
        if (!addressLine1.trim()) {
            formIsValid = false;
            errors.addressLine1 = ADDRESS_LINE1_ERROR;
        }
        if (!country) {
            formIsValid = false;
            errors.country = COUNTRY_ERROR;
        }
        if (!state) {
            formIsValid = false;
            errors.state = STATE_ERROR;
        }
        if (!city) {
            formIsValid = false;
            errors.city = CITY_ERROR;
        }
        if (!pincode) {
            formIsValid = false;
            errors.pincode = PINCODE_ERROR;
        }
        else {
            if (pincode.length < 6) {
                formIsValid = false;
                errors.pincode = INVALID_PINCODE_ERROR;
            }
        }
        if (file) {
            for (const item of file) {
                if (!isValidLogoType(item)) {
                    formIsValid = false;
                    errors.file = ALLOWED_IMAGE_TYPES;
                    break;
                }
                if (item.size > MAX_FILE_SIZE) {
                    formIsValid = false;
                    errors.file = UPLOAD_MAX_SIZE_ERROR;
                    break;
                }
            }
        }
        // if (relevantDoc.length === 0) {
        //     formIsValid = false;
        //     errors.relevantDoc = "Please upload a document !";
        // }
        // else {
        //     for (const item of relevantDoc) {
        //         if (!isValidRelevantDoc(item)) {
        //             formIsValid = false;
        //             errors.relevantDoc = "Only PDF is allowed !";
        //             break;
        //         }
        //         if (item.size > MAX_FILE_SIZE) {
        //             formIsValid = false;
        //             errors.relevantDoc = "Maximum allowed file size is 2MB";
        //             break;
        //         }
        //     }
        // }
        rows.forEach((item, index) => {
            const rowsErrors = {};

            if (!item.firstName.trim()) {
                formIsValid = false;
                rowsErrors.firstName = FIRST_NAME_ERROR;
            }
            if (!item.lastName.trim()) {
                formIsValid = false;
                rowsErrors.lastName = LAST_NAME_ERROR;
            }
            if (!item.email) {
                formIsValid = false;
                rowsErrors.email = ENTER_EMAIL_ERROR;
            }
            else {
                if (!EMAIL_REGX.test(item.email)) {
                    formIsValid = false;
                    rowsErrors.email = VALID_FORMAT_ERROR;
                }
            }
            if (item.phoneNo && item.phoneNo.length < 10) {
                formIsValid = false;
                rowsErrors.phoneNo = INVALID_PHONE_ERROR;
            }
            if (Object.keys(rowsErrors).length > 0) {
                errors.rows[index] = rowsErrors;
            }
            return rowsErrors;
        });

        for (const fieldName in inputValues) {
            if (inputValues.hasOwnProperty(fieldName)) {
                const { fieldValue } = inputValues[fieldName];

                if (!fieldValue.trim()) {
                    formIsValid = false;
                    errors[fieldName] = `Enter ${fieldName}`;
                } else {
                    switch (fieldName) {
                        case 'Bank Name':
                            if (!TEXT_ONLY_REGX.test(fieldValue)) {
                                formIsValid = false;
                                errors[fieldName] = ALPHABETIC_ONLY_ERROR;
                            }
                            break;
                        case 'Account Number':
                            if (fieldValue.length < 5 || fieldValue.length > 25) {
                                formIsValid = false;
                                errors[fieldName] = ACCOUNT_NUMBER_LENGTH_ERROR;
                            }
                            break;
                        case 'IFSC Code':
                            if (fieldValue.length !== 11) {
                                formIsValid = false;
                                errors[fieldName] = IFSC_CODE_LENGTH_ERROR;
                            }
                            break;
                        default:
                            break;
                    }
                }
            }

        }
        if (!paymentModeId) {
            formIsValid = false;
            errors.paymentModeId = PAYMENT_MODE_ERROR;
        }
        if (paymentModeId === 1) {
            if (!bankDocumentObject && bankDocument.length === 0) {
                formIsValid = false;
                errors.bankDocument = UPLOAD_DOCUMENT_ERROR;
            }
            else if (!bankDocumentObject?.BankDocumentName && bankDocument.length === 0) {
                formIsValid = false;
                errors.bankDocument = UPLOAD_DOCUMENT_ERROR;
            } else if (bankDocument.length > 0) {
                if (bankDocument) {
                    for (const item of bankDocument) {
                        if (!isValidBankDocument(item)) {
                            formIsValid = false;
                            errors.bankDocument = UPLOAD_PDF_ERROR;
                        }
                        if (item.size > MAX_FILE_SIZE) {
                            formIsValid = false;
                            errors.bankDocument = UPLOAD_MAX_SIZE_ERROR;
                            break;
                        }
                    }
                }
            }
        }
        setErrors(errors);
        return formIsValid;
    };

    return { errors, setErrors, validateForm };
};

export default EditProfileValidation;
