import ReactApexChart from "react-apexcharts";
import { THOUSAND_VALUE } from "../../../utils/constant";
function PieDonutChart({ title, data = [], label = [] }) {
    const chartData = {
        series: data,
        options: {
            chart: {
                type: 'donut',
            },
            title: {
                show: true,
                text: title,
                height: 350
            },
            colors: ['#5543b3', '#a6f5c5', '#FF7600', "#FF9D00", '#3498db', '#e74c3c', '#2ecc71', '#9b59b6', '#f1c40f', '#1abc9c', '#e67e22', '#16a085', '#f39c12', '#d35400', '#7f8c8d', '#95a5a6', '#27ae60', '#8e44ad', '#2980b9', '#34495e'],

            labels: label,
            yaxis: [{
                title: {
                    text: 'Amount',
                },
                labels: {
                    show: true,
                    formatter: function (val) {
                        return val + THOUSAND_VALUE;
                    }
                }
            }],

            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
        }
    }
    return (
        <div id="PieDonutChart">
            <ReactApexChart options={chartData.options} series={chartData.series} type="donut" height={352}></ReactApexChart>
        </div>
    )
}
export default PieDonutChart

