import { useState } from 'react';
import { validateBankInfo } from '../utils/helpers/globalValidation';
import { validateEditBankDocument } from '../utils/helpers/globalValidation';
import { PAYMENT_MODE_ERROR } from '../utils/helpers/globalErrorMessage';

const EditBankInfoValidation = () => {
    const [errors, setErrors] = useState({});

    const validateForm = (paymentModeId, inputValues, bankDocumentObject, bankDocument) => {
        let { formIsValid, errors: bankInfoErrors } = validateBankInfo(inputValues);

        if (!paymentModeId) {
            formIsValid = false;
            bankInfoErrors.paymentModeId = PAYMENT_MODE_ERROR;
        }

        if (paymentModeId === 1) {
            const bankDocumentValidation = validateEditBankDocument(bankDocument, bankDocumentObject);
            formIsValid = formIsValid && bankDocumentValidation.formIsValid;
            bankInfoErrors = { ...bankInfoErrors, ...bankDocumentValidation.errors };
        }

        setErrors(bankInfoErrors);

        return formIsValid;
    };

    return { errors, setErrors, validateForm };
};

export default EditBankInfoValidation;
