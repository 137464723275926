import { useState } from 'react';
import { validateUseName, validateCaptcha, validatePassword, validateUserType } from '../utils/helpers/globalValidation';

const LoginFormValidation = () => {
  const [errors, setErrors] = useState({});

  const validateForm = (loginType, loginData, captcha, captchaInput) => {
    const _errors = {};

    const roleTypeError = validateUserType(loginType);
    const userNameError = validateUseName(loginData.email);
    const passwordError = validatePassword(loginData.password);
    const captchaError = validateCaptcha(captcha, captchaInput);

    if (roleTypeError) {
      _errors.logintype = roleTypeError;
    }
    else if (userNameError) {
      _errors.email = userNameError;
    }
    else if (passwordError) {
      _errors.password = passwordError;
    }
    else if (captchaError) {
      _errors.captcha = captchaError;
    }
    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  };

  return { errors, setErrors, validateForm };
};

export default LoginFormValidation;