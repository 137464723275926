import React, { useContext, useState } from "react";
import { ModalContext } from "../../utils/Context";
import { postApi } from "../../utils/services";
import { HOLD_Bill, VOID_BILL } from "../../utils/apiRoutes";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { HOLD_STATUS_ID, VOID_STATUS_ID } from "../../utils/constant";
import Alert from "./Alert";

const HoldReleaseVoidPage = ({ data, type }) => {
  const modalContext = useContext(ModalContext);
  const { handleModalData } = modalContext;
  const { closeModal } = modalContext;
  const [rejectReason, setRejectReason] = useState("");
  const [holdReason, setHoldReason] = useState("");
  const [releaseReason, setReleaseReason] = useState("");
  const [error, setError] = useState("");
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();

  const handleHoldRelease = async () => {
    if (type === "hold" && !holdReason) {
      setError("Please provide a hold reason!");
      return;
    }
    if (type === "release" && !releaseReason) {
      setError("Please provide a release reason!");
      return;
    }

    setDisable(true);

    try {
      const result = await postApi(HOLD_Bill, {
        billId: Number(data.id),
        billStatusId: type === "hold" ? HOLD_STATUS_ID : null,
        holdReason: type === "hold" ? holdReason : null,
        releaseReason: type === "release" ? releaseReason : null,
      });

      if (result.res.success) {
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: result.res.message,
          showConfirmButton: false,
          timer: 2000,
        });
        closeModal();
        setTimeout(() => {
          if (data.IndividualBill) {
            navigate("/individual-bill-list");
          } else {
            navigate("/invoice-listing");
          }
        }, 2000);
      } else {
        if (result.res.fullyPaidMessage || result.res.voidMessage) {
          const alertMessage = result.res.fullyPaidMessage || result.res.voidMessage;
          const alert = <Alert message={alertMessage} />;
          handleModalData(alert, "md");
        }
        if (result.res.message) {
          toast.error(result.res.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleVoidBill = async () => {
    if (!rejectReason) {
      setError("Please provide a void reason!");
      return;
    }
    setDisable(true);
    try {
      const result = await postApi(VOID_BILL, {
        billId: data.id,
        voidReason: rejectReason,
        billStatusId: VOID_STATUS_ID,
      });
      if (result.res.success) {
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: result.res.message,
          showConfirmButton: false,
          timer: 2000,
        });
        closeModal();
        setTimeout(() => {
          if (data.IndividualBill) {
            navigate("/individual-bill-list");
          } else {
            navigate("/invoice-listing");
          }
        }, 2000);
      } else {
        if (result.res.fullyPaidMessage || result.res.partiallyPaidMessage) {
          const alertMessage =
            result.res.fullyPaidMessage || result.res.partiallyPaidMessage;
          const alert = <Alert message={alertMessage} />;
          handleModalData(alert, "md");
        }
        if (result.res.message) {
          toast.error(result.res.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleChangeReason = (e, type) => {
    if (type === "hold") {
      setHoldReason(e.target.value);
    } else if (type === "release") {
      setReleaseReason(e.target.value);
    } else {
      setRejectReason(e.target.value);
    }
    setError("");
  };

  return (
    <div className="cstm-frm-mdl custom-cnfm-mdl">
      {type === VOID_STATUS_ID || type === "hold" || type === "release" ? (
        <>
          {type === VOID_STATUS_ID && (
            <div className="modal-header">
              <h4 className="modal-title">Void Rejection</h4>
            </div>
          )}
          <div className="">
            {type === VOID_STATUS_ID ? (
              <>
                <div className="modal-body-container">
                  <label htmlFor="" className="form-label">
                    Are you sure you want to void this{" "}
                    {data.IndividualBill ? "Standalone Bill" : "Bill"}?
                  </label>
                  <textarea
                    value={rejectReason}
                    className="form-control reject-modal-text-area"
                    placeholder="Enter the reason here..."
                    rows={3}
                    maxLength={100}
                    onChange={(e) => handleChangeReason(e, "void")}
                  ></textarea>
                  {error && <p className="validation-error">{error}</p>}
                </div>
              </>
            ) : type === "hold" ? (
              <>
                {type === "hold" && (
                  <div className="modal-header">
                    <h4 className="modal-title">Hold Bill</h4>
                  </div>
                )}
                <div className="modal-body-container">
                  <label htmlFor="" className="form-label">
                    Are you sure you want to hold this{" "}
                    {data.IndividualBill ? "Standalone Bill" : "Bill"}?
                  </label>
                  <textarea
                    value={holdReason}
                    className="form-control reject-modal-text-area"
                    placeholder="Enter the reason here..."
                    rows={3}
                    maxLength={100}
                    onChange={(e) => handleChangeReason(e, "hold")}
                  ></textarea>
                  {error && <p className="validation-error">{error}</p>}
                </div>
              </>
            ) : type === "release" ? (
              <>
                {type === "release" && (
                  <div className="modal-header">
                    <h4 className="modal-title">Release Bill</h4>
                  </div>
                )}
                <div className="modal-body-container">
                  <label htmlFor="" className="form-label">
                    Are you sure you want to release this{" "}
                    {data.IndividualBill ? "Standalone Bill" : "Bill"}?
                  </label>
                  <textarea
                    value={releaseReason}
                    className="form-control reject-modal-text-area"
                    placeholder="Enter the reason here..."
                    rows={3}
                    maxLength={100}
                    onChange={(e) => handleChangeReason(e, "release")}
                  ></textarea>
                  {error && <p className="validation-error">{error}</p>}
                </div>
              </>
            ) : null}
          </div>
          <div
            className={`modal-footer  d-flex align-items-center gap-2 mb-20 justify-content-end`}   
          >
            <div className="danger-outline-btn">
              <button type="button" className="btn" onClick={closeModal}>
                Cancel
              </button>
            </div>
            <div
              className={`${data.BillStatusId === HOLD_STATUS_ID && type !== VOID_STATUS_ID
                ? "success-btn"
                : data.BillStatusId !== HOLD_STATUS_ID &&
                  type !== VOID_STATUS_ID
                  ? "hold-btn"
                  : "danger-btn"
                }`}
            >
              <button
                type="submit"
                className="btn"
                onClick={
                  type === VOID_STATUS_ID
                    ? handleVoidBill
                    : handleHoldRelease
                }
                disabled={disable}
              >
                {type === VOID_STATUS_ID
                  ? "Void"
                  : type === "hold"
                    ? "Hold"
                    : "Release"}
              </button>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default HoldReleaseVoidPage;
