import { useState } from 'react';
import { validateBillDate, validateDepartmentId, validateIndividualGSTFields, validateItemDescription, validateItemDescriptionLineLevel, validateSubTotal, validateUploadDocuments } from '../utils/helpers/globalValidation';

const EditIndividuallBillValidation = () => {
    const [errors, setErrors] = useState({});

    const validateForm = (roleId, departmentId, billDate, itemDescription, subTotal, cgst, sgst, utgst, igst, lineLevelCheck, oldFiles, individualBillDocument) => {
        let errors = {};
        let formIsValid = true;

        const departmentError = validateDepartmentId(roleId, departmentId);
        if (departmentError) {
            formIsValid = false;
            errors.departmentId = departmentError;
        }

        const billDateError = validateBillDate(billDate);
        if (billDateError) {
            formIsValid = false;
            errors.billDate = billDateError;
        }

        if (!lineLevelCheck) {
            const itemErrors = validateItemDescription(itemDescription);
            if (itemErrors) {
                formIsValid = false;
                Object.assign(errors, itemErrors);
            }
        } else {
            const lineItemErrors = validateItemDescriptionLineLevel(itemDescription);
            if (lineItemErrors) {
                formIsValid = false;
                Object.assign(errors, lineItemErrors);
            }
        }

        const subtotalError = validateSubTotal(subTotal);
        if (subtotalError) {
            formIsValid = false;
            errors.subTotal = subtotalError;
        }

        if (!lineLevelCheck) {
            const gstErrors = validateIndividualGSTFields(cgst, sgst, utgst, igst);
            if (!gstErrors.formIsValid) {
                formIsValid = false;
                Object.assign(errors, gstErrors.errors);
            }
        }

        const documentError = validateUploadDocuments(oldFiles, individualBillDocument);
        formIsValid = formIsValid && documentError.formIsValid;
        errors.files = documentError.errors.files;

        setErrors(errors);
        return formIsValid;
    };

    return { errors, setErrors, validateForm };
};

export default EditIndividuallBillValidation;

