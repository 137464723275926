import React, { useEffect, useState } from "react";
import { uploadFile } from "../../../utils/helpers/useFileUpload";
import { getApi, postApi } from "../../../utils/services";
import { ADD_VENDOR, BANK_DETAILS_LIST, PAYMENT_MODE_LIST } from "../../../utils/apiRoutes";
import { useTransactionContext } from "../../../utils/helpers/useTransaction";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import AddFinanceInfoValidation from "../../../middleware/addFinanceInfoValidation";

const AddFinanceInfo = ({ activeStep, steps, setActiveStep, vendorDetails, setVendorDetails, setEmailError, setBankInfoDetails, bankinfoDetails, setUpdatedEmail }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const navigate = useNavigate();
    const transactionList = useTransactionContext();
    const [bankDetailsList, setBankDetailsList] = useState([]);
    const [bankDocument, setBankDocument] = useState([]);
    const [submitDisable, setSubmitDisable] = useState(false);
    const [loader, setLoader] = useState(false);
    const [documentFlag, setDocumentFlag] = useState(false);
    const [paymentModeList, setPaymentModeList] = useState([]);
    const [paymentModeId, setPaymentModeId] = useState('');
    const { errors, setErrors, validateForm } = AddFinanceInfoValidation();
    const [inputValues, setInputValues] = useState({});

    useEffect(() => {
        if (vendorDetails && vendorDetails.subsidiary) {
            setDocumentFlag(true);
        } else {
            setDocumentFlag(false);
        }
    }, [vendorDetails]);

    useEffect(() => {
        const handleViewPaymentMode = async () => {
            if (vendorDetails && vendorDetails.subsidiary) {
                let result = await getApi(`${PAYMENT_MODE_LIST}?subsidiaryId=${vendorDetails.subsidiary}`);
                setPaymentModeList(result.res.paymentMode_list);
            }
        };

        handleViewPaymentMode();
    }, [vendorDetails]);

    useEffect(() => {

        const handleBankDetails = async () => {
            if (bankinfoDetails.paymentModeId === paymentModeId) {
                const bankDetailsArray = Object.entries(bankinfoDetails.bankDetails).map(([key, value]) => ({
                    FieldName: key,
                    fieldId: value.fieldId,
                    fieldValue: value.fieldValue,
                }));

                const initialValues = bankDetailsArray.reduce((acc, curr) => {
                    acc[curr.FieldName] = {
                        fieldId: curr.fieldId,
                        fieldValue: curr.fieldValue || '',
                    };
                    return acc;
                }, {});
                setInputValues(initialValues);
                setBankDetailsList(bankDetailsArray);
            } else {
                let selectedPaymentModeId = paymentModeId || bankinfoDetails.paymentModeId;
                if (selectedPaymentModeId) {
                    let result = await getApi(`${BANK_DETAILS_LIST}/${selectedPaymentModeId}`);
                    if (result.res && Array.isArray(result.res.bank_details_list)) {
                        setBankDetailsList(result.res.bank_details_list);
                        const fieldIdToValueMap = bankinfoDetails.bankDetails
                            ? Object.fromEntries(
                                Object.entries(bankinfoDetails.bankDetails).map(([key, value]) => [value.fieldId, value.fieldValue])
                            )
                            : {};

                        const initialValues = result.res.bank_details_list.reduce((acc, curr) => {
                            acc[curr.FieldName] = {
                                fieldId: curr.id,
                                fieldValue: fieldIdToValueMap[curr.id] || '',
                            };
                            return acc;
                        }, {});
                        setInputValues(initialValues);
                    }
                }
            }
        };
        handleBankDetails();
    }, [paymentModeId, bankinfoDetails]);

    useEffect(() => {
        if (bankinfoDetails.paymentModeId && bankinfoDetails.paymentModeId !== paymentModeId) {
            setPaymentModeId(bankinfoDetails.paymentModeId);
        }
    }, [bankinfoDetails]);

    const handleFileChange = (e) => {
        const newfile = e.target.files;
        setBankDocument(Array.from(newfile));

        const updatedErrors = { ...errors };
        delete updatedErrors.bankDocument;

        setErrors(updatedErrors);
    };

    const handlePayMode = (e) => {
        const selectedPaymentModeId = e.target.value;
        setPaymentModeId(selectedPaymentModeId);
        setDocumentFlag(selectedPaymentModeId === "1");

        if (errors.paymentModeId) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                paymentModeId: "",
            }));
        }
        setErrors({});
    };

    const handleInputChange = (fieldName, id, value) => {
        if (fieldName === 'Account Number') {
            // const newVal = value.replace(/[^0-9]/g, '');

            setInputValues((prevValues) => ({
                ...prevValues,
                [fieldName]: { fieldId: id, fieldValue: value },
            }));
        } else {
            setInputValues((prevValues) => ({
                ...prevValues,
                [fieldName]: { fieldId: id, fieldValue: value },
            }));
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: '',
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isValid = validateForm(paymentModeId, inputValues, bankDocument);

        if (isValid) {
            setLoader(true);
            setSubmitDisable(true);

            let bankFile = {};
            if (bankDocument.length <= 0) {
                bankFile = { BankDocumentName: null, BankDocumentPath: null };
            }

            const filePromises = bankDocument.map(async (file) => {
                if (file) {
                    const result = await uploadFile("document", 'finance', 'vendor', file);
                    bankFile = { BankDocumentName: result.fileName, BankDocumentPath: result.filePath };
                }
            });

            await Promise.all(filePromises);

            const updatedValues = Object.entries(inputValues).reduce((acc, [key, value]) => {
                const newKey = key.replace(/\s+/g, '');
                acc[newKey] = value;
                return acc;
            }, {});

            const data = {
                ...vendorDetails,
                "vendorId": user.id,
                "transactionId": transactionList[3].id,
                "roleId": user.RoleId,
                "bankDetails": updatedValues,
                "paymentModeId": paymentModeId ? paymentModeId : null,
                "bankDocument": bankFile
            };

            const result = await postApi(ADD_VENDOR, data);

            if (result.res.success) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: result.res.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                navigate("/vendors");
            }
            if (!result.res.success) {
                setSubmitDisable(false);
                setLoader(false);

                if (result.res.emailIdMessage) {
                    setEmailError({ pEmail: result.res.emailIdMessage });
                    setUpdatedEmail(result.res.pEmail)
                    setActiveStep(activeStep - 1)
                } else {
                    setSubmitDisable(false);
                    setLoader(false);
                }
            }
        }
    };

    const handlePrevious = async () => {
        let bankFile = {};
        if (bankDocument.length <= 0) {
            bankFile = { BankDocumentName: null, BankDocumentPath: null };
        }

        const filePromises = bankDocument.map(async (file) => {
            if (file) {
                const result = await uploadFile("document", 'finance', 'vendor', file);
                bankFile = { BankDocumentName: result.fileName, BankDocumentPath: result.filePath };
            }
        });

        await Promise.all(filePromises);

        const updatedValues = Object.entries(inputValues).reduce((acc, [key, value]) => {
            const newKey = key.replace(/\s+/g, '');
            acc[newKey] = value;
            return acc;
        }, {});

        const data = {
            "vendorId": user.id,
            "transactionId": transactionList[3].id,
            "roleId": user.RoleId,
            "bankDetails": updatedValues,
            "paymentModeId": paymentModeId ? paymentModeId : null,
            "bankDocument": bankFile
        };
        setBankInfoDetails(data);
        setVendorDetails(vendorDetails);
        setActiveStep(activeStep - 1);
    };

    return (
        <>
            <div className="edit-vendor-container mt-4 mb-3">
                <div className="section-header">
                    <h3>Financial Information</h3>
                </div>
                <div className="form-style">
                    <div className="row">
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 mb-20 position-relative">
                            <label htmlFor="paymentMode" className="form-label">Payment Mode</label>
                            <select name="paymentMode" id="paymentMode" className="form-select" value={paymentModeId} onChange={handlePayMode} >
                                <option value="">Select</option>
                                {
                                    paymentModeList?.map((item, index) => (
                                        <option key={index} value={item?.id}>{item?.Name}</option>))
                                }
                            </select>
                        </div>
                        {paymentModeId && bankDetailsList.map((row, index) => (
                            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 mb-20 position-relative" key={index}>
                                <label htmlFor="" className="form-label">{row.FieldName}<span className="required">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={`Enter ${row.FieldName}`}
                                    value={inputValues[row.FieldName]?.fieldValue || ''}
                                    onChange={(e) => handleInputChange(row.FieldName, row.id, e.target.value)}
                                />
                                {errors[row.FieldName] && (
                                    <span className="error-text">{errors[row.FieldName]}</span>
                                )}
                            </div>
                        ))}
                        {paymentModeId == 1 && documentFlag && (
                            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6">
                                <label htmlFor="fileInput" className="form-label">Upload Document<span className="required">*</span></label>
                                <div className="upload-documents-fields">
                                    <div className="input-group file-input-container">
                                        <input
                                            type="file"
                                            id="files"
                                            className="form-control input-fields-cstm"
                                            onChange={handleFileChange}
                                        // multiple
                                        />
                                        {errors.bankDocument && <span className="error-text">{errors.bankDocument}</span>}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="stepper-next-btn">
                {activeStep !== 0 && (
                    <span className="danger-outline-btn me-2">
                        <button
                            onClick={(e) => handlePrevious(e)}
                            className="btn previousstepper"
                        >
                            Previous
                        </button>
                    </span>
                )}
                <div className="success-btn ">
                    <button
                        onClick={(e) => handleSubmit(e)}
                        className="btn"
                        disabled={submitDisable}
                    >
                        {loader ?
                            <>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </>
                            :
                            "Submit"
                        }
                    </button>
                </div>
            </div>
        </>
    );
};

export default AddFinanceInfo;
